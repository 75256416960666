import React, { ChangeEvent, useState } from 'react';
import { Box, Stack, TextField, Typography } from '@mui/material';
import { useFilterBookings } from '../../../providers/FilterBookings';
import PeopleIcon from '@mui/icons-material/People';
import { handlePreventNumber, numberValidation } from '../../../helpers';

export default function RoomCapacityFilter(): JSX.Element {
  const { state, dispatch: dispatchFilter } = useFilterBookings();
  const [errorText, setErrorText] = useState<boolean>(false);

  const handleOnchangeCapacity = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { value } = event.target;

    const isNumber = numberValidation({ value });
    if (value === '' || isNumber) {
      if (Number(value) > 200) {
        setErrorText(true);
        return;
      }

      setErrorText(false);

      dispatchFilter({
        type: 'setParams',
        payload: {
          ...state.filter,
          capacity: Number(value),
        },
      });

      //handleChange(event);
    }
  };

  return (
    <Box sx={{ my: 2 }}>
      <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
        <Box sx={{ width: 160 }}>
          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
            <PeopleIcon color="primary" />
            <Typography variant="body2">Capacity</Typography>
          </Stack>
        </Box>
        <Box>
          <Stack spacing={2}>
            <TextField
              id="outlined-capacity"
              variant="outlined"
              onChange={handleOnchangeCapacity}
              onKeyDown={handlePreventNumber}
              type="number"
              helperText={errorText ? 'Maximum is 200.' : ''}
              error={errorText}
              value={state?.filter?.capacity ? Number(state?.filter?.capacity) : ''}
            />
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
}
