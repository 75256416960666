import React, { useEffect, useMemo, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import {
  EDayOfWeek,
  EOptionRecurrencePatternType,
  ERecurrencePatternType,
} from '../../../middlewares/nura-client/lib/redux/constants';
import DaysOfWeek from '../../DaysOfWeek';
import OnRadioBooking from './OnRadioBooking';
import { useBookingDetails } from '../../../contexts/BookingDetails';
import { REACT_APP_RECURRING_MAX_INTERVAL_MONTHS, monthlyArr, yearlyArr } from '../../../constants/AddIn';
import { addMonths, addWeeks, differenceInDays, format, getDay, setDay, startOfWeek } from 'date-fns';
import { getDayNumber } from '../../../helpers';

interface IOnBookingProps {
  value: string;
}

const OnBooking = ({ value }: IOnBookingProps): JSX.Element => {
  const { state: bookingState, dispatch: bookingDispatch } = useBookingDetails();

  const [selectedDay, setSelectedDay] = useState<Date[]>([new Date()]);

  const handleWeeklyChange = (day: Date) => {
    const daysSelected = selectedDay?.map((dayItem) => dayItem.getDay());
    const isSelected = daysSelected.includes(day.getDay());
    let newDateSelected = [];
    if (isSelected) {
      newDateSelected = selectedDay.filter((filterItem) => filterItem.getDay() !== day.getDay());
      setSelectedDay(newDateSelected);
    } else {
      newDateSelected = [...selectedDay, day];
      setSelectedDay([...selectedDay, day]);
    }
    const newState = bookingState.recurringEvents;
    const selectedDaysOfWeek = newDateSelected?.map((day) => EDayOfWeek[format(day, 'iiii').toUpperCase()]);
    if (selectedDaysOfWeek && ERecurrencePatternType.WEEKLY === value) {
      const currentDate = new Date(newState?.range?.startDate);
      newState.weekly.pattern.daysOfWeek = selectedDaysOfWeek;
      newState.weekly.pattern.dateSelectedOfWeek = newDateSelected;

      if (selectedDaysOfWeek?.length && newState?.range?.startDate) {
        const startOfThisWeek = startOfWeek(new Date(newState.range.startDate));
        const firstSelected = selectedDaysOfWeek[0];
        const numberOfDay = getDayNumber(firstSelected);
        const nthDay = setDay(startOfThisWeek, numberOfDay, { weekStartsOn: getDay(startOfThisWeek) });
        const daysGap = differenceInDays(nthDay, currentDate);
        if (daysGap < 0) {
          const nextWeek = addWeeks(nthDay, 1);
          newState.range.startDate = nextWeek.toDateString();
          newState.range.endDate = addMonths(nextWeek, REACT_APP_RECURRING_MAX_INTERVAL_MONTHS).toDateString();
        } else {
          if (daysGap > 7) {
            const prevWeek = addWeeks(nthDay, -1);
            newState.range.startDate = prevWeek.toDateString();
            newState.range.endDate = addMonths(prevWeek, REACT_APP_RECURRING_MAX_INTERVAL_MONTHS).toDateString();
          } else {
            newState.range.startDate = nthDay.toDateString();
            newState.range.endDate = addMonths(nthDay, REACT_APP_RECURRING_MAX_INTERVAL_MONTHS).toDateString();
          }
        }
      }
      if (selectedDaysOfWeek.length === 0) {
        newState.range.startDate = currentDate.toDateString();
        newState.range.endDate = addMonths(currentDate, REACT_APP_RECURRING_MAX_INTERVAL_MONTHS).toDateString();
      }
      bookingDispatch({
        type: 'setRecurringEvents',
        payload: { recurringEvents: { ...bookingState.recurringEvents, ...newState } },
      });
    }
  };

  useEffect(() => {
    if (EOptionRecurrencePatternType.MONTHLY === value || EOptionRecurrencePatternType.YEARLY === value) {
      setSelectedDay([new Date()]);
    }
    if (ERecurrencePatternType.WEEKLY === value) {
      const currentStartDate = bookingState?.recurringEvents?.range?.startDate;
      if (currentStartDate) setSelectedDay([new Date(currentStartDate)]);
    }
  }, [value]);

  useEffect(() => {
    if (
      bookingState?.recurringEvents?.weekly?.pattern?.dateSelectedOfWeek &&
      bookingState?.recurringEvents?.weekly?.pattern?.dateSelectedOfWeek?.length > selectedDay?.length
    ) {
      setSelectedDay(bookingState?.recurringEvents?.weekly?.pattern?.dateSelectedOfWeek);
    }
  }, [bookingState?.recurringEvents?.weekly?.pattern?.dateSelectedOfWeek, selectedDay]);

  const renderOnContent = useMemo(() => {
    const orderSelectedDay = selectedDay.reduce((sorted, dayOfList) => {
      let index = 0;
      const numberOfDay = dayOfList.getTime();
      while (index < sorted.length && numberOfDay > sorted[index].getTime()) index++;
      sorted.splice(index, 0, dayOfList);
      return sorted;
    }, []);

    let currentValue = value;
    if (monthlyArr.includes(value)) {
      currentValue = EOptionRecurrencePatternType.MONTHLY;
    }
    if (yearlyArr.includes(value)) {
      currentValue = EOptionRecurrencePatternType.YEARLY;
    }

    switch (currentValue) {
      case ERecurrencePatternType.WEEKLY:
        return <DaysOfWeek handleClick={handleWeeklyChange} value={orderSelectedDay} />;
      case EOptionRecurrencePatternType.MONTHLY:
        return <OnRadioBooking />;
      case EOptionRecurrencePatternType.YEARLY:
        return <OnRadioBooking />;
    }
  }, [value, selectedDay]);
  return (
    <Grid item xs={12}>
      {value !== ERecurrencePatternType.DAILY && (
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography variant="body2" component="span">
              On
            </Typography>
          </Grid>
          <Grid item xs={9}>
            {renderOnContent}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default OnBooking;
