import React, { useMemo } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DurationTextField from '../../../components/DurationTextField';
import { useMailbox } from '../../../contexts';
import { addMinutes, differenceInMinutes } from 'date-fns';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { REACT_APP_MEETING_ROOM_MIN_TIME_INTERVAL } from '../../../constants/AddIn';

export default function BookingDuration(): JSX.Element {
  const { state: mailboxState } = useMailbox();
  const { width: widthHeight } = useWindowDimensions();

  const renderDurationTimes = useMemo(() => {
    const handleOnChange = async (value: number) => {
      const currentEndTime = addMinutes(new Date(mailboxState.start), value);
      if (currentEndTime) await Office.context.mailbox.item.end.setAsync(currentEndTime);
    };

    return (
      <DurationTextField
        stepInMinutes={REACT_APP_MEETING_ROOM_MIN_TIME_INTERVAL}
        value={differenceInMinutes(new Date(mailboxState.end), new Date(mailboxState.start))}
        onChange={handleOnChange}
        width={Number(widthHeight) < 350 ? 120 : 200}
      />
    );
  }, [mailboxState.start, mailboxState.end, widthHeight]);

  return (
    <Box sx={{ mb: 2 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <Box>
          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
            <AccessTimeIcon color="primary" />
            <Typography variant="body2">Duration</Typography>
          </Stack>
        </Box>
        {renderDurationTimes}
      </Stack>
    </Box>
  );
}
