import React from 'react';
import { Chip, Box } from '@mui/material';
import { eachDayOfInterval, format, isMonday, isFriday, isWednesday, addDays } from 'date-fns';

interface IDaysOfWeekProps {
  value?: Date[];
  handleClick?: (day: Date) => void;
}
const DaysOfWeek = ({ value, handleClick }: IDaysOfWeekProps): JSX.Element => {
  const startDayOfWeek = value?.length > 0 ? value[0] : new Date();
  const listDaysOfTheWeek = eachDayOfInterval({
    start: startDayOfWeek,
    end: addDays(startDayOfWeek, 6),
  });

  const sortData = listDaysOfTheWeek.reduce((sorted, dayOfList) => {
    let index = 0;
    const numberOfDay = dayOfList.getDay();
    while (index < sorted.length && numberOfDay > sorted[index].getDay()) index++;
    sorted.splice(index, 0, dayOfList);
    return sorted;
  }, []);

  const fistArray = sortData?.length > 0 ? sortData[0] : startDayOfWeek;
  const dataDays = sortData?.length > 0 ? [...sortData.slice(1), fistArray] : sortData;

  return (
    <Box component="div" sx={{ p: 0, m: 0 }}>
      {dataDays?.map((day, index) => {
        const currentDay = format(day, 'EEEEEE');
        const currentValues = value?.map((selectedDay) => format(selectedDay, 'EEEEEE'));
        return (
          <Chip
            key={index}
            label={isMonday(day) || isWednesday(day) || isFriday(day) ? format(day, 'EEEEE') : format(day, 'EEEEEE')}
            size="small"
            style={{ borderRadius: '10%', cursor: 'pointer' }}
            sx={{ mr: 1, mb: 1 }}
            onClick={() => handleClick(day)}
            color={currentValues.includes(currentDay) ? 'primary' : 'default'}
          />
        );
      })}
    </Box>
  );
};

export default DaysOfWeek;
