import React, { useEffect, useMemo, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import {
  EDayOfWeek,
  EOptionRecurrencePatternType,
  ERecurrencePatternType,
  ERecurrenceRangeType,
} from '../../middlewares/nura-client/lib/redux/constants';
import { useBookingDetails } from '../../contexts/BookingDetails';
import DesktopDatePickerCustom from '../DesktopDatePickerCustom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import EveryBooking from './EveryBooking';
import OnBooking from './OnBooking';
import EndBooking from './EndBooking';
import TextRecurringBooking from './TextRecurringBooking';
import OnRepeat from './OnRepeat';
import { useMailbox } from '../../contexts';
import { useSnackbar } from 'notistack';
import { addMonths, format } from 'date-fns';
import { REACT_APP_RECURRING_MAX_INTERVAL_MONTHS } from '../../constants/AddIn';
import { ParamsReadMeetingRoomStatus } from '../../middlewares/nura-client/lib/redux/actions/api/officeAddIns/outlook';
import { officeService } from '../../services/Office';
import { ReducerOutlookOfficeAddIn } from '../../middlewares/nura-client/lib/redux/reducers/api/officeAddIns';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../middlewares/nura-client/lib/redux/reducers';
import { ActionOutlookOfficeAddIn } from '../../middlewares/nura-client/lib/redux/actions';
import PageLoading from '../PageLoading';

const DialogBookingRecurrence = (): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);
  const { state: mailboxState, dispatch: mailboxDispatch } = useMailbox();
  const { state: bookingState, dispatch: bookingDispatch } = useBookingDetails();
  const { enqueueSnackbar } = useSnackbar();
  const { resource } = useSelector<RootState, ReducerOutlookOfficeAddIn.IOutlookAddInState>(
    ({ outlookAddIn }) => outlookAddIn,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [expandContent, setExpandContent] = useState<boolean>(false);

  /** Update out look date time */
  useEffect(() => {
    const setOutlookDateTime = async () => {
      const outlookStartDate = new Date(mailboxState.start);
      const newDate = new Date(bookingState.recurringEvents?.range?.startDate);
      outlookStartDate.setDate(newDate.getDate());
      outlookStartDate.setMonth(newDate.getMonth());
      outlookStartDate.setFullYear(newDate.getFullYear());
      const outlookEndDate = new Date(mailboxState.end);
      outlookEndDate.setDate(newDate.getDate());
      outlookEndDate.setMonth(newDate.getMonth());
      outlookEndDate.setFullYear(newDate.getFullYear());
      await Office.context.mailbox.item.start.setAsync(
        outlookStartDate,
        async (asyncResult: Office.AsyncResult<void>) => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            await Office.context.mailbox.item.end.setAsync(
              outlookEndDate,
              (asyncEndResult: Office.AsyncResult<void>) => {
                if (asyncEndResult.error) enqueueSnackbar(`End date.`, { variant: 'error' });
              },
            );
          } else {
            enqueueSnackbar(`Start date.`, { variant: 'error' });
          }
        },
      );
    };
    if (bookingState.recurringEvents?.range?.startDate && open && mailboxState?.start && mailboxState?.end) {
      setOutlookDateTime();
    }
  }, [bookingState.recurringEvents?.range?.startDate, mailboxState?.start, mailboxState?.end, open]);

  useEffect(() => {
    if (bookingState.recurringEvents.selectedUIType !== EOptionRecurrencePatternType.DOES_NOT_REPEAT) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [bookingState.recurringEvents.selectedUIType]);

  useEffect(() => {
    if (bookingState?.reopenRecurringDialog && !open) {
      const currenReOpen =
        bookingState.recurringEvents.selectedUIType === EOptionRecurrencePatternType.DOES_NOT_REPEAT
          ? false
          : bookingState?.reopenRecurringDialog;
      setOpen(currenReOpen);
    }
  }, [bookingState?.reopenRecurringDialog, open, bookingState?.recurringEvents?.selectedUIType]);

  const handleClose = () => {
    bookingDispatch({
      type: 'setRecurringEvents',
      payload: {
        recurringEvents: {
          ...bookingState.recurringEvents,
          daily: undefined,
          weekly: undefined,
          absoluteMonthly: undefined,
          absoluteYearly: undefined,
          selectedUIType: EOptionRecurrencePatternType.DOES_NOT_REPEAT,
        },
      },
    });
  };

  //Fetching Schedule
  const fetchRoomSchedule = async () => {
    try {
      setLoading(true);
      /** Recurring Events */
      const params: ParamsReadMeetingRoomStatus = {
        roomId: undefined,
        localTz: undefined,
        companyId: undefined,
        accessToken: undefined,
        address: undefined,
        startDate: undefined,
        endDate: undefined,
        recurringEvents: {
          pattern: {
            type: undefined,
            interval: undefined,
            daysOfWeek: undefined,
            dayOfMonth: undefined,
            index: undefined,
            month: undefined,
          },
          range: {
            type: undefined,
            startDate: undefined,
            endDate: undefined,
            numberOfOccurrences: undefined,
          },
        },
      };

      if (bookingState?.recurringEvents?.selectedUIType) {
        const {
          daily: dailyPattern,
          weekly: weeklyPattern,
          absoluteMonthly,
          absoluteYearly,
          range,
          relativeMonthly,
          relativeYearly,
        } = bookingState?.recurringEvents;
        const recurringEventsParam = params.recurringEvents;
        //DAILY
        if (
          dailyPattern?.pattern?.type === ERecurrencePatternType.DAILY &&
          dailyPattern?.pattern?.type &&
          dailyPattern?.pattern?.interval
        ) {
          const { type, interval } = dailyPattern.pattern;
          recurringEventsParam.pattern.type = type;
          recurringEventsParam.pattern.interval = interval;
        }
        //WEEKLY
        if (
          weeklyPattern?.pattern?.type === ERecurrencePatternType.WEEKLY &&
          weeklyPattern?.pattern?.type &&
          weeklyPattern?.pattern?.interval &&
          weeklyPattern?.pattern?.daysOfWeek
        ) {
          const { type, interval, daysOfWeek } = weeklyPattern.pattern;
          recurringEventsParam.pattern.type = type;
          recurringEventsParam.pattern.interval = interval;
          recurringEventsParam.pattern.daysOfWeek = daysOfWeek;
        }
        //Absolute Monthly
        if (
          absoluteMonthly?.pattern?.type &&
          absoluteMonthly?.pattern?.interval &&
          absoluteMonthly?.pattern?.dayOfMonth
        ) {
          const { dayOfMonth, interval, type } = absoluteMonthly?.pattern;
          recurringEventsParam.pattern.type = type;
          recurringEventsParam.pattern.interval = interval;
          recurringEventsParam.pattern.dayOfMonth = dayOfMonth;
        }
        //Absolute Yearly
        if (
          absoluteYearly?.pattern?.type &&
          absoluteYearly?.pattern?.interval &&
          absoluteYearly?.pattern?.dayOfMonth &&
          absoluteYearly?.pattern?.month
        ) {
          const { dayOfMonth, month, interval, type } = absoluteYearly?.pattern;
          recurringEventsParam.pattern.type = type;
          recurringEventsParam.pattern.interval = interval;
          recurringEventsParam.pattern.month = month;
          recurringEventsParam.pattern.dayOfMonth = dayOfMonth;
        }

        if (
          relativeMonthly?.pattern?.index &&
          relativeMonthly?.pattern?.daysOfWeek &&
          relativeMonthly?.pattern?.interval &&
          relativeMonthly?.pattern?.type
        ) {
          const { index, interval, type } = relativeMonthly.pattern;
          recurringEventsParam.pattern.type = type;
          recurringEventsParam.pattern.interval = interval;
          recurringEventsParam.pattern.index = index;
          if (range?.startDate) {
            const getDayOfWeek = format(new Date(range.startDate), 'EEEE').toUpperCase();
            if (EDayOfWeek[getDayOfWeek]) {
              recurringEventsParam.pattern.daysOfWeek = EDayOfWeek[getDayOfWeek];
            }
          }
        }

        if (
          relativeYearly?.pattern?.daysOfWeek &&
          relativeYearly?.pattern?.index &&
          relativeYearly?.pattern?.interval &&
          relativeYearly?.pattern?.month &&
          relativeYearly?.pattern?.type
        ) {
          const { index, interval, type, month } = relativeYearly.pattern;
          recurringEventsParam.pattern.type = type;
          recurringEventsParam.pattern.interval = interval;
          recurringEventsParam.pattern.index = index;
          recurringEventsParam.pattern.month = month;
          if (range?.startDate) {
            const getDayOfWeek = format(new Date(range.startDate), 'EEEE').toUpperCase();
            if (EDayOfWeek[getDayOfWeek]) {
              recurringEventsParam.pattern.daysOfWeek = EDayOfWeek[getDayOfWeek];
            }
          }
        }

        if (range?.startDate && range?.endDate && range?.type) {
          recurringEventsParam.range = range;
          const dateRangeStart = new Date(range.startDate);
          dateRangeStart.setHours(new Date(mailboxState.start).getHours());
          dateRangeStart.setMinutes(new Date(mailboxState.start).getMinutes());
          dateRangeStart.setSeconds(0);
          dateRangeStart.setMilliseconds(0);
          const dateRangeEnd = new Date(range.endDate);
          dateRangeEnd.setHours(new Date(mailboxState.end).getHours());
          dateRangeEnd.setMinutes(new Date(mailboxState.end).getMinutes());
          dateRangeEnd.setSeconds(0);
          dateRangeEnd.setMilliseconds(0);

          recurringEventsParam.range.startDate = dateRangeStart?.toISOString();
          recurringEventsParam.range.endDate = dateRangeEnd?.toISOString();
          if (range.type === ERecurrenceRangeType.END_DATE) recurringEventsParam.range.numberOfOccurrences = 0;
        }

        params.recurringEvents = recurringEventsParam;
      }
      /** End of recurring events */

      const userAccessToken = await officeService.getAccessToken();
      if (userAccessToken && mailboxState?.start && mailboxState?.end && resource?.emailAddress && resource?.id) {
        params.accessToken = userAccessToken;
        params.address = resource.emailAddress;
        params.startDate = mailboxState.start;
        params.endDate = mailboxState.end;
        params.roomId = resource.id;
        params.localTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        await dispatch(ActionOutlookOfficeAddIn.readMeetingRoomScheduleForOutlook(params));
        setLoading(false);
        setOpen(false);
      } else {
        setOpen(false);
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(`Error: ${error?.message}`, { variant: 'error', autoHideDuration: 2000 });
    }
  };

  const renderStart = useMemo(() => {
    const handleDateChange = async (date: Date | null) => {
      if (!date) return;
      const currentPayload = bookingState.recurringEvents;
      currentPayload.range.startDate = date.toDateString();
      currentPayload.range.endDate = addMonths(date, REACT_APP_RECURRING_MAX_INTERVAL_MONTHS).toDateString();
      bookingDispatch({
        type: 'setRecurringEvents',
        payload: { recurringEvents: { ...bookingState.recurringEvents, ...currentPayload } },
      });
    };
    const currentDate = new Date(bookingState?.recurringEvents?.range?.startDate);

    return (
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography variant="body2" component="span">
              Start
            </Typography>
          </Grid>
          <Grid item>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePickerCustom
                variant="standard"
                width={130}
                value={currentDate}
                onChange={handleDateChange}
                minDate={new Date()}
                type="basic"
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Grid>
    );
  }, [
    bookingState.recurringEvents,
    bookingState.recurringEvents.range.startDate,
    mailboxState?.end,
    mailboxState?.start,
  ]);

  const renderEvery = useMemo(() => {
    return <EveryBooking value={bookingState.recurringEvents.selectedUIType} />;
  }, [bookingState.recurringEvents.selectedUIType]);

  const renderOnSchedule = useMemo(() => {
    return <OnBooking value={bookingState.recurringEvents.selectedUIType} />;
  }, [bookingState.recurringEvents.selectedUIType]);

  const handleOnOpenDatePickerCustom = (value: boolean) => {
    setExpandContent(value);
  };

  const renderEndBooking = useMemo(() => {
    return <EndBooking handleOnOpenDatePickerCustom={handleOnOpenDatePickerCustom} />;
  }, []);

  const renderTExtBooking = useMemo(() => {
    return <TextRecurringBooking />;
  }, []);

  let disabledButton = false;
  if (
    bookingState?.recurringEvents?.selectedUIType === ERecurrencePatternType.WEEKLY &&
    bookingState?.recurringEvents?.weekly?.pattern?.daysOfWeek?.length === 0
  ) {
    disabledButton = true;
  }
  return (
    <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Recurrence</DialogTitle>
      <DialogContent>
        {loading && <PageLoading />}
        <Grid container spacing={2}>
          {renderStart}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Typography variant="body2" component="span">
                  Repeat
                </Typography>
              </Grid>
              <Grid item>
                <OnRepeat />
              </Grid>
            </Grid>
          </Grid>
          {renderEvery}
          {renderOnSchedule}
          {renderEndBooking}
          {renderTExtBooking}
          {expandContent && <div style={{ height: 400 }}></div>}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="outlined" fullWidth>
          Cancel
        </Button>
        <Button
          onClick={fetchRoomSchedule}
          color="primary"
          variant="contained"
          autoFocus
          fullWidth
          disabled={disabledButton}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogBookingRecurrence;
