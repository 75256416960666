export enum LoginWithOfficeAddInActionType {
  request = 'loginWithOfficeAddIn.request',
  response = 'loginWithOfficeAddIn.response',
  select = 'loginWithOfficeAddIn.select',
  objectId = 'loginWithOfficeAddIn.objectId',
  error = 'loginWithOfficeAddIn.error',
}

export enum UserOfficeLoginProvider {
  OUTLOOK = 'OUTLOOK',
  WORD = 'WORD',
  EXCEL = 'EXCEL',
}
