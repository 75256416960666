import React from 'react';
import { ReactComponent as ReactLogo } from '../../../src/assets/logos/nuraspace.svg';
interface CompanyLogoProps {
  width?: number;
}

const CompanyLogo = ({ width = 160 }: CompanyLogoProps): JSX.Element => {
  return <ReactLogo width={width} />;
};

export default CompanyLogo;
