import { IRecurringEventsDateSeriesResponse } from '.';
import { IRecurringEventRelativeMonthly } from '../../middlewares/nura-client/lib/redux/constants';

export const getRelativeMonthlyDateSeries = (
  data: IRecurringEventRelativeMonthly,
): IRecurringEventsDateSeriesResponse => {
  return {
    dates: [],
  };
};
