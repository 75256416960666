import { ThunkAction } from 'redux-thunk';
import { LoginWithOfficeAddInActionType } from '../../../constants';
import { RootState } from '../../../reducers';
import { Action } from 'redux';
import { storeTokens } from '../../../../../../nura-helpers/lib/helpers/auth';
import { getIpDetails } from '../../../../helpers/ip';
import {
  ILoginWithOfficeAddInParams,
  requestLoginWithOfficeAddIn,
} from '../../../../../../nura-helpers/lib/helpers/authWithOfficeAddIn';
import { CURRENT_COMPANY_KEY } from '../../../../../../nura-helpers/lib/constants';

export interface IUserCompany {
  id: string;
  name: string;
  label: string;
}
export interface LoginWithOfficeAddInAction extends Action<LoginWithOfficeAddInActionType> {
  [index: string]: any;
  error?: any;
  data?: {
    accessToken?: string;
    refreshToken?: string;
    isMultiCompany?: boolean;
    companies?: string[];
    selectedCompany?: string;
    userCompanies?: IUserCompany[];
  };
}

interface LoginWithOfficeAddInResponse {
  data: {
    accessToken?: string;
    refreshToken?: string;
    isMultiCompany?: boolean;
    companies?: string[];
    selectedCompany?: string;
    userCompanies?: IUserCompany[];
  };
}

/**
 *
 * @param param0
 * officeAccessToken : Get from office add-in
 * @returns
 */
export function loginWithOfficeAddIn({
  provider,
  deviceId,
  user,
  meta,
  selectedCompany,
  officeAccessToken,
}: ILoginWithOfficeAddInParams): ThunkAction<Promise<void>, RootState, unknown, LoginWithOfficeAddInAction> {
  return async (dispatch) => {
    try {
      dispatch({
        type: LoginWithOfficeAddInActionType.request,
      });
      const ipDetails = await getIpDetails();

      const response = await requestLoginWithOfficeAddIn<LoginWithOfficeAddInResponse>({
        provider,
        deviceId,
        user,
        meta,
        ipDetails,
        selectedCompany,
        officeAccessToken,
      });

      if (response.status !== 200) throw response;
      if (!response) {
        throw new Error('Login failed.');
      }

      if (response && response.data.data.accessToken && response.data.data.refreshToken) {
        if (!response.data.data.isMultiCompany) {
          localStorage.setItem(CURRENT_COMPANY_KEY, response.data.data.companies[0]);
          await storeTokens(response.data.data.accessToken, response.data.data.refreshToken);
        }

        dispatch({
          type: LoginWithOfficeAddInActionType.response,
          data: response.data.data,
        });
      } else {
        dispatch({
          type: LoginWithOfficeAddInActionType.response,
          data: response.data.data,
        });
      }

      return;
    } catch (err) {
      const genericError = new Error('Error processing request.');
      dispatch({
        type: LoginWithOfficeAddInActionType.error,
        error: err.response?.data || genericError,
      });
      throw err.response?.data || genericError;
    }
  };
}
