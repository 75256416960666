import { addDays, eachMinuteOfInterval, endOfToday, format, isToday, startOfDay, startOfToday } from 'date-fns';
import { REACT_APP_MEETING_ROOM_MIN_TIME_INTERVAL } from '../../../constants/AddIn';
import { ITimeItem } from '../../../middlewares/nura-client/lib/redux/constants';

/**
 *Nearest 30 minutes
 * @param date
 * @returns
 */
export const roundToNearest30 = (date: Date = new Date()): Date => {
  const nearestValueInMinutes =
    Number(REACT_APP_MEETING_ROOM_MIN_TIME_INTERVAL) > 0 ? Number(REACT_APP_MEETING_ROOM_MIN_TIME_INTERVAL) : 30;
  const minutes = nearestValueInMinutes;
  const ms = 1000 * 60 * minutes;

  return new Date(Math.ceil(date.getTime() / ms) * ms);
};

/**
 * Format Date
 * @param param0
 * @returns
 */
export const formatDateTime = ({
  date,
  format: formatDate = 'MM/dd/yyyy',
}: {
  date: Date;
  format?: string;
}): string => {
  return format(date, formatDate);
};

/**
 * List of times
 * @param param0
 * @returns
 */
export const getEachMinuteOfInterval = ({
  start: startDate = startOfToday(),
  end: endDate = endOfToday(),
  step = 30,
  format = 'p',
  isDeliveryTime = false,
}: {
  start: Date;
  end: Date;
  step?: number;
  format?: string;
  isDeliveryTime?: boolean;
}): string[] => {
  let currentStart = isToday(startDate) ? roundToNearest30(startDate) : startOfDay(startDate);
  if (isDeliveryTime) {
    currentStart = roundToNearest30(startDate);
  }

  const result = eachMinuteOfInterval(
    {
      start: currentStart,
      end: endDate,
    },
    { step },
  );

  if (!result) return [];
  let resultFormat = result.map((dateItem) => formatDateTime({ date: dateItem, format }));
  return resultFormat;
};

/**
 * Add date by number of days
 * @param param0
 * @returns
 */
export const addDateByDay = ({ date, days }: { date: Date; days: number }): Date => {
  return addDays(date, days);
};

export const getIntervalInMinutes = ({
  intervalInHours = 3,
  step = 15,
}: {
  intervalInHours?: number;
  step?: number;
}): ITimeItem[] => {
  const totalMinutes = intervalInHours * 60;
  const newItems: ITimeItem[] = [];
  for (let i = 15; i <= totalMinutes; i += step) {
    const newItem = {
      id: Number(i),
      label: `${i} Minutes`,
      value: i,
    };
    newItems.push(newItem);
  }
  return newItems;
};
