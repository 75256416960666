import theme from '../../../styles/theme';

export const classes = {
  active: {
    background: theme.palette.primary.main,
  },
  label: {
    color: '#707171',
    fontWeight: 'bold',
    paddingRight: theme.spacing(2),
  },
  value: {
    color: '#707171',
    paddingLeft: theme.spacing(2),
  },
  email: {
    color: '#707171',
    paddingLeft: theme.spacing(2),
    overflow: 'hidden',
    cursor: 'pointer',
  },
};
