import { de } from 'date-fns/locale';
import { BookingStatus, Company, Resource, User } from '..';

export enum TeamStatus {
  UN_FOLLOW = 'UN_FOLLOW',
  FOLLOWING = 'FOLLOWING',
  MEMBER = 'MEMBER',
  TEAM_LEADER = 'TEAM_LEADER',
}
export interface TeamsUser {
  id: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  name?: string;
  /**
   * @deprecated use profilePhotoUrl
   */
  profilePhoto?: string;
  profilePhotoUrl?: string;
  isLeader?: boolean;
}
export interface TeamsBooking {
  id: string;
  startDate: string;
  endDate: string;
  resource: Resource;
  user: User;
  status?: BookingStatus;
  createdAt?: string;
  updatedAt?: string;
  expiredAt?: string;
  checkedInAt?: string;
  checkedOutAt?: string;
  cancelledAt?: string;
  completedAt?: string;
  hasChildren?: boolean;
  parentId?: string;
  comments?: string;
}
export interface TeamAnnouncement {
  id: string;
  title?: string;
  description?: string;
  isRead?: boolean;
  created: Date;
}

export interface TeamsGoalSetting {
  id: string;
  teamId: string;
  totalMembers: number;
  frequency: number;
  createdBy: string;
  createdOnUTC: string;
  updatedBy: string;
  updatedOnUTC: string;
}
export interface TeamsNotificationSetting {
  id: string;
  teamId: string;
  userId: string;
  totalBookings: number;
  bookingDays: string;
  totalCheckIns: number;
  checkInDays: string;
  createdOnUTC: string;
  updatedOnUTC: string;
}

export interface TeamsActivity {
  id: string;
  name: string;
  text: string;
  date: Date;
  status: BookingStatus;
}

export interface TeamConnectionGoal {
  current: TeamsBooking[];
  prev: TeamsBooking[];
}
export interface TeamConnectionGoalWithChartFormat {
  current: {
    label: string;
    value: number;
  }[];
  prev: {
    label: string;
    value: number;
  }[];
}

export interface TeamTheme {
  path: string;
  teamId: string;
  file: string;
}

export interface Team {
  id?: string;
  name?: string;
  description?: string;
  company?: Company;
  members?: TeamsUser[];
  followers?: TeamsUser[];
  bookings?: TeamsBooking[];
  announcements?: TeamAnnouncement[];
  settings?: {
    goal?: TeamsGoalSetting;
    notifications?: TeamsNotificationSetting[];
  };
  activities?: TeamsActivity[];
  createdBy?: string;
  createdOnUTC: string;
  updatedBy?: string;
  updatedOnUTC?: string;
  status?: TeamStatus;
  image?: string;
}

export interface TeamLivePageProps {
  isLiveSearchHidden?: boolean;
  teamsMembersIds?: TeamsUser[];
  maxZoom?: number;
}

export enum ReadTeamsMembersActionType {
  request = 'read.teams.members.request',
  response = 'read.teams.members.response',
  error = 'read.teams.members.error',
}

export enum CreateTeamActionType {
  request = 'create.team.request',
  response = 'create.team.response',
  error = 'create.team.error',
}

export enum ReadTeamsActionType {
  request = 'read.teams.request',
  response = 'read.teams.response',
  error = 'read.teams.error',
}

export enum ReadMyTeamsActionType {
  request = 'read.teams.myTeams.request',
  response = 'read.teams.myTeams.response',
  error = 'read.teams.myTeams.error',
}

export enum UpdateTeamActionType {
  request = 'update.team.request',
  response = 'update.team.response',
  error = 'update.team.error',
}

export enum UpdateTeamsMembersActionType {
  request = 'update.teams.members.request',
  response = 'update.teams.members.response',
  error = 'update.teams.members.error',
}

export enum CreateTeamAnnouncementActionType {
  request = 'create.team.announcement.request',
  response = 'create.team.announcement.response',
  error = 'create.team.announcement.error',
}

export enum ReadTeamBookingsActionType {
  request = 'read.team.bookings.request',
  response = 'read.team.bookings.response',
  error = 'read.team.bookings.error',
}

export enum CreateTeamFollowerActionType {
  request = 'create.team.follower.request',
  response = 'create.team.follower.response',
  error = 'create.team.follower.error',
}

export enum ReadTeamNotificationSettingActionType {
  request = 'read.team.notification.setting.request',
  response = 'read.team.notification.setting.response',
  error = 'read.team.notification.setting.error',
}

export enum ReadTeamGoalSettingActionType {
  request = 'read.team.goal.setting.request',
  response = 'read.team.goal.setting.response',
  error = 'read.team.goal.setting.error',
}

export enum ReadTeamsFollowersActionType {
  request = 'read.teams.followers.setting.request',
  response = 'read.teams.followers.setting.response',
  error = 'read.teams.followers.setting.error',
}

export enum ReadTeamConnectionGoalType {
  request = 'read.team.connection.goal.request',
  response = 'read.team.connection.goal.response',
  error = 'read.team.connection.goal.error',
}

export enum ReadTeamThemeActionType {
  request = 'read.team.themes.request',
  response = 'read.team.themes.response',
  error = 'read.team.themes.error',
}

export enum DeleteTeamAnnouncementActionType {
  request = 'delete.team.announcement.request',
  response = 'delete.team.announcement.response',
  error = 'delete.team.announcement.error',
}

export enum ReadTeamsUserActivitiesActionType {
  request = 'read.teams.user.activities.request',
  response = 'read.teams.user.activities.response',
  error = 'read.teams.user.activities.error',
}

export enum CreateTeamsUserActionsActionType {
  request = 'create.teams.user.actions.request',
  response = 'create.teams.user.actions.response',
  error = 'create.teams.user.actions.error',
}
