/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';

interface ContextValues {
  startDate: Date;
  endDate: Date;
}

const initState: ContextValues = {
  startDate: new Date(),
  endDate: new Date(),
};

const OfficeContext = createContext<ContextValues>(initState);

export const OfficeContextProvider = ({ children }): JSX.Element => {
  const officeRef = useRef<typeof Office | null>(null);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());

  useEffect(() => {
    const listenerHandler = (asyncResult): void => {
      if (asyncResult?.start) setStartDate(new Date(asyncResult?.start));
      if (asyncResult?.end) setEndDate(new Date(asyncResult?.end));
    };
    Office.onReady(() => {
      officeRef.current = Office;
      Office.context.mailbox.item.start.getAsync((asyncStartResult: Office.AsyncResult<Date>) => {
        if (asyncStartResult.status === Office.AsyncResultStatus.Succeeded) {
          setStartDate(asyncStartResult.value);
        }
      });
      Office.context.mailbox.item.end.getAsync((asyncEndResult: Office.AsyncResult<Date>) => {
        if (asyncEndResult.status === Office.AsyncResultStatus.Succeeded) {
          setEndDate(asyncEndResult.value);
        }
      });

      Office.context.mailbox.item.addHandlerAsync(Office.EventType.AppointmentTimeChanged, listenerHandler);
    });

    return () => {
      if (officeRef.current) {
        officeRef.current.context.mailbox.item.removeHandlerAsync(
          Office.EventType.AppointmentTimeChanged,
          listenerHandler,
        );
      }
    };
  }, []);

  return (
    <OfficeContext.Provider
      value={{
        startDate,
        endDate,
      }}
    >
      {children}
    </OfficeContext.Provider>
  );
};

export const useOfficeContext = (): ContextValues => useContext(OfficeContext);
