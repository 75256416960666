import { ReadSettingsActionType, Settings } from '../../../constants';
import { ReadSettingsAction } from '../../../actions';

export interface SettingState {
  isFetching: boolean;
  settings: Settings;
  error?: string;
}

const initState: SettingState = {
  isFetching: false,
  settings: {},
};

export function setting(state = initState, action: ReadSettingsAction): SettingState {
  switch (action.type) {
    /** READ */
    case ReadSettingsActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadSettingsActionType.response:
      return {
        ...state,
        isFetching: false,
        settings: action.data,
      };
    case ReadSettingsActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    default:
      return state;
  }
}
