import AuthEventHub from '../events/auth';
import {
  getTokens,
  isTokenAboutToExpire,
  isExpired,
  decodeAccessToken,
  AccessTokenDecoded,
  getRefreshedAccessToken,
  isTokenExpired,
  decodeRefreshToken,
} from '../helpers/auth';

export default class AuthTokenService {
  private static _instance: AuthTokenService;

  public static get instance(): AuthTokenService {
    if (!this._instance) this._instance = new AuthTokenService();
    return this._instance;
  }

  public isRefreshing = false;

  private async refreshAccessToken(): Promise<string | null> {
    const { refreshToken, accessToken } = await getTokens();

    // Check if the refresh token exists.
    if (!refreshToken) {
      // User not logged in so we return null.
      return null;
    }

    // Check if the refresh token is still valid.
    const decodedRefreshToken = decodeRefreshToken(refreshToken);

    // iat = Issued At (time since epoch in seconds).
    // exp = Expiry in epoch time (seconds).
    if (!decodedRefreshToken) throw new Error('There was an error decoding the refresh token.');
    if (isTokenExpired(decodedRefreshToken.exp)) {
      AuthEventHub.instance.emit(AuthEventHub.REFRESH_TOKEN_EXPIRED);
      // throw new Error('Refresh token has expired.');
      return null;
    }

    // If the refresh token exists and is valid,
    // We can proceed to refresh the access token.

    // Check if the access token exists
    if (accessToken) {
      // Check if the access token has expired.
      // If so, we then proceed with refreshing it.
      // Otherwise if it is still valid, return it.
      const decodedAccessToken = decodeAccessToken(accessToken);

      if (!decodedAccessToken) throw new Error('There was an error decoding the access token.');
      if (
        isTokenAboutToExpire(decodedAccessToken.exp, decodedAccessToken.iat) ||
        isTokenExpired(decodedAccessToken.exp)
      ) {
        // Access token is expired, we need to refresh it.
        const newAccessToken = await getRefreshedAccessToken(refreshToken);
        if (!newAccessToken) throw new Error('There was an error trying to refresh the expired access token.');
        return newAccessToken;
      }

      return accessToken;
    } else {
      // Handle refresh because access token does not exist.
      // We need to create it.
      const newAccessToken = await getRefreshedAccessToken(refreshToken);
      if (!newAccessToken) throw new Error('There was an error trying to create new access token.');
      return newAccessToken;
    }
  }

  public async getUserIdAndDeviceId(): Promise<AccessTokenDecoded | null> {
    try {
      const accessToken = await AuthTokenService.instance.handleRefreshAccessToken();
      // Return a default decoded so other functions can handle the object without error.
      if (!accessToken) return { userId: null, deviceId: null, exp: 0, iat: 0, email: null };

      const decoded = decodeAccessToken(accessToken);
      return decoded;
    } catch (err) {
      console.log('Error getting user id and device id.', err.message);
      throw err;
    }
  }

  public async handleRefreshAccessToken(): Promise<string | null> {
    let newAccessToken;
    try {
      const { accessToken } = await getTokens();
      newAccessToken = accessToken; // We set the access token

      // We now check if the session is currently valid,
      // If so we do not need to refresh the access token.
      if (accessToken) {
        const decoded = decodeAccessToken(accessToken);
        if (decoded) {
          const isExpiringSoon = isTokenAboutToExpire(decoded.exp, decoded.iat) || isExpired(decoded.exp);
          // If it is already refreshing and its not critical to refresh for this request.
          // We can use the existing access token.
          if (!isExpiringSoon) {
            throw new Error('The access token is valid, no need to refresh.');
          }
          if (this.isRefreshing && !isExpiringSoon) {
            throw new Error('The access token is being refreshed and is currently valid, no need to refresh.');
          }
        }
      }

      // If its not already being refreshed and expiring soon, we need to get a new token.
      this.isRefreshing = true;
      const res = await this.refreshAccessToken();
      this.isRefreshing = false;

      return res;
    } catch (err) {
      this.isRefreshing = false;
      return newAccessToken ? newAccessToken : null;
    }
  }
}
