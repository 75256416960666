import { Company } from '../company';

export interface FirstAidStation {
  id: string;
  name?: string;
  description?: string;
  company?: Company;
  active?: boolean;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  floor?: {
    id: string;
    name: string;
    building?: {
      id: string;
      name: string;
      timezone: string;
    };
  };
  deleted?: boolean;
}

export enum ReadFirstAidStationsActionType {
  request = 'read.firstAidStations.request',
  response = 'read.firstAidStations.response',
  error = 'read.firstAidStations.error',
}

export enum ReadFirstAidStationActionType {
  request = 'read.firstAidStation.request',
  response = 'read.firstAidStation.response',
  error = 'read.firstAidStation.error',
}

export enum CreateFirstAidStationActionType {
  request = 'create.firstAidStation.request',
  response = 'create.firstAidStation.response',
  error = 'create.firstAidStation.error',
}

export enum UpdateFirstAidStationActionType {
  request = 'update.firstAidStation.request',
  response = 'update.firstAidStation.response',
  error = 'update.firstAidStation.error',
}

interface OptionsFloors {
  Id: number;
  Name: string;
}
interface OptionsBuildings {
  Id: number;
  Name: string;
  Floors: OptionsFloors[];
}
