import { ChangeEvent } from 'react';

export enum RequestQuestionnaireTypes {
  VACCINATION_STATUS = 'vaccination-status',
  CONDITIONS_OF_ENTRY = 'conditions-of-entry',
  COMPLIANCE_ALERTS = 'compliance-alerts',
}

export interface QuestionnaireProps {
  id?: string;
  type?: RequestQuestionnaireTypes;
  state?: Questionnaire;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSwitchChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onSubmit?: () => void;
  isFetching?: boolean;
}

export interface Questionnaire {
  id?: string;
  question?: string;
  active?: boolean;
  answerType?: string;
  answerValues?: string;
  required?: string;
  order?: number;
  type?: {
    id?: string;
    code?: string;
    description?: string;
    companyId?: string;
  };
}

export interface ConditionsOfEntrySettings {
  id?: string;
  email?: string;
  message?: string;
}

export const initialConditionsOfEntrySettings: ConditionsOfEntrySettings = {
  id: '',
  email: '',
  message: '',
};

export interface ConditionsOfEntrySettingParams {
  id?: string;
  email?: string;
  message?: string;
  scope?: string;
}

export interface QuestionParams {
  id?: string;
  question?: string;
  active?: boolean;
  answerType?: string;
  answerValues?: string;
  scope?: string;
  order?: number;
  required?: string;
}

export const initialQuestionnaire: Questionnaire = {
  question: '',
  answerValues: '',
  active: false,
};

export type DailyChecklistAnswerValue = 'Yes' | 'No' | undefined;
export interface DailyChecklistAnswer {
  id?: string;
  value?: DailyChecklistAnswerValue | string;
}

export interface DailyChecklistUser {
  id?: string;
  companyId?: string;
  userId?: string;
  firstName?: string;
  sureName?: string;
  building?: string;
  entryPoint?: string;
  entryDate?: string;
  entryTime?: string;
  declareConditionsOfEntry?: boolean;
  answers?: DailyChecklistAnswer[];
}

export interface CreateDailyChecklistParams {
  scope: string;
  user: string;
  date: string;
  typeId: string;
  resource: string;
  bookingId?: string;
  email: string;
  answers: DailyChecklistAnswer[];
}

export interface ReadDailyChecklistParams {
  scope: string;
  user: string;
  date: string;
  typeId?: string;
}

export enum QuestionnaireAnswerType {
  DROPDOWN = 'dropdown',
  BOOLEAN = 'boolean',
  TEXT = 'text',
}

export interface DailyChecklistUserForGrid {
  id?: string;
  companyId?: string;
  userId?: string;
  firstName?: string;
  sureName?: string;
  building?: string;
  entryPoint?: string;
  entryDate?: string;
  entryTime?: string;
  [answer: string]: string;
}

export interface ReadDailyChecklistUsersParams {
  scope: string;
  limit?: string;
}

/** Questionnaire */
export enum ReadQuestionnaireActionType {
  request = 'read.questionnaire.request',
  response = 'read.questionnaire.response',
  error = 'read.questionnaire.error',
}

export enum ReadQuestionActionType {
  request = 'read.question.request',
  response = 'read.question.response',
  error = 'read.question.error',
}

export enum UpdateQuestionActionType {
  request = 'update.question.request',
  response = 'update.question.response',
  error = 'update.question.error',
}

export enum ReadConditionOfEntrySettingsActionType {
  request = 'read.conditionOfEntry.settings.request',
  response = 'read.conditionOfEntry.settings.response',
  error = 'read.conditionOfEntry.settings.error',
}

export enum CreateConditionOfEntrySettingsActionType {
  request = 'create.conditionOfEntry.settings.request',
  response = 'create.conditionOfEntry.settings.response',
  error = 'create.conditionOfEntry.settings.error',
}

export enum UpdateConditionOfEntrySettingsActionType {
  request = 'update.conditionOfEntry.settings.request',
  response = 'update.conditionOfEntry.settings.response',
  error = 'update.conditionOfEntry.settings.error',
}

export enum ReadDailyChecklistActionType {
  request = 'read.questionnaire.checklist.user.request',
  response = 'read.questionnaire.checklist.user.response',
  error = 'read.questionnaire.checklist.user.error',
}

export enum CreateDailyChecklistActionType {
  request = 'create.questionnaire.checklist.user.request',
  response = 'create.questionnaire.checklist.user.response',
  error = 'create.questionnaire.checklist.user.error',
}

export enum ReadDailyChecklistUsersActionType {
  request = 'read.questionnaire.checklist.users.request',
  response = 'read.questionnaire.checklist.users.response',
  error = 'read.questionnaire.checklist.users.error',
}
