import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { api, DefaultResponse } from '../../../../../../../nura-helpers/lib/services/api';
import { RootState } from '../../../../reducers';
import { getCompanyId } from '../../../../../../../nura-helpers/lib/helpers/auth';
import {
  IMeetingRoomStatusResponse,
  IRecurringEventsParams,
  ReadMeetingRoomStatusActionType,
} from '../../../../constants';

export interface ReadMeetingRoomStatusAction extends Action<ReadMeetingRoomStatusActionType> {
  error?: any;
  data?: IMeetingRoomStatusResponse;
}

export interface ReadMeetingRoomStatusResult {
  data: IMeetingRoomStatusResponse;
}

type ReadMeetingRoomStatusResponse = DefaultResponse & ReadMeetingRoomStatusResult;

export interface ParamsReadMeetingRoomStatus {
  roomId?: string;
  localTz?: string;
  companyId?: string;
  accessToken: string;
  address: string;
  startDate: string;
  endDate: string;
  recurringEvents?: IRecurringEventsParams;
}

export async function readMeetingRoomScheduleForOutlookAsync({
  roomId,
  localTz,
  companyId,
  accessToken,
  address,
  startDate,
  endDate,
  recurringEvents,
}: ParamsReadMeetingRoomStatus): Promise<ReadMeetingRoomStatusResult> {
  const scope = getCompanyId();

  const response = await api('/api/outlook/booking/meetingRoom/schedule', {
    outlookId: true,
  }).post<ReadMeetingRoomStatusResponse>({
    roomId,
    localTz,
    companyId: `${scope}`,
    currentDate: new Date().toISOString(),
    accessToken,
    address,
    startDate,
    endDate,
    recurringEvents,
  });

  if (response.status !== 200) throw response;

  if (!response || !response.data) {
    throw response;
  }
  return {
    data: response.data,
  };
}

export function readMeetingRoomScheduleForOutlook(
  params: ParamsReadMeetingRoomStatus,
): ThunkAction<Promise<ReadMeetingRoomStatusResult>, RootState, unknown, ReadMeetingRoomStatusAction> {
  return async (dispatch) => {
    try {
      dispatch({
        type: ReadMeetingRoomStatusActionType.request,
      });

      const response = await readMeetingRoomScheduleForOutlookAsync(params);

      dispatch({
        type: ReadMeetingRoomStatusActionType.response,
        data: response.data,
      });
      return {
        data: response.data,
      };
    } catch (e) {
      dispatch({
        type: ReadMeetingRoomStatusActionType.error,
        error: e,
      });
      throw e;
    }
  };
}
