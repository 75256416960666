import {
  ReadDeviceTypesActionType,
  DeviceType,
  ReadDeviceManufacturersActionType,
  DeviceManufacturer,
} from '../../../constants';
import { ReadDeviceTypesAction, ReadDeviceManufacturersAction } from '../../../actions';
import { QueryState } from '..';

export interface ResourceDeviceState extends QueryState {
  isFetching: boolean;
  error?: string;
  deviceTypes: DeviceType[];
  deviceManufacturers: DeviceManufacturer[];
}

const initState: ResourceDeviceState = {
  isFetching: false,
  pageInfo: {
    total: 0,
  },
  deviceTypes: [],
  deviceManufacturers: [],
};

export function resourceDevice(
  state = initState,
  action: ReadDeviceTypesAction | ReadDeviceManufacturersAction,
): ResourceDeviceState {
  switch (action.type) {
    /** Read device types */
    case ReadDeviceTypesActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadDeviceTypesActionType.response:
      return {
        ...state,
        deviceTypes: action.data,
        isFetching: false,
      };
    case ReadDeviceTypesActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    /** Read device types */
    case ReadDeviceManufacturersActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadDeviceManufacturersActionType.response:
      return {
        ...state,
        deviceManufacturers: action.data,
        isFetching: false,
      };
    case ReadDeviceManufacturersActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    default:
      return state;
  }
}
