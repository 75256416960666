import {
  AccountAdmin,
  Company,
  LoginActionType,
  LoginWithProviderActionType,
  LogoutActionType,
  LoginClearActionType,
  LoginClearAction,
  LoginEmailExistsActionType,
  UserConnectionProvider,
  LoginEmailClearAction,
  LoginEmailClearActionType,
  LoginMfaClearActionType,
  LoginMfaClearAction,
} from '../../../constants';
import { LoginAction, LoginEmailExistsAction, LoginWithProviderAction, LogoutAction } from '../../../actions';

export interface LoginState {
  isFetching: boolean;
  isLoggedIn: boolean;
  error?: string | null;
  accessToken?: string;
  refreshToken?: string;
  isMultipleTenant?: boolean;
  tenantCompanies?: Company[];
  file?: string | undefined;
  account?: AccountAdmin;
  mfaRequired: boolean;
  qrCode: string;
  updateObjectId?: boolean;
  isFetchingEmail: boolean;
  emailExists?: boolean;
  provider?: UserConnectionProvider;
  emailExistsError?: string;
  emailExistsFirstName?: string;
  scopes?: string[];
}

const initState: LoginState = {
  isFetching: false,
  isLoggedIn: false,
  isMultipleTenant: false,
  tenantCompanies: [],
  file: undefined,
  account: { Name: '' },
  error: undefined,
  accessToken: undefined,
  refreshToken: undefined,
  mfaRequired: false,
  qrCode: '',
  updateObjectId: false,
  isFetchingEmail: false,
  emailExists: undefined,
  provider: undefined,
  emailExistsError: undefined,
  emailExistsFirstName: undefined,
  scopes: [],
};

export function login(
  state = initState,
  action:
    | LoginAction
    | LogoutAction
    | LoginWithProviderAction
    | LoginClearAction
    | LoginEmailExistsAction
    | LoginEmailClearAction
    | LoginMfaClearAction,
): LoginState {
  switch (action.type) {
    case LoginActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case LoginActionType.response:
      return {
        ...state,
        error: null,
        isFetching: false,
        isLoggedIn: Boolean(action.data?.accessToken),
        accessToken: action.data?.accessToken,
        refreshToken: action.data?.refreshToken,
        mfaRequired: action.data?.mfaRequired,
        qrCode: action.data?.qrCode,
      };
    case LoginActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    case LogoutActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case LogoutActionType.response:
      return {
        ...initState,
        error: action.showError ? 'Your session has expired, please login again.' : undefined,
      };
    case LogoutActionType.error:
      return {
        ...state,
        isFetching: false,
        // error: action.error.message,
      };
    case LoginWithProviderActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case LoginWithProviderActionType.response:
      return {
        ...state,
        isFetching: false,
        isLoggedIn: true,
        accessToken: action.data?.accessToken,
        refreshToken: action.data?.refreshToken,
        updateObjectId: false,
        error: null,
      };
    case LoginWithProviderActionType.select:
      return {
        ...state,
        isFetching: false,
        isMultipleTenant: action.data?.isMultipleTenant,
        tenantCompanies: action.data?.tenantCompanies,
        file: action.data?.file,
        account: action.data?.account,
      };
    case LoginWithProviderActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    case LoginClearActionType:
      return {
        ...initState,
      };

    case LoginWithProviderActionType.objectId:
      return {
        ...state,
        isFetching: false,
        updateObjectId: action.data?.updateObjectId,
      };

    /** Login email exists check */
    case LoginEmailExistsActionType.request:
      return {
        ...state,
        isFetchingEmail: true,
        emailExists: undefined,
        provider: undefined,
        emailExistsFirstName: undefined,
        emailExistsError: undefined,
      };
    case LoginEmailExistsActionType.response:
      return {
        ...state,
        isFetchingEmail: false,
        emailExists: action.data?.exists,
        provider: action.data?.provider,
        emailExistsFirstName: action.data?.firstName,
        emailExistsError: undefined,
        scopes: action.data?.scopes || [],
      };
    case LoginEmailExistsActionType.error:
      return {
        ...state,
        isFetchingEmail: false,
        emailExists: undefined,
        provider: undefined,
        emailExistsFirstName: undefined,
        emailExistsError: action.error.message,
        scopes: [],
      };
    case LoginEmailClearActionType:
      return {
        ...initState,
      };

    case LoginMfaClearActionType:
      return {
        ...state,
        mfaRequired: false,
        qrCode: '',
        isFetching: false,
      };

    default:
      return state;
  }
}
