import { Booking } from '../booking';
import { Resource } from '../resource';

export enum ERecurrenceTypes {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export enum ECarParkContextActionType {
  SET_RESOURCES = 'SET_RESOURCES',
  SET_AUTO_BOOKING = 'SET_AUTO_BOOKING',
  SET_RELOAD_DATA = 'SET_RELOAD_DATA',
  SET_CAR_PARK_SPACE = 'SET_CAR_PARK_SPACE',
  SET_FILTER = 'SET_FILTER',
  SET_DEFAULT_FILTER = 'SET_DEFAULT_FILTER',
}

interface ICarParkFilterRecurring {
  isRecurring: boolean;
  type: ERecurrenceTypes;
  interval: number | string;
  daysOfWeek?: number[];
}

interface ICarParkFilterState {
  resourceTypes: string[];
  allDay: boolean;
  start: Date;
  end: Date;
  recurring?: ICarParkFilterRecurring;
  openStartDate?: boolean;
  openEndDate?: boolean;
}

export interface IDataFilterState {
  selectedResource?: Resource;
  booking?: Booking;
}

type ToggleBookCarParkSpace = {
  bookCarParkSpace: boolean;
};

export interface ICarParkContextState {
  carParkSpaces?: Resource[];
  carParkSpace?: Resource; // this will be used on Desk booking details for auto booking a car park space.
  reloadDateTime?: Date;
  buildingTz: string;
  operationalDays: number[];
  filter: ICarParkFilterState;
  data: IDataFilterState;
  deskDetails: ToggleBookCarParkSpace;
}

export const initialCarParkContextState: ICarParkContextState = {
  carParkSpaces: [],
  carParkSpace: undefined,
  deskDetails: {
    bookCarParkSpace: false,
  },
  reloadDateTime: undefined,
  buildingTz: 'Australia/Melbourne',
  operationalDays: [0, 1, 2, 3, 4, 5, 6],
  data: {
    selectedResource: undefined,
    booking: undefined,
  },
  filter: {
    resourceTypes: [],
    allDay: true,
    start: new Date(),
    end: new Date(),
    openStartDate: false,
    openEndDate: false,
    recurring: {
      isRecurring: false,
      type: ERecurrenceTypes.WEEKLY,
      interval: 1,
      daysOfWeek: [],
    },
  },
};

export const vehicleBodyTypes = [
  'Coupe',
  'Hatchback',
  'Sedan',
  'Station Wagon',
  'Motorbike',
  'SUV',
  'Minivan',
  'Ute',
  'Van',
  'Truck',
];
