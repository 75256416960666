import {
  Amenity,
  ReadAmenitiesActionType,
  UploadImageActionType,
  UploadIMG,
  ListContacts,
  ReadListContactsActionType,
  MeetingRoomSchedule,
  ReadMeetingRoomScheduleActionType,
  MeetingroomServicesOption,
  ReadMeetingroomServicesOptionsActionType,
} from '../../../constants';
import { QueryState } from '..';

import {
  ReadAmenitiesAction,
  UploadMeetingRoomImageAction,
  ReadListContactsAction,
  ReadMeetingRoomScheduleAction,
  ReadMeetingroomServicesOptionsAction,
} from '../../../actions';

export interface AmenityState extends QueryState {
  isFetching: boolean;
  amenities: Amenity[];
  error?: string;
}

const initState: AmenityState = {
  isFetching: false,
  amenities: [],
  pageInfo: {
    total: 0,
  },
};

export function amenity(state = initState, action: ReadAmenitiesAction): AmenityState {
  switch (action.type) {
    /** READ */
    case ReadAmenitiesActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadAmenitiesActionType.response:
      return {
        ...state,
        isFetching: false,
        amenities: [...action.data],
        pageInfo: action.pageInfo,
      };
    case ReadAmenitiesActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    default:
      return state;
  }
}

export interface ImageMeetingRoomState {
  isFetching: boolean;
  image: UploadIMG;
  error?: string;
}

const initImageState: ImageMeetingRoomState = {
  isFetching: false,
  image: {
    imageURL: '',
    status: false,
    message: '',
  },
};

export function imageMeetingRoom(state = initImageState, action: UploadMeetingRoomImageAction): ImageMeetingRoomState {
  switch (action.type) {
    /** Upload */
    case UploadImageActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case UploadImageActionType.response:
      return {
        ...state,
        isFetching: false,
        image: action.data,
      };
    case UploadImageActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    default:
      return state;
  }
}

export interface ListContactsState extends QueryState {
  isFetching: boolean;
  listcontacts: ListContacts[];
  error?: string;
}

const initListContactsState: ListContactsState = {
  isFetching: false,
  listcontacts: [],
  pageInfo: {
    total: 0,
  },
};

export function listcontact(state = initListContactsState, action: ReadListContactsAction): ListContactsState {
  switch (action.type) {
    /** READ */
    case ReadListContactsActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadListContactsActionType.response:
      return {
        ...state,
        isFetching: false,
        listcontacts: [...action.data],
        pageInfo: action.pageInfo,
      };
    case ReadListContactsActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    default:
      return state;
  }
}

export interface MeetingRoomScheduleState extends QueryState {
  isFetching: boolean;
  schedule: MeetingRoomSchedule[];
  error?: string;
}

const initMeetingRoomScheduleState: MeetingRoomScheduleState = {
  isFetching: false,
  schedule: [],
  pageInfo: {
    total: 0,
  },
};

export function meetingRoomSchedule(
  state = initMeetingRoomScheduleState,
  action: ReadMeetingRoomScheduleAction,
): MeetingRoomScheduleState {
  switch (action.type) {
    /** READ */
    case ReadMeetingRoomScheduleActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadMeetingRoomScheduleActionType.response:
      return {
        ...state,
        isFetching: false,
        schedule: [...action.data],
        pageInfo: action.pageInfo,
      };
    case ReadMeetingRoomScheduleActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    default:
      return state;
  }
}

export interface MeetingroomServicesOptionsState extends QueryState {
  isFetching: boolean;
  meetingroomServicesOptions: MeetingroomServicesOption[];
  error?: string;
}

const initServicesState: MeetingroomServicesOptionsState = {
  isFetching: false,
  meetingroomServicesOptions: [],
  pageInfo: {
    total: 0,
  },
};

export function meetingroomServicesOptions(
  state = initServicesState,
  action: ReadMeetingroomServicesOptionsAction,
): MeetingroomServicesOptionsState {
  switch (action.type) {
    /** READ */
    case ReadMeetingroomServicesOptionsActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadMeetingroomServicesOptionsActionType.response:
      return {
        ...state,
        isFetching: false,
        meetingroomServicesOptions: [...action.data],
        pageInfo: action.pageInfo,
      };
    case ReadMeetingroomServicesOptionsActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    default:
      return state;
  }
}
