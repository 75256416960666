import React from 'react';
import { Grid, Typography, Chip } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../middlewares/nura-client/lib/redux/reducers';
import { ReducerOutlookOfficeAddIn } from '../../../../../middlewares/nura-client/lib/redux/reducers/api/officeAddIns';
import { IOutlookAddInMeetingRoomCateringMenu } from '../../../../../middlewares/nura-client/lib/redux/constants';

interface IProps {
  menuSelected: IOutlookAddInMeetingRoomCateringMenu;
  handleOnSelectMenu: (newMenu: IOutlookAddInMeetingRoomCateringMenu) => void;
}
export default function MenuItems({ menuSelected, handleOnSelectMenu }: IProps): JSX.Element {
  const { resource } = useSelector<RootState, ReducerOutlookOfficeAddIn.IOutlookAddInState>(
    ({ outlookAddIn }) => outlookAddIn,
  );

  return (
    <>
      {resource?.catering.map((menu, index) => {
        const isSelected = Number(menuSelected?.id) === Number(menu.id);
        return (
          <Grid item xs={4} key={index}>
            <Chip
              sx={{ width: '100%', height: 75 }}
              label={
                <Typography
                  variant="body2"
                  style={{ whiteSpace: 'normal', textAlign: 'center', color: isSelected ? 'white' : '#2E2F2F' }}
                >
                  {menu.name}
                </Typography>
              }
              onClick={() => handleOnSelectMenu(menu)}
              clickable
              color={isSelected ? 'primary' : 'default'}
            />
          </Grid>
        );
      })}
    </>
  );
}
