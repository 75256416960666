import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IResourcesItem } from '../../middlewares/nura-client/lib/redux/constants';
import { classes } from './styles';

interface IAccordionService {
  expanded?: string;
  handleExpend?: (newValue: Pick<IResourcesItem, 'id' | 'name' | 'displayName'>) => void;
  item: Pick<IResourcesItem, 'id' | 'name' | 'displayName'>;
  children?: React.ReactNode;
}

/**
 * List of services with Accordion Styles
 * @returns
 */
export default function AccordionService({ expanded, handleExpend, item, children }: IAccordionService): JSX.Element {
  if (!item) return null;

  return (
    <Accordion
      key={item?.id}
      expanded={Number(expanded) === Number(item?.id)}
      onChange={() => handleExpend(item)}
      style={{ overflowX: 'hidden', overflowY: 'auto' }}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            style={{
              color: Number(expanded) === Number(item?.id) ? '#FFF' : null,
            }}
          />
        }
        aria-controls={`panel${item?.id}bh-content`}
        id={`panel${item?.id}bh-header`}
        style={Number(expanded) === Number(item?.id) ? classes.headerActive : classes.headerNoActive}
      >
        <Typography
          variant="subtitle1"
          style={Number(expanded) === Number(item?.id) ? classes.headerTextActive : classes.headerTextNoActive}
        >
          {item?.displayName || item?.name}
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ overflowX: 'hidden', overflowY: 'auto' }}>{children}</AccordionDetails>
    </Accordion>
  );
}
