export enum LoginWithProviderActionType {
  request = 'loginWithProvider.request',
  response = 'loginWithProvider.response',
  select = 'loginWithProvider.select',
  objectId = 'loginWithProvider.objectId',
  error = 'loginWithProvider.error',
}

export enum UserLoginProvider {
  ACTIVE_DIRECTORY = 'ACTIVE_DIRECTORY',
  GOOGLE = 'GOOGLE',
  APPLE = 'APPLE',
}
