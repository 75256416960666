export interface PolicySetting {
  name: string;
  displayName?: string;
  category: string;
  categoryType: string;
}

export interface Settings {
  policy?: {
    categories: string[];
    policies: PolicySetting[];
    requiredPolicies: PolicySetting[];
  };
  app?: {
    floorplanRefresh: number;
  };
  web?: {
    navigation?: {
      sidebar?: {
        [index: string]: {
          hide: boolean;
        };
      };
    };
    pages?: {
      [index: string]: {
        hideInviteButton: boolean;
      };
    };
  };
}

export enum ReadSettingsActionType {
  request = 'read.settings.request',
  response = 'read.settings.response',
  error = 'read.settings.error',
}
