import React, { useEffect, useMemo, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import { useFilterBookings } from '../../../providers/FilterBookings';
import AutoCompleteCustom from '../../../components/AutoCompleteCustom';
import LayersIcon from '@mui/icons-material/Layers';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../middlewares/nura-client/lib/redux/reducers';
import { ReducerOutlookOfficeAddIn } from '../../../middlewares/nura-client/lib/redux/reducers/api/officeAddIns';
import { getTokens } from '../../../middlewares/nura-helpers/lib/helpers/auth';
import { useSnackbar } from 'notistack';
import { ActionOutlookOfficeAddIn } from '../../../middlewares/nura-client/lib/redux/actions';
import { IAutoCompleteCustom } from '../../../middlewares/nura-client/lib/redux/constants';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

export default function BookingLocation(): JSX.Element {
  const dispatch = useDispatch();
  const { width: widthHeight } = useWindowDimensions();
  const { enqueueSnackbar } = useSnackbar();
  const { accessToken: nuraAccessToken } = getTokens();
  const { resources } = useSelector<RootState, ReducerOutlookOfficeAddIn.IOutlookAddInState>(
    ({ outlookAddIn }) => outlookAddIn,
  );
  const { state, dispatch: dispatchFilter } = useFilterBookings();

  const [buildings, setBuildings] = useState<IAutoCompleteCustom[]>([]);
  const [floors, setFloors] = useState<IAutoCompleteCustom[]>([]);

  /** Fetching Resources */
  const fetchResources = async () => {
    try {
      await dispatch(ActionOutlookOfficeAddIn.readResourcesForRoomAvailabilities());
    } catch (error) {
      enqueueSnackbar(`Error: ${error?.message}`, { variant: 'error' });
    }
  };
  useEffect(() => {
    if (nuraAccessToken) {
      fetchResources();
    }
  }, [nuraAccessToken]);

  //buildings
  useEffect(() => {
    const newBuildings: IAutoCompleteCustom[] = resources.buildings?.map(({ id, name }) => {
      return {
        id,
        label: name,
      };
    });
    setBuildings(newBuildings);
    //Floors
    if (newBuildings?.length > 0) {
      const newFloors: IAutoCompleteCustom[] = resources.buildings[0].floors?.map(({ id, name }) => {
        return {
          id,
          label: name,
        };
      });
      setFloors(newFloors);
    }

    return () => {
      setBuildings([]);
    };
  }, [resources.buildings]);

  useEffect(() => {
    const buildingSelected = resources.buildings?.find(
      (itemBuilding) => Number(itemBuilding.id) === Number(state.filter?.building?.id),
    );
    const currentFloors = buildingSelected?.floors?.map(({ id, name }) => {
      return {
        id,
        label: name,
      };
    });
    setFloors(currentFloors || []);
  }, [state.filter.building, resources.buildings]);

  const renderBuildings = useMemo(() => {
    const handleBuildingChange = (event: any, newValue: IAutoCompleteCustom | undefined | null) => {
      dispatchFilter({
        type: 'setParams',
        payload: {
          ...state.filter,
          building: newValue,
          floor: undefined,
        },
      });
    };
    return (
      <>
        {buildings.length > 0 ? (
          <AutoCompleteCustom
            options={buildings}
            placeholder="Select a building"
            value={state?.filter?.building?.label}
            onChange={handleBuildingChange}
            width={Number(widthHeight) < 350 ? 120 : 200}
          />
        ) : (
          <AutoCompleteCustom
            options={[]}
            placeholder="Select a building"
            value={undefined}
            onChange={handleBuildingChange}
            width={Number(widthHeight) < 350 ? 120 : 200}
          />
        )}
      </>
    );
  }, [buildings, dispatchFilter, state.filter, widthHeight]);

  const renderFloors = useMemo(() => {
    const handleChange = (event: any, newValue: IAutoCompleteCustom | undefined | null) => {
      dispatchFilter({
        type: 'setParams',
        payload: {
          ...state.filter,
          floor: newValue,
        },
      });
    };
    return (
      <>
        {floors.length > 0 ? (
          <AutoCompleteCustom
            options={floors}
            placeholder="Select a floor"
            value={state?.filter?.floor?.label}
            onChange={handleChange}
            width={Number(widthHeight) < 350 ? 120 : 200}
          />
        ) : (
          <AutoCompleteCustom
            options={[]}
            placeholder="Select a floor"
            value={undefined}
            onChange={handleChange}
            width={Number(widthHeight) < 350 ? 120 : 200}
          />
        )}
      </>
    );
  }, [floors, dispatchFilter, state.filter, widthHeight]);

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ mb: 1 }}>
          <Box>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
              <BusinessIcon color="primary" />
              <Typography variant="body2">Building</Typography>
            </Stack>
          </Box>
          {renderBuildings}
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ mb: 1 }}>
          <Box>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
              <LayersIcon color="primary" />
              <Typography variant="body2">Floor</Typography>
            </Stack>
          </Box>
          {renderFloors}
        </Stack>
      </Box>
    </>
  );
}
