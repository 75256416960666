import React, { useEffect, useMemo } from 'react';
import { Box, Grid, IconButton, Tooltip, Chip, Stack } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux';
import { RootState } from '../../middlewares/nura-client/lib/redux/reducers';
import { ReducerOutlookOfficeAddIn } from '../../middlewares/nura-client/lib/redux/reducers/api/officeAddIns';
import { useBookingDetails } from '../../contexts/BookingDetails';
import DeleteIcon from '@mui/icons-material/Delete';
import { ICateringMenuSelected } from '../../middlewares/nura-client/lib/redux/constants';
import { useMailbox } from '../../contexts';
import { DateTime } from 'luxon';
import { format } from 'date-fns';
interface ICateringControlledAccordion {
  componentType?: 'preview' | 'booking';
}
const CateringControlledAccordion = ({ componentType = 'booking' }: ICateringControlledAccordion): JSX.Element => {
  const { state: stateMailbox } = useMailbox();
  const { state: bookingState, dispatch: bookingDispatch } = useBookingDetails();
  const { resource } = useSelector<RootState, ReducerOutlookOfficeAddIn.IOutlookAddInState>(
    ({ outlookAddIn }) => outlookAddIn,
  );
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    if (bookingState?.catering?.cateringMenus?.length > 0 && stateMailbox?.start) {
      const selectedFoodItems = bookingState.catering.cateringMenus;

      const newFilter = selectedFoodItems?.map((itemNewDelivery) => {
        if (itemNewDelivery?.deliveredAt && stateMailbox.start && stateMailbox.end) {
          const currentStartBooking = new Date(stateMailbox.start);
          const currentEndBooking = new Date(stateMailbox.end);
          const bookingStartDate = format(currentStartBooking, 'MM/dd/yyyy');
          const dateTimeDelivery = new Date(`${bookingStartDate} ${itemNewDelivery.deliveredAt}`);
          if (
            dateTimeDelivery?.getTime() < currentStartBooking.getTime() ||
            dateTimeDelivery.getTime() > currentEndBooking.getTime()
          ) {
            const newDeliveryTime = DateTime.fromJSDate(new Date(stateMailbox.start)).toFormat('h:mm a');
            itemNewDelivery.deliveredAt = newDeliveryTime;
          }
        }
        return itemNewDelivery;
      });

      bookingDispatch({
        type: 'setCateringMenus',
        payload: {
          currentMenus: newFilter,
        },
      });
    }
  }, [stateMailbox?.start, stateMailbox?.end]);

  const renderFoodAccordion = useMemo(() => {
    const handleRemoveFood = (item: ICateringMenuSelected) => {
      const selectedFoodItems = bookingState.catering.cateringMenus;
      const newFilter = selectedFoodItems?.filter((itemFilter) => Number(itemFilter.id) !== Number(item.id));
      bookingDispatch({
        type: 'setCateringMenus',
        payload: {
          currentMenus: newFilter,
        },
      });
    };
    const dataCatering = resource?.catering;

    const selectedFoodItems = bookingState.catering.cateringMenus;
    if (dataCatering.length === 0) return null;
    if (selectedFoodItems.length === 0) return null;

    return (
      <>
        {selectedFoodItems?.map((foodITem) => {
          const getMenu = dataCatering.find((itemMenu) => Number(itemMenu.id) === Number(foodITem.menu));
          const getPackage = getMenu.packages.find(
            (itemPackage) => Number(itemPackage.id) === Number(foodITem.package),
          );
          const getFoods = getPackage.foodItems.filter((itemFood) => foodITem.foodItems.includes(itemFood.id));
          const foodNames = getFoods.map(({ name, displayName }) => displayName || name);
          return (
            <Accordion
              key={foodITem.id}
              expanded={expanded === `panel-${foodITem.id}`}
              onChange={handleChange(`panel-${foodITem.id}`)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${foodITem.id}bh-content`}
                id={`panel${foodITem.id}bh-header`}
              >
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                  <Typography sx={{ flexShrink: 0 }}>{getMenu.displayName || getMenu.name}</Typography>
                  {componentType === 'booking' && (
                    <Typography sx={{ color: 'text.secondary' }}>
                      {getPackage.displayName || getPackage.name}{' '}
                    </Typography>
                  )}
                  <Chip label={foodNames.length} />
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={0}>
                  <Grid item xs={10}>
                    <Grid container spacing={0}>
                      <Grid item xs={12}>
                        <Typography variant="body2">Foods : {foodNames.join(', ')} </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body2">Quantity : {foodITem.quantity} </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body2">Delivery : {foodITem.deliveredAt} </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {componentType === 'booking' && (
                    <Grid item xs={2}>
                      <Grid container justifyContent="flex-end">
                        <Tooltip title={`Delete ${getPackage.displayName || getPackage.name}`} placement="left" arrow>
                          <IconButton
                            color="secondary"
                            aria-label="delete package"
                            component="label"
                            onClick={() => handleRemoveFood(foodITem)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </>
    );
  }, [resource, bookingState.catering.cateringMenus, expanded, bookingDispatch, componentType]);
  return <Box>{renderFoodAccordion}</Box>;
};

export default CateringControlledAccordion;
