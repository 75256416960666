import { Mailbox } from '../../constants';
import { ICurrentUser, OfficeAccessTokenDecoded } from '../../constants/Auth';
import { v5 as uuIDv5 } from 'uuid';
import JwtDecode from 'jwt-decode';
import { setOutlookDeviceId } from '../../middlewares/nura-helpers/lib/helpers/auth';

/**
 * Get User Identity Token
 * @returns
 */
export const getUserIdentityToken = async (): Promise<string | undefined> => {
  try {
    const mailbox = Office.context.mailbox;
    if (!mailbox) return undefined;
    await mailbox.getUserIdentityTokenAsync((asyncResult: Office.AsyncResult<string>) => {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        return asyncResult.value;
      }
    });
    return undefined;
  } catch (error) {
    return undefined;
  }
};

export const getAccessToken = async (): Promise<string | undefined> => {
  try {
    const result = await Office.auth.getAccessToken();
    return result || undefined;
  } catch (error) {
    return undefined;
  }
};

export const getMailBoxItems = async (): Promise<Mailbox.IMailboxItem> => {
  let response = Mailbox.initialIMailboxItem;
  try {
    await Office.context.mailbox.item.subject.getAsync((asyncResult: Office.AsyncResult<string>) => {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        response = { ...response, subject: asyncResult.value };
      }
    });

    await Office.context.mailbox.item.requiredAttendees.getAsync(
      (asyncResult: Office.AsyncResult<Office.EmailAddressDetails[]>) => {
        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
          response = { ...response, requiredAttendees: asyncResult.value };
        }
      },
    );
    return response;
  } catch (error) {
    return response;
  }
};

export const getOfficeUserInfo = async (): Promise<ICurrentUser> => {
  try {
    const mailbox = Office.context?.mailbox;

    if (mailbox?.userProfile) {
      return {
        isAuth: true,
        name: mailbox.userProfile.displayName || undefined,
        email: mailbox.userProfile.emailAddress || undefined,
      };
    }

    return { isAuth: false };
  } catch (error) {
    return { isAuth: false };
  }
};

export const getAndSetOutlookId = async (): Promise<string> => {
  try {
    const accessToken = await getAccessToken();
    const userDecode = await JwtDecode<OfficeAccessTokenDecoded>(accessToken);
    const deviceId = uuIDv5('oid', userDecode.oid);
    if (deviceId) {
      setOutlookDeviceId(deviceId);
      return deviceId;
    }
    return undefined;
  } catch (error) {}
};

export const getSubject = async (): Promise<string> => {
  let subject = '';
  await Office.context.mailbox.item.subject.getAsync((asyncResult: Office.AsyncResult<string>) => {
    if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
      subject = asyncResult.value;
    } else {
      subject = undefined;
    }
  });
  return subject;
};

export const clearAllStorage = (): void => {
  localStorage.clear();
};
