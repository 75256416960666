import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { api, DefaultResponse } from '../../../../../../../nura-helpers/lib/services/api';
import { RootState } from '../../../../reducers';
import { getCompanyId } from '../../../../../../../nura-helpers/lib/helpers/auth';
import {
  IMeetingRoomOrganizersForResponse,
  ReadMeetingRoomOrganizersForOutlookActionType,
} from '../../../../constants';

export interface ReadMeetingRoomOrganizersForOutlookAction
  extends Action<ReadMeetingRoomOrganizersForOutlookActionType> {
  error?: any;
  data?: IMeetingRoomOrganizersForResponse[];
}

export interface ReadMeetingRoomOrganizersForOutlookResult {
  data: IMeetingRoomOrganizersForResponse[];
}

type ReadMeetingRoomOrganizersForOutlookResponse = DefaultResponse & ReadMeetingRoomOrganizersForOutlookResult;

export interface IReadMeetingRoomOrganizersParams {
  key: string;
  companyId?: string;
  accessToken: string;
}

export async function readOrganizersForOutlookAsync({
  key,
  accessToken,
}: IReadMeetingRoomOrganizersParams): Promise<ReadMeetingRoomOrganizersForOutlookResult> {
  const scope = getCompanyId();

  const response = await api('/api/outlook/booking/meetingRoom/organizers', {
    outlookId: true,
  }).post<ReadMeetingRoomOrganizersForOutlookResponse>({
    companyId: `${scope}`,
    key,
    accessToken,
  });
  if (response.status !== 200) throw response;

  if (!response || !response.data) {
    throw response;
  }
  return {
    data: response.data,
  };
}

export function readOrganizersForOutlook(
  params: IReadMeetingRoomOrganizersParams,
): ThunkAction<
  Promise<ReadMeetingRoomOrganizersForOutlookResult>,
  RootState,
  unknown,
  ReadMeetingRoomOrganizersForOutlookAction
> {
  return async (dispatch) => {
    try {
      dispatch({
        type: ReadMeetingRoomOrganizersForOutlookActionType.request,
      });

      const response = await readOrganizersForOutlookAsync(params);

      dispatch({
        type: ReadMeetingRoomOrganizersForOutlookActionType.response,
        data: response.data,
      });
      return {
        data: response.data,
      };
    } catch (e) {
      dispatch({
        type: ReadMeetingRoomOrganizersForOutlookActionType.error,
        error: e,
      });
      throw e;
    }
  };
}
