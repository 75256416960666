import FingerprintJS, { Agent } from '@fingerprintjs/fingerprintjs';

export default class FingerprintService {
  private static _instance: FingerprintService;

  public static get instance(): FingerprintService {
    if (!this._instance) this._instance = new FingerprintService();
    return this._instance;
  }

  fp: Agent;

  constructor() {
    if (!this.fp) {
      this.init();
    }
  }

  public async init(): Promise<void> {
    try {
      this.fp = await FingerprintJS.load();
    } catch (err) {
      // Do nothing
    }
  }

  public async getVisitorId(): Promise<string> {
    const res = await this.fp.get();

    delete res.components.screenFrame;
    delete res.components.screenResolution;
    delete res.components.colorDepth;
    delete res.components.colorGamut;
    delete res.components.forcedColors;
    delete res.components.invertedColors;
    delete res.components.plugins;
    delete res.components.touchSupport;
    delete res.components.monochrome;
    delete res.components.reducedMotion;
    delete res.components.hdr;
    delete res.components.contrast;

    const hash = FingerprintJS.hashComponents(res.components);
    return hash;
  }
}
