export class SingletonPattern {
  protected static _instance: SingletonPattern;
  protected constructor() {}
  public static get instance(): SingletonPattern {
    if (!this._instance) {
      this._instance = new SingletonPattern();
    }
    return this._instance;
  }
}

export class ExtendedSingleton extends SingletonPattern {
  protected static _instance: ExtendedSingleton;
  public static get instance(): ExtendedSingleton {
    if (!this._instance) {
      this._instance = new ExtendedSingleton();
    }
    return this._instance;
  }
}
