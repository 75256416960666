import React, { ChangeEvent, useMemo } from 'react';
import { Grid, Radio, SelectChangeEvent, Typography } from '@mui/material';
import {
  EDayOfWeek,
  EOnDayBookingRecurrence,
  ERecurrencePatternType,
  EWeekIndex,
} from '../../../../middlewares/nura-client/lib/redux/constants';
import SelectCustom from '../../../SelectCustom';
import { addMonths, addYears, format, getMonth, setDate, setMonth } from 'date-fns';
import { useBookingDetails } from '../../../../contexts/BookingDetails';
import { getDataRecurringEventTypes } from '../../../../helpers';
import {
  REACT_APP_RECURRING_MAX_INTERVAL_MONTHS,
  getAbsoluteOptions,
  weekDaysOptions,
  weekIndexOptions,
} from '../../../../constants/AddIn';
import { getNthDayOfMonth } from '../../../../helpers/recurrence';

const OnRadioBooking = (): JSX.Element => {
  const { state: bookingState, dispatch: bookingDispatch } = useBookingDetails();
  const {
    absoluteMonthly,
    absoluteYearly,
    selectedUIType,
    relativeMonthly,
    relativeYearly,
    range: dateRange,
  } = bookingState?.recurringEvents;

  const renderAbsoluteRadio = useMemo(() => {
    const newState = bookingState?.recurringEvents;
    let dayOptions = getAbsoluteOptions(dateRange?.startDate ? new Date(dateRange.startDate) : new Date());
    const dayOfMonth = absoluteMonthly?.pattern?.dayOfMonth || absoluteYearly?.pattern?.dayOfMonth;

    let currentDayOfMonthValue = dayOfMonth ? dayOfMonth?.toString() : format(new Date(), 'd');
    const handleAbsoluteChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.value) {
        const newState = getDataRecurringEventTypes({
          state: bookingState,
          type: event.target.value,
          pattern: 'absolute',
        });
        bookingDispatch({
          type: 'setRecurringEvents',
          payload: { recurringEvents: { ...newState.recurringEvents } },
        });
      }
    };

    const handleSelectChange = (event: SelectChangeEvent) => {
      const currentStartDateRange = newState?.range?.startDate;

      if (currentStartDateRange) {
        const newStartDateRange = setDate(new Date(currentStartDateRange), Number(event.target.value));
        if (newStartDateRange) {
          newState.range.startDate = newStartDateRange?.toString();
          newState.range.endDate = addMonths(newStartDateRange, REACT_APP_RECURRING_MAX_INTERVAL_MONTHS)?.toString();
        }
      }

      if (absoluteYearly?.pattern && event?.target?.value) {
        newState.absoluteYearly.pattern.dayOfMonth = Number(event.target.value);
      }

      if (absoluteMonthly?.pattern && event?.target?.value) {
        newState.absoluteMonthly.pattern.dayOfMonth = Number(event.target.value);
      }
      bookingDispatch({
        type: 'setRecurringEvents',
        payload: { recurringEvents: { ...bookingState.recurringEvents, ...newState } },
      });
    };

    if (newState?.absoluteYearly?.pattern?.month) {
      const monthSelected = newState.absoluteYearly.pattern.interval;
      let dateModified = setMonth(new Date(), Number(monthSelected - 1));

      if (dateModified?.getTime() < new Date()?.getTime()) {
        dateModified = addYears(dateModified, 1);
      }
      dayOptions = getAbsoluteOptions(dateModified);
    }

    if (!dayOptions?.map((dayOption) => dayOption.value).includes(currentDayOfMonthValue)) {
      currentDayOfMonthValue = dayOptions[0]?.value;
    }

    return (
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <Radio
            checked={dayOfMonth ? true : false}
            onChange={handleAbsoluteChange}
            value={selectedUIType}
            name="radio-buttons"
            sx={{
              p: 0,
              mb: 1,
              opacity: dayOfMonth ? 1 : 0.5,
            }}
            size="small"
          />
        </Grid>
        <Grid item>
          <Grid container spacing={1}>
            <Grid item>
              <SelectCustom
                options={dayOptions}
                minWidth={80}
                value={currentDayOfMonthValue}
                handleChange={handleSelectChange}
                disabled={!dayOfMonth}
              />
            </Grid>
            <Grid item>
              <Typography variant="body2" component="span" sx={{ opacity: dayOfMonth ? 1 : 0.5 }}>
                day(s)
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }, [
    absoluteMonthly?.pattern?.dayOfMonth,
    absoluteYearly?.pattern?.dayOfMonth,
    absoluteYearly?.pattern?.month,
    bookingState,
    selectedUIType,
    dateRange?.startDate,
  ]);

  const renderRelativeRadio = useMemo(() => {
    const newState = bookingState?.recurringEvents;
    const isRelative = relativeMonthly || relativeYearly;

    const nthOfMonthlyOrYearly = relativeMonthly?.pattern?.index || relativeYearly?.pattern?.index;

    const daysOfWeekOfMonthlyOrYearly =
      relativeMonthly?.pattern?.daysOfWeek[0] || relativeYearly?.pattern?.daysOfWeek[0];

    const handleRelativeChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.value) {
        const newState = getDataRecurringEventTypes({
          state: bookingState,
          type: event.target.value,
          pattern: 'relative',
        });

        const currentNthOfMonthlyOrYearly =
          newState?.recurringEvents?.relativeMonthly?.pattern?.index ||
          newState?.recurringEvents?.relativeYearly?.pattern?.index;

        const currentDaysOfWeekOfMonthlyOrYearly =
          newState?.recurringEvents?.relativeMonthly?.pattern?.daysOfWeek[0] ||
          newState?.recurringEvents?.relativeYearly?.pattern?.daysOfWeek[0];

        if (currentNthOfMonthlyOrYearly && currentDaysOfWeekOfMonthlyOrYearly) {
          const newStartDate = getNthDayOfMonth({
            startDate: newState?.recurringEvents?.range?.startDate
              ? new Date(newState.recurringEvents.range.startDate)
              : new Date(dateRange.startDate),
            nth: currentNthOfMonthlyOrYearly,
            dayOfWeek: currentDaysOfWeekOfMonthlyOrYearly,
          });

          newState.recurringEvents.range.startDate = newStartDate?.toDateString();
          newState.recurringEvents.range.endDate = addMonths(
            newStartDate,
            REACT_APP_RECURRING_MAX_INTERVAL_MONTHS,
          )?.toDateString();
          if (newState?.recurringEvents?.relativeYearly?.pattern?.index) {
            newState.recurringEvents.relativeYearly.pattern.interval = getMonth(newStartDate) + 1; // get month response JAN is 0
          }
        }

        bookingDispatch({
          type: 'setRecurringEvents',
          payload: { recurringEvents: { ...newState.recurringEvents } },
        });
      }
    };

    const handleIndexChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.value) {
        const newStateIndex = bookingState?.recurringEvents;

        if (relativeMonthly?.pattern?.daysOfWeek[0] || relativeYearly?.pattern?.daysOfWeek[0]) {
          const newStartDate = getNthDayOfMonth({
            startDate: new Date(dateRange.startDate),
            nth: event.target.value,
            dayOfWeek: relativeMonthly?.pattern?.daysOfWeek[0] || relativeYearly?.pattern?.daysOfWeek[0],
          });
          newStateIndex.range.startDate = newStartDate?.toDateString();
          newStateIndex.range.endDate = addMonths(
            newStartDate,
            REACT_APP_RECURRING_MAX_INTERVAL_MONTHS,
          )?.toDateString();
          if (newState?.relativeYearly?.pattern?.interval) {
            newState.relativeYearly.pattern.interval = newStartDate.getMonth() + 1;
          }
        }

        if (relativeMonthly) {
          newStateIndex.relativeYearly = undefined;
          newStateIndex.relativeMonthly.pattern.index = EWeekIndex[event.target.value?.toUpperCase()];
        }

        if (relativeYearly) {
          newStateIndex.relativeMonthly = undefined;
          newStateIndex.relativeYearly.pattern.index = EWeekIndex[event.target.value?.toUpperCase()];
        }

        bookingDispatch({
          type: 'setRecurringEvents',
          payload: {
            recurringEvents: {
              ...bookingState.recurringEvents,
              ...newStateIndex,
            },
          },
        });
      }
    };

    const handleRelativeOnDayChange = (event: SelectChangeEvent) => {
      const currentValueDayOfWeek =
        EDayOfWeek[event.target.value?.replace(' ', '_').toUpperCase()] ||
        EOnDayBookingRecurrence[event.target.value?.replace(' ', '_').toUpperCase()]?.toLowerCase();

      if (currentValueDayOfWeek) {
        if (relativeMonthly?.pattern?.daysOfWeek) {
          newState.relativeMonthly.pattern.daysOfWeek = [currentValueDayOfWeek];
        }
        if (relativeYearly?.pattern?.daysOfWeek) {
          newState.relativeYearly.pattern.daysOfWeek = [currentValueDayOfWeek];
        }

        if (nthOfMonthlyOrYearly || daysOfWeekOfMonthlyOrYearly) {
          const newStartDate = getNthDayOfMonth({
            startDate: new Date(dateRange.startDate),
            nth: nthOfMonthlyOrYearly,
            dayOfWeek: currentValueDayOfWeek,
          });
          newState.range.startDate = newStartDate?.toDateString();
          newState.range.endDate = addMonths(newStartDate, REACT_APP_RECURRING_MAX_INTERVAL_MONTHS)?.toDateString();
          if (newState?.relativeYearly?.pattern?.interval) {
            newState.relativeYearly.pattern.interval = newStartDate.getMonth() + 1;
          }
        }

        bookingDispatch({
          type: 'setRecurringEvents',
          payload: { recurringEvents: { ...newState } },
        });
      }
    };

    const currentRadioValue = absoluteMonthly?.pattern?.type
      ? ERecurrencePatternType.RELATIVE_MONTHLY
      : ERecurrencePatternType.RELATIVE_YEARLY;

    const weekIndexValue = relativeMonthly?.pattern?.index || relativeYearly?.pattern?.index;

    const currentDaysOfWeek =
      newState?.relativeYearly?.pattern?.daysOfWeek || newState?.relativeMonthly?.pattern?.daysOfWeek;

    return (
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <Radio
            checked={isRelative ? true : false}
            onChange={handleRelativeChange}
            value={currentRadioValue}
            name="radio-buttons"
            sx={{
              p: 0,
              mb: 1,
              opacity: isRelative ? 1 : 0.5,
            }}
            size="small"
          />
        </Grid>
        <Grid item>
          <Grid container spacing={1}>
            <Grid item>
              <SelectCustom
                options={weekIndexOptions}
                minWidth={80}
                handleChange={handleIndexChange}
                value={weekIndexValue || EWeekIndex.FIRST}
                disabled={!isRelative}
              />
            </Grid>
            <Grid item>
              <SelectCustom
                // options2={defaultOnDayOptions}
                options={weekDaysOptions}
                minWidth={80}
                handleChange={handleRelativeOnDayChange}
                value={
                  currentDaysOfWeek?.length > 0
                    ? currentDaysOfWeek[0]?.toLowerCase()
                    : format(new Date(), 'EEEE')?.toLowerCase()
                }
                disabled={!isRelative}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }, [
    relativeMonthly,
    relativeYearly,
    absoluteMonthly?.pattern?.type,
    bookingState,
    relativeMonthly?.pattern?.index,
    relativeYearly?.pattern?.index,
    relativeMonthly?.pattern?.daysOfWeek,
    relativeYearly?.pattern?.daysOfWeek,
    dateRange.startDate,
  ]);

  return (
    <Grid item xs={12} sx={{ p: 0, m: 0 }}>
      {renderAbsoluteRadio}
      {renderRelativeRadio}
    </Grid>
  );
};

export default OnRadioBooking;
