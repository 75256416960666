/** Admin Dashbaord */

export interface MapState {
  fitBounds?: [[number, number], [number, number]] | any;
  bboxes?: any;
  attributionControl?: boolean;
  center: [number, number];
  zoom?: [number];
  minZoom?: number;
  maxZoom?: number;
}

export interface AdminDashboardTotal {
  companies: number;
  buildings: number;
  floors: number;
  desks: number;
}

export enum ReadAdminDashboardTotalActionType {
  request = 'read.admindashboard.total.request',
  response = 'read.admindashboard.total.response',
  error = 'read.admindashboard.total.error',
}

export interface AdminDashboardMap {
  name: string;
  total: {
    buildings: number;
    floors: number;
    desks: number;
  };
  location: string;
  lat?: number;
  long?: number;
}

export interface AdminDashboardMarker {
  name: string;
  total: {
    buildings: number;
    floors: number;
    desks: number;
  };
  location: string;
  lat?: number;
  long?: number;
  latLong?: [number, number];
  bbox?: [[number, number]];
}

export enum ReadAdminDashboardMapActionType {
  request = 'read.admindashboard.map.request',
  response = 'read.admindashboard.map.response',
  error = 'read.admindashboard.map.error',
}

export interface AdminDashboardUtilisation {
  value: number;
}

export enum ReadAdminDashboarUtilisationActionType {
  request = 'read.admindashboard.utilisation.request',
  response = 'read.admindashboard.utilisation.response',
  error = 'read.admindashboard.utilisation.error',
}

interface AdminDashboardChartCompany {
  name: string;
  count: number;
}
export interface AdminDashboardChartCompanies {
  name: number;
  companies: AdminDashboardChartCompany[];
}

export enum ReadAdminDashboardChartActionType {
  request = 'read.admindashboard.chart.request',
  response = 'read.admindashboard.chart.response',
  error = 'read.admindashboard.chart.error',
}

export const MAP_BOX_MIN_ZOOM = 0;
export const MAP_BOX_MAX_ZOOM = 22;
export const SUB_MONTHS_CHART = 6;
export interface MapBoxStyles {
  light: string;
  dark: string;
  basic: string;
  outdoor: string;
}
export const MAP_BOX_STYLES: MapBoxStyles = {
  light: 'mapbox://styles/mapbox/light-v9',
  dark: 'mapbox://styles/mapbox/dark-v9',
  basic: 'mapbox://styles/mapbox/basic-v9',
  outdoor: 'mapbox://styles/mapbox/outdoors-v10',
};
export const MAP_BOX_ACCESS_TOKEN_DEV =
  'pk.eyJ1IjoibnVyYXNwYWNlIiwiYSI6ImNrcW4xMDBncDBrZWgydnE0a3dkanUyNjIifQ.0wb-ykvWSQ28xdrjP1SKsg';
export const MAP_BOX_ACCESS_TOKEN_PROD =
  'pk.eyJ1IjoibnVyYXNwYWNlIiwiYSI6ImNrcW4xMXo2eDBrM3Eyb3E0Mjc0ZjVleDYifQ.x_HKPeFOClKTD0If703h0g';
