import { IRecurringEventsDateSeriesResponse } from '.';
import { IRecurringEventAbsoluteYearly } from '../../middlewares/nura-client/lib/redux/constants';

export const getAbsoluteYearlyDateSeries = (
  data: IRecurringEventAbsoluteYearly,
): IRecurringEventsDateSeriesResponse => {
  return {
    dates: [],
  };
};
