import {
  ReadAttendanceReportParamsAction,
  ReadAttendanceReportAction,
  ReadUserAttendanceReportAction,
  ReadUserAttendanceDayDataAction,
} from '../../../actions/api/attendance';
import { CompleteAttendanceWfhAction } from '../../../actions/api/attendance/completeAttendanceWfh';
import { CreateAttendanceWfhAction } from '../../../actions/api/attendance/createAttendanceWfh';
import {
  Attendance,
  AttendanceReport,
  CompleteAttendanceWfhActionType,
  CreateAttendanceWfhActionType,
  ReadAttendanceReportActionType,
  ReadAttendanceReportParamsActionType,
  ReadUserAttendanceDayDataActionType,
  ReadUserAttendanceReportActionType,
  UserAttendanceDayData,
  UserAttendanceReport,
  UserAttendanceReportDay,
} from '../../../constants';

export interface AttendanceState {
  isFetching: boolean;
  attendance: Attendance;
  attendanceReport: AttendanceReport[];
  attendanceReportParams: {
    companyNames: string[];
    departments: string[];
  };
  attendanceUserReport: UserAttendanceReport;
  userAttendanceDayData: UserAttendanceDayData;
  error?: string;
}

const initState: AttendanceState = {
  isFetching: false,
  attendance: null,
  attendanceReport: [],
  attendanceReportParams: {
    companyNames: [],
    departments: [],
  },
  attendanceUserReport: {
    days: [],
    totalSeconds: 0,
  },
  userAttendanceDayData: null,
  error: null,
};

export function attendance(
  state = initState,
  action:
    | ReadAttendanceReportParamsAction
    | ReadAttendanceReportAction
    | ReadUserAttendanceReportAction
    | ReadUserAttendanceDayDataAction
    | CreateAttendanceWfhAction
    | CompleteAttendanceWfhAction,
): AttendanceState {
  switch (action.type) {
    /** Fetch params for attendance report */
    case ReadAttendanceReportParamsActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadAttendanceReportParamsActionType.response:
      return {
        ...state,
        isFetching: false,
        attendanceReportParams: {
          companyNames: [...action.data.companyNames],
          departments: [...action.data.departments],
        },
      };
    case ReadAttendanceReportParamsActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Attendance report details*/
    case ReadAttendanceReportActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadAttendanceReportActionType.response:
      return {
        ...state,
        isFetching: false,
        attendanceReport: action.data,
      };
    case ReadAttendanceReportActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Attendance user report details*/
    case ReadUserAttendanceReportActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadUserAttendanceReportActionType.response:
      return {
        ...state,
        isFetching: false,
        attendanceUserReport: action.data,
      };
    case ReadUserAttendanceReportActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Attendance latest attendance report details*/
    case ReadUserAttendanceDayDataActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadUserAttendanceDayDataActionType.response:
      return {
        ...state,
        isFetching: false,
        userAttendanceDayData: action.data,
      };
    case ReadUserAttendanceDayDataActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Attendance wfh creation*/
    case CreateAttendanceWfhActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case CreateAttendanceWfhActionType.response:
      return {
        ...state,
        isFetching: false,
        attendance: action.data,
      };
    case CreateAttendanceWfhActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Attendance wfh completion*/
    case CompleteAttendanceWfhActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case CompleteAttendanceWfhActionType.response:
      return {
        ...state,
        isFetching: false,
        attendance: action.data,
      };
    case CompleteAttendanceWfhActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    default:
      return state;
  }
}
