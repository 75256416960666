import {
  AdminDashboardTotal,
  ReadAdminDashboardTotalActionType,
  AdminDashboardMap,
  ReadAdminDashboardMapActionType,
  ReadAdminDashboarUtilisationActionType,
  AdminDashboardChartCompanies,
  ReadAdminDashboardChartActionType,
} from '../../../constants';
import {
  ReadAdminDashboardTotalAction,
  ReadAdminDashboardMapAction,
  ReadAdminDashboardUtilisationAction,
  ReadAdminDashboardChartAction,
} from '../../../actions';

export interface ReadAdminDashboardState {
  isFetching: boolean;
  total: AdminDashboardTotal;
  map: AdminDashboardMap[];
  utilisation: AdminDashboardChartCompanies[];
  overallutilisation: number;
  error?: string;
}

const initState: ReadAdminDashboardState = {
  isFetching: false,
  total: {
    companies: 0,
    buildings: 0,
    floors: 0,
    desks: 0,
  },
  map: [],
  utilisation: [],
  overallutilisation: 0,
};

export function adminDashboard(
  state = initState,
  action:
    | ReadAdminDashboardTotalAction
    | ReadAdminDashboardMapAction
    | ReadAdminDashboardUtilisationAction
    | ReadAdminDashboardChartAction,
): ReadAdminDashboardState {
  switch (action.type) {
    /** READ TOTAL*/
    case ReadAdminDashboardTotalActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadAdminDashboardTotalActionType.response:
      return {
        ...state,
        isFetching: false,
        total: action.data,
      };
    case ReadAdminDashboardTotalActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    /** Read Map */
    case ReadAdminDashboardMapActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadAdminDashboardMapActionType.response:
      return {
        ...state,
        isFetching: false,
        map: action.data,
      };
    case ReadAdminDashboardMapActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    /** Read Utilisation */
    case ReadAdminDashboarUtilisationActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadAdminDashboarUtilisationActionType.response:
      return {
        ...state,
        isFetching: false,
        overallutilisation: action.data?.value,
      };
    case ReadAdminDashboarUtilisationActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Read Chart */
    case ReadAdminDashboardChartActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadAdminDashboardChartActionType.response:
      return {
        ...state,
        isFetching: false,
        utilisation: action.data,
      };
    case ReadAdminDashboardChartActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    default:
      return state;
  }
}
