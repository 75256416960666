import React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { Box, Divider, IconButton, Stack, Typography, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import AvailableDate from './AvailableDate';
import StartTime from './StartTime';
import BookingDuration from './Duration';
import BookingLocation from './Location';
import ServicesManagement from './Management';
import ActionsFilter from './Actions';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import RoomCapacityFilter from './Capacity';

export default function Filter(): JSX.Element {
  const { height: windowsHeight } = useWindowDimensions();
  let navigate = useNavigate();

  const onClose = async () => {
    navigate('/bookings', { replace: true });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        component="div"
        sx={{
          m: 0,
          p: 1,
          height: windowsHeight - 120 || '82vh',
          overflow: 'auto',
          position: 'relative',
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box>
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={0}>
                <Typography variant="body1">Filter search</Typography>
                <IconButton aria-label="close" onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <AvailableDate />
          </Grid>
          <Grid item xs={12}>
            <StartTime />
          </Grid>
          <Grid item xs={12}>
            <BookingDuration />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <BookingLocation />
          </Grid>
          <Grid item xs={12}>
            <RoomCapacityFilter />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <ServicesManagement />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <ActionsFilter />
          </Grid>
        </Grid>
      </Box>
    </LocalizationProvider>
  );
}
