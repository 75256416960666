import {
  Booking,
  ReadAllocationUsersByGroupActionType,
  CreateDeskAllocationActionType,
  UpdateDeskAllocationActionType,
  ReadAllocationAvailabilityActionType,
  AllocationAvailability,
  UserAllocationDetails,
} from '../../../constants';
import {
  ReadAllocationUsersByGroupAction,
  CreateDeskAllocationAction,
  UpdateDeskAllocationAction,
} from '../../../actions';
import { QueryState } from '..';
import { ReadAllocationAvailabilityAction } from '../../../actions/api/allocation/readAllocationsAvailability';

export interface AllocationState extends QueryState {
  isFetching: boolean;
  permanentBooking: Booking;
  updateBooking: {
    id: string;
  };
  error?: string;
  userGroupAllocationDetails: UserAllocationDetails[];
  allocationAvailability: AllocationAvailability[];
}

const initState: AllocationState = {
  isFetching: false,
  userGroupAllocationDetails: [],
  permanentBooking: undefined,
  updateBooking: undefined,
  pageInfo: {
    total: 0,
  },
  allocationAvailability: [],
};

export function allocation(
  state = initState,
  action:
    | ReadAllocationUsersByGroupAction
    | CreateDeskAllocationAction
    | UpdateDeskAllocationAction
    | ReadAllocationAvailabilityAction,
): AllocationState {
  switch (action.type) {
    /** READ */
    case ReadAllocationUsersByGroupActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadAllocationUsersByGroupActionType.response:
      return {
        ...state,
        isFetching: false,
        userGroupAllocationDetails: action.data,
        pageInfo: action.pageInfo,
      };
    case ReadAllocationUsersByGroupActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** CREATE */
    case CreateDeskAllocationActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case CreateDeskAllocationActionType.response:
      return {
        ...state,
        isFetching: false,
        permanentBooking: action.data,
      };
    case CreateDeskAllocationActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** UPDATE */
    case UpdateDeskAllocationActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case UpdateDeskAllocationActionType.response:
      return {
        ...state,
        isFetching: false,
        updateBooking: action.data,
      };
    case UpdateDeskAllocationActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /* allocationAvailability */
    case ReadAllocationAvailabilityActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadAllocationAvailabilityActionType.response:
      return {
        ...state,
        allocationAvailability: action.data,
        isFetching: false,
      };
    case ReadAllocationAvailabilityActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    default:
      return state;
  }
}
