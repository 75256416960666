import React, { useMemo } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { endOfToday } from 'date-fns';
import AutoCompleteCustom from '../../../components/AutoCompleteCustom';
import { dateService } from '../../../services';
import { useMailbox } from '../../../contexts';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { REACT_APP_MEETING_ROOM_MIN_TIME_INTERVAL } from '../../../constants/AddIn';

export default function StartTime(): JSX.Element {
  const { state: mailboxState } = useMailbox();
  const { width: widthHeight } = useWindowDimensions();

  const renderStartTime = useMemo(() => {
    const timeOptions = dateService.getEachMinuteOfInterval({
      start: new Date(),
      end: endOfToday(),
      step: REACT_APP_MEETING_ROOM_MIN_TIME_INTERVAL,
    });
    const handleChange = async (event: any, newValue: string | null) => {
      const currentTime = `${dateService.formatDateTime({
        date: new Date(mailboxState.start),
        format: 'MM/dd/yyyy',
      })} ${newValue}`;
      if (new Date(currentTime)) await Office.context.mailbox.item.start.setAsync(new Date(currentTime));
    };
    if (!mailboxState.start) return null;
    return (
      <AutoCompleteCustom
        options={timeOptions}
        placeholder="Select a start time"
        value={dateService.formatDateTime({ date: new Date(mailboxState.start), format: 'p' })}
        onChange={handleChange}
        width={Number(widthHeight) < 350 ? 120 : 200}
      />
    );
  }, [mailboxState.start, widthHeight]);

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <Box>
          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
            <AccessTimeIcon color="primary" />
            <Typography variant="body2">Start time </Typography>
          </Stack>
        </Box>
        {renderStartTime}
      </Stack>
    </Box>
  );
}
