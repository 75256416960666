import React, { Fragment } from 'react';
import { Box, InputBase, Stack, TextField } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { classes } from '../../styles/theme';
import { handlePreventNumber, numberValidation } from '../../helpers';

interface Props {
  value: number;
  onChange: (value: number) => void;
  label?: string;
  width?: string | number;
  variant?: 'outlined' | 'standard';
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | undefined;
  onTyping?: (value: number) => void;
}

const TextFieldCustomNumber = ({
  width = '100%',
  value,
  onChange,
  label = undefined,
  variant = 'outlined',
  color = undefined,
  onTyping,
}: Props): JSX.Element => {
  const handleTyping = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event?.target?.value) onTyping(Number(0));
    if (event?.target?.value) {
      const isNumber = numberValidation({ value: event.target.value });
      if (isNumber) onTyping(Number(event.target.value));
    }
  };
  return (
    <Fragment>
      {variant === 'standard' ? (
        <Box sx={{ width: width, m: 0, p: 0 }}>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={0}>
            <KeyboardArrowLeftIcon onClick={() => onChange(-1)} style={{ ...classes.iconInput }} color={color} />
            <InputBase
              inputProps={{ style: { textAlign: 'center' } }}
              value={value}
              sx={{ width: 'auto', minWidth: 20 }}
            />
            <KeyboardArrowRightIcon onClick={() => onChange(1)} style={{ ...classes.iconInput }} color={color} />
          </Stack>
        </Box>
      ) : (
        <TextField
          label={label}
          variant={variant}
          value={value}
          inputProps={{ style: { textAlign: 'center' } }}
          onChange={handleTyping}
          onKeyDown={handlePreventNumber}
          sx={{
            '.MuiInputBase-root': {
              width: width,
              minWidth: width,
              px: 0,
              textAlign: 'center',
            },
            '.MuiOutlinedInput-input': {
              textAlign: 'center',
              px: 0,
            },
          }}
          InputProps={{
            startAdornment: (
              <Box style={{ ...classes.boxIconInput, backgroundColor: 'white' }}>
                <KeyboardArrowLeftIcon onClick={() => onChange(-1)} style={{ ...classes.iconInput }} color={color} />
              </Box>
            ),

            endAdornment: (
              <Box style={{ ...classes.boxIconInput, backgroundColor: 'white' }}>
                <KeyboardArrowRightIcon onClick={() => onChange(1)} style={{ ...classes.iconInput }} color={color} />
              </Box>
            ),
          }}
        />
      )}
    </Fragment>
  );
};

export default TextFieldCustomNumber;
