import React, { useMemo } from 'react';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CateringMenuItems from '../CateringMenuItems';
import { Fragment } from 'react';
import CateringControlledAccordion from '../../../../components/CateringControlledAccordion';
import { useBookingDetails } from '../../../../contexts/BookingDetails';
import { useMailbox } from '../../../../contexts';

export default function CateringMenu(): JSX.Element {
  const { state: bookingState, dispatch: bookingDispatch } = useBookingDetails();
  const { state: stateMailbox } = useMailbox();

  const renderTopBarMenu = useMemo(() => {
    const handleOpen = () => {
      bookingDispatch({
        type: 'setAddMenu',
        payload: {
          addMenu: true,
        },
      });
    };

    if (bookingState.addMenu) return null;
    return (
      <>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Typography variant="body1">Food Items</Typography>
            <Button startIcon={<AddIcon />} variant="text" onClick={handleOpen}>
              Add Food Item
            </Button>
          </Stack>
        </Box>
        <Divider />
      </>
    );
  }, [bookingState.addMenu, bookingDispatch]);

  const renderMenuItems = useMemo(() => {
    if (!bookingState.addMenu) return null;

    return <CateringMenuItems />;
  }, [bookingState.addMenu]);

  const renderFoodSelected = useMemo(() => {
    if (bookingState.addMenu || bookingState.catering.cateringMenus.length === 0) return null;

    return <CateringControlledAccordion />;
  }, [bookingState.addMenu, bookingState.catering.cateringMenus, stateMailbox?.start, stateMailbox?.end]);

  return (
    <Box sx={{ mb: 3 }}>
      <Stack spacing={1}>
        {renderTopBarMenu}
        {renderMenuItems}
        {renderFoodSelected}
      </Stack>
    </Box>
  );
}
