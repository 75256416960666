import { Action, combineReducers } from 'redux';
import { login, register, loginWithOfficeAddIn } from './auth';
import {
  accessgroup,
  appversion,
  booking,
  company,
  dashboard,
  device,
  invitation,
  notification,
  user,
  resource,
  resourcegroup,
  usergroup,
  policy,
  policygroup,
  reportResourceAnalytics,
  reportResourceStats,
  reportGroup,
  bookingReport,
  activeUsersReport,
  setting,
  resourceAnalytics,
  bookingReports,
  userReports,
  accountAdmin,
  adminDashboard,
  shorturl,
  lockerbank,
  amenity,
  imageMeetingRoom,
  listcontact,
  meetingRoomSchedule,
  attendance,
  questionnaire,
  vaccination,
  blobStorage,
  vaccinationSurvey,
  myTeam,
  permissiongroup,
  resourceDevice,
  tablet,
  bookingDetailsReports,
  deskHistoryReports,
  meetingroomServicesOptions,
  allocation,
  outlookAddIn,
  catering,
  cateringPackage,
  cateringFoodItem,
  conciergeRoomBooking,
  lockerCluster,
  vehicles,
  userLicensePlate,
  firstAidStation,
} from './api';
import { RESET_APP_ACTION } from '../constants';

export const rawReducers = {
  accessgroup,
  appversion,
  booking,
  company,
  dashboard,
  device,
  invitation,
  notification,
  login,
  register,
  policy,
  policygroup,
  resource,
  resourcegroup,
  user,
  usergroup,
  reportResourceAnalytics,
  reportResourceStats,
  reportGroup,
  bookingReport,
  activeUsersReport,
  setting,
  resourceAnalytics,
  bookingReports,
  userReports,
  accountAdmin,
  adminDashboard,
  shorturl,
  lockerbank,
  amenity,
  imageMeetingRoom,
  listcontact,
  meetingRoomSchedule,
  attendance,
  questionnaire,
  vaccination,
  blobStorage,
  vaccinationSurvey,
  myTeam,
  permissiongroup,
  resourceDevice,
  tablet,
  bookingDetailsReports,
  deskHistoryReports,
  meetingroomServicesOptions,
  allocation,
  outlookAddIn,
  loginWithOfficeAddIn,
  catering,
  cateringPackage,
  cateringFoodItem,
  conciergeRoomBooking,
  lockerCluster,
  vehicles,
  userLicensePlate,
  firstAidStation,
};

const allReducers = combineReducers(rawReducers);

export type RootState = ReturnType<typeof allReducers>;

const reducers = (state: RootState, action: Action): RootState => {
  if (action.type === RESET_APP_ACTION) {
    state = {} as RootState;
  }

  return allReducers(state, action);
};

export default reducers;
