import { Company, Resource, User } from '..';

export interface ResourceAnalyticsResources {
  name: string;
  count: number;
}
export interface ResourceAnalyticsResourcegroups {
  name: string;
  resources: ResourceAnalyticsResources[];
}
interface Groups {
  name: string;
  count: number;
}
interface DeskUtilisation {
  name: string;
  groups?: Groups[];
}
export interface ResourceUsage {
  name: string;
  count: number;
}

export interface ResourceAnalyticsProps {
  deskUtilisation?: DeskUtilisation[];
  resourceUsage?: ResourceUsage[];
  groupStats?: GroupStats[];
  totalPeakUsage?: number;
  avgBookings?: number;
  totalDesks?: number;
}

export enum ReadResourceAnalyticsActionType {
  request = 'read.resourceanalytcis.request',
  response = 'read.resourceanalytcis.response',
  error = 'read.resourceanalytcis.error',
}

export interface BookingsReportStatus {
  name: string;
  count: number;
}
export interface BookingsReportUserGroups {
  name: string;
  status: BookingsReportStatus[];
}

export interface CheckInsVSAutoCancellationsByDays {
  name: number;
  'Check-Ins'?: number;
  'Auto-Cancelled'?: number;
}
export interface CheckInsVSAutoCancellationsByGroups {
  name: string;
  'Check-Ins'?: number;
  'Auto-Cancelled'?: number;
}
export interface BookingsReportProps {
  avgCheckIns: number;
  totalAutoCancelled: number;
  totalBookings: number;
  totalCheckIns: number;
  checkInsVSAutoCancellationsByDays: CheckInsVSAutoCancellationsByDays[];
  checkInsVSAutoCancellationsByGroups: CheckInsVSAutoCancellationsByGroups[];
  groups?: BookingsReportUserGroups[];
}

export enum ReadBookingsReportActionType {
  request = 'read.bookingsreport.request',
  response = 'read.bookingsReport.response',
  error = 'read.bookingsReport.error',
}

export enum ReportStatusBooking {
  OTHER = 'OTHER',
  AUTO_CANCELLED = 'AUTO_CANCELLED',
  AUTO_COMPLETED = 'AUTO_COMPLETED',
  BOOKING = 'BOOKING',
  CHECKED_IN = 'CHECKED_IN',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
}

export interface UserActivityChart {
  name: string;
  Users: number;
}

export interface UserActivityTable {
  id?: number;
  name: string;
  userGroups: string;
  count: number;
}

export interface ActiveUsersReportProps {
  totalUsers: number;
  totalActiveUsers: number;
  userActivityChart: UserActivityChart[];
  userActivityTable: UserActivityTable[];
}

export enum ReadActiveUsersReportActionType {
  request = 'read.activeusersreport.request',
  response = 'read.activeusersreport.response',
  error = 'read.activeusersreport.error',
}
export interface ReportResourceGroup {
  id: string;
  name: string;
  description?: string;
  resources?: Resource[];
  isDefault?: boolean;
  createdAt: string;
  updatedAt: string;
  buildingIds?: string[];
}

export interface ReportUserGroup {
  id: string;
  name: string;
  description?: string;
  company?: Company;
  resourcegroups?: any[];
  policygroups?: any[];
  users?: User[];
  isDefault?: boolean;
  activeDirectoryDepartmentMapping?: string;
  createdAt: string;
  updatedAt: string;
}

export interface ReportGroups {
  userGroups: ReportUserGroup[];
  resourceGroups: ReportResourceGroup[];
}

export enum ReadGroupsActionType {
  request = 'read.readgroups.request',
  response = 'read.readgroups.response',
  error = 'read.readgroups.error',
}

/** Resource Analytics Total Desks */
export interface ResourceAnalyticsTotalDesks {
  totalDesks: number;
}

export enum ResourceAnalyticsTotalDesksActionType {
  request = 'read.resourceanalytics.totaldesk.request',
  response = 'read.resourceanalytics.totaldesk.response',
  error = 'read.resourceanalytics.totaldesk.error',
}

/** Resource Analytics  Desk utilisation */
export interface ReadResourceAnalyticsDeskUtilisation {
  deskUtilisation?: DeskUtilisation[];
  totalPeakUsage?: number;
  avgBookings?: number;
}

export enum ReadResourceAnalyticsDeskUtilisationActionType {
  request = 'read.resourceanalytics.deskutilisation.request',
  response = 'read.resourceanalytics.deskutilisation.response',
  error = 'read.resourceanalytics.deskutilisation.error',
}

/** Resource Analytics  Resource usage */
export enum ReadResourceAnalyticsResourceUsageType {
  request = 'read.resourceanalytics.resourceusage.request',
  response = 'read.resourceanalytics.resourceusage.response',
  error = 'read.resourceanalytics.resourceusage.error',
}

/** Bookings*/

export interface TotalBookingsReport {
  avgCheckIns: number;
  totalAutoCancelled: number;
  totalBookings: number;
  totalCheckIns: number;
}

export enum ReadTotalBookingsReportType {
  request = 'read.bookingsreport.total.request',
  response = 'read.bookingsreport.total.response',
  error = 'read.bookingsreport.total.error',
}

export interface CheckInsVSAutoCancellationsByDay {
  name: number;
  'Check-Ins'?: number;
  'Auto-Cancelled'?: number;
}

export enum ReadCheckInsVSAutoCancellationsByDayType {
  request = 'read.bookingsreport.byday.request',
  response = 'read.bookingsreport.byday.response',
  error = 'read.bookingsreport.byday.error',
}

export interface CheckInsVSAutoCancellationsByGroup {
  name: string;
  'Check-Ins'?: number;
  'Auto-Cancelled'?: number;
}

export enum ReadCheckInsVSAutoCancellationsByGroupType {
  request = 'read.bookingsreport.bygroup.request',
  response = 'read.bookingsreport.bygroup.response',
  error = 'read.bookingsreport.bygroup.error',
}

/** Active Users Report  */

export interface ReadActivityUsersReport {
  totalUsers: number;
  totalActiveUsers: number;
  userActivityChart: UserActivityChart[];
}

export enum ReadActivityUsersReportActionType {
  request = 'read.usersreport.activity.request',
  response = 'read.usersreport.activity.response',
  error = 'read.usersreport.activity.error',
}

export enum ReadActivityUsersByUserReportType {
  request = 'read.usersreport.byuser.request',
  response = 'read.usersreport.byuser.response',
  error = 'read.usersreport.byuser.error',
}

/** Resource Analytics Total Desks */
export interface GroupStats {
  name: string;
  count: number;
  floors: string[];
  buildings: string[];
  checkedInCount: number;
  autoCancelledCount: number;
  cancelledCount: number;
  availableCount: number;
  totalPeakUsage: number;
}

export enum GroupStatsActionType {
  request = 'read.groups.stats.request',
  response = 'read.groups.stats.response',
  error = 'read.groups.stats.error',
}

export interface ResourceStatsProps {
  name: string;
  count: number;
}

export enum ReadResourceStatsActionType {
  request = 'read.resourcestats.request',
  response = 'read.resourcestats.response',
  error = 'read.resourcestats.error',
}

export interface BookingDetails {
  bookingStartUTC: Date;
  bookingEndUTC: Date;
  checkInTimeUTC: Date;
  checkOutTimeUTC: Date;
  status: string;
  bookedFor: string;
  email: string;
  resourceName: string;
  buildingName: string;
  floorName: string;
  roles: string[];
}
export enum ReadReportBookingDetailsType {
  request = 'read.booking.details.request',
  response = 'read.booking.details.response',
  error = 'read.booking.details.error',
}

export interface DeskHistory {
  deskName: string;
  bookingDate: Date;
  bookedFor: string;
  checkedIn: boolean;
  autoCancelled: boolean;
}

export enum ReadReportDeskHistoryType {
  request = 'read.desk.history.request',
  response = 'read.desk.history.response',
  error = 'read.desk.history.error',
}
