import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';

import { api, DefaultResponse } from '../../../../../../../nura-helpers/lib/services/api';
import { RootState } from '../../../../reducers';
import {
  CreateMeetingRoomBookingFromOutlookActionType,
  IBookingResponse,
  ICreateBooking,
} from '../../../../constants/api/officeAdIns/outlook';
import { getCompanyId } from '../../../../../../../nura-helpers/lib/helpers/auth';

export interface CreateMeetingRoomBookingFromOutlookAction
  extends Action<CreateMeetingRoomBookingFromOutlookActionType> {
  error?: any;
  data?: IBookingResponse;
}

interface CreateMeetingRoomBookingFromOutlookResponse extends DefaultResponse {
  data: IBookingResponse;
}

export function createMeetingRoomBookingFromOutlook(
  params: ICreateBooking,
): ThunkAction<Promise<IBookingResponse>, RootState, unknown, CreateMeetingRoomBookingFromOutlookAction> {
  return async (dispatch) => {
    try {
      dispatch({
        type: CreateMeetingRoomBookingFromOutlookActionType.request,
      });

      const scope = getCompanyId();
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const response = await api('/api/outlook/booking/meetingRoom', {
        outlookId: true,
      }).post<CreateMeetingRoomBookingFromOutlookResponse>({
        companyId: `${scope}`,
        localTz: tz || 'Australia/Melbourne',
        ...params,
      });

      if (![200, 201].includes(response.status)) throw response;

      if (!response || !response.data) {
        throw response;
      }

      dispatch({
        type: CreateMeetingRoomBookingFromOutlookActionType.response,
        data: response.data,
      });
      return response.data;
    } catch (e) {
      dispatch({
        type: CreateMeetingRoomBookingFromOutlookActionType.error,
        error: e,
      });
      throw e;
    }
  };
}
