import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, TextField } from '@mui/material';
import { classes } from '../../styles/theme';

const MINUTES = 60;

interface Props {
  value?: number;
  onChange: (value: number) => void;
  stepInMinutes?: number;
  width?: number;
}

export default function DurationTextField({
  value: currentValue = 30,
  stepInMinutes = 30,
  onChange,
  width = 250,
}: Props) {
  const handleAddDuration = (hours: number) => {
    const newValue = Number(currentValue) + Number(hours);
    onChange(newValue);
  };

  const hours = currentValue / MINUTES;
  return (
    <TextField
      variant="outlined"
      value={`${currentValue >= MINUTES ? hours : currentValue}${
        hours < 1 ? ' minutes' : hours > 1 ? ' hours' : ' hour'
      }`}
      sx={{
        '.MuiInputBase-root': {
          px: 0,
          width: width,
          maxWidth: width,
          minWidth: width,
          height: 56,
        },
        '.MuiOutlinedInput-input': {
          textAlign: 'center',
        },
      }}
      InputProps={{
        startAdornment: (
          <Box
            style={classes.boxIconInput}
            onClick={() => (currentValue > stepInMinutes ? handleAddDuration(-stepInMinutes) : null)}
          >
            <RemoveIcon style={{ ...classes.iconInput, fontSize: 14 }} />
          </Box>
        ),
        endAdornment: (
          <Box style={classes.boxIconInput} onClick={() => handleAddDuration(stepInMinutes)}>
            <AddIcon style={{ ...classes.iconInput, fontSize: 14 }} />
          </Box>
        ),
      }}
    />
  );
}
