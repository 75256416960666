import React from 'react';
import { Grid, Chip, Typography } from '@mui/material';
import {
  IOutlookAddInMeetingRoomCateringFoodItem,
  IOutlookAddInMeetingRoomCateringPackage,
} from '../../../../../middlewares/nura-client/lib/redux/constants';
interface IProps {
  foodsSelected: IOutlookAddInMeetingRoomCateringFoodItem[];
  packageSelected: IOutlookAddInMeetingRoomCateringPackage;
  handleOnSelectFoods: (newFood: IOutlookAddInMeetingRoomCateringFoodItem) => void;
}
export default function FoodItems({ foodsSelected, packageSelected, handleOnSelectFoods }: IProps): JSX.Element {
  return (
    <>
      {packageSelected?.foodItems?.map((objFood, index) => {
        const isSelected = foodsSelected?.find((objItem) => Number(objItem.id) === Number(objFood.id));
        return (
          <Grid item xs={4} key={index}>
            <Chip
              sx={{ width: '100%', height: 75 }}
              label={
                <Typography
                  variant="body2"
                  style={{
                    whiteSpace: 'normal',
                    textAlign: 'center',
                    color: isSelected?.id ? 'white' : '#2E2F2F',
                  }}
                >
                  {objFood.name}
                </Typography>
              }
              onClick={() => handleOnSelectFoods(objFood)}
              clickable
              color={isSelected?.id ? 'primary' : 'default'}
            />
          </Grid>
        );
      })}
    </>
  );
}
