import React from 'react';
import { Box, Button, Stack } from '@mui/material';
import { useFilterBookings } from '../../../providers/FilterBookings';
import { useNavigate } from 'react-router-dom';

export default function ActionsFilter(): JSX.Element {
  let navigate = useNavigate();
  const { state, dispatch } = useFilterBookings();

  const handleClear = () => {
    dispatch({
      type: 'clear',
    });
  };

  const handleSubmit = async () => {
    try {
      dispatch({ type: 'setFilter', payload: { ...state.filter, isFiltering: true } });

      navigate('/bookings', { replace: true });
    } catch (error) {}
  };
  return (
    <Box sx={{ mt: 2 }}>
      <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
        <Button sx={{ minWidth: 100 }} variant="text" onClick={handleClear}>
          Clear all
        </Button>
        <Button sx={{ minWidth: 100 }} variant="contained" onClick={handleSubmit}>
          Apply
        </Button>
      </Stack>
    </Box>
  );
}
