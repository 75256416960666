import { SelectChangeEvent } from '@mui/material';
import React from 'react';
import { repeatOptions } from '../../../constants/AddIn';
import { useBookingDetails } from '../../../contexts/BookingDetails';
import { getDataRecurringEventTypes } from '../../../helpers';
import SelectCustom from '../../SelectCustom';
import { useMailbox } from '../../../contexts';
import { EOptionRecurrencePatternType } from '../../../middlewares/nura-client/lib/redux/constants';

const OnRepeat = (): JSX.Element => {
  const { state: bookingState, dispatch: bookingDispatch } = useBookingDetails();
  const { state: mailboxState } = useMailbox();

  const handleChangeRecurrenceType = (event: SelectChangeEvent) => {
    if (event?.target?.value) {
      const currentValue = event.target.value;
      const newState = getDataRecurringEventTypes({ state: bookingState, type: currentValue, mailboxState });

      bookingDispatch({
        type: 'setRecurringEvents',
        payload: {
          recurringEvents: { ...newState.recurringEvents, selectedUIType: currentValue },
        },
      });
    }
  };

  const handleOnClick = (value: string) => {
    const { recurringEvents } = bookingState;
    if (EOptionRecurrencePatternType.DOES_NOT_REPEAT !== recurringEvents?.selectedUIType) {
      bookingDispatch({
        type: 'setReOpenRecurringDialog',
      });
    }
  };

  return (
    <SelectCustom
      options={repeatOptions}
      minWidth={120}
      value={bookingState.recurringEvents.selectedUIType}
      handleChange={handleChangeRecurrenceType}
      handleOnClick={handleOnClick}
    />
  );
};

export default OnRepeat;
