import { addMonths } from 'date-fns';
import { createContext, Dispatch, useContext } from 'react';
import {
  ICateringMenuSelected,
  ILinkRooms,
  IMeetingRoomOrganizersForResponse,
  IOutlookAddInMeetingRoomCatering,
  IOutlookAddInMeetingRoomDetails,
  IOutlookAddInMeetingRoomFacilities,
  IOutlookAddInMeetingRoomIT,
  IRecurringEventDaily,
  IRecurringEventWeekly,
  IRecurringEventAbsoluteMonthly,
  IRecurringEventRelativeMonthly,
  IRecurringEventAbsoluteYearly,
  IRecurringEventRelativeYearly,
  EOptionRecurrencePatternType,
  IRecurrenceRange,
  ERecurrenceRangeType,
} from '../../middlewares/nura-client/lib/redux/constants';
import { REACT_APP_RECURRING_MAX_INTERVAL_MONTHS } from '../../constants/AddIn';

export interface IReducerBookingDetailsState {
  general?: IOutlookAddInMeetingRoomDetails;
  it?: IOutlookAddInMeetingRoomIT;
  facilities?: IOutlookAddInMeetingRoomFacilities;
  catering?: IOutlookAddInMeetingRoomCatering;
  tabValue?: number;
  addMenu?: boolean;
  currentMenus?: ICateringMenuSelected[];
  organizer?: IMeetingRoomOrganizersForResponse;
  isOnOrganizer?: boolean;
  isLinkRoom?: boolean;
  selectedLinkRoom?: ILinkRooms[];
  recurringEventsDateSeries?: Date[]; //this is for checking schedule
  recurringEvents?: {
    maxDate: Date;
    dateSeries: Date[]; //this is for checking schedule
    selectedUIType: string;
    daily: IRecurringEventDaily;
    weekly: IRecurringEventWeekly;
    absoluteMonthly: IRecurringEventAbsoluteMonthly;
    relativeMonthly: IRecurringEventRelativeMonthly;
    absoluteYearly: IRecurringEventAbsoluteYearly;
    relativeYearly: IRecurringEventRelativeYearly;
    range: IRecurrenceRange;
  };
  reopenRecurringDialog?: boolean;
}

export interface IBookingDetailsContextAction {
  type:
    | 'setGeneral'
    | 'setIT'
    | 'setFacilities'
    | 'setCatering'
    | 'setTabValue'
    | 'setCateringMenus'
    | 'setAddMenu'
    | 'setClearCateringMenus'
    | 'setOrganizer'
    | 'setOnBehalf'
    | 'setLinkRoom'
    | 'setSelectedLinkRoom'
    | 'setRecurringEvents'
    | 'setReOpenRecurringDialog'
    | 'setEventsDateSeries'
    | 'clear'
    | undefined;
  payload?: IReducerBookingDetailsState;
}

export interface IBookingDetailsContextProps {
  state: IReducerBookingDetailsState;
  dispatch: Dispatch<IBookingDetailsContextAction>;
}

export const initialBookingDetailsState: IReducerBookingDetailsState = {
  general: {
    notes: '',
    comments: '',
    behalfOfSomeoneElse: {
      name: '',
      email: '',
      phone: '',
    },
    inPersonAttendees: null,
  },
  it: {
    notes: '',
    equipment: [],
  },
  facilities: {
    equipment: [],
    noChairs: 0,
    noTables: 0,
    setup: undefined,
    cleanDown: undefined,
    notes: '',
  },
  catering: {
    dietaries: '',
    costCentre: '',
    notes: '',
    cateringMenus: [],
    costCentreId: null,
  },
  tabValue: 0,
  addMenu: false,
  organizer: undefined,
  isOnOrganizer: false,
  isLinkRoom: false,
  selectedLinkRoom: [],
  recurringEventsDateSeries: [],
  recurringEvents: {
    maxDate: addMonths(new Date(), REACT_APP_RECURRING_MAX_INTERVAL_MONTHS),
    dateSeries: [],
    selectedUIType: EOptionRecurrencePatternType.DOES_NOT_REPEAT,
    daily: undefined,
    weekly: undefined,
    absoluteMonthly: undefined,
    relativeMonthly: undefined,
    absoluteYearly: undefined,
    relativeYearly: undefined,
    range: {
      type: ERecurrenceRangeType.END_DATE,
      startDate: new Date().toDateString(),
      endDate: addMonths(new Date(), REACT_APP_RECURRING_MAX_INTERVAL_MONTHS).toDateString(),
      numberOfOccurrences: 10,
    },
  },
  reopenRecurringDialog: false,
};

export const BookingDetailsContext = createContext<IBookingDetailsContextProps>({
  state: initialBookingDetailsState,
  dispatch: () => {},
});

const useBookingDetails = (): IBookingDetailsContextProps => useContext(BookingDetailsContext);

export { useBookingDetails };
