import React, { useEffect, useMemo, useState } from 'react';
import { Grid, Typography, Tooltip } from '@mui/material';
import { useBookingDetails } from '../../../../../contexts/BookingDetails';
import { classes } from '../../styles';
import { useMailbox } from '../../../../../contexts';
import { IAttendees } from '../../../../../middlewares/nura-client/lib/redux/constants';
import { useAuthentication } from '../../../../../providers/AuthProvider';
const GeneralPreview = (): JSX.Element => {
  const { officeUser } = useAuthentication();
  const { state: mailboxState } = useMailbox();
  const { state: bookingState } = useBookingDetails();
  const [subject, setSubject] = useState<string>('');
  useEffect(() => {
    Office.context.mailbox.item.subject.getAsync((asyncResult: Office.AsyncResult<string>) => {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        setSubject(asyncResult.value);
      }
    });
  }, [bookingState?.general]);

  const renderSubject = useMemo(() => {
    return (
      <Grid container spacing={0}>
        <Grid item xs={3}>
          <Typography variant="body2" style={classes.label}>
            Subject
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body2" style={classes.value}>
            {subject}
          </Typography>
        </Grid>
      </Grid>
    );
  }, [subject]);

  const renderAttendees = useMemo(() => {
    const attendeesRequired: IAttendees[] =
      mailboxState.requiredAttendees?.map((itemRequired) => {
        return {
          emailAddress: {
            address: itemRequired?.emailAddress,
            name: itemRequired?.displayName,
          },
          type: 'required',
        };
      }) || [];
    const attendeesOptionals: IAttendees[] =
      mailboxState.optionalAttendees?.map((itemRequired) => {
        return {
          emailAddress: {
            address: itemRequired?.emailAddress,
            name: itemRequired?.displayName,
          },
          type: 'optional',
        };
      }) || [];

    const attendees: IAttendees[] = [...attendeesRequired, ...attendeesOptionals];

    const attendeesNames = attendees?.map((objAtt) => objAtt?.emailAddress?.name);
    if (!attendeesNames?.length) return null;
    return (
      <Grid container spacing={0}>
        <Grid item xs={3}>
          <Typography variant="body2" style={classes.label}>
            Attendees
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body2" style={classes.value}>
            {attendeesNames.join(', ')}
          </Typography>
        </Grid>
      </Grid>
    );
  }, [mailboxState]);

  const renderOrganizer = useMemo(() => {
    const email = bookingState?.organizer?.mail || officeUser?.email;

    const name = bookingState?.organizer?.displayName || officeUser?.name;

    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography variant="body2" style={{ fontWeight: 'bold' }}>
            Organizer
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body2" style={classes.label}>
            Name
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body2" style={classes.value}>
            {name}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body2" style={classes.label}>
            Email
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Tooltip title={email}>
            <Typography variant="body2" style={classes.email}>
              {email}
            </Typography>
          </Tooltip>
        </Grid>
      </Grid>
    );
  }, [bookingState, officeUser]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {renderSubject}
      </Grid>

      <Grid item xs={12}>
        {renderAttendees}
      </Grid>
      <Grid item xs={12}>
        {renderOrganizer}
      </Grid>
      {bookingState?.general?.comments && (
        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Grid item xs={3}>
              <Typography variant="body2" style={classes.label}>
                Comments
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body2" style={classes.value}>
                {bookingState.general.comments}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default GeneralPreview;
