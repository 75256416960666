import { Resource, User } from '..';

export interface ResourceGroup {
  id: string;
  name: string;
  description?: string;
  resources?: Resource[];
  admins?: User[];
  isDefault?: boolean;
  createdAt?: string;
  updatedAt?: string;
  active?: boolean;
  inactiveEndAt?: string;
  inactiveNote?: string;
  inactivatedBy?: string;
}

export enum ReadResourceGroupsActionType {
  request = 'read.resourcegroups.request',
  response = 'read.resourcegroups.response',
  error = 'read.resourcegroups.error',
}

export enum ReadResourceGroupActionType {
  request = 'read.resourcegroup.request',
  response = 'read.resourcegroup.response',
  error = 'read.resourcegroup.error',
}

export enum CreateResourceGroupActionType {
  request = 'create.resourcegroup.request',
  response = 'create.resourcegroup.response',
  error = 'create.resourcegroup.error',
}

export enum UpdateResourceGroupActionType {
  request = 'update.resourcegroup.request',
  response = 'update.resourcegroup.response',
  error = 'update.resourcegroup.error',
}

export enum DeleteResourceGroupActionType {
  request = 'delete.resourcegroup.request',
  response = 'delete.resourcegroup.response',
  error = 'delete.resourcegroup.error',
}

export enum AddToResourceGroupActionType {
  request = 'addTo.resourcegroup.request',
  response = 'addTo.resourcegroup.response',
  error = 'addTo.resourcegroup.error',
}

export enum RemoveFromResourceGroupActionType {
  request = 'removeFrom.resourcegroup.request',
  response = 'removeFrom.resourcegroup.response',
  error = 'removeFrom.resourcegroup.error',
}
