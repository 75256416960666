import React, { useEffect, useMemo, useState } from 'react';
import { Box, Stack, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { sxCustom } from '../styles';
import CateringMenu from './CateringMenu';
import { useBookingDetails } from '../../../contexts/BookingDetails';
import { api } from '../../../middlewares/nura-helpers/lib/services/api';
import { useSnackbar } from 'notistack';

interface CostCentreItem {
  displayName: string;
  id: number;
  name: string;
}

interface CostCentreCategoryItem {
  displayName: string;
  id: number;
  name: string;
  costCentres: CostCentreItem[];
}
export default function CateringServices(): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const { state: bookingState, dispatch: bookingDispatch } = useBookingDetails();
  const [centreOptions, setCentreOptions] = useState<{ label: string; value: number; category: string }[]>([]);
  const renderNotes = useMemo(() => {
    const handleNotes = async (event: React.ChangeEvent<HTMLInputElement>) => {
      bookingDispatch({
        type: 'setCatering',
        payload: {
          catering: {
            ...bookingState.catering,
            notes: event.target.value,
          },
        },
      });
    };

    return (
      <TextField
        id="notes-outlined-multiline-flexible"
        name="notes"
        label="Notes"
        placeholder="Enter a note"
        multiline
        maxRows={4}
        value={bookingState.catering.notes}
        onChange={handleNotes}
        InputLabelProps={{ shrink: true }}
      />
    );
  }, [bookingState.catering, bookingDispatch]);

  const renderCostCentre = useMemo(() => {
    const handleCostCentre = async (costCentre: string, costCentreId: number | null) => {
      bookingDispatch({
        type: 'setCatering',
        payload: {
          catering: {
            ...bookingState.catering,
            costCentre,
            costCentreId,
          },
        },
      });
    };
    if (bookingState.catering?.cateringMenus?.length === 0) return null;

    const value = centreOptions.find((o) => o.label === bookingState.catering.costCentre) ?? '';
    return (
      <Autocomplete
        id="cost-centre-outlined-multiline-flexible"
        options={centreOptions}
        value={value}
        groupBy={(option) => {
          if (typeof option === 'string') return '';
          return option.category;
        }}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
        onChange={(_, v) => {
          if (v === null) {
            handleCostCentre('', null);
            return;
          }
          if (typeof v === 'string' || !v) return;
          handleCostCentre(v.label, v.value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            name="costCentre"
            label="Cost Centre"
            placeholder="Enter a cost centre"
            maxRows={4}
            required={bookingState.catering?.cateringMenus?.length > 0}
            fullWidth
          />
        )}
      />
    );
  }, [bookingState.catering, bookingDispatch, centreOptions.length]);

  const renderDietaries = useMemo(() => {
    const handleDietaries = async (event: React.ChangeEvent<HTMLInputElement>) => {
      bookingDispatch({
        type: 'setCatering',
        payload: {
          catering: {
            ...bookingState.catering,
            dietaries: event.target.value,
          },
        },
      });
    };

    if (bookingState.catering?.cateringMenus?.length === 0) return null;

    return (
      <TextField
        id="cost-centre-outlined-multiline-flexible"
        name="dietaries"
        label="Dietaries"
        placeholder="Enter a dietary"
        value={bookingState.catering.dietaries || ''}
        onChange={handleDietaries}
        InputLabelProps={{ shrink: true }}
      />
    );
  }, [bookingState.catering, bookingDispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await api('/api/outlook/cost-centre-categories', {
          outlookId: true,
        }).get<{
          data: CostCentreCategoryItem[];
        }>();
        const options = [];
        for (let i = 0; i < data.length; i++) {
          const item = data[i];
          for (let j = 0; j < item.costCentres.length; j++) {
            const cc = item.costCentres[j];
            options.push({
              label: cc.displayName,
              value: cc.id,
              category: item.displayName,
            });
          }
        }
        setCentreOptions(options);
      } catch (e) {
        enqueueSnackbar(`Error getting Cost Centre Options, the error is ${e?.message}`, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    };
    fetchData();
  }, []);
  return (
    <Box sx={sxCustom.box}>
      <Stack spacing={3}>
        <CateringMenu />
        {renderDietaries}
        {renderCostCentre}
        {renderNotes}
      </Stack>
    </Box>
  );
}
