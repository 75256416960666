import React, { useMemo, useState } from 'react';
import JwtDecode from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, Box, Button, Grid, Link, Paper, Stack, TextField, Typography } from '@mui/material';
import { Navigate } from 'react-router-dom';
import { classes, sxCustom } from './styles';
import { ReactComponent as UserIcon } from '../../../src/assets/images/login/login_users.svg';
import CompanyLogo from '../../components/CompanyLogo';
import PageLoading from '../../components/PageLoading';
import { useAuthentication } from '../../providers/AuthProvider';
import { officeService } from '../../services/Office';
import { UserOfficeLoginProvider } from '../../middlewares/nura-client/lib/redux/constants';
import { OfficeAccessTokenDecoded } from '../../constants/Auth';
import { getTokens } from '../../middlewares/nura-helpers/lib/helpers/auth';
import {
  IUserCompany,
  loginWithOfficeAddIn,
} from '../../middlewares/nura-client/lib/redux/actions/auth/loginWithOfficeAddIn';
import { useSnackbar } from 'notistack';
import { RootState } from '../../middlewares/nura-client/lib/redux/reducers';
import { ILoginLoginWithOfficeAddInState } from '../../middlewares/nura-client/lib/redux/reducers/auth';

export default function Login(): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { officeUser } = useAuthentication();
  const [loading, setLoading] = useState<boolean>(false);
  const { accessToken: nuraAccessToken } = getTokens();
  const [selectedCompany, setSelectedCompany] = useState<IUserCompany>(undefined);
  const { isMultiCompany, userCompanies } = useSelector<RootState, ILoginLoginWithOfficeAddInState>(
    ({ loginWithOfficeAddIn }) => loginWithOfficeAddIn,
  );

  const renderToBookingPage = useMemo(() => {
    if (!nuraAccessToken) return null;
    return <Navigate to="bookings" replace />;
  }, [nuraAccessToken]);

  const handleLogin = async () => {
    try {
      setLoading(true);
      officeService.clearAllStorage();
      const officeAccessToken = await Office.auth.getAccessToken();
      if (!officeAccessToken) {
        enqueueSnackbar(`Error getting access token.`, { variant: 'error' });
        setLoading(false);
        return;
      }
      const userDecode = await JwtDecode<OfficeAccessTokenDecoded>(officeAccessToken);
      const deviceId = await officeService.getAndSetOutlookId(); //uuIDv5('oid', userDecode.oid);
      if (officeUser.email && officeUser.name && userDecode.tid && userDecode.oid && deviceId) {
        //set device id
        await dispatch(
          loginWithOfficeAddIn({
            provider: UserOfficeLoginProvider.OUTLOOK,
            deviceId,
            selectedCompany: selectedCompany?.id ? `${selectedCompany?.id}` : undefined,
            officeAccessToken,
          }),
        );
        setLoading(false);
      } else {
        enqueueSnackbar(`Please check user details.`, { variant: 'error' });
        setLoading(false);
      }
    } catch (error) {
      enqueueSnackbar(`${error?.message}`, { variant: 'error' });
      setLoading(false);
    }
  };

  const renderCompanies = useMemo(() => {
    const handleCompanyChange = (event: any, newValue: IUserCompany | undefined | null) => {
      setSelectedCompany(newValue);
    };
    if (!isMultiCompany && userCompanies?.length < 1) return null;

    return (
      <Autocomplete
        id="company-map"
        fullWidth
        filterOptions={(x) => x}
        options={userCompanies}
        autoComplete
        value={selectedCompany}
        onChange={handleCompanyChange}
        renderInput={(params) => <TextField {...params} label="Select a company" fullWidth />}
      />
    );
  }, [isMultiCompany, userCompanies, selectedCompany]);

  if (nuraAccessToken) return <Navigate to="/bookings" replace />;

  return (
    <div style={classes.root}>
      {renderToBookingPage}
      <div style={{ ...classes.bg, position: 'absolute' }} />
      <div style={{ ...classes.bgOverlay, position: 'absolute' }} />
      <Grid container direction="row" justifyContent="center" alignItems="center" style={classes.content}>
        <Box component="div" display="flex" justifyContent="center" alignItems="center" sx={sxCustom.box}>
          {loading && <PageLoading />}
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent="center">
                    <Typography style={classes.welcome} variant="h4" component="span">
                      Welcome to
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent="center">
                    <CompanyLogo />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Paper elevation={0} sx={sxCustom.paper}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Stack direction="row" justifyContent="center">
                        <UserIcon width={36} height={36} />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" justifyContent="center">
                        <Typography variant="body1" component="span" style={classes.span}>
                          Please enter your email to login
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" justifyContent="center">
                        {!isMultiCompany && (
                          <TextField
                            id="outlined-email"
                            placeholder="Email"
                            variant="outlined"
                            color="primary"
                            fullWidth
                            disabled
                            value={officeUser?.email}
                          />
                        )}

                        {renderCompanies}
                      </Stack>
                    </Grid>

                    <Grid item xs={12}>
                      <Stack direction="row" justifyContent="center">
                        <Button size="large" variant="contained" onClick={() => handleLogin()} fullWidth>
                          Next
                        </Button>
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" justifyContent="center" textAlign="center">
                        <Typography variant="caption" component="p" sx={sxCustom.caption} style={classes.span}>
                          In order to use this app, your company will need to have an active subscription with Nura
                          Space. For more information please fill out our contact form at
                          <Link href="https://www.nuraspace.com/contact."> https://www.nuraspace.com/contact.</Link>
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </div>
  );
}
