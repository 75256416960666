export interface IBreadcrumbMenu {
  id: number;
  name: string;
  displayName: string;
  active: boolean;
}

export interface ICateringBookingMenu {
  id: number;
  menu: number | string | undefined;
  package: number | string | undefined;
  foodItems: number[] | string[] | undefined;
  quantity: number | undefined;
  deliveredAt: string | undefined;
}

export const cateringMenuBreadcrumb: IBreadcrumbMenu[] = [
  {
    id: 1,
    name: 'menu',
    displayName: 'Catering Menu',
    active: true,
  },
  {
    id: 2,
    name: 'packages',
    displayName: 'Packages',
    active: false,
  },
  {
    id: 3,
    name: 'foodItems',
    displayName: 'Food Items',
    active: false,
  },
  {
    id: 4,
    name: 'details',
    displayName: 'Details',
    active: false,
  },
];
