import {
  CreatePolicyGroupActionType,
  ReadPolicyGroupActionType,
  PolicyGroup,
  ReadPolicyGroupsActionType,
  DeletePolicyGroupActionType,
  UpdatePolicyGroupActionType,
} from '../../../constants';
import {
  CreatePolicyGroupAction,
  DeletePolicyGroupAction,
  ReadPolicyGroupAction,
  ReadPolicyGroupsAction,
  UpdatePolicyGroupAction,
} from '../../../actions';
import { QueryState } from '..';

export interface PolicyGroupState extends QueryState {
  isFetching: boolean;
  policygroups: PolicyGroup[];
  error?: string;
  policygroup: Map<string, PolicyGroup>;
}

const initState: PolicyGroupState = {
  isFetching: false,
  policygroups: [],
  policygroup: new Map<string, PolicyGroup>(),
  pageInfo: {
    total: 0,
  },
};

export function policygroup(
  state = initState,
  action:
    | CreatePolicyGroupAction
    | DeletePolicyGroupAction
    | ReadPolicyGroupAction
    | ReadPolicyGroupsAction
    | UpdatePolicyGroupAction,
): PolicyGroupState {
  switch (action.type) {
    /** READ */
    case ReadPolicyGroupsActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadPolicyGroupsActionType.response:
      return {
        ...state,
        isFetching: false,
        policygroups: [...action.data],
        pageInfo: action.pageInfo,
      };
    case ReadPolicyGroupsActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    case ReadPolicyGroupActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadPolicyGroupActionType.response:
      return {
        ...state,
        isFetching: false,
        policygroup: state.policygroup.set(action.data.id, action.data),
      };
    case ReadPolicyGroupActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** CREATE */
    case CreatePolicyGroupActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case CreatePolicyGroupActionType.response:
      return {
        ...state,
        isFetching: false,
        policygroups: [action.data, ...state.policygroups],
        policygroup: state.policygroup.set(action.data.id, action.data),
      };
    case CreatePolicyGroupActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** DELETE */
    case DeletePolicyGroupActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case DeletePolicyGroupActionType.response:
      state.policygroup.delete(action.data);
      return {
        ...state,
        isFetching: false,
        policygroups: [...state.policygroups.filter(({ id }) => id !== action.data)],
        policygroup: state.policygroup,
      };
    case DeletePolicyGroupActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** UPDATE */
    case UpdatePolicyGroupActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case UpdatePolicyGroupActionType.response:
      return {
        ...state,
        isFetching: false,
        policygroups: [...state.policygroups.map((user) => (user.id === action.data.id ? action.data : user))],
        policygroup: state.policygroup.set(action.data.id, action.data),
      };
    case UpdatePolicyGroupActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    default:
      return state;
  }
}
