import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getTokens } from '../../middlewares/nura-helpers/lib/helpers/auth';

interface Props {
  children: JSX.Element;
}
const ProtectedRoute = ({ children }: Props): JSX.Element => {
  const { accessToken: nuraAccessToken } = getTokens();
  let navigate = useNavigate();
  useEffect(() => {
    if (!nuraAccessToken) {
      navigate('/login', { replace: true });
    }
  }, [nuraAccessToken, navigate]);

  return children;
};

export default ProtectedRoute;
