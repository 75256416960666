import { Booking, Resource, User } from '..';

export interface DeskAllocation {
  user: User;
  resource: Resource;
}

export interface AllocationAvailability {
  resource: Resource;
  activeAllocation: boolean;
  differentUserGroupAllocation: boolean;
  bookings: Booking[];
}

export interface UserAllocationDetails {
  user: User;
  currentAllocations: Booking[];
}

export enum AllocationSource {
  DESK = 'desk',
  LOCKER = 'locker',
  CAR_PARK_SPACE = 'Car Park Space',
}

export enum ReadDeskAllocationsActionType {
  request = 'read.desk.allocations.request',
  response = 'read.desk.allocations.response',
  error = 'read.desk.allocations.error',
}

export enum ReadDeskAllocationActionType {
  request = 'read.desk.allocation.request',
  response = 'read.desk.allocation.response',
  error = 'read.desk.allocation.error',
}

export enum CreateDeskAllocationActionType {
  request = 'create.permanent.booking.request',
  response = 'create.permanent.booking.response',
  error = 'create.permanent.booking.error',
}

export enum UpdateDeskAllocationActionType {
  request = 'update.permanent.booking.request',
  response = 'update.permanent.booking.response',
  error = 'update.permanent.booking.error',
}

export enum ReadAllocationUsersActionType {
  request = 'read.allocation.users.request',
  response = 'read.allocation.users.response',
  error = 'read.allocation.users.error',
}

export enum ReadAllocationUsersByGroupActionType {
  request = 'read.allocation.users.by.group.request',
  response = 'read.allocation.users.by.group.response',
  error = 'read.allocation.users.by.group.error',
}

export enum ReadAllocationAvailabilityActionType {
  request = 'read.allocation.availability.request',
  response = 'read.allocation.availability.response',
  error = 'read.allocation.availability.error',
}
