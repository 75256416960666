import { createContext, Dispatch } from 'react';
import { IAutoCompleteCustom } from '../../middlewares/nura-client/lib/redux/constants';

interface FilterManagementParams {
  types?: number[];
  services?: number[];
  data?: {
    id: number;
    displayName: string;
  }[];
}

export interface FilterParams {
  availableDate?: Date;
  startTime?: string;
  duration?: number;
  management?: FilterManagementParams;
  isFiltering?: boolean;
  building?: IAutoCompleteCustom | undefined;
  floor?: IAutoCompleteCustom | undefined;
  capacity?: number;
}
export interface FilterBookingsState {
  result: any[];
  filter: FilterParams;
  total: number;
}

export interface FilterBookingsAction {
  type: 'setParams' | 'setFilter' | 'clear' | undefined;
  payload?: FilterParams;
}

export interface FilterBookingContextProps {
  state: FilterBookingsState;
  dispatch: Dispatch<FilterBookingsAction>;
}
export const initialFilterBookingsState: FilterBookingsState = {
  result: [],
  filter: {
    availableDate: new Date(),
    startTime: undefined,
    duration: 30,
    management: {
      types: [],
      services: [],
      data: [
        {
          id: 1,
          displayName: 'managed',
        },
        {
          id: 2,
          displayName: 'unmanaged',
        },
      ],
    },
    isFiltering: false,
    building: undefined,
    floor: undefined,
    capacity: 0,
  },
  total: 0,
};

export const FilterBookingsContext = createContext<FilterBookingContextProps>({
  state: initialFilterBookingsState,
  dispatch: () => {},
});
