import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { api, DefaultResponse } from '../../../../../../../nura-helpers/lib/services/api';
import { RootState } from '../../../../reducers';
import { getCompanyId } from '../../../../../../../nura-helpers/lib/helpers/auth';
import { IResourceForRoomAvailabilities, ReadResourcesForRoomAvailabilitiesActionType } from '../../../../constants';

export interface ReadResourcesForRoomAvailabilitiesAction extends Action<ReadResourcesForRoomAvailabilitiesActionType> {
  error?: any;
  data?: IResourceForRoomAvailabilities;
}

export interface ReadResourcesForRoomAvailabilitiesResult {
  data: IResourceForRoomAvailabilities;
}

type ReadResourcesForRoomAvailabilitiesResponse = DefaultResponse & ReadResourcesForRoomAvailabilitiesResult;

export async function readResourcesForRoomAvailabilitiesAsync(): Promise<ReadResourcesForRoomAvailabilitiesResult> {
  const scope = getCompanyId();

  const response = await api(`/api/outlook/${scope}/resources`, {
    outlookId: true,
  }).get<ReadResourcesForRoomAvailabilitiesResponse>();

  if (response.status !== 200) throw response;

  if (!response || !response.data) {
    throw response;
  }
  return {
    data: response.data,
  };
}

export function readResourcesForRoomAvailabilities(): ThunkAction<
  Promise<ReadResourcesForRoomAvailabilitiesResult>,
  RootState,
  unknown,
  ReadResourcesForRoomAvailabilitiesAction
> {
  return async (dispatch) => {
    try {
      dispatch({
        type: ReadResourcesForRoomAvailabilitiesActionType.request,
      });

      const response = await readResourcesForRoomAvailabilitiesAsync();

      dispatch({
        type: ReadResourcesForRoomAvailabilitiesActionType.response,
        data: response.data,
      });
      return {
        data: response.data,
      };
    } catch (e) {
      dispatch({
        type: ReadResourcesForRoomAvailabilitiesActionType.error,
        error: e,
      });
      throw e;
    }
  };
}
