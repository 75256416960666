export interface ShortUrl {
  shortLink: string;
  resourceName: string;
}

export enum ReadShortUrlActionType {
  request = 'read.shorturl.request',
  response = 'read.shorturl.response',
  error = 'read.shorturl.error',
}

export enum ReadResourceGroupShortUrlsActionType {
  request = 'read.resourceGroup.shorturl.request',
  response = 'read.resourceGroup.shorturl.response',
  error = 'read.resourceGroup.shorturl.error',
}

export enum CreateShortUrlActionType {
  request = 'create.shorturl.request',
  response = 'create.shorturl.response',
  error = 'create.shorturl.error',
}

export enum ReadFromFloorShortUrlsActionType {
  request = 'read.FromFloor.shorturl.request',
  response = 'read.FromFloor.shorturl.response',
  error = 'read.FromFloor.shorturl.error',
}

export enum ReadAttendanceShortUrlActionType {
  request = 'create.shorturl.request',
  response = 'create.shorturl.response',
  error = 'create.shorturl.error',
}

export enum DecodeShortUrlWebActionType {
  request = 'decode.shorturl.web.request',
  response = 'create.shorturl.web.response',
  error = 'create.shorturl.web.error',
}

export enum ReadShortUrlForFirstAidStationActionType {
  request = 'read.shorturl.fa.request',
  response = 'read.shorturl.fa.response',
  error = 'read.shorturl.fa.error',
}

export enum ReadFromFloorFirstAidStationShortUrlsActionType {
  request = 'read.FromFloor.shorturl.fa.request',
  response = 'read.FromFloor.shorturl.fa.response',
  error = 'read.FromFloor.shorturl.fa.error',
}
