import React, { Fragment, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import AccordionService from '../../../components/AccordionService';
import RoomCard from '../../../components/RoomCard';
import { useMailbox } from '../../../contexts';
import { EMeetingRoomService, IResourcesItem } from '../../../middlewares/nura-client/lib/redux/constants';
import { RootState } from '../../../middlewares/nura-client/lib/redux/reducers';
import { ReducerOutlookOfficeAddIn } from '../../../middlewares/nura-client/lib/redux/reducers/api/officeAddIns';
import FacilitiesPreview from './Services/Facilities';
import FoodsPreview from './Services/Foods';
import GeneralPreview from './Services/General';
import ITPreview from './Services/IT';

const roomServiceAvailable: IResourcesItem[] = [{ id: '0', name: EMeetingRoomService.GENERAL, displayName: 'General' }];

const BookingPreview = (): JSX.Element => {
  const [selectedAccordion, setSelectedAccordion] = useState<Pick<IResourcesItem, 'id' | 'name'>>(
    roomServiceAvailable[0],
  );
  const { resource } = useSelector<RootState, ReducerOutlookOfficeAddIn.IOutlookAddInState>(
    ({ outlookAddIn }) => outlookAddIn,
  );
  const location = useLocation();
  const { state: mailboxState } = useMailbox();

  const renderRoomDetail = useMemo(() => {
    if (!location.state) return null;

    const item: any = location.state;
    return (
      <RoomCard
        services={item.services}
        name={item.name}
        start={mailboxState.start}
        end={mailboxState.end}
        building={item.building.name}
        floor={item.floor.name}
        selected={true}
      />
    );
  }, [location.state, mailboxState.start, mailboxState.end]);

  const renderAccordions = useMemo(() => {
    const roomServiceAvailableNew: IResourcesItem[] = [roomServiceAvailable[0]];
    if (resource?.services?.length) roomServiceAvailableNew.push(...resource.services);

    const handleExpend = (newValue: Pick<IResourcesItem, 'id' | 'name' | 'displayName'>) => {
      if (selectedAccordion?.id === newValue.id) {
        setSelectedAccordion(undefined);
      } else {
        setSelectedAccordion(newValue);
      }
    };

    return (
      <>
        {roomServiceAvailableNew?.map((service, index) => {
          return (
            <AccordionService key={index} expanded={selectedAccordion?.id} handleExpend={handleExpend} item={service}>
              {service?.name.toLowerCase() === EMeetingRoomService.GENERAL.toLowerCase() && <GeneralPreview />}
              {service?.name.toLowerCase() === EMeetingRoomService.IT.toLowerCase() && <ITPreview />}
              {service?.name.toLowerCase() === EMeetingRoomService.FACILITIES.toLowerCase() && <FacilitiesPreview />}
              {service?.name.toLowerCase() === EMeetingRoomService.CATERING.toLowerCase() && <FoodsPreview />}
            </AccordionService>
          );
        })}
      </>
    );
  }, [selectedAccordion, resource?.services]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {renderRoomDetail}
      </Grid>
      <Grid item xs={12}>
        {renderAccordions}
      </Grid>
    </Grid>
  );
};

export default BookingPreview;
