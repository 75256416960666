import {
  CreateFirstAidStationActionType,
  ReadFirstAidStationActionType,
  FirstAidStation,
  ReadFirstAidStationsActionType,
  UpdateFirstAidStationActionType,
  FloorType,
  Resource,
} from '../../../constants';
import {
  CreateFirstAidStationAction,
  ReadFirstAidStationAction,
  ReadFirstAidStationsAction,
  UpdateFirstAidStationAction,
} from '../../../actions';
import { QueryState } from '..';

export interface FirstAidStationState extends QueryState {
  isFetching: boolean;
  firstAidStations: FirstAidStation[];
  error?: string;
  firstAidStation: Map<string, FirstAidStation>;
  buildings: Resource[];
  building: Map<string, Resource>;
  floors: Resource[];
  floor: Map<string, Resource>;
  floorTypes: FloorType[];
}

const initState: FirstAidStationState = {
  isFetching: false,
  firstAidStations: [],
  firstAidStation: new Map<string, FirstAidStation>(),
  pageInfo: {
    total: 0,
  },
  buildings: [],
  building: new Map<string, Resource>(),
  floors: [],
  floor: new Map<string, Resource>(),
  floorTypes: [],
};

export function firstAidStation(
  state = initState,
  action:
    | CreateFirstAidStationAction
    | ReadFirstAidStationAction
    | ReadFirstAidStationsAction
    | UpdateFirstAidStationAction,
): FirstAidStationState {
  switch (action.type) {
    /** READ */
    case ReadFirstAidStationsActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadFirstAidStationsActionType.response:
      return {
        ...state,
        isFetching: false,
        firstAidStations: [...action.data],
      };
    case ReadFirstAidStationsActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    case ReadFirstAidStationActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadFirstAidStationActionType.response:
      return {
        ...state,
        isFetching: false,
        firstAidStation: state.firstAidStation.set(action.data.id, action.data),
      };
    case ReadFirstAidStationActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** CREATE */
    case CreateFirstAidStationActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case CreateFirstAidStationActionType.response:
      return {
        ...state,
        isFetching: false,
        firstAidStations: [...state.firstAidStations, action.data],
        firstAidStation: state.firstAidStation.set(action.data.id, action.data),
      };
    case CreateFirstAidStationActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** UPDATE */
    case UpdateFirstAidStationActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case UpdateFirstAidStationActionType.response:
      return {
        ...state,
        isFetching: false,
        firstAidStations: [...state.firstAidStations.map((fa) => (fa.id === action.data.id ? action.data : fa))],
        firstAidStation: state.firstAidStation.set(action.data.id, action.data),
      };
    case UpdateFirstAidStationActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    default:
      return state;
  }
}
