import { ILocationDetail } from '../../middlewares/nura-client/lib/redux/actions/api/officeAddIns/outlook';

export interface IMailboxItem {
  subject?: string;
  requiredAttendees?: Office.EmailAddressDetails[];
  optionalAttendees?: Office.EmailAddressDetails[];
  body?: string;
  location?: string;
  start?: string;
  end?: string;
  organizer?: string;
  deviceId?: string;
  locationDetails?: ILocationDetail[];
}

export const initialIMailboxItem = {
  subject: undefined,
  requiredAttendees: [],
  optionalAttendees: [],
  body: undefined,
  location: undefined,
  start: undefined, // roundToNearest30().toUTCString(),
  end: undefined, //addMinutes(roundToNearest30(), 30).toUTCString(),
  organizer: undefined,
  deviceId: undefined,
  locationDetails: [],
};

export const defaultIMailboxItem = {
  subject: undefined,
  requiredAttendees: [],
  optionalAttendees: [],
  body: undefined,
  location: undefined,
  organizer: undefined,
};
