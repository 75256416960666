export interface IPackageParams {
  id: string;
  scope: string;
  name: string;
  menuId: string;
  active?: boolean;
}

export interface IPackageResponse {
  id: string;
  name: string;
  menuId: string;
  menuName?: string;
  menu?: {
    id: string;
    name: string;
  };
}

export enum ReadCateringPackagesActionType {
  request = 'read.catering.packages.request',
  response = 'read.catering.packages.response',
  error = 'read.catering.packages.error',
}

export enum CreateCateringPackageActionType {
  request = 'create.catering.package.request',
  response = 'create.catering.package.response',
  error = 'create.catering.package.error',
}

export enum ReadCateringPackageActionType {
  request = 'read.catering.package.request',
  response = 'read.catering.package.response',
  error = 'read.catering.package.error',
}

export enum UpdateCateringPackageActionType {
  request = 'update.catering.package.request',
  response = 'update.catering.package.response',
  error = 'update.catering.package.error',
}

export enum DeleteCateringPackageActionType {
  request = 'delete.catering.package.request',
  response = 'delete.catering.package.response',
  error = 'delete.catering.package.error',
}

export enum ReadCateringPackageByMenuActionType {
  request = 'read.catering.package.byMenu.request',
  response = 'read.catering.package.byMenu.response',
  error = 'read.catering.package.byMenu.error',
}
