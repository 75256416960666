import React, { useState } from 'react';
import { Box, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { classes } from '../../styles/theme';
import { isToday } from 'date-fns';
import { dateService } from '../../services';

interface Props {
  value?: Date | null;
  minDate?: Date | null;
  maxDate?: Date | null;
  inputFormat?: string;
  onChange: (value: Date | null) => void;
  width?: number;
  variant?: 'standard' | 'outlined' | 'filled';
  type?: 'basic' | 'custom';
  handleOnOpen?: (value: boolean) => void;
}

export default function DesktopDatePickerCustom({
  value: currentDate = new Date(),
  minDate = null,
  maxDate = null,
  inputFormat = 'dd/MM/yyyy',
  onChange,
  width = 250,
  variant = 'outlined',
  type = 'custom',
  handleOnOpen,
}: Props): JSX.Element {
  const [open, setOpen] = useState<boolean>(false);

  const handleAddDate = (days: number) => {
    if (currentDate && days) {
      const addDate = dateService.addDateByDay({ date: currentDate, days });
      const newDate = isToday(addDate) ? new Date() : addDate;
      onChange(newDate);
    }
  };

  const handleOnChange = (newValue: Date | null) => {
    if (onChange) onChange(newValue);
  };

  const handleOnOpenDatePicker = () => {
    setOpen(true);
    handleOnOpen(true);
  };
  const handleOnCloseDatePicker = () => {
    setOpen(false);
    handleOnOpen(false);
  };

  return (
    <DesktopDatePicker
      minDate={minDate}
      maxDate={maxDate ? maxDate : undefined}
      inputFormat={inputFormat}
      value={currentDate}
      onChange={(value) => {
        handleOnChange(value);
      }}
      open={open}
      onOpen={handleOnOpenDatePicker}
      onClose={handleOnCloseDatePicker}
      renderInput={(params) => (
        <>
          {type === 'custom' && (
            <TextField
              variant={variant}
              {...params}
              onClick={() => setOpen(true)}
              sx={{
                '.MuiInputBase-root': {
                  px: 0,
                  width: width,
                  maxWidth: width,
                },
                '.MuiOutlinedInput-input': {
                  textAlign: 'center',
                },
              }}
              InputProps={{
                startAdornment: (
                  <Box style={{ ...classes.boxIconInput, backgroundColor: 'white' }} onClick={() => handleAddDate(-1)}>
                    <KeyboardArrowLeftIcon style={{ ...classes.iconInput }} />
                  </Box>
                ),

                endAdornment: (
                  <Box style={{ ...classes.boxIconInput, backgroundColor: 'white' }} onClick={() => handleAddDate(1)}>
                    <KeyboardArrowRightIcon style={{ ...classes.iconInput }} />
                  </Box>
                ),
              }}
            />
          )}

          {type === 'basic' && (
            <TextField
              variant={variant}
              {...params}
              sx={{
                '.MuiInputBase-root': {
                  px: 0,
                  width: width,
                  maxWidth: width,
                },
                '.MuiOutlinedInput-input': {
                  textAlign: 'center',
                },
              }}
              onClick={() => setOpen(true)}
            />
          )}
        </>
      )}
    />
  );
}
