import React from 'react';
import { Box, Card, CardContent } from '@mui/material';
import { sxCustom } from './styles';
type Props = {
  children?: JSX.Element;
  content?: JSX.Element;
  actions?: JSX.Element | null;
  selected?: boolean;
};

const CardCustom = ({ children, content, actions, selected = false }: Props): JSX.Element => {
  const selectedSX = selected ? sxCustom.cardContentSelected : sxCustom.cardContent;
  return (
    <Card variant="outlined" sx={sxCustom.card}>
      <Box sx={selectedSX}>
        {children && <CardContent> {children}</CardContent>}
        {content && <CardContent> {content}</CardContent>}
        {actions && <CardContent> {actions}</CardContent>}
      </Box>
    </Card>
  );
};

export default CardCustom;
