import { QueryState } from '..';
import {
    ReadVehiclesAction
  } from '../../../actions/api/vehicles';
  
import { Vehicles } from '../../../constants';
import { ReadVehiclesActionType } from '../../../constants/api/vehicles';
  
  export interface VehiclesState extends QueryState {
    isFetching: boolean;
    vehicles: Vehicles[];
    error?: string;
  }
  
  const initState: VehiclesState = {
    isFetching: false,
    vehicles: [],
    error: null,
    pageInfo: {
      total: 0,
    },
  };
  
  export function vehicles(
    state = initState,
    action:
      | ReadVehiclesAction
  ): VehiclesState {
    switch (action.type) {
      /** Fetch params for attendance report */
      case ReadVehiclesActionType.request:
        return {
          ...state,
          isFetching: true,
        };
      case ReadVehiclesActionType.response:
        return {
            ...state,
            isFetching: false,
            vehicles: action.data,
            pageInfo: action.pageInfo,
        };
        case ReadVehiclesActionType.error:
        return {
            ...state,
            isFetching: false,
            error: action.error.message,
        }
      default:
        return state;
    }
  }
  