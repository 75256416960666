import React, { useMemo } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DesktopDatePickerCustom from '../../../components/DesktopDatePickerCustom';
import { useMailbox } from '../../../contexts';
import { dateService } from '../../../services';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

export default function AvailableDate(): JSX.Element {
  const { state: mailboxState } = useMailbox();
  const { width: widthHeight } = useWindowDimensions();

  const renderAvailableDate = useMemo(() => {
    const handleDateChange = async (date: Date | null) => {
      const currentTime = dateService.formatDateTime({ date: new Date(mailboxState.start), format: 'p' });
      const currentDateTime = `${dateService.formatDateTime({
        date: new Date(date),
        format: 'MM/dd/yyyy',
      })} ${currentTime}`;
      if (currentDateTime) await Office.context.mailbox.item.start.setAsync(new Date(currentDateTime));
    };

    return (
      <DesktopDatePickerCustom
        width={Number(widthHeight) < 350 ? 120 : 200}
        value={new Date(mailboxState.start)}
        onChange={handleDateChange}
        minDate={new Date()}
      />
    );
  }, [mailboxState.start, widthHeight]);

  return (
    <Box sx={{ mt: 2 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <Box>
          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
            <CalendarTodayIcon color="primary" />
            <Typography variant="body2">Available date</Typography>
          </Stack>
        </Box>
        <Box>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
            {renderAvailableDate}
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
}
