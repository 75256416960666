import { addDays, addMonths, format } from 'date-fns';
import { KeyboardEvent } from 'react';
import { IReducerBookingDetailsState } from '../contexts/BookingDetails';
import {
  EDayOfWeek,
  EOptionRecurrencePatternType,
  ERecurrencePatternType,
  ERecurrenceRangeType,
  EWeekIndex,
} from '../middlewares/nura-client/lib/redux/constants';
import {
  getWeeklyDateSeries,
  getDailyDateSeries,
  getAbsoluteMonthlyDateSeries,
  getRelativeMonthlyDateSeries,
  getAbsoluteYearlyDateSeries,
  getRelativeYearlyDateSeries,
  IRecurringEventsDateSeriesResponse,
} from './recurrence';
import { Mailbox } from '../constants';
import { REACT_APP_RECURRING_MAX_INTERVAL_MONTHS } from '../constants/AddIn';

export const numberValidation = ({ value }: { value: any }): Boolean => {
  const pattern = /^\d+$/;
  return pattern.test(value); // returns a boolean
};

export const handlePreventNumber = (e: KeyboardEvent<HTMLDivElement>): void => {
  if (e) {
    const invalidChars = ['-', 'e', '+', 'E', '.'];
    if (invalidChars.includes(e?.key)) {
      e.preventDefault();
    }
  }
};

export interface ISetRecurringEventTypesParams {
  state: IReducerBookingDetailsState;
  type: string;
  pattern?: 'relative' | 'absolute';
  mailboxState?: Mailbox.IMailboxItem;
}
export const getDataRecurringEventTypes = ({
  state,
  type,
  pattern = 'absolute',
  mailboxState,
}: ISetRecurringEventTypesParams): IReducerBookingDetailsState => {
  let result = state;
  if (state.recurringEvents) {
    const recurringEvents = state.recurringEvents;
    recurringEvents.absoluteMonthly = undefined;
    recurringEvents.absoluteYearly = undefined;
    recurringEvents.relativeMonthly = undefined;
    recurringEvents.relativeYearly = undefined;
    recurringEvents.daily = undefined;
    recurringEvents.weekly = undefined;
    recurringEvents.dateSeries = [];

    const startDateRange = mailboxState?.start ? new Date(mailboxState.start) : new Date();
    recurringEvents.range = {
      type: ERecurrenceRangeType.END_DATE,
      startDate: startDateRange.toDateString(),
      endDate: addMonths(startDateRange, REACT_APP_RECURRING_MAX_INTERVAL_MONTHS).toDateString(),
      numberOfOccurrences: 10,
    };

    if (type === ERecurrencePatternType.DAILY)
      recurringEvents.daily = { pattern: { type: ERecurrencePatternType.DAILY, interval: 1 } };
    if (type === ERecurrencePatternType.WEEKLY) {
      const currentDay = format(startDateRange, 'EEEE').toUpperCase();
      recurringEvents.weekly = {
        pattern: { type: ERecurrencePatternType.WEEKLY, interval: 1, daysOfWeek: [EDayOfWeek[currentDay]] },
      };
    }

    if (type === EOptionRecurrencePatternType.MONTHLY && pattern === 'absolute') {
      const currentDay = format(startDateRange, 'd');
      recurringEvents.absoluteMonthly = {
        pattern: {
          ...recurringEvents.absoluteMonthly?.pattern,
          type: ERecurrencePatternType.ABSOLUTE_MONTHLY,
          interval: 1,
          dayOfMonth: Number(currentDay),
        },
      };
    }

    if (type === EOptionRecurrencePatternType.YEARLY && pattern === 'absolute') {
      const currentDay = format(startDateRange, 'd');
      const currentMonth = format(startDateRange, 'M');
      recurringEvents.absoluteYearly = {
        pattern: {
          ...recurringEvents.absoluteYearly?.pattern,
          type: ERecurrencePatternType.ABSOLUTE_YEARLY,
          interval: Number(currentMonth),
          dayOfMonth: Number(currentDay),
          month: Number(currentMonth),
        },
      };
    }

    if (type === ERecurrencePatternType.RELATIVE_MONTHLY && pattern === 'relative') {
      const defaultStart = recurringEvents?.range?.startDate ? new Date(recurringEvents.range.startDate) : new Date();
      const currentDaysOfWeek = format(defaultStart, 'EEEE')?.toUpperCase();
      recurringEvents.relativeMonthly = {
        pattern: {
          ...recurringEvents.relativeMonthly?.pattern,
          type: ERecurrencePatternType.RELATIVE_MONTHLY,
          interval: 1,
          daysOfWeek: [EDayOfWeek[currentDaysOfWeek]],
          index: EWeekIndex.FIRST,
        },
      };
    }

    if (type === ERecurrencePatternType.RELATIVE_YEARLY && pattern === 'relative') {
      const currentMonth = format(startDateRange, 'M');
      const defaultStart = recurringEvents?.range?.startDate ? new Date(recurringEvents.range.startDate) : new Date();
      const currentDaysOfWeek = format(defaultStart, 'EEEE')?.toUpperCase();
      recurringEvents.relativeYearly = {
        pattern: {
          ...recurringEvents.relativeYearly?.pattern,
          type: ERecurrencePatternType.RELATIVE_YEARLY,
          interval: Number(currentMonth),
          daysOfWeek: [EDayOfWeek[currentDaysOfWeek]],
          index: EWeekIndex.FIRST,
          month: Number(currentMonth),
        },
      };
    }
    result.recurringEvents = recurringEvents;
  }

  return result;
};

export interface IRecurringEventsDateSeries {
  state: IReducerBookingDetailsState;
  type: string;
}
export const getRecurringEventsDateSeries = ({
  state,
  type,
}: IRecurringEventsDateSeries): IRecurringEventsDateSeriesResponse => {
  const { recurringEvents } = state;
  switch (type) {
    case ERecurrencePatternType.DAILY:
      return getDailyDateSeries({ data: recurringEvents.daily, range: recurringEvents.range });
    case ERecurrencePatternType.WEEKLY:
      return getWeeklyDateSeries(recurringEvents.weekly);
    case EOptionRecurrencePatternType.MONTHLY:
      if (recurringEvents?.relativeMonthly?.pattern) {
        return getRelativeMonthlyDateSeries(recurringEvents.relativeMonthly);
      } else {
        return getAbsoluteMonthlyDateSeries(recurringEvents.absoluteMonthly);
      }
    case EOptionRecurrencePatternType.YEARLY:
      if (recurringEvents?.relativeYearly?.pattern) {
        return getRelativeYearlyDateSeries(recurringEvents.relativeYearly);
      } else {
        return getAbsoluteYearlyDateSeries(recurringEvents.absoluteYearly);
      }
    default:
      return { dates: [] };
  }
};

export const getDayNumber = (day: string): number => {
  switch (day) {
    case EDayOfWeek.SUNDAY:
      return 0;
    case EDayOfWeek.MONDAY:
      return 1;
    case EDayOfWeek.TUESDAY:
      return 2;
    case EDayOfWeek.WEDNESDAY:
      return 3;
    case EDayOfWeek.THURSDAY:
      return 4;
    case EDayOfWeek.FRIDAY:
      return 5;
    default:
      return 6;
  }
};
