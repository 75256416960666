import * as yup from 'yup';

interface OptionsArgs {
  validateInPersonAttendeesField?: boolean;
}

interface TabValidationFn {
  (data: { mailboxState: Record<string, any>; bookingState: Record<string, any> }, options: OptionsArgs): {
    isValid: boolean;
    message: string;
  };
}
interface TabValidationConfig {
  [key: number]: TabValidationFn;
}

export const cateringMenuValidation = yup
  .array()
  .of(
    yup.object({
      id: yup.string().required('Catering menu is required.'),
      deliveredAt: yup.string().required('Delivered at is required.'),
      menu: yup.string().required('Menu is required.'),
      quantity: yup.number().required('Quantity is required.'),
      package: yup.string().required('Package is required.'),
      foodItems: yup.array().of(yup.string()),
    }),
  )
  .test(
    'cateringMenus',
    'You can not include the same food item with the same delivery time more than once.',
    (value) => {
      const count = (value || []).reduce((acc, curr) => {
        const { deliveredAt, foodItems = [] } = curr;
        const newValue = { ...acc };
        foodItems.forEach((foodItemId) => {
          const key = `${deliveredAt}-${foodItemId}`;
          newValue[key] = (newValue[key] || 0) + 1;
        });
        return newValue;
      }, {} as Record<string, number>);
      return !Object.values(count).some((value) => value > 1);
    },
  );

export const getGeneralTabScheme = (args: OptionsArgs) =>
  yup.object({
    bookingState: yup.object({
      catering: yup.object({
        costCentre: yup.string().test('costCentre', 'Cost centre is required. :(', (value, context) => {
          const { cateringMenus } = context.parent;
          if (cateringMenus?.length > 0) {
            return !!value;
          }
          return true;
        }),
        cateringMenus: cateringMenuValidation,
      }),
      ...(args.validateInPersonAttendeesField
        ? {
            general: yup
              .object({
                inPersonAttendees: yup.number().required('In person attendees is required.'),
              })
              .required('In person attendees is required.'),
          }
        : {}),
    }),
    mailboxState: yup
      .object({
        subject: yup.string().required('Subject is required.'),
      })
      .required('Subject is required.'),
  });

export const tabValidationsFns: TabValidationConfig = {
  0: (data, options) => {
    try {
      getGeneralTabScheme(options).validateSync(data);
      return { isValid: true, message: '' };
    } catch (e) {
      return { isValid: false, message: e.message };
    }
  },
  3: (data, options) => {
    try {
      getGeneralTabScheme(options).validateSync(data);
      return { isValid: true, message: '' };
    } catch (e) {
      return { isValid: false, message: e.message };
    }
  },
};

export const validateStep = (args: {
  tabValue: number;
  data: {
    mailboxState: Record<string, any>;
    bookingState: Record<string, any>;
  };
  options?: OptionsArgs;
}) => {
  const { tabValue, data, options = { validateInPersonAttendeesField: false } } = args;
  const validationFn = tabValidationsFns[tabValue];
  if (validationFn) {
    return validationFn(data, options);
  }
  return { isValid: true, message: '' };
};

export const validateAllSteps = (args: {
  data: {
    mailboxState: Record<string, any>;
    bookingState: Record<string, any>;
  };
  options?: OptionsArgs;
}) => {
  const { data, options = { validateInPersonAttendeesField: false } } = args;
  const keys = Object.keys(tabValidationsFns);
  const errors = keys.reduce((acc, curr) => {
    const { isValid, message } = validateStep({ tabValue: Number(curr), data, options });
    if (!isValid) {
      acc.push(message);
    }
    return acc;
  }, [] as string[]);
  return { isValid: errors.length === 0, messages: errors };
};
