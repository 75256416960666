import theme from '../../styles/theme';

export const classes = {
  headerActive: {
    background: theme.palette.primary.main,
    color: '#FFF',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  headerNoActive: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  headerTextActive: {
    color: '#FFF',
  },
  headerTextNoActive: {
    color: '#2E2F2F',
  },
};
