import React, { useMemo } from 'react';
import { Box, Chip, Stack, TextField, Typography } from '@mui/material';
import { sxCustom } from '../styles';
import RoomServicesSetup from './RoomSetup';
import { useSelector } from 'react-redux';
import { RootState } from '../../../middlewares/nura-client/lib/redux/reducers';
import { ReducerOutlookOfficeAddIn } from '../../../middlewares/nura-client/lib/redux/reducers/api/officeAddIns';
import { getDataServiceTypes } from '../Helpers';
import { AddInConstant } from '../../../constants';
import { useBookingDetails } from '../../../contexts/BookingDetails';
import { EMeetingRoomService, IResourcesItem } from '../../../middlewares/nura-client/lib/redux/constants';

export default function Facilities(): JSX.Element {
  const { resource } = useSelector<RootState, ReducerOutlookOfficeAddIn.IOutlookAddInState>(
    ({ outlookAddIn }) => outlookAddIn,
  );
  const { state: bookingState, dispatch: bookingDispatch } = useBookingDetails();

  const renderFacilitiesServiceTypes = useMemo(() => {
    const equipmentData = getDataServiceTypes({
      data: resource,
      service: EMeetingRoomService.FACILITIES,
      type: AddInConstant.EServiceType.EQUIPMENT,
    });
    const selectedEquipmentIds = bookingState?.facilities?.equipment?.map(({ id }) => Number(id));

    const handleChipOnClick = (item: IResourcesItem): void => {
      const { id: idItem, name, displayName } = item;
      const selectedEquipment = bookingState?.facilities?.equipment;
      const findValue = selectedEquipment?.find((itemPrev) => Number(itemPrev.id) === Number(item.id));

      if (findValue) {
        const newValues = selectedEquipment?.filter((itemPrev) => Number(itemPrev.id) !== Number(item.id));
        bookingDispatch({
          type: 'setFacilities',
          payload: {
            facilities: {
              ...bookingState.facilities,
              equipment: newValues,
            },
          },
        });
      } else {
        bookingDispatch({
          type: 'setFacilities',
          payload: {
            facilities: {
              ...bookingState.facilities,
              equipment: [
                ...selectedEquipment,
                {
                  id: idItem,
                  name,
                  displayName,
                },
              ],
            },
          },
        });
      }
    };

    return (
      <>
        <Typography variant="body1">{equipmentData.displayName || 'Equipment'}</Typography>
        {equipmentData.inputType.toLowerCase() === AddInConstant.EServiceInputType.CHIP && (
          <Box>
            {equipmentData?.child.map((item) => {
              const isSelected = selectedEquipmentIds.indexOf(Number(item.id));

              return (
                <Chip
                  key={item.id}
                  label={item.name}
                  onClick={() => handleChipOnClick(item)}
                  clickable
                  color={isSelected > -1 ? 'primary' : 'default'}
                  sx={{ mb: 1, mr: 1 }}
                />
              );
            })}
          </Box>
        )}
      </>
    );
  }, [resource, bookingDispatch, bookingState.facilities]);

  const renderNotes = useMemo(() => {
    const handleNotes = async (event: React.ChangeEvent<HTMLInputElement>) => {
      bookingDispatch({
        type: 'setFacilities',
        payload: {
          facilities: {
            ...bookingState.facilities,
            notes: event.target.value,
          },
        },
      });
    };
    return (
      <TextField
        id="notes-outlined-multiline-flexible"
        name="notes"
        label="Notes"
        placeholder="Enter a note"
        multiline
        maxRows={4}
        value={bookingState.facilities.notes || ''}
        onChange={handleNotes}
        InputLabelProps={{ shrink: true }}
      />
    );
  }, [bookingState.facilities, bookingDispatch]);

  return (
    <Box sx={sxCustom.box}>
      <Stack spacing={3}>
        {renderFacilitiesServiceTypes}
        <RoomServicesSetup />
        {renderNotes}
      </Stack>
    </Box>
  );
}
