import React, { ChangeEvent, useMemo } from 'react';
import { Grid, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { EEndOfBookingRecurrence, ERecurrenceRangeType } from '../../../middlewares/nura-client/lib/redux/constants';
import SelectCustom, { ISelectCustomOptions } from '../../SelectCustom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import DesktopDatePickerCustom from '../../DesktopDatePickerCustom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useBookingDetails } from '../../../contexts/BookingDetails';
import { addMonths } from 'date-fns';
import { REACT_APP_RECURRING_MAX_INTERVAL_MONTHS } from '../../../constants/AddIn';

const defaultOptions: ISelectCustomOptions[] = [
  { name: EEndOfBookingRecurrence.ON_THIS_DAY, value: ERecurrenceRangeType.END_DATE },
  { name: EEndOfBookingRecurrence.AFTER, value: ERecurrenceRangeType.NUMBERED },
];

interface IEndBookingProps {
  handleOnOpenDatePickerCustom?: (value: boolean) => void;
}

const EndBooking = ({ handleOnOpenDatePickerCustom }: IEndBookingProps): JSX.Element => {
  const { state: bookingState, dispatch: bookingDispatch } = useBookingDetails();
  const { recurringEvents } = bookingState;
  const currentRangeStartDate = recurringEvents.range.startDate;
  const handleTypeChange = (event: SelectChangeEvent) => {
    if (event.target.value === ERecurrenceRangeType.END_DATE) {
      recurringEvents.range.type = ERecurrenceRangeType.END_DATE;
      recurringEvents.range.endDate = addMonths(
        new Date(currentRangeStartDate),
        REACT_APP_RECURRING_MAX_INTERVAL_MONTHS,
      )?.toDateString();
      recurringEvents.range.numberOfOccurrences = 10;
    }

    if (event.target.value === ERecurrenceRangeType.NUMBERED) {
      recurringEvents.range.type = ERecurrenceRangeType.NUMBERED;
      recurringEvents.range.endDate = addMonths(
        new Date(currentRangeStartDate),
        REACT_APP_RECURRING_MAX_INTERVAL_MONTHS,
      )?.toDateString();
      recurringEvents.range.numberOfOccurrences = 10;
    }

    bookingDispatch({
      type: 'setRecurringEvents',
      payload: { recurringEvents: { ...recurringEvents } },
    });
  };

  const renderEndDate = useMemo(() => {
    const handleDateChange = async (date: Date | null) => {
      if (!date) return;
      recurringEvents.range.type = ERecurrenceRangeType.END_DATE;
      recurringEvents.range.endDate = date?.toDateString();
      bookingDispatch({
        type: 'setRecurringEvents',
        payload: { recurringEvents: { ...recurringEvents } },
      });
    };
    const currentEndDate = recurringEvents?.range?.endDate ? new Date(recurringEvents.range.endDate) : new Date();
    const limitDate =
      new Date(recurringEvents?.range?.endDate).getTime() > recurringEvents.maxDate.getTime()
        ? new Date(recurringEvents?.range?.endDate)
        : recurringEvents.maxDate;
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePickerCustom
          variant="standard"
          width={130}
          value={currentEndDate}
          onChange={handleDateChange}
          minDate={new Date()}
          maxDate={limitDate}
          type="basic"
          handleOnOpen={handleOnOpenDatePickerCustom}
        />
      </LocalizationProvider>
    );
  }, [recurringEvents?.range?.endDate, recurringEvents?.range?.type]);

  const renderOccurrences = useMemo(() => {
    const handleOnChangeNumber = (event: ChangeEvent<HTMLInputElement>) => {
      if (isNaN(Number(event.target.value)) === false) {
        const currentValue = Number(event.target.value);
        recurringEvents.range.numberOfOccurrences = currentValue <= 100 ? event.target.value : 100;
      }
      bookingDispatch({
        type: 'setRecurringEvents',
        payload: { recurringEvents: { ...recurringEvents } },
      });
    };

    const currentNumberOfOccurrences = recurringEvents?.range?.numberOfOccurrences;
    return (
      <>
        <TextField
          id="numberOfOccurrences-outlined-multiline-flexible"
          variant="standard"
          value={currentNumberOfOccurrences}
          onChange={handleOnChangeNumber}
          sx={{ width: 50 }}
          fullWidth
        />

        <Typography variant="body2" component="span">
          occurrences
        </Typography>
      </>
    );
  }, [recurringEvents?.range?.type, recurringEvents?.range?.numberOfOccurrences]);

  const currentType = recurringEvents?.range?.type ? recurringEvents.range.type : ERecurrenceRangeType.END_DATE;
  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Typography variant="body2" component="span">
            End
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Grid container spacing={2}>
            <Grid item>
              <SelectCustom
                options={defaultOptions}
                minWidth={100}
                value={currentType}
                handleChange={handleTypeChange}
              />
            </Grid>
            <Grid item>
              {currentType === ERecurrenceRangeType.NUMBERED && renderOccurrences}
              {currentType === ERecurrenceRangeType.END_DATE && renderEndDate}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EndBooking;
