import React, { useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import { useBookingDetails } from '../../../../../contexts/BookingDetails';
import { classes } from '../../styles';
import CateringControlledAccordion from '../../../../../components/CateringControlledAccordion';

const FoodsPreview = (): JSX.Element => {
  const { state: bookingState } = useBookingDetails();
  const cateringRequest = bookingState?.catering;

  const renderFoods = useMemo(() => {
    const foodRequests = cateringRequest?.cateringMenus;
    if (!foodRequests?.length) return null;
    return (
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body2" style={{ fontWeight: 'bold' }}>
              Food Items
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <CateringControlledAccordion componentType="preview" />
          </Grid>
        </Grid>
      </Grid>
    );
  }, [cateringRequest]);

  return (
    <Grid container spacing={1}>
      {cateringRequest?.dietaries && (
        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Grid item xs={3}>
              <Typography variant="body2" style={classes.label}>
                Dietaries
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body2" style={classes.value}>
                {cateringRequest?.dietaries}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}

      {cateringRequest?.costCentre && (
        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Grid item xs={3}>
              <Typography variant="body2" style={classes.label}>
                Cost centre
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body2" style={classes.value}>
                {cateringRequest.costCentre}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}

      {cateringRequest?.notes && (
        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Grid item xs={3}>
              <Typography variant="body2" style={classes.label}>
                Notes
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body2" style={classes.value}>
                {cateringRequest.notes}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      {renderFoods}
    </Grid>
  );
};

export default FoodsPreview;
