import { AppVersion, ReadLatestAppVersionActionType } from '../../../constants';
import { ReadLatestAppVersionAction } from '../../../actions';

export interface AppVersionState {
  isFetching: boolean;
  latestAppVersion?: AppVersion;
}

const initState: AppVersionState = {
  isFetching: false,
  latestAppVersion: undefined,
};

export function appversion(state = initState, action: ReadLatestAppVersionAction): AppVersionState {
  switch (action.type) {
    case ReadLatestAppVersionActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadLatestAppVersionActionType.response:
      return {
        ...state,
        isFetching: false,
        latestAppVersion: action.data,
      };
    case ReadLatestAppVersionActionType.error:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
}
