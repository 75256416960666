import {
  Vaccination,
  ReadVaccinationsActionType,
  ReadVaccinationActionType,
  UpdateVaccinationActionType,
  CreateVaccinationSurveyActionType,
  ReadVaccinationSurveyActionType,
} from '../../../constants';
import {
  ReadVaccinationsAction,
  ReadVaccinationAction,
  UpdateVaccinationAction,
  CreateVaccinationSurveyAction,
  ReadVaccinationSurveyAction,
} from '../../../actions';
import { QueryState } from '..';

export interface VaccinationState extends QueryState {
  isFetching: boolean;
  vaccinations: Vaccination[];
  vaccination: Map<string, Vaccination>;
  vaccinationSurvey: Map<string, Vaccination>;
  error?: string;
}

const initState: VaccinationState = {
  isFetching: false,
  vaccinations: [],
  vaccination: new Map<string, Vaccination>(),
  vaccinationSurvey: new Map<string, Vaccination>(),
  pageInfo: {
    total: 0,
  },
};

export function vaccination(
  state = initState,
  action: ReadVaccinationsAction | ReadVaccinationAction | UpdateVaccinationAction,
): VaccinationState {
  switch (action.type) {
    /** READ */
    case ReadVaccinationsActionType.request:
      return {
        ...state,
        isFetching: true,
      };

    case ReadVaccinationsActionType.response:
      return {
        ...state,
        isFetching: false,
        vaccinations: [...action.data],
        pageInfo: action.pageInfo,
      };

    case ReadVaccinationsActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    case ReadVaccinationActionType.request:
      return {
        ...state,
        isFetching: true,
      };

    case ReadVaccinationActionType.response:
      return {
        ...state,
        isFetching: false,
        vaccination: state.vaccination.set(action.data.id, action.data),
      };

    case ReadVaccinationActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** UPDATE */
    case UpdateVaccinationActionType.request:
      return {
        ...state,
        isFetching: true,
      };

    case UpdateVaccinationActionType.response:
      return {
        ...state,
        isFetching: false,
        vaccinations: [
          ...state.vaccinations.map(vaccination => (vaccination.id === action.data.id ? action.data : vaccination)),
        ],
        vaccination: state.vaccination.set(action.data.id, action.data),
      };

    case UpdateVaccinationActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    default:
      return state;
  }
}

export function vaccinationSurvey(
  state = initState,
  action: CreateVaccinationSurveyAction | ReadVaccinationSurveyAction,
): VaccinationState {
  switch (action.type) {
    /** READ */
    case ReadVaccinationSurveyActionType.request:
      return {
        ...state,
        isFetching: true,
      };

    case ReadVaccinationSurveyActionType.response:
      return {
        ...state,
        isFetching: false,
        vaccinationSurvey: state.vaccination.set(action.data.id, action.data),
      };

    case ReadVaccinationSurveyActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** CREATE VACCINATION SURVEY */
    case CreateVaccinationSurveyActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case CreateVaccinationSurveyActionType.response:
      return {
        ...state,
        isFetching: false,
        vaccinationSurvey: state.vaccinationSurvey.set(action.data.id, action.data),
      };
    case CreateVaccinationSurveyActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    default:
      return state;
  }
}
