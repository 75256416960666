import { Company } from '../company';
import { PolicyGroup } from '../policygroup';
import { ResourceGroup } from '../resourcegroup';
import { UserGroup } from '../usergroup';

export interface AccessGroup {
  id: string;
  name: string;
  description?: string;
  company?: Company;
  resourcegroups?: ResourceGroup[];
  policygroup?: PolicyGroup;
  usergroup?: UserGroup;
  isDefault?: boolean;
  createdAt: string;
  updatedAt: string;
}

export enum ReadAccessGroupsActionType {
  request = 'read.accessgroups.request',
  response = 'read.accessgroups.response',
  error = 'read.accessgroups.error',
}

export enum ReadAccessGroupActionType {
  request = 'read.accessgroup.request',
  response = 'read.accessgroup.response',
  error = 'read.accessgroup.error',
}

export enum CreateAccessGroupActionType {
  request = 'create.accessgroup.request',
  response = 'create.accessgroup.response',
  error = 'create.accessgroup.error',
}

export enum UpdateAccessGroupActionType {
  request = 'update.accessgroup.request',
  response = 'update.accessgroup.response',
  error = 'update.accessgroup.error',
}

export enum DeleteAccessGroupActionType {
  request = 'delete.accessgroup.request',
  response = 'delete.accessgroup.response',
  error = 'delete.accessgroup.error',
}
