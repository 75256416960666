import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Box, Checkbox, Typography, Stack, TextField, Autocomplete } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../middlewares/nura-client/lib/redux/reducers';
import { ReducerOutlookOfficeAddIn } from '../../../../middlewares/nura-client/lib/redux/reducers/api/officeAddIns';
import { ActionOutlookOfficeAddIn } from '../../../../middlewares/nura-client/lib/redux/actions';
import { useSnackbar } from 'notistack';
import { officeService } from '../../../../services/Office';
import { useBookingDetails } from '../../../../contexts/BookingDetails';
import { IMeetingRoomOrganizersForResponse } from '../../../../middlewares/nura-client/lib/redux/constants';
export default function BookingOrganizer(): JSX.Element {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { organizers } = useSelector<RootState, ReducerOutlookOfficeAddIn.IOutlookAddInState>(
    ({ outlookAddIn }) => outlookAddIn,
  );

  const [search, setsSearch] = useState<string>(undefined);
  const { state: bookingState, dispatch: bookingDispatch } = useBookingDetails();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    bookingDispatch({
      type: 'setOnBehalf',
      payload: {
        isOnOrganizer: event.target.checked,
      },
    });
    if (!event.target.checked) {
      bookingDispatch({
        type: 'setOrganizer',
        payload: {
          organizer: undefined,
        },
      });
    }
  };
  const fetchData = async () => {
    try {
      const userAccessToken = await officeService.getAccessToken();
      if (userAccessToken)
        await dispatch(
          ActionOutlookOfficeAddIn.readOrganizersForOutlook({ key: search, accessToken: userAccessToken }),
        );
    } catch (error) {
      enqueueSnackbar(`Error: ${error?.message}`, { variant: 'error' });
    }
  };
  useEffect(() => {
    if (search) {
      fetchData();
    }
  }, [search]);

  const renderOrganizers = useMemo(() => {
    const handleOrganizerChange = (event: any, newValue: IMeetingRoomOrganizersForResponse | undefined | null) => {
      bookingDispatch({
        type: 'setOrganizer',
        payload: {
          organizer: newValue,
        },
      });
    };

    const handleOnInputChange = (event: React.SyntheticEvent, value: string, reason: string) => {
      setsSearch(value);
    };

    return (
      <>
        <Autocomplete
          id="organizer-map-demo"
          fullWidth
          filterOptions={(x) => x}
          options={organizers}
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={bookingState.organizer}
          onChange={handleOrganizerChange}
          onInputChange={handleOnInputChange}
          renderInput={(params) => (
            <TextField {...params} label="Select an organizer" fullWidth InputLabelProps={{ shrink: true }} />
          )}
        />
      </>
    );
  }, [organizers, bookingDispatch, bookingState]);

  return (
    <Fragment>
      <Box sx={{ m: 0, p: 0, mb: 1 }}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center">
          <Checkbox checked={bookingState?.isOnOrganizer} onChange={handleChange} />
          <Typography variant="body1">Change Organizer</Typography>
        </Stack>
      </Box>
      {bookingState?.isOnOrganizer && (
        <Box sx={{ m: 0, p: 0 }}>
          <Stack spacing={2}>
            {renderOrganizers}
            <TextField
              id="outlined-name"
              value={bookingState?.organizer?.displayName}
              name="hostName"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              label="Host name"
              fullWidth
            />
            {bookingState?.organizer?.mobilePhone && (
              <TextField
                id="outlined-phone"
                name="hostPhone"
                label="Host phone"
                value={bookingState?.organizer?.mobilePhone}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            )}
          </Stack>
        </Box>
      )}
    </Fragment>
  );
}
