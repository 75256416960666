import { QueryState } from '..';
import {
  TeamsUser,
  ReadTeamsMembersActionType,
  CreateTeamActionType,
  Team,
  ReadMyTeamsActionType,
  UpdateTeamActionType,
  UpdateTeamsMembersActionType,
  CreateTeamAnnouncementActionType,
  ReadTeamBookingsActionType,
  TeamsBooking,
  ReadTeamsActionType,
  CreateTeamFollowerActionType,
  ReadTeamNotificationSettingActionType,
  TeamsNotificationSetting,
  ReadTeamGoalSettingActionType,
  TeamsGoalSetting,
  ReadTeamsFollowersActionType,
  ReadTeamConnectionGoalType,
  TeamConnectionGoal,
  DeleteTeamAnnouncementActionType,
  ReadTeamsUserActivitiesActionType,
  CreateTeamsUserActionsActionType,
} from '../../../constants';
import {
  CreateTeamAction,
  CreateTeamAnnouncementAction,
  ReadMyTeamsAction,
  ReadTeamsMembersAction,
  UpdateTeamAction,
  UpdateTeamsMembersAction,
  ReadTeamBookingsAction,
  ReadTeamsAction,
  CreateTeamFollowerAction,
  ReadTeamNotificationSettingAction,
  ReadTeamGoalSettingAction,
  ReadTeamsFollowersAction,
  ReadTeamConnectionGoalAction,
  DeleteTeamAnnouncementAction,
  ReadTeamsUserActivitiesAction,
  CreateTeamsUserActionsAction,
} from '../../../actions';

export interface MyTeamsState extends QueryState {
  isFetching?: boolean;
  teamConnectionGoal?: TeamConnectionGoal;
  teamsUsers?: TeamsUser[];
  teamsFollowers?: TeamsUser[];
  teamsBookings?: TeamsBooking[];
  teamNotificationSettings?: TeamsNotificationSetting;
  teamsGoalSettings?: TeamsGoalSetting;
  teams?: Team[]; //all teams by company
  myTeams?: Team[]; // all teams by company & user
  teamsActivities?: TeamsBooking[];
  error?: string;
}

const initState: MyTeamsState = {
  isFetching: false,
  teamConnectionGoal: undefined,
  teamsUsers: [],
  teamsFollowers: [],
  teamsBookings: [],
  teamNotificationSettings: undefined,
  teamsGoalSettings: undefined,
  teams: [],
  myTeams: [],
  teamsActivities: [],
  pageInfo: {
    total: 0,
  },
};

export function myTeam(
  state = initState,
  action:
    | ReadTeamsAction
    | ReadMyTeamsAction
    | ReadTeamsMembersAction
    | CreateTeamAction
    | UpdateTeamAction
    | UpdateTeamsMembersAction
    | CreateTeamAnnouncementAction
    | ReadTeamBookingsAction
    | CreateTeamFollowerAction
    | ReadTeamNotificationSettingAction
    | ReadTeamGoalSettingAction
    | ReadTeamsFollowersAction
    | ReadTeamConnectionGoalAction
    | DeleteTeamAnnouncementAction
    | ReadTeamsUserActivitiesAction
    | CreateTeamsUserActionsAction,
): MyTeamsState {
  switch (action.type) {
    /** Read Teams */
    case ReadTeamsActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadTeamsActionType.response:
      return {
        ...state,
        isFetching: false,
        teams: [...action.data],
        pageInfo: action.pageInfo,
      };
    case ReadTeamsActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Read My Teams */
    case ReadMyTeamsActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadMyTeamsActionType.response:
      return {
        ...state,
        isFetching: false,
        myTeams: [...action.data],
        pageInfo: action.pageInfo,
      };
    case ReadMyTeamsActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Read teams users */
    case ReadTeamsMembersActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadTeamsMembersActionType.response:
      return {
        ...state,
        isFetching: false,
        teamsUsers: [...action.data],
        pageInfo: action.pageInfo,
      };
    case ReadTeamsMembersActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** CREATE */
    case CreateTeamActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case CreateTeamActionType.response:
      return {
        ...state,
        isFetching: false,
        myTeams: [...state.myTeams, action.data],
      };
    case CreateTeamActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** UPDATE TEAM*/
    case UpdateTeamActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case UpdateTeamActionType.response:
      return {
        ...state,
        isFetching: false,
        myTeams: action.data,
        // myTeams: [...state.myTeams.map((team) => (team.id === action.data.id ? action.data : team))],
      };
    case UpdateTeamActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** UPDATE TEAMS MEMBERS*/
    case UpdateTeamsMembersActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case UpdateTeamsMembersActionType.response:
      return {
        ...state,
        isFetching: false,
        myTeams: action.data,
      };
    case UpdateTeamsMembersActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    /** CREATE TEAM ANNOUNCEMENT*/
    case CreateTeamAnnouncementActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case CreateTeamAnnouncementActionType.response:
      return {
        ...state,
        isFetching: false,
      };
    case CreateTeamAnnouncementActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Read Teams Bookings*/
    case ReadTeamBookingsActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadTeamBookingsActionType.response:
      return {
        ...state,
        isFetching: false,
        teamsBookings: action.data,
        pageInfo: action.pageInfo,
      };
    case ReadTeamBookingsActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Create Team Follower */
    case CreateTeamFollowerActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case CreateTeamFollowerActionType.response:
      return {
        ...state,
        isFetching: false,
        myTeams: action.data,
      };
    case CreateTeamFollowerActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** ReadTeam Notification Setting  */
    case ReadTeamNotificationSettingActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadTeamNotificationSettingActionType.response:
      return {
        ...state,
        isFetching: false,
        teamNotificationSettings: action.data,
      };
    case ReadTeamNotificationSettingActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Read TeamGoal Setting */
    case ReadTeamGoalSettingActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadTeamGoalSettingActionType.response:
      return {
        ...state,
        isFetching: false,
        teamsGoalSettings: action.data,
      };
    case ReadTeamGoalSettingActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Read Teams Followers */
    case ReadTeamsFollowersActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadTeamsFollowersActionType.response:
      return {
        ...state,
        isFetching: false,
        teamsFollowers: action.data,
        pageInfo: action.pageInfo,
      };
    case ReadTeamsFollowersActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    /** ReadTeamConnectionGoalAction */
    case ReadTeamConnectionGoalType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadTeamConnectionGoalType.response:
      return {
        ...state,
        isFetching: false,
        teamConnectionGoal: action.data,
      };
    case ReadTeamConnectionGoalType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** DELETE TEAM ANNOUNCEMENT*/
    case DeleteTeamAnnouncementActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case DeleteTeamAnnouncementActionType.response:
      return {
        ...state,
        isFetching: false,
      };
    case DeleteTeamAnnouncementActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Read Teams User Activities*/
    case ReadTeamsUserActivitiesActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadTeamsUserActivitiesActionType.response:
      return {
        ...state,
        isFetching: false,
        teamsActivities: action.data,
        pageInfo: action.pageInfo,
      };
    case ReadTeamsUserActivitiesActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** CREATE TEAM ANNOUNCEMENT*/
    case CreateTeamsUserActionsActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case CreateTeamsUserActionsActionType.response:
      return {
        ...state,
        teamsActivities: action.data,
        isFetching: false,
      };
    case CreateTeamsUserActionsActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    default:
      return state;
  }
}
