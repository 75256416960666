import { palette } from '../../styles/theme';

export const sxCustom = {
  card: {
    minWidth: 150,
    minHeight: 100,
    background: palette.background.card,
    mb: 2,
    border: 0,
  },
  cardContent: {
    '&:hover': {
      borderLeft: 6,
      borderColor: palette.primary.dark,
    },
  },
  cardContentSelected: {
    borderLeft: 6,
    borderColor: palette.primary.dark,
    '&:hover': {
      borderLeft: 6,
      borderColor: palette.primary.dark,
    },
  },
};
