import { User } from '../user';
export interface Vaccination {
  id: string;
  companyId: string;
  userId: string;
  vaccinationStatus: string;
  vaccinationProof: string;
  uploadDate: Date;
  verificationStatus: boolean;
  verificationDate: Date;
  verifiedBy: string;
  comment: string;
  user: User;
  verifiedByUser: User;
  userQuestionnaireResponse: {
    id: string;
    questionnaireId: string;
    response: string;
    responseDate: Date;
  };
  displaySurveyVaccination?: boolean;
}

export interface GridVaccination {
  id: string;
  fullName?: string;
  firstName?: string;
  lastName?: string;
  name?: string;
  email?: string;
  vaccinationStatus?: string;
  vaccinationProof?: string;
  uploadDate?: Date;
  verificationStatus?: boolean;
  verificationDate?: Date;
  verifiedBy?: string;
  userId?: string;
  companyId?: string;
  comment?: string;
}

export enum RejectionReasons {
  DOCUMENT_IS_UNCLEAR = 'DOCUMENT_IS_UNCLEAR',
  INCORRECT_DOCUMENT = 'INCORRECT_DOCUMENT',
}

/** Vaccinations */
export enum ReadVaccinationsActionType {
  request = 'read.vaccinations.request',
  response = 'read.vaccinations.response',
  error = 'read.vaccinations.error',
}

export enum ReadVaccinationActionType {
  request = 'read.vaccination.request',
  response = 'read.vaccination.response',
  error = 'read.vaccination.error',
}

export enum UpdateVaccinationActionType {
  request = 'update.vaccination.request',
  response = 'update.vaccination.response',
  error = 'update.vaccination.error',
}

export enum CreateVaccinationSurveyActionType {
  request = 'create.vaccination.survey.request',
  response = 'create.vaccination.survey.response',
  error = 'create.vaccination.survey.error',
}

export enum ReadVaccinationSurveyActionType {
  request = 'read.vaccination.survey.request',
  response = 'read.vaccination.survey.response',
  error = 'read.vaccination.survey.error',
}
