import { Action } from 'redux';

export enum LoginActionType {
  request = 'login.request',
  response = 'login.response',
  error = 'login.error',
}

export const LoginClearActionType = 'login.clear';
export type LoginClearActionType = 'login.clear';
export type LoginClearAction = Action<LoginClearActionType>;

export enum LoginEmailExistsActionType {
  request = 'login.email.exists.request',
  response = 'login.email.exists.response',
  error = 'login.email.exists.error',
}

export const LoginEmailClearActionType = 'login.email.clear';
export type LoginEmailClearActionType = 'login.email.clear';
export type LoginEmailClearAction = Action<LoginEmailClearActionType>;

export const LoginMfaClearActionType = 'login.mfa.clear';
export type LoginMfaClearActionType = 'login.mfa.clear';
export type LoginMfaClearAction = Action<LoginMfaClearActionType>;
