import { User } from '../user';

export interface DeviceMeta {
  brand?: string;
  userAgent?: string;
  os?: string;
  model?: string;
  systemName?: string;
  systemVersion?: string;
  version?: string;
  buildNumber?: string;
  deviceName?: string;
}
export interface Device {
  id: string;
  user: User;
  app: {
    buildNumber: string;
    version: string;
  };
  deviceId: string;
  userAgent: string;
  ip: string;
  meta?: DeviceMeta;
  createdAt: string;
  updatedAt: string;
}

export enum ReadDevicesActionType {
  request = 'read.devices.request',
  response = 'read.devices.response',
  error = 'read.devices.error',
}
