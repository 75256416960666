export interface Microsoft365Subscription {
  dataContext: string;
  id: string;
  resource: string;
  applicationId: string;
  changeType: string;
  clientState: string;
  notificationUrl: string;
  expirationDateTime: string;
  creatorId: string;
  latestSupportedTlsVersion: string;
  notificationContentType: string;
}

/** Application level permission */
export interface CreateMicrosoft365SubscriptionParams {
  tenantId?: string;
  companyId?: string;
  email: string;
}
/** Delegated level permission */
export interface CreateMicrosoft365MeSubscriptionParams {
  accessToken: string;
  email: string;
}

export enum CreateMicrosoft365SubscriptionActionType {
  request = 'create.subscription.request',
  response = 'create.subscription.response',
  select = 'create.subscription.select',
  error = 'create.subscription.error',
}

export enum PostMessageTypes {
  ACTIVE = 'ACTIVE',
  CREATED = 'CREATED',
  EXPIRED = 'EXPIRED',
  UNDEFINED = 'UNDEFINED',
  ERROR = 'ERROR',
}
