import {
  CreateUserGroupActionType,
  ReadUserGroupActionType,
  UserGroup,
  ReadUserGroupsActionType,
  DeleteUserGroupActionType,
  UpdateUserGroupActionType,
  CreateUserGroupUserActionType,
  DeleteUserGroupUserActionType,
} from '../../../constants';
import {
  CreateUserGroupAction,
  CreateUserGroupUserAction,
  DeleteUserGroupAction,
  DeleteUserGroupUserAction,
  ReadUserGroupAction,
  ReadUserGroupsAction,
  UpdateUserGroupAction,
} from '../../../actions';
import { QueryState } from '..';

export interface UserGroupState extends QueryState {
  isFetching: boolean;
  usergroups: UserGroup[];
  error?: string;
  usergroup: Map<string, UserGroup>;
}

const initState: UserGroupState = {
  isFetching: false,
  usergroups: [],
  usergroup: new Map<string, UserGroup>(),
  pageInfo: {
    total: 0,
  },
};

export function usergroup(
  state = initState,
  action:
    | CreateUserGroupAction
    | DeleteUserGroupAction
    | ReadUserGroupAction
    | ReadUserGroupsAction
    | UpdateUserGroupAction
    | CreateUserGroupUserAction
    | DeleteUserGroupUserAction,
): UserGroupState {
  switch (action.type) {
    /** READ */
    case ReadUserGroupsActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadUserGroupsActionType.response:
      return {
        ...state,
        isFetching: false,
        usergroups: [...action.data],
        pageInfo: action.pageInfo,
      };
    case ReadUserGroupsActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    case ReadUserGroupActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadUserGroupActionType.response:
      return {
        ...state,
        isFetching: false,
        usergroup: state.usergroup.set(action.data.id, action.data),
      };
    case ReadUserGroupActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** CREATE */
    case CreateUserGroupActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case CreateUserGroupActionType.response:
      return {
        ...state,
        isFetching: false,
        usergroups: [...state.usergroups, action.data],
      };
    case CreateUserGroupActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** DELETE */
    case DeleteUserGroupActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case DeleteUserGroupActionType.response:
      state.usergroup.delete(action.data);
      return {
        ...state,
        isFetching: false,
        usergroups: [...state.usergroups.filter(({ id }) => id !== action.data)],
        usergroup: state.usergroup,
      };
    case DeleteUserGroupActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** UPDATE */
    case UpdateUserGroupActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case UpdateUserGroupActionType.response:
      return {
        ...state,
        isFetching: false,
        usergroups: [...state.usergroups.map((user) => (user.id === action.data.id ? action.data : user))],
        usergroup: state.usergroup.set(action.data.id, action.data),
      };
    case UpdateUserGroupActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Adding/Removing user from user group */
    case CreateUserGroupUserActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case CreateUserGroupUserActionType.response:
      return {
        ...state,
        isFetching: false,
      };
    case CreateUserGroupUserActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    case DeleteUserGroupUserActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case DeleteUserGroupUserActionType.response:
      return {
        ...state,
        isFetching: false,
      };
    case DeleteUserGroupUserActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    default:
      return state;
  }
}
