import React, { ChangeEvent, Fragment, useMemo } from 'react';
import { Box, Checkbox, Typography, Stack, TextField, Autocomplete } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../middlewares/nura-client/lib/redux/reducers';
import { ReducerOutlookOfficeAddIn } from '../../../../middlewares/nura-client/lib/redux/reducers/api/officeAddIns';
import { useBookingDetails } from '../../../../contexts/BookingDetails';
import { ILinkRooms } from '../../../../middlewares/nura-client/lib/redux/constants';

export default function LinkRoom(): JSX.Element {
  const { state: bookingState, dispatch: bookingDispatch } = useBookingDetails();

  const { resource } = useSelector<RootState, ReducerOutlookOfficeAddIn.IOutlookAddInState>(
    ({ outlookAddIn }) => outlookAddIn,
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    bookingDispatch({
      type: 'setLinkRoom',
      payload: {
        isLinkRoom: event.target.checked,
      },
    });
    if (!event.target.checked) {
      bookingDispatch({
        type: 'setLinkRoom',
        payload: {
          isLinkRoom: undefined,
        },
      });
    }
  };

  const handleRoomChange = (event: ChangeEvent<unknown>, newValue: ILinkRooms[]) => {
    bookingDispatch({
      type: 'setSelectedLinkRoom',
      payload: {
        selectedLinkRoom: newValue,
      },
    });
  };

  const renderRooms = useMemo(() => {
    if (!bookingState?.isLinkRoom) return null;
    const listLinkRooms: ILinkRooms[] = resource?.listLinkRooms?.length > 0 ? resource.listLinkRooms : [];
    if (!listLinkRooms?.length) return null;
    return (
      <>
        <Autocomplete
          id="link-room-map-demo"
          fullWidth
          multiple
          getOptionLabel={(option: ILinkRooms) => option.name}
          options={listLinkRooms}
          filterSelectedOptions
          value={bookingState?.selectedLinkRoom}
          onChange={handleRoomChange}
          renderInput={(params) => (
            <TextField {...params} label="Select a meeting room" fullWidth InputLabelProps={{ shrink: true }} />
          )}
        />
      </>
    );
  }, [bookingState, resource, bookingDispatch]);

  if (!resource?.listLinkRooms?.length) return null;
  return (
    <Fragment>
      <Box sx={{ m: 0, p: 0 }}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center">
          <Checkbox checked={bookingState?.isLinkRoom} onChange={handleChange} />
          <Typography variant="body1">Link meeting room</Typography>
        </Stack>
      </Box>
      {bookingState?.isLinkRoom && (
        <Box sx={{ m: 0, p: 0, mt: 1 }}>
          <Stack spacing={2}>{renderRooms}</Stack>
        </Box>
      )}
    </Fragment>
  );
}
