export const sxCustom = {
  appBar: {
    maxHight: '10vh',
    p: 0,
    m: 0,
  },
  container: {
    p: 2,
  },
  box: {
    fontSize: 20,
    textTransform: 'capitalize',
  },
};
