import { ReadDevicesActionType, Device } from '../../../constants';
import { ReadDevicesAction } from '../../../actions';

export interface DeviceState {
  isFetching: boolean;
  devices: Device[];
  error?: string;
  device: Map<string, any>;
}

const initState: DeviceState = {
  isFetching: false,
  devices: [],
  device: new Map<string, Device>(),
};

export function device(state = initState, action: ReadDevicesAction): DeviceState {
  switch (action.type) {
    /** READ */
    case ReadDevicesActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadDevicesActionType.response:
      return {
        ...state,
        isFetching: false,
        devices: [...action.data],
      };
    case ReadDevicesActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    default:
      return state;
  }
}
