import { Box, Button } from '@mui/material';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import RoomCard from '../../../components/RoomCard';
import { IBooking } from '../../../middlewares/nura-client/lib/redux/constants';
import { RootState } from '../../../middlewares/nura-client/lib/redux/reducers';
import { ReducerOutlookOfficeAddIn } from '../../../middlewares/nura-client/lib/redux/reducers/api/officeAddIns';
import { classes } from './styles';

export interface Booking {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  status: string;
  resource: any;
}

export default function BookingCards(): JSX.Element {
  const { availabilities } = useSelector<RootState, ReducerOutlookOfficeAddIn.IOutlookAddInState>(
    ({ outlookAddIn }) => outlookAddIn,
  );
  const navigate = useNavigate();
  const handleViewBooking = (item: IBooking) => {
    navigate(`/booking/${item.id}`, { replace: true, state: { ...item } });
  };

  return (
    <>
      {availabilities?.length > 0 ? (
        availabilities.map((item) => (
          <RoomCard
            key={item.id}
            actions={
              <Button variant="contained" fullWidth onClick={() => handleViewBooking(item)}>
                Select
              </Button>
            }
            services={item.services}
            name={item.name}
            start={item.start}
            end={item.end}
            building={item.building.name}
            floor={item.floor.name}
            capacity={item?.capacity}
          />
        ))
      ) : (
        <Box sx={classes.noRoomsRoot}>No Rooms Available</Box>
      )}
    </>
  );
}
