import React, { Fragment, useMemo } from 'react';
import { Box, Checkbox, Stack, Typography } from '@mui/material';
import { useFilterBookings } from '../../../providers/FilterBookings';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import { useSelector } from 'react-redux';
import { RootState } from '../../../middlewares/nura-client/lib/redux/reducers';
import { ReducerOutlookOfficeAddIn } from '../../../middlewares/nura-client/lib/redux/reducers/api/officeAddIns';

export default function ServicesManagement(): JSX.Element {
  const { state, dispatch } = useFilterBookings();

  const { resources } = useSelector<RootState, ReducerOutlookOfficeAddIn.IOutlookAddInState>(
    ({ outlookAddIn }) => outlookAddIn,
  );

  const renderServices = useMemo(() => {
    const handleChangeServices = (event: React.ChangeEvent<HTMLInputElement>) => {
      const prevServices: number[] = state.filter.management?.services ? [...state.filter.management?.services] : [];
      const findService = prevServices.find((itmPrev) => Number(itmPrev) === Number(event.currentTarget.name));
      let newServices = prevServices;
      if (event.currentTarget.checked) {
        if (!findService) {
          newServices = [...prevServices, Number(event.currentTarget.name)];
        } else {
          newServices = [...prevServices];
        }
      } else {
        newServices = prevServices.filter((itemService) => Number(itemService) !== Number(event.currentTarget.name));
      }
      dispatch({
        type: 'setParams',
        payload: {
          ...state.filter,
          management: {
            ...state.filter.management,
            services: newServices,
          },
        },
      });
    };

    return (
      <Fragment>
        {resources.services.map((service) => {
          const isChecked = state.filter.management?.services?.find(
            (objService) => Number(objService) === Number(service.id),
          );
          return (
            <Box key={service.id}>
              <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                <Checkbox
                  sx={{ p: 0 }}
                  name={`${service.id}`}
                  onChange={handleChangeServices}
                  checked={isChecked ? true : false}
                />
                <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
                  {service.displayName}
                </Typography>
              </Stack>
            </Box>
          );
        })}
      </Fragment>
    );
  }, [state.filter, dispatch, resources.services]);

  return (
    <Box sx={{ my: 2 }}>
      <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
        <Box sx={{ width: 160 }}>
          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
            <PlaylistAddCheckIcon color="primary" />
            <Typography variant="body2">Services</Typography>
          </Stack>
        </Box>
        <Box>
          <Stack spacing={2}>{renderServices}</Stack>
        </Box>
      </Stack>
    </Box>
  );
}
