import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Button, DialogActions } from '@mui/material';
type Props = {
  children?: JSX.Element;
  open: boolean;
  handleClickOpen?: (value: boolean) => void;
  handleSendOnPreview: () => void;
  textMessage?: string;
  loading?: boolean;
};

const DialogBookingPreviewPage = ({
  children,
  open,
  handleClickOpen,
  handleSendOnPreview,
  loading = false,
}: Props): JSX.Element => {
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableEscapeKeyDown
    >
      <DialogContent sx={{ p: 1 }}>{children}</DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => handleClickOpen(false)} variant="outlined" fullWidth>
          Modify
        </Button>
        <Button onClick={handleSendOnPreview} autoFocus variant="contained" fullWidth>
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogBookingPreviewPage;
