import { IMenuResponse } from '../catering';
import { IPackageResponse } from '../package';

export interface IFoodItemParams {
  id: string;
  scope: string;
  name: string;
  packageId: string;
  description?: string;
  cost?: string;
  active?: boolean;
}

export interface IFoodItemResponse {
  id: string;
  name: string;
  description: string;
  packageId: string;
  cost?: string;
  packageName?: string;
  menuName?: string;
  menu?: IMenuResponse;
  package?: IPackageResponse;
}

export const initialFoodItemResponse = {
  id: undefined,
  name: undefined,
  description: undefined,
  packageId: undefined,
  cost: undefined,
  packageName: undefined,
  menuName: undefined,
  menu: undefined,
  package: undefined,
};

export enum ReadCateringFoodsActionType {
  request = 'read.catering.foods.request',
  response = 'read.catering.foods.response',
  error = 'read.catering.foods.error',
}

export enum ReadCateringFoodActionType {
  request = 'read.catering.food.request',
  response = 'read.catering.food.response',
  error = 'read.catering.food.error',
}

export enum CreateCateringFoodActionType {
  request = 'create.catering.Food.request',
  response = 'create.catering.Food.response',
  error = 'create.catering.Food.error',
}

export enum UpdateCateringFoodActionType {
  request = 'update.catering.Food.request',
  response = 'update.catering.Food.response',
  error = 'update.catering.Food.error',
}

export enum DeleteCateringFoodActionType {
  request = 'delete.catering.Food.request',
  response = 'delete.catering.Food.response',
  error = 'delete.catering.Food.error',
}
