import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import TextFieldCustomNumber from '../../../../components/TextFieldCustomNumber';
import AutoCompleteCustom from '../../../../components/AutoCompleteCustom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../middlewares/nura-client/lib/redux/reducers';
import { ReducerOutlookOfficeAddIn } from '../../../../middlewares/nura-client/lib/redux/reducers/api/officeAddIns';
import { getDataServiceTypes } from '../../Helpers';
import { AddInConstant } from '../../../../constants';
import { useBookingDetails } from '../../../../contexts/BookingDetails';
import { setupTimes } from '../../../../constants/AddIn';
import { EMeetingRoomService, ITimeItem } from '../../../../middlewares/nura-client/lib/redux/constants';
import { getIntervalInMinutes } from '../../../../services/Format/Date';

const RoomServicesSetup = (): JSX.Element => {
  const [intervalInMinutes, setIntervalInMinutes] = useState<ITimeItem[]>(setupTimes);
  const { resource } = useSelector<RootState, ReducerOutlookOfficeAddIn.IOutlookAddInState>(
    ({ outlookAddIn }) => outlookAddIn,
  );
  const { state: bookingState, dispatch: bookingDispatch } = useBookingDetails();

  useEffect(() => {
    const timesInMinutes = getIntervalInMinutes({
      intervalInHours: 3,
      step: 15,
    });
    if (timesInMinutes.length) {
      setIntervalInMinutes(timesInMinutes);

      if (resource?.cleanDown && resource?.setUp) {
        const defaultCleanDown = timesInMinutes?.find(
          (itemCleanDown) => Number(itemCleanDown?.id) === Number(resource?.cleanDown),
        );
        const defaultSetUp = timesInMinutes?.find((itemSetUp) => Number(itemSetUp?.id) === Number(resource?.setUp));
        if (defaultCleanDown?.id && defaultSetUp?.id) {
          bookingDispatch({
            type: 'setFacilities',
            payload: {
              facilities: {
                ...bookingState.facilities,
                cleanDown: defaultCleanDown,
                setup: defaultSetUp,
              },
            },
          });
        }
      }
    }
  }, [resource]);

  const renderChairs = useMemo(() => {
    const handleChair = (newValue: number, type?: 'typing' | 'count') => {
      let newChairs =
        type === 'typing' ? Number(newValue) : Number(bookingState.facilities.noChairs) + Number(newValue);
      if (newChairs < 0) newChairs = 0;
      bookingDispatch({
        type: 'setFacilities',
        payload: {
          facilities: {
            ...bookingState.facilities,
            noChairs: Number(newChairs),
          },
        },
      });
    };

    const noChairsService = getDataServiceTypes({
      data: resource,
      service: EMeetingRoomService.FACILITIES,
      type: AddInConstant.EServiceType.NO_CHAIRS,
    });

    if (!noChairsService) return null;
    return (
      <>
        {noChairsService?.inputType.toLowerCase() === AddInConstant.EServiceInputType.COUNTER.toLowerCase() && (
          <TextFieldCustomNumber
            width="100%"
            label="No. chairs"
            value={bookingState.facilities.noChairs}
            onChange={(newValue) => handleChair(newValue)}
            onTyping={(newValue) => handleChair(newValue, 'typing')}
          />
        )}
      </>
    );
  }, [resource, bookingDispatch, bookingState.facilities]);

  const renderTables = useMemo(() => {
    const handleTable = (newValue: number, type?: 'typing' | 'count') => {
      let newTables =
        type === 'typing' ? Number(newValue) : Number(bookingState.facilities.noTables) + Number(newValue);
      if (newTables < 0) newTables = 0;
      bookingDispatch({
        type: 'setFacilities',
        payload: {
          facilities: {
            ...bookingState.facilities,
            noTables: Number(newTables),
          },
        },
      });
    };

    const noTablesService = getDataServiceTypes({
      data: resource,
      service: EMeetingRoomService.FACILITIES,
      type: AddInConstant.EServiceType.NO_TABLES,
    });

    if (!noTablesService) return null;
    return (
      <>
        {noTablesService?.inputType.toLowerCase() === AddInConstant.EServiceInputType.COUNTER.toLowerCase() && (
          <TextFieldCustomNumber
            width="100%"
            label="No. tables"
            value={bookingState.facilities.noTables}
            onChange={(newValue) => handleTable(newValue)}
            onTyping={(newValue) => handleTable(newValue, 'typing')}
          />
        )}
      </>
    );
  }, [resource, bookingDispatch, bookingState.facilities]);

  const renderTableSetUpInMinutes = useMemo(() => {
    const handleSetup = (newValue: ITimeItem) => {
      bookingDispatch({
        type: 'setFacilities',
        payload: {
          facilities: {
            ...bookingState.facilities,
            setup: newValue,
          },
        },
      });
    };

    const tableSetUpService = getDataServiceTypes({
      data: resource,
      service: EMeetingRoomService.FACILITIES,
      type: AddInConstant.EServiceType.SET_UP,
    });

    /* if (!tableSetUpService) */ return null;
    // return (
    //   <>
    //     {tableSetUpService?.inputType.toLowerCase() === AddInConstant.EServiceInputType.DROPDOWN.toLowerCase() &&
    //     resource?.setUp ? (
    //       <AutoCompleteCustom
    //         value={resource.setUp}
    //         width="100%"
    //         options={intervalInMinutes}
    //         label="Set up"
    //         onChange={(event: any, newValue: ITimeItem) => handleSetup(newValue)}
    //         isDisabled={true}
    //       />
    //     ) : null}
    //   </>
    // );
  }, [resource, bookingState.facilities, bookingDispatch, intervalInMinutes]);

  const renderCleanDownInMinutes = useMemo(() => {
    const handleCleanDown = (newValue: ITimeItem) => {
      bookingDispatch({
        type: 'setFacilities',
        payload: {
          facilities: {
            ...bookingState.facilities,
            cleanDown: newValue,
          },
        },
      });
    };

    const tableSetUpService = getDataServiceTypes({
      data: resource,
      service: EMeetingRoomService.FACILITIES,
      type: AddInConstant.EServiceType.CLEAN_DOWN,
    });

    /* if (!tableSetUpService) */ return null;
    // return (
    //   <>
    //     {tableSetUpService?.inputType.toLowerCase() === AddInConstant.EServiceInputType.DROPDOWN.toLowerCase() &&
    //     resource?.cleanDown ? (
    //       <AutoCompleteCustom
    //         width="100%"
    //         options={intervalInMinutes}
    //         label="Clean down"
    //         value={resource.cleanDown}
    //         onChange={(event: any, newValue: ITimeItem) => handleCleanDown(newValue)}
    //         isDisabled={true}
    //       />
    //     ) : null}
    //   </>
    // );
  }, [resource, bookingState.facilities, bookingDispatch, intervalInMinutes]);

  return (
    <Fragment>
      <Typography variant="body1">Room setup</Typography>
      <Box>
        <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
          {renderChairs}
          {renderTables}
        </Stack>
        <Stack direction="row" spacing={2}>
          {renderTableSetUpInMinutes}
          {renderCleanDownInMinutes}
        </Stack>
      </Box>
    </Fragment>
  );
};

export default RoomServicesSetup;
