import { palette } from '../../../styles/theme';

export const classes = {
  button: {
    maxWidth: '30px',
    maxHeight: '30px',
    minWidth: '30px',
    minHeight: '30px',
    borderColor: palette.border.main,
  },
  icon: {
    opacity: 0.5,
    fontSize: 14,
  },
  iconLabel: {
    fontSize: 12,
  },
  noRoomsRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
};
