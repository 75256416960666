export const RANGES_OF_DAYS = 7;
export const RANGES_OF_REPORT_DAYS = 7;

export enum ReadDashboardAvailableVsTotalDesksActionType {
  request = 'read.dashboard.available.total.desks.request',
  response = 'read.available.total.desks.response',
  error = 'read.available.total.desks.error',
}

export enum ReadDashboardCheckedInVsBookedActionType {
  request = 'read.dashboard.checkedin.booked.request',
  response = 'read.dashboard.checkedin.booked.response',
  error = 'read.dashboard.checkedin.booked.error',
}

export enum ReadDashboardTotalsActionType {
  request = 'read.dashboard.totals.request',
  response = 'read.dashboard.totals.response',
  error = 'read.dashboard.totals.error',
}

export enum ReadDashboardActiveUsersActionType {
  request = 'read.dashboard.active.users.request',
  response = 'read.dashboard.active.users.response',
  error = 'read.dashboard.active.users.error',
}

export enum ReadDashboardPeakVsAverageUtilisationActionType {
  request = 'read.dashboard.peak.average.request',
  response = 'read.dashboard.peak.average.response',
  error = 'read.dashboard.peak.average.error',
}

export interface DashboardDesks {
  activeDesks: number;
  currentBookings: number;
}

export interface DashboardCheckedIn {
  currentCheckedIn: number;
  currentBookings: number;
}

export interface DashboardTotals {
  activeBuildings: number;
  activeFloors: number;
  activeDesks: number;
  inactiveDesks: number;
  activeUsers: number;
}

export interface DashboardActiveUsers {
  date: string;
  value: number;
}

export interface DashboardPeakVsAverage {
  date: Date;
  max: number;
  avg: number;
}
