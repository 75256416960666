import React from 'react';
import { Box, Container } from '@mui/material';
import { common } from '@mui/material/colors';
import AppBarCustom from '../AppBarCustom';
import { sxCustom } from './styles';
import useWindowDimensions from '../../hooks/useWindowDimensions';
type Props = {
  children: JSX.Element;
  header?: boolean;
};
const Layout = ({ header = true, children }: Props): JSX.Element => {
  const { width: windowsWidth, height: windowsHeight } = useWindowDimensions();
  return (
    <Container
      maxWidth="sm"
      sx={sxCustom.container}
      style={{
        overflow: header ? 'hidden' : 'auto',
        height: windowsHeight || '100%',
        width: windowsWidth || '100%',
      }}
    >
      {header && <AppBarCustom />}
      <Box sx={{ p: 2, background: common.white }}>{children}</Box>
    </Container>
  );
};

export default Layout;
