import React, { Fragment } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Autocomplete, InputBase, TextField } from '@mui/material';
import { classes } from '../../styles/theme';

export default function AutoCompleteCustom({
  value = null,
  options,
  placeholder,
  width = 250,
  onChange,
  label = undefined,
  variant = 'outlined',
  color = undefined,
  disableClearable = false,
  isDisabled = false,
}: {
  value?: any;
  options: any[];
  placeholder?: string;
  width?: number | string;
  label?: string;
  onChange: (event: any, newValue: any) => void;
  variant?: 'outlined' | 'standard';
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | undefined;
  disableClearable?: boolean;
  isDisabled?: boolean;
}) {
  return (
    <Autocomplete
      disablePortal
      disableClearable={disableClearable}
      id={`combo-box-custom-${Math.random()}`}
      options={options}
      disabled={isDisabled}
      sx={{
        width,
        '.MuiInputBase-root': {
          width: width,
          minWidth: width,
          pr: 0,
        },
        '.MuiOutlinedInput': {
          root: {
            p: 0,
          },
        },
      }}
      value={value ? value : null}
      onChange={onChange}
      renderInput={(params) => {
        const { InputLabelProps, InputProps, ...rest } = params;

        return (
          <Fragment>
            {variant === 'outlined' ? (
              <TextField
                {...params}
                placeholder={placeholder || ''}
                label={label}
                variant={variant}
                InputLabelProps={{ shrink: true }}
              />
            ) : (
              <InputBase {...params.InputProps} {...rest} placeholder={placeholder || ''} />
            )}
          </Fragment>
        );
      }}
      popupIcon={<KeyboardArrowDownIcon style={classes.iconInput} color={color} />}
    />
  );
}
