import React from 'react';
import { Grid, Chip, Typography } from '@mui/material';
import {
  IOutlookAddInMeetingRoomCateringMenu,
  IOutlookAddInMeetingRoomCateringPackage,
} from '../../../../../middlewares/nura-client/lib/redux/constants';
interface IProps {
  menuSelected: IOutlookAddInMeetingRoomCateringMenu;
  packageSelected: IOutlookAddInMeetingRoomCateringPackage;
  handleOnSelectPackage: (newPackage: IOutlookAddInMeetingRoomCateringPackage) => void;
}
export default function PackageItems({ menuSelected, packageSelected, handleOnSelectPackage }: IProps): JSX.Element {
  return (
    <>
      {menuSelected?.packages?.map((objPackage, index) => {
        const isSelected = Number(packageSelected?.id) === Number(objPackage.id);
        return (
          <Grid item xs={4} key={index}>
            <Chip
              sx={{ width: '100%', height: 75 }}
              label={
                <Typography
                  variant="body2"
                  style={{
                    whiteSpace: 'normal',
                    textAlign: 'center',
                    color: isSelected ? 'white' : '#2E2F2F',
                  }}
                >
                  {objPackage.name}
                </Typography>
              }
              onClick={() => handleOnSelectPackage(objPackage)}
              clickable
              color={isSelected ? 'primary' : 'default'}
            />
          </Grid>
        );
      })}
    </>
  );
}
