import { CreateShortUrlAction, DecodeShortUrlWebAction, ReadShortUrlAction } from '../../../actions';
import { ReadAttendanceShortUrlAction } from '../../../actions/api/shorturl/readAttendanceShortUrl';
import { ReadFromFloorFirstAidStationShortUrlsAction } from '../../../actions/api/shorturl/readFromFloorFirstAidStationShortUrls';
import { ReadFromFloorShortUrlsAction } from '../../../actions/api/shorturl/readFromFloorShortUrl';
import { ReadResourceGroupShortUrlsAction } from '../../../actions/api/shorturl/readResourceGroupShortUrls';
import { ReadShortUrlForFirstAidStationAction } from '../../../actions/api/shorturl/readShortUrlForFirstAidStation';
import {
  CreateShortUrlActionType,
  DecodeShortUrlWebActionType,
  ReadAttendanceShortUrlActionType,
  ReadFromFloorFirstAidStationShortUrlsActionType,
  ReadFromFloorShortUrlsActionType,
  ReadResourceGroupShortUrlsActionType,
  ReadShortUrlActionType,
  ReadShortUrlForFirstAidStationActionType,
  Resource,
  ShortUrl,
} from '../../../constants';

export interface ShortUrlState {
  isFetching: boolean;
  shortUrl: ShortUrl;
  shortUrls: ShortUrl[];
  resource: Resource;
  resourceCheckedIn: boolean;
  resourceSerialNumber: string;
  error?: string;
}

const initState: ShortUrlState = {
  isFetching: false,
  shortUrl: null,
  shortUrls: [],
  resource: null,
  resourceCheckedIn: false,
  resourceSerialNumber: null,
  error: null,
};

export function shorturl(
  state = initState,
  action:
    | ReadShortUrlAction
    | CreateShortUrlAction
    | ReadResourceGroupShortUrlsAction
    | ReadFromFloorShortUrlsAction
    | ReadAttendanceShortUrlAction
    | DecodeShortUrlWebAction
    | ReadShortUrlForFirstAidStationAction
    | ReadFromFloorFirstAidStationShortUrlsAction,
): ShortUrlState {
  switch (action.type) {
    /**Read or Create from resource Id */

    case ReadShortUrlActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadShortUrlActionType.response:
      return {
        ...state,
        isFetching: false,
        shortUrl: action.data,
      };
    case ReadShortUrlActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** CREATE */
    case CreateShortUrlActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case CreateShortUrlActionType.response:
      return {
        ...state,
        isFetching: false,
        shortUrl: action.data,
      };
    case CreateShortUrlActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Read or Create from resource group */

    case ReadResourceGroupShortUrlsActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadResourceGroupShortUrlsActionType.response:
      return {
        ...state,
        isFetching: false,
        shortUrls: action.data,
      };
    case ReadResourceGroupShortUrlsActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Read or Create from floor id */

    case ReadFromFloorShortUrlsActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadFromFloorShortUrlsActionType.response:
      return {
        ...state,
        isFetching: false,
        shortUrls: action.data,
      };
    case ReadFromFloorShortUrlsActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** CREATE  OR CREATE FOR ATTENDANCE*/
    case ReadAttendanceShortUrlActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadAttendanceShortUrlActionType.response:
      return {
        ...state,
        isFetching: false,
        shortUrl: action.data,
      };
    case ReadAttendanceShortUrlActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Decode shorturl for web */
    case DecodeShortUrlWebActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case DecodeShortUrlWebActionType.response:
      return {
        ...state,
        isFetching: false,
        resource: action.resource,
        resourceCheckedIn: action.checkedIn,
        resourceSerialNumber: action.serialNumber,
      };
    case DecodeShortUrlWebActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    case ReadShortUrlForFirstAidStationActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadShortUrlForFirstAidStationActionType.response:
      return {
        ...state,
        isFetching: false,
        shortUrl: action.data,
      };
    case ReadShortUrlForFirstAidStationActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    case ReadFromFloorFirstAidStationShortUrlsActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadFromFloorFirstAidStationShortUrlsActionType.response:
      return {
        ...state,
        isFetching: false,
        shortUrls: action.data,
      };
    case ReadFromFloorFirstAidStationShortUrlsActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    default:
      return state;
  }
}
