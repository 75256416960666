import React, { Fragment } from 'react';
import { Grid, Stack, Tooltip, Typography } from '@mui/material';
import CardCustom from '../CardCustom';
import BusinessIcon from '@mui/icons-material/Business';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { ReactComponent as CateringIcon } from '../../assets/images/booking/catering.svg';
import { ReactComponent as FacilityIcon } from '../../assets/images/booking/facility.svg';
import { ReactComponent as ItIcon } from '../../assets/images/booking/it.svg';
import { classes } from '../../styles/theme';
import { dateService } from '../../services';
import { EMeetingRoomService, IResourcesItem } from '../../middlewares/nura-client/lib/redux/constants';
import PeopleIcon from '@mui/icons-material/People';
import RepeatIcon from '@mui/icons-material/Repeat';
import OnRepeat from '../DialogBookingRecurrence/OnRepeat';
import TextRecurringBooking from '../DialogBookingRecurrence/TextRecurringBooking';
import { differenceInHours } from 'date-fns';

export interface Services {}

interface Props {
  services?: IResourcesItem[];
  name: string;
  start: string;
  end: string;
  building: string;
  floor: string;
  actions?: JSX.Element | null;
  selected?: boolean;
  capacity?: number;
}

const RoomCard = ({
  services,
  name,
  start,
  end,
  building,
  floor,
  actions = null,
  selected = false,
  capacity = 0,
}: Props): JSX.Element => {
  const startDateFormat = dateService.formatDateTime({ date: new Date(start), format: 'LLL dd' });

  const startFormat = dateService.formatDateTime({ date: new Date(start), format: 'p' });
  const endFormat = dateService.formatDateTime({ date: new Date(end), format: 'p' });
  const totalDurationInHours = differenceInHours(new Date(end), new Date(start));

  const isAllDay = totalDurationInHours === 24;
  return (
    <CardCustom selected={selected} actions={actions ? actions : null}>
      <Fragment>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={0}>
              <Typography variant="h5">{name}</Typography>
              <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                {services?.map((itemService) => {
                  return (
                    <Fragment key={itemService.id}>
                      {itemService.name.toLowerCase() === EMeetingRoomService.FACILITIES.toLowerCase() ? (
                        <Tooltip title="Facilities" arrow>
                          <FacilityIcon />
                        </Tooltip>
                      ) : null}
                      {itemService.name.toLowerCase() === EMeetingRoomService.IT.toLowerCase() ? (
                        <Tooltip title="IT" arrow>
                          <ItIcon />
                        </Tooltip>
                      ) : null}
                      {itemService.name.toLowerCase() === EMeetingRoomService.CATERING.toLowerCase() ? (
                        <Tooltip title="Catering" arrow>
                          <CateringIcon />
                        </Tooltip>
                      ) : null}
                    </Fragment>
                  );
                })}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              {isAllDay ? 'All day' : startFormat}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
              <BusinessIcon style={classes.icon} sx={{ opacity: 0.5 }} />
              <Typography variant="caption" style={classes.iconLabel}>
                {`${building},${floor}`}
              </Typography>
            </Stack>

            {capacity > 0 && (
              <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                <PeopleIcon style={classes.icon} sx={{ opacity: 0.5 }} />
                <Typography variant="caption" style={classes.iconLabel}>
                  {`${capacity} people`}
                </Typography>
              </Stack>
            )}

            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
              <CalendarTodayIcon style={classes.icon} sx={{ opacity: 0.5 }} />

              <Typography variant="caption" style={classes.iconLabel}>
                {isAllDay ? 'All day' : `${startDateFormat}, from ${startFormat} to ${endFormat}`}
              </Typography>
            </Stack>
            {selected && (
              <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                <RepeatIcon style={classes.icon} sx={{ opacity: 0.5 }} />
                <OnRepeat />
              </Stack>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <TextRecurringBooking />
          </Grid>
        </Grid>
      </Fragment>
    </CardCustom>
  );
};

export default RoomCard;
