import { Permission } from '../permission';

export interface PermissionGroup {
  id: string;
  name: string;
  displayName: string;
  description?: string;
  scope: string;
  company?: string;
  permissions: Permission[];
  readonly?: boolean;
  createdAt: string;
  updatedAt: string;
  isDirectAssignable?: boolean;
  isContactRequired?: boolean;
  isHealthSafetyRole?: boolean;
  companyName?: string;
}

export enum ReadPermissionGroupsActionType {
  request = 'read.permissiongroups.request',
  response = 'read.permissiongroups.response',
  error = 'read.permissiongroups.error',
}

export enum PermissionGroupName {
  SUPER_ADMINISTRATOR = 'super-administrator',
  MASTER_ADMINISTRATOR = 'master-administrator',
  TEAM_LEADER = 'team-leader',
  RESOURCE_GROUP_ADMINISTRATOR = 'resource-group-administrator',
  GROUP_ADMINISTRATOR = 'group-administrator',
  FIRST_AIDER = 'first-aider',
  FIRE_WARDEN = 'fire-warden',
  SUPPORT = 'support',
  ENTRY_SCREEN = 'entry-screen',
  CONCIERGE = 'concierge',
  EXECUTIVE_ASSISTANT = 'executive-assistant',
  IT_DEPARTMENT = 'it-department',
  FACILITY_TEAM = 'facility-team',
  CATERING_TEAM = 'catering-team',
  WELLBEING_OFFICER = 'wellbeing-officer',
}
