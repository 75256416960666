import { addDays, eachDayOfInterval } from 'date-fns';
import { IRecurringEventsDateSeriesParams, IRecurringEventsDateSeriesResponse } from '.';
import { ERecurrenceRangeType, IRecurringEventDaily } from '../../middlewares/nura-client/lib/redux/constants';

interface IDailyDateSeriesParams extends IRecurringEventsDateSeriesParams {
  data: IRecurringEventDaily;
}
export const getDailyDateSeries = ({ data, range }: IDailyDateSeriesParams): IRecurringEventsDateSeriesResponse => {
  const result = { dates: [] };
  if (!data?.pattern) return result;

  const { startDate: rangeStart, endDate: rangeEnd, type: rangeType, numberOfOccurrences } = range;
  const { interval } = data.pattern;
  let limitNumberOfOccurrences = 0; // set default is no limit

  /** Ranges with Date */
  if (rangeType === ERecurrenceRangeType.END_DATE) {
    const allDates = eachDayOfInterval(
      {
        start: new Date(rangeStart),
        end: new Date(rangeEnd),
      },
      { step: interval },
    );
    result.dates = allDates;
  }

  /** Ranges with number */
  if (rangeType === ERecurrenceRangeType.NUMBERED) {
    limitNumberOfOccurrences = Number(numberOfOccurrences);
    const allDates = eachDayOfInterval(
      {
        start: new Date(rangeStart),
        end: addDays(new Date(rangeStart), limitNumberOfOccurrences > 0 ? limitNumberOfOccurrences : 1),
      },
      { step: interval },
    );
    result.dates = allDates;
  }

  return result;
};
