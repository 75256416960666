import { Team } from '..';
import { Company } from '../company';
import { User } from '../user';
import { UserGroup } from '../usergroup';

export enum NotificationType {
  GROUP = 'GROUP',
  BOOKING = 'BOOKING',
  PUSH = 'PUSH',
  EMAIL = 'EMAIL',
  APP = 'APP',
  TEAM = 'TEAM',
}

export enum NotificationStyle {
  SUCCESS = 'SUCCESS',
  INFO = 'INFO',
  WARNING = 'WARNING',
  CRITICAL = 'CRITICAL',
}

export enum NotificationStatus {
  SENT = 'SENT',
  CANCELLED = 'CANCELLED',
  SCHEDULED = 'SCHEDULED',
}

export interface Notification {
  id: string;
  scheduledAt?: string;
  user?: User;
  company?: Company;
  userGroups?: UserGroup[];
  teams?: Team[];
  title?: string;
  message?: string;
  data?: any;
  type: NotificationType;
  style: NotificationStyle;
  status: NotificationStatus;
  createdAt: string;
  updatedAt: string;
}

export interface NotificationRegistration {
  ETag: string;
  ExpirationTime: string;
  RegistrationId: string;
  Tags: string;
  DeviceToken?: string;
  GcmRegistrationId?: string;
}

export enum ReadNotificationsActionType {
  request = 'read.notifications.request',
  response = 'read.notifications.response',
  error = 'read.notifications.error',
}

export enum CreateNotificationActionType {
  request = 'create.notification.request',
  response = 'create.notification.response',
  error = 'create.notification.error',
}

export enum DeleteNotificationActionType {
  request = 'delete.notification.request',
  response = 'delete.notification.response',
  error = 'delete.notification.error',
}
