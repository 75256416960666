import { QueryState } from '../..';
import {
  IFoodItemResponse,
  ReadCateringFoodsActionType,
  ReadCateringFoodActionType,
  UpdateCateringFoodActionType,
  CreateCateringFoodActionType,
} from '../../../../constants';
import {
  ReadCateringFoodsAction,
  ReadCateringFoodAction,
  UpdateCateringFoodAction,
  CreateCateringFoodAction,
} from '../../../../actions';

export interface ICateringFoodState extends QueryState {
  isFetching: boolean;
  foods: IFoodItemResponse[];
  food: IFoodItemResponse;
  error?: string;
}

const initState: ICateringFoodState = {
  isFetching: false,
  foods: [],
  food: undefined,
  pageInfo: {
    total: 0,
  },
};

export function cateringFoodItem(
  state = initState,
  action: ReadCateringFoodsAction | ReadCateringFoodAction | UpdateCateringFoodAction | CreateCateringFoodAction,
): ICateringFoodState {
  switch (action.type) {
    /** Create Food */
    case CreateCateringFoodActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case CreateCateringFoodActionType.response:
      return {
        ...state,
        isFetching: false,
        food: action.data,
      };
    case CreateCateringFoodActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Rad Foods */
    case ReadCateringFoodsActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadCateringFoodsActionType.response:
      return {
        ...state,
        isFetching: false,
        foods: action.data,
        pageInfo: action.pageInfo,
      };
    case ReadCateringFoodsActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Rad Food */
    case ReadCateringFoodActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadCateringFoodActionType.response:
      return {
        ...state,
        isFetching: false,
        food: action.data,
      };
    case ReadCateringFoodActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Update Food */
    case UpdateCateringFoodActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case UpdateCateringFoodActionType.response:
      return {
        ...state,
        isFetching: false,
        food: action.data,
      };
    case UpdateCateringFoodActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    default:
      return state;
  }
}
