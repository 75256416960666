import React, { useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import { useBookingDetails } from '../../../../../contexts/BookingDetails';
import { classes } from '../../styles';

const FacilitiesPreview = (): JSX.Element => {
  const { state: bookingState } = useBookingDetails();
  const facilityRequests = bookingState?.facilities;

  const renderEquipmentRequests = useMemo(() => {
    const equipmentRequests = facilityRequests?.equipment?.map((objRequest) => objRequest?.name);
    if (!equipmentRequests?.length) return null;
    return (
      <Grid item xs={12}>
        <Grid container spacing={0}>
          <Grid item xs={5}>
            <Typography variant="body2" style={classes.label}>
              Equipment
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body2" style={classes.value}>
              {equipmentRequests?.join(', ')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }, [facilityRequests?.equipment]);

  const renderServiceRequests = useMemo(() => {
    return (
      <>
        {Number(facilityRequests?.noChairs) > 0 && (
          <Grid item xs={12}>
            <Grid container spacing={0}>
              <Grid item xs={5}>
                <Typography variant="body2" style={classes.label}>
                  Chairs
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="body2" style={classes.value}>
                  {facilityRequests?.noChairs} {Number(facilityRequests?.noChairs) > 1 ? `chairs` : `chair`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        {Number(facilityRequests?.noTables) > 0 && (
          <Grid item xs={12}>
            <Grid container spacing={0}>
              <Grid item xs={5}>
                <Typography variant="body2" style={classes.label}>
                  Tables
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="body2" style={classes.value}>
                  {facilityRequests?.noTables} {Number(facilityRequests?.noChairs) > 1 ? `tables` : `table`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}

        {facilityRequests?.setup?.id && (
          <Grid item xs={12}>
            <Grid container spacing={0}>
              <Grid item xs={5}>
                <Typography variant="body2" style={classes.label}>
                  Set up
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="body2" style={classes.value}>
                  {facilityRequests?.setup?.label}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}

        {facilityRequests?.cleanDown?.id && (
          <Grid item xs={12}>
            <Grid container spacing={0}>
              <Grid item xs={5}>
                <Typography variant="body2" style={classes.label}>
                  Clean down
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="body2" style={classes.value}>
                  {facilityRequests?.cleanDown?.label}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </>
    );
  }, [facilityRequests]);

  return (
    <Grid container spacing={0}>
      {renderEquipmentRequests}
      {facilityRequests?.notes && (
        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Grid item xs={5}>
              <Typography variant="body2" style={classes.label}>
                Notes
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="body2" style={classes.value}>
                {facilityRequests.notes}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      {renderServiceRequests}
    </Grid>
  );
};

export default FacilitiesPreview;
