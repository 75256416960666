import { User, AccessGroup, Company, Resource, DayOfTheWeek } from '..';

export interface ResourceAllocation {
  Id: number;
  Resource: Resource;
  DayOfTheWeek: DayOfTheWeek;
}

export interface UserGroupLocation {
  Location?: string;
  ZoneId: number;
  LabelX?: string;
  LabelY?: string;
  DayOfTheWeek: DayOfTheWeek;
}

export interface UserGroup {
  id: string;
  name: string;
  description?: string;
  companyId?: string;
  company?: Company;
  accessGroup: AccessGroup;
  users?: User[];
  admins?: User[];
  isDefault?: boolean;
  createdAt: string;
  updatedAt: string;
  isAdmin?: boolean; // attribute on users_usergroup table
  isSearchableOnFloor?: boolean;
  IsTSMCreated: boolean;
  DesiredSharingRatio?: number; // Attribute on zones_usergroups
  ResourcesAllocation?: ResourceAllocation[];
  userCount?: number;
  Locations?: UserGroupLocation[];
}

export enum ReadUserGroupsActionType {
  request = 'read.usergroups.request',
  response = 'read.usergroups.response',
  error = 'read.usergroups.error',
}

export enum ReadUserGroupActionType {
  request = 'read.usergroup.request',
  response = 'read.usergroup.response',
  error = 'read.usergroup.error',
}

export enum CreateUserGroupActionType {
  request = 'create.usergroup.request',
  response = 'create.usergroup.response',
  error = 'create.usergroup.error',
}

export enum UpdateUserGroupActionType {
  request = 'update.usergroup.request',
  response = 'update.usergroup.response',
  error = 'update.usergroup.error',
}

export enum DeleteUserGroupActionType {
  request = 'delete.usergroup.request',
  response = 'delete.usergroup.response',
  error = 'delete.usergroup.error',
}

/** Add/remove user to/from user from */

export enum CreateUserGroupUserActionType {
  request = 'create.usergroup.user.request',
  response = 'create.usergroup.user.response',
  error = 'create.usergroup.user.error',
}

export enum DeleteUserGroupUserActionType {
  request = 'delete.usergroup.user.request',
  response = 'delete.usergroup.user.response',
  error = 'delete.usergroup.user.error',
}

export enum UpdateUsersInGroupActionType {
  request = 'update.users.usergroup.request',
  response = 'update.users.usergroup.response',
  error = 'update.users.usergroup.error',
}
