import {
  CreateResourceGroupActionType,
  ReadResourceGroupActionType,
  ResourceGroup,
  ReadResourceGroupsActionType,
  DeleteResourceGroupActionType,
  UpdateResourceGroupActionType,
  AddToResourceGroupActionType,
  RemoveFromResourceGroupActionType,
} from '../../../constants';
import {
  CreateResourceGroupAction,
  DeleteResourceGroupAction,
  ReadResourceGroupAction,
  ReadResourceGroupsAction,
  UpdateResourceGroupAction,
} from '../../../actions';
import { QueryState } from '..';
import { RemoveFromResourceGroupAction } from '../../../actions/api/resourcegroup/removeFromResourceGroup';
import { AddToResourceGroupAction } from '../../../actions/api/resourcegroup/addToResourceGroup';

export interface ResourceGroupState extends QueryState {
  isFetching: boolean;
  resourcegroups: ResourceGroup[];
  error?: string;
  resourcegroup: Map<string, ResourceGroup>;
}

const initState: ResourceGroupState = {
  isFetching: false,
  resourcegroups: [],
  resourcegroup: new Map<string, ResourceGroup>(),
  pageInfo: {
    total: 0,
  },
};

export function resourcegroup(
  state = initState,
  action:
    | CreateResourceGroupAction
    | DeleteResourceGroupAction
    | ReadResourceGroupAction
    | ReadResourceGroupsAction
    | UpdateResourceGroupAction
    | RemoveFromResourceGroupAction
    | AddToResourceGroupAction,
): ResourceGroupState {
  switch (action.type) {
    /** READ */
    case ReadResourceGroupsActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadResourceGroupsActionType.response:
      return {
        ...state,
        isFetching: false,
        resourcegroups: [...action.data],
        pageInfo: action.pageInfo,
      };
    case ReadResourceGroupsActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    case ReadResourceGroupActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadResourceGroupActionType.response:
      return {
        ...state,
        isFetching: false,
        resourcegroup: state.resourcegroup.set(action.data.id, action.data),
      };
    case ReadResourceGroupActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** CREATE */
    case CreateResourceGroupActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case CreateResourceGroupActionType.response:
      return {
        ...state,
        isFetching: false,
        resourcegroups: [action.data, ...state.resourcegroups],
      };
    case CreateResourceGroupActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** DELETE */
    case DeleteResourceGroupActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case DeleteResourceGroupActionType.response:
      state.resourcegroup.delete(action.data);
      return {
        ...state,
        isFetching: false,
        resourcegroups: [...state.resourcegroups.filter(({ id }) => id !== action.data)],
        resourcegroup: state.resourcegroup,
      };
    case DeleteResourceGroupActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** UPDATE */
    case UpdateResourceGroupActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case UpdateResourceGroupActionType.response:
      return {
        ...state,
        isFetching: false,
        resourcegroups: [...state.resourcegroups.map((user) => (user.id === action.data.id ? action.data : user))],
        resourcegroup: state.resourcegroup.set(action.data.id, action.data),
      };
    case UpdateResourceGroupActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Add To */
    case AddToResourceGroupActionType.request:
      return {
        ...state,
        isFetching: true,
      };

    case AddToResourceGroupActionType.response:
      return {
        ...state,
        isFetching: false,
        resourcegroups: [...state.resourcegroups.map((group) => (group.id === action.data.id ? action.data : group))],
        resourcegroup: state.resourcegroup.set(action.data.id, action.data),
      };

    case AddToResourceGroupActionType.error:
      return {
        ...state,
        isFetching: true,
        error: action.error.message,
      };

    /** Remove from */
    case RemoveFromResourceGroupActionType.request:
      return {
        ...state,
        isFetching: true,
      };

    case RemoveFromResourceGroupActionType.response:
      return {
        ...state,
        isFetching: false,
        resourcegroups: [...state.resourcegroups.map((group) => (group.id === action.data.id ? action.data : group))],
        resourcegroup: state.resourcegroup.set(action.data.id, action.data),
      };

    case RemoveFromResourceGroupActionType.error:
      return {
        ...state,
        isFetching: true,
        error: action.error.message,
      };

    default:
      return state;
  }
}
