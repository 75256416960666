export interface Amenity {
  id: string;
  name: string;
  displayName: string;
  description?: string;
  resourceTypeId?: string;
}

export enum ReadAmenitiesActionType {
  request = 'read.Amenities.request',
  response = 'read.Amenities.response',
  error = 'read.Amenities.error',
}

export enum UploadImageActionType {
  request = 'read.meetingroom.upload.image.request',
  response = 'read.meetingroom.upload.image.response',
  error = 'read.meetingroom.upload.image.error',
}

export interface UploadIMG {
  imageURL: string;
  status: boolean;
  message: string;
}

export enum CreateMeetingRoomBookingActionType {
  request = 'create.meetingroom.booking.request',
  response = 'create.meetingroom.booking.response',
  error = 'create.meetingroom.booking.error',
}

export enum DeleteMeetingRoomBookingActionType {
  request = 'delete.meetingroom.booking.request',
  response = 'delete.meetingroom.booking.response',
  error = 'delete.meetingroom.booking.error',
}

export enum CreateMeetingRoomBackgroundActionType {
  request = 'create.meetingroom.background.request',
  response = 'create.meetingroom.background.response',
  error = 'create.meetingroom.background.error',
}

export interface ListContacts {
  id?: {
    type: 'string';
  };
  displayName?: {
    type: 'string';
  };
  givenName?: {
    type: 'string';
  };
  surname?: {
    type: 'string';
  };
  emailAddresses: {
    type: 'string';
  };
  companyName?: {
    type: 'string';
  };
}

export enum ReadListContactsActionType {
  request = 'read.meetingroom.contacts.request',
  response = 'read.meetingroom.contacts.response',
  error = 'read.meetingroom.contacts.error',
}

interface ScheduleItems {
  status: string;
  start: {
    dateTime: string;
    timeZone: string;
  };
  end: {
    dateTime: string;
    timeZone: string;
  };
}
export interface MeetingRoomSchedule {
  scheduleId: {
    type: 'string';
  };
  availabilityView?: {
    type: 'string';
  };
  scheduleItems?: ScheduleItems[];
}

export enum ReadMeetingRoomScheduleActionType {
  request = 'read.meetingroom.schedule.request',
  response = 'read.meetingroom.schedule.response',
  error = 'read.meetingroom.schedule.error',
}

export interface MeetingRoomMS365 {
  name: string;
  address: string;
}

export interface PlacesMS365 {
  displayName: string;
  emailAddress: string;
}

export interface MeetingroomServicesOption {
  id: string;
  name: string;
  displayName: string;
  description?: string;
  companyId?: string;
  hasNotes?: boolean;
  notes?: string;
}

export interface ServicesSelectedParams {
  serviceId: string;
  notes: string;
}

export enum ReadMeetingroomServicesOptionsActionType {
  request = 'read.MeetingroomServicesOptions.request',
  response = 'read.MeetingroomServicesOptions.response',
  error = 'read.MeetingroomServicesOptions.error',
}
