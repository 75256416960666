import {
  CreateBookingActionType,
  ReadBookingActionType,
  Booking,
  ReadBookingsActionType,
  DeleteBookingActionType,
  UpdateBookingActionType,
  ReadBookingsCalendarActionType,
  DeleteMeetingRoomBookingActionType,
  CreateMeetingRoomBookingActionType,
  RoleBookingData,
  ReadRoleBookingsActionType,
  ReadCovid19UserGroupsBookingsActionType,
  Covid19BookingResourceData,
  Covid19BookingUserGroupsData,
  ReadCovid19ResourceBookingsActionType,
  ReadMeetingRoomBookingsWithServicesActionType,
  MeetingRoomBookingsWithServices,
  CreateRandomBookingActionType,
  ReadCurrentLockerBookingActionType,
  CancelLockerBookingActionType,
} from '../../../constants';
import {
  CreateBookingAction,
  DeleteBookingAction,
  ReadBookingAction,
  ReadBookingsAction,
  UpdateBookingAction,
  ReadBookingsCalendarAction,
  DeleteMeetingRoomBookingAction,
  CreateMeetingRoomBookingAction,
  ReadRoleBookingsAction,
  CreateRandomBookingAction,
  ReadCurrentLockerBookingAction,
} from '../../../actions';
import { QueryState } from '..';
import { ReadCovid19ResourceBookingsAction } from '../../../actions/api/bookings/readCovid19ResourceBookings';
import { ReadCovid19UserGroupsBookingsAction } from '../../../actions/api/bookings/readCovid19UserGroupBookings';
import { ReadMeetingRoomBookingsWithServicesAction } from '../../../actions/api/bookings';
import { CancelLockerBookingAction } from '../../../actions/api/booking/cancelLockerBooking';

export interface BookingState extends QueryState {
  isFetching: boolean;
  bookings: Booking[];
  error?: string;
  booking: Map<string, Booking>;
  roleBookingData: RoleBookingData[];
  covid19ResourceData: Covid19BookingResourceData[];
  covid19UserGroupsData: Covid19BookingUserGroupsData[];
  meetingRoomBookingsWithServices: MeetingRoomBookingsWithServices[];
  currentLockerBooking: Booking[];
}

const initState: BookingState = {
  isFetching: false,
  bookings: [],
  booking: new Map<string, Booking>(),
  pageInfo: {
    total: 0,
  },
  roleBookingData: [],
  covid19ResourceData: [],
  covid19UserGroupsData: [],
  meetingRoomBookingsWithServices: [],
  currentLockerBooking: [],
};

export function booking(
  state = initState,
  action:
    | CreateBookingAction
    | DeleteBookingAction
    | ReadBookingAction
    | ReadBookingsAction
    | UpdateBookingAction
    | ReadBookingsCalendarAction
    | DeleteMeetingRoomBookingAction
    | CreateMeetingRoomBookingAction
    | ReadRoleBookingsAction
    | ReadCovid19ResourceBookingsAction
    | ReadCovid19UserGroupsBookingsAction
    | ReadMeetingRoomBookingsWithServicesAction
    | CreateRandomBookingAction
    | ReadCurrentLockerBookingAction
    | CancelLockerBookingAction,
): BookingState {
  switch (action.type) {
    /** READ */
    case ReadBookingsActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadBookingsActionType.response:
      return {
        ...state,
        isFetching: false,
        bookings: action.data ? [...action.data] : state.bookings,
        pageInfo: action.pageInfo || state.pageInfo,
      };
    case ReadBookingsActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Calendar */
    case ReadBookingsCalendarActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadBookingsCalendarActionType.response:
      return {
        ...state,
        isFetching: false,
        bookings: action.data ? [...action.data] : state.bookings,
        pageInfo: action.pageInfo || state.pageInfo,
      };
    case ReadBookingsCalendarActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    case ReadBookingActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadBookingActionType.response:
      return {
        ...state,
        isFetching: false,
        booking: state.booking.set(action.data.id, action.data),
      };
    case ReadBookingActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** CREATE */
    case CreateBookingActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case CreateBookingActionType.response:
      return {
        ...state,
        isFetching: false,
        bookings: [...action.data, ...state.bookings],
      };
    case CreateBookingActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** DELETE */
    case DeleteBookingActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case DeleteBookingActionType.response:
      return {
        ...state,
        isFetching: false,
        bookings: state.bookings.filter(({ id }) => !action.data?.includes(id)),
        booking: state.booking,
      };
    case DeleteBookingActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** UPDATE */
    case UpdateBookingActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case UpdateBookingActionType.response:
      return {
        ...state,
        isFetching: false,
        bookings: [...state.bookings.map((item) => (item.id === action.data.id ? action.data : item))],
        booking: state.booking.set(action.data.id, action.data),
      };
    case UpdateBookingActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** CREATE MEETING ROOM BOOKING*/
    case CreateMeetingRoomBookingActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case CreateMeetingRoomBookingActionType.response:
      return {
        ...state,
        isFetching: false,
        bookings: [...action.data, ...state.bookings],
      };
    case CreateMeetingRoomBookingActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** DELETE MEETING ROOM*/
    case DeleteMeetingRoomBookingActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case DeleteMeetingRoomBookingActionType.response:
      return {
        ...state,
        isFetching: false,
        bookings: state.bookings.filter(({ id }) => !action.data?.includes(id)),
        booking: state.booking,
      };
    case DeleteMeetingRoomBookingActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** READ ROLE BOOKINGS (fire-warden, first-aider) */
    case ReadRoleBookingsActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadRoleBookingsActionType.response:
      return {
        ...state,
        isFetching: false,
        roleBookingData: action.data,
      };
    case ReadRoleBookingsActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** READ COVID 19 RESOURCE BOOKINGS */
    case ReadCovid19ResourceBookingsActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadCovid19ResourceBookingsActionType.response:
      return {
        ...state,
        isFetching: false,
        covid19ResourceData: action.data,
      };
    case ReadCovid19ResourceBookingsActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** READ COVID 19 USER GROUP BOOKINGS */
    case ReadCovid19UserGroupsBookingsActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadCovid19UserGroupsBookingsActionType.response:
      return {
        ...state,
        isFetching: false,
        covid19UserGroupsData: action.data,
      };
    case ReadCovid19UserGroupsBookingsActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Read Meeting Room Bookings With Services*/
    case ReadMeetingRoomBookingsWithServicesActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadMeetingRoomBookingsWithServicesActionType.response:
      return {
        ...state,
        isFetching: false,
        meetingRoomBookingsWithServices: action.data,
      };
    case ReadMeetingRoomBookingsWithServicesActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** CREATE RANDOM DESKS For Anti Claiming Policy*/
    case CreateRandomBookingActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case CreateRandomBookingActionType.response:
      return {
        ...state,
        isFetching: false,
        bookings: [...action.data, ...state.bookings],
      };
    case CreateRandomBookingActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Locker bookings */
    case ReadCurrentLockerBookingActionType.request:
      return {
        ...state,
        isFetching: true,
        currentLockerBooking: [],
      };
    case ReadCurrentLockerBookingActionType.response:
      return {
        ...state,
        isFetching: false,
        currentLockerBooking: [...action.data],
      };
    case ReadCurrentLockerBookingActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    case CancelLockerBookingActionType.response:
      return {
        ...state,
        currentLockerBooking: [
          ...state.currentLockerBooking.filter((lockerBooking) => {
            if (lockerBooking && action.data) {
              if (lockerBooking.locker?.vecosBookingId) {
                if (action.data.includes(lockerBooking.locker.vecosBookingId)) return false;
              }
              if (lockerBooking.locker?.id) {
                if (action.data.includes(lockerBooking.locker.id)) return false;
              }
            }
            return true;
          }),
        ],
      };
    default:
      return state;
  }
}
