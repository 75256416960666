export interface Attendance {
  id: string;
  userId: string;
  buildingId?: string;
  buildingName?: string;
  floorId?: string;
  bookingDetailId?: string;
  checkInTimeUTC?: string;
  checkOutTimeUTC?: string;
  status: string;
  isWfh?: boolean;
  companyId: string;
}

export interface AttendanceReportDay {
  day: Date;
  seconds: number;
}

export interface AttendanceReport {
  companyName: string;
  department: string;
  userId: number;
  name: string;
  totalDays: number;
  dayData: AttendanceReportDay[];
  totalTime: string;
}

// this is now depricated due to the updated way to calculate attendance
export interface UserAttendanceReportDay {
  day: Date;
  lastCheckIn?: Date;
  firstCheckIn?: Date;
  lastCheckOut?: Date;
  seconds: number;
  isWfh?: boolean;
}

export interface UserAttendanceDayData {
  day: Date;
  lastCheckIn?: Date;
  attendanceSeconds?: number;
  attendanceSecondsWFH?: number;
  activeAttendance?: boolean;
  activeAttendanceWFH?: boolean;
  lastAttendanceWFH?: boolean;
}

export interface UserAttendanceReport {
  days: UserAttendanceReportDay[];
  totalSeconds: number;
}

export enum AttendanceStatus {
  AUTO_CHECKED_IN = 'AUTO_CHECKED_IN',
  AUTO_CHECKED_OUT = 'AUTO_CHECKED_OUT',
  CHECKED_IN = 'CHECKED_IN',
  CHECKED_OUT = 'CHECKED_OUT',
}

export enum ReadAttendanceReportActionType {
  request = 'read.attendance.report.request',
  response = 'read.attendance.report.response',
  error = 'read.attendance.report.error',
}

export enum ReadAttendanceReportParamsActionType {
  request = 'read.attendanceReportParams.request',
  response = 'read.attendanceReportParams.response',
  error = 'read.attendanceReportParams.error',
}

export enum ReadUserAttendanceReportActionType {
  request = 'read.user.attendance.report.request',
  response = 'read.user.attendance.report.response',
  error = 'read.user.attendance.report.error',
}

export enum ReadUserAttendanceDayDataActionType {
  request = 'read.user.attendance.day.data.request',
  response = 'read.user.attendance.day.data.response',
  error = 'read.user.attendance.day.data.error',
}

export enum CreateAttendanceWfhActionType {
  request = 'create.attendance.wfh.request',
  response = 'create.attendance.wfh.response',
  error = 'create.attendance.wfh.error',
}

export enum CompleteAttendanceWfhActionType {
  request = 'complete.attendance.wfh.request',
  response = 'complete.attendance.wfh.response',
  error = 'complete.attendance.wfh.error',
}
