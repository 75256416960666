import { format, setDay, setMonth } from 'date-fns';
import { getDayNumber } from '..';
import { IReducerBookingDetailsState } from '../../contexts/BookingDetails';

const sortDays = (days: string[]): string[] => {
  let newDays = [];
  if (days.length > 0) {
    newDays = days
      .map((day) => getDayNumber(day))
      .sort()
      .map((itemDate) => format(setDay(new Date(), itemDate), 'EEEE'));
  }

  return newDays;
};
export const getTextDateSeries = (data: IReducerBookingDetailsState): string => {
  let text = '';
  const { daily, weekly, absoluteMonthly, relativeMonthly, absoluteYearly, relativeYearly } = data.recurringEvents;
  if (daily?.pattern) {
    const { interval } = daily.pattern;
    text = Number(interval) < 2 ? 'every day' : `every ${Number(interval)} days`;
  } else if (weekly?.pattern) {
    const { interval: weeklyInterval, daysOfWeek } = weekly.pattern;
    text = Number(weeklyInterval) < 2 ? 'every week ' : `every ${Number(weeklyInterval)} weeks on `;
    if (daysOfWeek.length > 0) {
      let currentDays: string[] = sortDays(daysOfWeek);
      if (currentDays?.length > 1) {
        const newLastOne = ` and ${currentDays[currentDays.length - 1]}`;
        currentDays.pop();
        text += currentDays.join(', ') + newLastOne;
      } else {
        text += currentDays?.join(',');
      }
    } else {
      text = '';
    }
  } else if (absoluteMonthly?.pattern) {
    const { interval: absoluteMonthlyInterval, dayOfMonth } = absoluteMonthly.pattern;
    text = `day ${dayOfMonth} of `;
    text += Number(absoluteMonthlyInterval) < 2 ? 'every month ' : `every ${Number(absoluteMonthlyInterval)} months `;
  } else if (relativeMonthly?.pattern) {
    const { interval: relativeMonthlyInterval, daysOfWeek, index: indexRelativeMonthly } = relativeMonthly.pattern;
    text = `the ${indexRelativeMonthly} ${daysOfWeek[0]?.toLowerCase()} of `;
    text += Number(relativeMonthlyInterval) < 2 ? 'every month ' : `every ${Number(relativeMonthlyInterval)} months `;
  } else if (absoluteYearly?.pattern) {
    const { dayOfMonth: absoluteYearlyDayOfMonth, interval: absoluteYearlyInterval } = absoluteYearly.pattern;
    const currentMonth = format(setMonth(new Date(), absoluteYearlyInterval - 1), 'MMMM');
    text = `every ${currentMonth} ${absoluteYearlyDayOfMonth}`;
  } else if (relativeYearly?.pattern) {
    const {
      index: indexRelativeYearly,
      interval: relativeYearlyInterval,
      daysOfWeek: relativeDaysOfWeek,
    } = relativeYearly.pattern;
    const currentMonth = format(setMonth(new Date(), relativeYearlyInterval - 1), 'MMMM');
    text = `the ${indexRelativeYearly} ${relativeDaysOfWeek[0]?.toLowerCase()} of `;
    text += `${currentMonth}`;
  } else {
    text = '';
  }

  return text;
};
