import React from 'react';
import { AppBar, Box, Container, IconButton, Menu, MenuItem, Stack, Toolbar } from '@mui/material';
import CompanyLogo from '../CompanyLogo';
import { sxCustom } from './styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Fade from '@mui/material/Fade';
import { useNavigate } from 'react-router-dom';

interface AppBarCustomProps {
  title?: string;
}
const AppBarCustom = ({ title = 'Find A Room' }: AppBarCustomProps): JSX.Element => {
  let navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    setAnchorEl(null);
    navigate('/login', { replace: true });
  };

  return (
    <AppBar position="static" sx={sxCustom.appBar}>
      <Container maxWidth="xl" sx={sxCustom.container}>
        <Toolbar disableGutters>
          <Box sx={{ width: '100%' }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
              <Stack>
                <CompanyLogo width={100} />
                {title && <Box sx={sxCustom.box}>{title}</Box>}
              </Stack>
              <Stack>
                <IconButton aria-label="user" onClick={handleClick}>
                  <AccountCircleIcon style={{ color: 'white' }} />
                </IconButton>

                <Menu
                  id="fade-menu"
                  MenuListProps={{
                    'aria-labelledby': 'fade-button',
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                >
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </Stack>
            </Stack>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default AppBarCustom;
