import { useLocation } from 'react-router-dom';

//TO DO => Complete interface
interface LocationState {
  id: string;
  name: string;
  services?: {
    id: string;
    name: string;
    displayName: string;
  }[];
  capacity?: number;
}
interface UseLocationTyped {
  (): Location & { state: LocationState };
}
export const useLocationTyped: UseLocationTyped = () => {
  const location = useLocation();
  return location as unknown as Location & { state: LocationState };
};
