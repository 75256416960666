import { IRecurringEventsDateSeriesResponse } from '.';
import { IRecurringEventRelativeYearly } from '../../middlewares/nura-client/lib/redux/constants';

export const getRelativeYearlyDateSeries = (
  data: IRecurringEventRelativeYearly,
): IRecurringEventsDateSeriesResponse => {
  return {
    dates: [],
  };
};
