import {
  CreateInvitationActionType,
  Invitation,
  DeleteInvitationActionType,
  UpdateInvitationActionType,
} from '../../../constants';
import { CreateInvitationAction, DeleteInvitationAction, UpdateInvitationAction } from '../../../actions';
import { QueryState } from '..';

export interface InvitationState extends QueryState {
  isFetching: boolean;
  invitations: Invitation[];
  error?: string;
  invitation: Map<string, Invitation>;
}

const initState: InvitationState = {
  isFetching: false,
  invitations: [],
  invitation: new Map<string, Invitation>(),
  pageInfo: {
    total: 0,
  },
};

export function invitation(
  state = initState,
  action: CreateInvitationAction | DeleteInvitationAction | UpdateInvitationAction,
): InvitationState {
  switch (action.type) {
    /** CREATE */
    case CreateInvitationActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case CreateInvitationActionType.response:
      return {
        ...state,
        isFetching: false,
        invitations: [action.data, ...state.invitations],
        invitation: state.invitation.set(action.data.id, action.data),
      };
    case CreateInvitationActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** DELETE */
    case DeleteInvitationActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case DeleteInvitationActionType.response:
      state.invitation.delete(action.data);
      return {
        ...state,
        isFetching: false,
        invitations: [...state.invitations.filter(({ id }) => id !== action.data)],
        invitation: state.invitation,
      };
    case DeleteInvitationActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** UPDATE */
    case UpdateInvitationActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case UpdateInvitationActionType.response:
      return {
        ...state,
        isFetching: false,
        invitations: [...state.invitations.map((item) => (item.id === action.data.id ? action.data : item))],
        invitation: state.invitation.set(action.data.id, action.data),
      };
    case UpdateInvitationActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    default:
      return state;
  }
}
