import { EventEmitter } from 'events';

export default class AuthEventHub extends EventEmitter {
  private static _instance: AuthEventHub;

  public static get instance(): AuthEventHub {
    if (!this._instance) this._instance = new AuthEventHub();
    return this._instance;
  }

  static LOGOUT = 'AUTH/LOGOUT';
  static REFRESH_TOKEN_EXPIRED = 'AUTH/REFRESH_TOKEN_EXPIRED';
  static FORBIDDEN = 'AUTH/FORBIDDEN';

  public isLoggingOut = false;
}
