import React from 'react';
import { Divider, FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';

export interface ISelectCustomOptions {
  name: string;
  value: string;
}
interface ISelectCustomProps {
  fontSize?: number;
  options?: ISelectCustomOptions[];
  options2?: ISelectCustomOptions[];
  value?: string;
  handleChange?: (event: SelectChangeEvent) => void;
  minWidth?: number;
  maxHeight?: number;
  variant?: 'standard' | 'outlined' | 'filled';
  disabled?: boolean;
  handleOnClick?: (value: string) => void;
}

const SelectCustom = ({
  fontSize = 14,
  options = [],
  options2 = [],
  value,
  handleChange,
  minWidth = 120,
  maxHeight = 400,
  variant = 'standard',
  disabled = false,
  handleOnClick,
}: ISelectCustomProps): JSX.Element => {
  return (
    <FormControl variant={variant} sx={{ minWidth }}>
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={value}
        onChange={handleChange}
        sx={{ fontSize: fontSize ? fontSize : null }}
        MenuProps={{ PaperProps: { sx: { maxHeight } } }}
        disabled={disabled}
        onClick={() => (handleOnClick ? handleOnClick(value) : null)}
      >
        {options.map((item, index) => (
          <MenuItem key={`${index}-${item.value}`} value={item.value} sx={{ fontSize: fontSize ? fontSize : null }}>
            {item.name}
          </MenuItem>
        ))}
        {options2?.length > 0 ? <Divider /> : null}
        {options2.map((item, index) => (
          <MenuItem key={`${index}-${item.value}`} value={item.value} sx={{ fontSize: fontSize ? fontSize : null }}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectCustom;
