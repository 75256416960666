import { DeviceDetails } from '../devices';
import { MeetingroomServicesOption } from '../meetingroom';
import { Resource } from '../resource';
import { Role } from '../role';
import { User } from '../user';
import { UserGroup } from '../usergroup';

export enum BookingStatus {
  BOOKING = 'BOOKING',
  CANCELLED = 'CANCELLED',
  REJECTED = 'REJECTED',
  CHECKED_IN = 'CHECKED_IN',
  CHECKED_OUT = 'CHECKED_OUT',
  COMPLETED = 'COMPLETED',
  AUTO_COMPLETED = 'AUTO_COMPLETED',
  AUTO_CANCELLED = 'AUTO_CANCELLED',
  BLOCKED = 'BLOCKED',
  EXPIRED = 'EXPIRED',
}

export enum BookingSource {
  WEB = 'WEB',
  APP = 'APP',
  QR = 'QR',
  OUTLOOK = 'OUTLOOK',
}

export interface MS365Calendar {
  id: string; // Id MS365
  start: Date;
  subject: string;
  end: Date;
  isOnlineMeeting: string;
  originalEndTimeZone: string;
  originalStartTimeZone: string;
  organizer: string;
  timeZone: string;
  transactionId: string;
  createdDateTime: Date;
}

interface Policy {
  type: string;
  value: string;
  unit: string;
}

export interface RoleBookingData {
  resourceId: string;
  firstName: string;
  lastName: string;
  userId: string;
  phone: string;
  mobile: string;
  status: string;
  roles: Role[];
  singleLocation?: boolean;
}

export interface Covid19BookingResourceData {
  user: User;
  resource: Resource;
  floor: {
    id: string;
    name: string;
  };
  bookingStart: Date;
  bookingEnd: Date;
  checkIn?: Date;
  checkOut?: Date;
}
export interface Covid19BookingUserGroupsData {
  user: User;
  resource: Resource;
  floor: {
    id: string;
    name: string;
  };
  bookingStart: Date;
  bookingEnd: Date;
  checkIn?: Date;
  checkOut?: Date;
  userGroups: UserGroup[];
}

export interface Booking {
  id: string;
  startDate: string;
  endDate: string;
  resource: Resource;
  user: User;
  status?: BookingStatus;
  createdAt?: string;
  updatedAt?: string;
  expiredAt?: string;
  checkedInAt?: string;
  checkedOutAt?: string;
  cancelledAt?: string;
  completedAt?: string;
  hasChildren?: boolean;
  parentId?: string;
  comments?: string;
  createdBy?: User;
  ms365Calendar?: MS365Calendar;
  locker?: {
    id: string;
    bookingDetailId: string;
    vecosLockerId: string;
    doorNumber: string;
    sectionName?: string;
    vecosBookingId?: string;
    lockerCluster?: {
      id: string;
      name?: string;
      location?: string;
    };
  };
  isLastAvailableBooking?: boolean;
  devices?: DeviceDetails[];
  policies?: Policy[];
  isAllocationBooking?: boolean;
  allocationDays?: string;
  permanentBooking?: {
    id: string;
    startDate: string;
    endDate: string;
  };
  userGroup?: {
    id: string;
    name: string;
  };
}

export interface MeetingRoomBookingsWithServices {
  id: string;
  startDate: string;
  endDate: string;
  resource: Resource;
  user: User;
  status?: BookingStatus;
  createdAt: string;
  updatedAt: string;
  expiredAt?: string;
  checkedInAt?: string;
  checkedOutAt?: string;
  cancelledAt?: string;
  completedAt?: string;
  hasChildren?: boolean;
  parentId?: string;
  comments?: string;
  createdBy?: User;
  ms365Calendar?: MS365Calendar;
  services: MeetingroomServicesOption[];
}

export interface MeetingRoomBookingsWithServicesForGrid {
  id: string;
  startDate: string;
  endDate: string;
  roomName: string;
  organizer: string;
  status?: BookingStatus;
  services: MeetingroomServicesOption[];
}

export interface EventBigCalendarData {
  id: string;
  title: string;
  roomName: string;
  location: string;
  desc?: string;
  organizer: string;
  allDay: boolean;
  start: Date;
  end: Date;
  services?: MeetingroomServicesOption[];
}

/** Bookings */
export enum ReadBookingsActionType {
  request = 'read.bookings.request',
  response = 'read.bookings.response',
  error = 'read.bookings.error',
}

/** Booking */
export enum ReadBookingActionType {
  request = 'read.booking.request',
  response = 'read.booking.response',
  error = 'read.booking.error',
}

export enum CreateBookingActionType {
  request = 'create.booking.request',
  response = 'create.booking.response',
  error = 'create.booking.error',
}

export enum UpdateBookingActionType {
  request = 'update.booking.request',
  response = 'update.booking.response',
  error = 'update.booking.error',
}

export enum DeleteBookingActionType {
  request = 'delete.booking.request',
  response = 'delete.booking.response',
  error = 'delete.booking.error',
}

/** Calendar */
export enum ReadBookingsCalendarActionType {
  request = 'read.bookings.calendar.request',
  response = 'read.bookings.calendar.response',
  error = 'read.bookings.calendar.error',
}

export enum ReadRoleBookingsActionType {
  request = 'read.role.booking.request',
  response = 'read.role.booking.response',
  error = 'read.role.booking.error',
}

export enum ReadLockerBookingsActionType {
  request = 'read.locker.booking.request',
  response = 'read.locker.booking.response',
  error = 'read.locker.booking.error',
}

export enum ReadCovid19ResourceBookingsActionType {
  request = 'read.covid19.resource.booking.request',
  response = 'read.covid19..resource.booking.response',
  error = 'read.covid19.resource.booking.error',
}
export enum ReadCovid19UserGroupsBookingsActionType {
  request = 'read.covid19.user.group.booking.request',
  response = 'read.covid19.user.group.booking.response',
  error = 'read.covid19.user.group.booking.error',
}

export enum ReadMeetingRoomBookingsWithServicesActionType {
  request = 'read.meeting.room.bookings.with.services.request',
  response = 'read.meeting.room.bookings.with.services.response',
  error = 'read.meeting.room.bookings.with.services.error',
}

export enum CreateRandomBookingActionType {
  request = 'create.random.booking.request',
  response = 'create.random.booking.response',
  error = 'create.random.booking.error',
}

/** Locker bookings */
export enum ReadCurrentLockerBookingActionType {
  request = 'read.bookings.locker.current.request',
  response = 'read.bookings.locker.current.response',
  error = 'read.bookings.locker.current.error',
}

export enum ReadLockerBookingsByUserActionType {
  request = 'read.locker.booking.user.request',
  response = 'read.locker.booking.user.response',
  error = 'read.locker.booking.user.error',
}
