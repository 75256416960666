export interface TraceData {
  colo?: string;
  fl?: string;
  gateway?: string;
  h?: string;
  http?: string;
  ip?: string;
  loc?: string;
  sni?: string;
  tls?: string;
  ts?: string;
  uag?: string;
  visit_scheme?: string;
  warp?: string;
}

export async function getIpDetails(): Promise<TraceData> {
  let traceData: any = {};
  try {
    const data = await fetch('https://1.1.1.1/cdn-cgi/trace').then((res) => res.text());
    const dataArray = data
      .trim()
      .split('\n')
      .map((e) => e.split('='));
    traceData = Object.fromEntries(dataArray);
  } catch {}

  return traceData;
}
