import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';

import { UpdateTeamsMembersActionType, Team } from '../../../constants';
import { RootState } from '../../../reducers';
import { DefaultResponse } from '../../../../../../nura-helpers/lib/services/api';
import { api } from '../../../../../../nura-helpers/lib/services/api';

export interface UpdateTeamsMembersAction extends Action<UpdateTeamsMembersActionType> {
  error?: any;
  data?: Team[];
}

export enum UpdateTeamsMembersTypes {
  ADD = 'ADD',
  DELETE = 'DELETE',
  MAKE_ADMIN = 'MAKE_ADMIN',
  REMOVE_ADMIN = 'REMOVE_ADMIN',
}

export interface ParamsUpdateTeamsMembers {
  id: string;
  user: string;
  scope?: string;
  type: UpdateTeamsMembersTypes;
}

interface UpdateTeamsMembersResponse extends DefaultResponse {
  data: Team[];
}

export function updateTeamsMembers({
  id,
  user,
  type,
  scope,
}: ParamsUpdateTeamsMembers): ThunkAction<Promise<Team[]>, RootState, unknown, UpdateTeamsMembersAction> {
  return async (dispatch) => {
    try {
      dispatch({
        type: UpdateTeamsMembersActionType.request,
      });
      const response = await api('/api/teams/members').put<UpdateTeamsMembersResponse>(id, {
        user,
        type,
        scope,
      });

      if (response.status !== 200) throw response;

      if (!response || !response.data) {
        throw response;
      }

      dispatch({
        type: UpdateTeamsMembersActionType.response,
        data: response.data,
      });

      return response.data;
    } catch (e) {
      dispatch({
        type: UpdateTeamsMembersActionType.error,
        error: e,
      });
      throw e;
    }
  };
}
