export interface IAutoCompleteCustom {
  id: string;
  label: string;
}
export interface IRoomManagement {
  managed: boolean;
  unmanaged: boolean;
}

export enum EMeetingRoomRoomServiceType {
  MANAGED = 'managed',
  UNMANAGED = 'unmanaged',
}

export enum EMeetingRoomService {
  GENERAL = 'general',
  IT = 'IT',
  FACILITIES = 'Facilities',
  CATERING = 'Catering',
}

export enum EMeetingRoomServiceType {
  CLEAN_DOWN = 'clean down',
  SET_UP = 'set up',
  EQUIPMENT = 'equipment',
  NO_TABLES = 'No. tables',
  NO_CHAIRS = 'No. chairs',
}

export enum EServiceInputType {
  COUNTER = 'counter',
  DROPDOWN = 'dropdown',
  CHIP = 'chip',
}

export enum ECateringServiceStatus {
  SUBMITTED = 'submitted',
  PENDING = 'pending',
  DELIVERED = 'delivered',
}

export enum EOfficeAdInSource {
  OUTLOOK = 'OUTLOOK',
}

export interface ITimeItem {
  id?: number | string;
  label?: string;
  value?: number | string;
}
export interface IResourcesItem {
  id: string;
  name?: EMeetingRoomService;
  displayName?: string;
  description?: string;
  value?: string;
}

export interface IEquipment extends IResourcesItem {
  description: string;
}

export interface IITService {
  equipment: IEquipment[];
  notes?: string;
}

export interface IOutlookAddInMeetingRoomDetails {
  notes?: string;
  comments?: string;
  behalfOfSomeoneElse?: {
    name: string;
    email: string;
    phone?: string;
  };
  inPersonAttendees?: number | null;
}

export interface IOutlookAddInMeetingRoomIT {
  notes?: string;
  equipment?: IResourcesItem[];
}

export interface IOutlookAddInMeetingRoomFacilities {
  equipment?: IResourcesItem[];
  noChairs?: number;
  noTables?: number;
  setup?: ITimeItem;
  cleanDown?: ITimeItem;
  noChairsSelected?: ITimeItem;
  noTablesSelected?: ITimeItem;
  notes?: string;
}

export interface ICateringMenuSelected {
  id: string;
  menu?: string;
  package?: string;
  foodItems?: string[];
  quantity?: number;
  deliveredAt?: string;
}

export interface IOutlookAddInMeetingRoomCateringFoodItem extends IResourcesItem {
  cost: number;
}
export interface IOutlookAddInMeetingRoomCateringPackage extends IResourcesItem {
  foodItems: IOutlookAddInMeetingRoomCateringFoodItem[];
}

export interface IOutlookAddInMeetingRoomCateringMenu extends IResourcesItem {
  packages: IOutlookAddInMeetingRoomCateringPackage[];
}

export interface IOutlookAddInMeetingRoomCatering {
  costCentre?: string;
  costCentreId?: number;
  dietaries?: string;
  notes?: string;
  cateringMenus: ICateringMenuSelected[];
}

/**
 * This IBooking  for Resource Availability
 */
export interface IBooking {
  id: string;
  name: string;
  building: {
    id: string;
    name: string;
  };
  floor: {
    id: string;
    name: string;
  };
  start?: string;
  end?: string;
  isManage?: boolean;
  status?: string;
  services?: IResourcesItem[];
  capacity?: number;
}

export interface IBuildingsForResources extends IResourcesItem {
  floors: IResourcesItem[];
}

export interface IResourceForRoomAvailabilities {
  buildings: IBuildingsForResources[];
  services: IResourcesItem[];
}

export interface IServicesTypeItem extends IResourcesItem {
  inputType?: string;
}
export interface IServicesType extends IServicesTypeItem {
  child: IServicesTypeItem[];
}
export interface IOutlookAddInServiceTypesResponse extends IResourcesItem {
  setUp?: string;
  cleanDown?: string;
  emailAddress: string;
  it: IServicesType[];
  facilities: IServicesType[];
  catering: IOutlookAddInMeetingRoomCateringMenu[];
  services: IResourcesItem[];
  listLinkRooms?: ILinkRooms[];
}

export interface IFoodOrder {
  id?: string;
  quantity?: number;
  deliveredAt?: string;
}

export interface IAttendees {
  emailAddress: {
    address: string;
    name: string;
  };
  type: 'required' | 'optional' | 'resource';
}

export interface IRecurringEventsParams {
  pattern?: {
    type?:
      | ERecurrencePatternType.DAILY
      | ERecurrencePatternType.WEEKLY
      | ERecurrencePatternType.ABSOLUTE_MONTHLY
      | ERecurrencePatternType.RELATIVE_MONTHLY
      | ERecurrencePatternType.ABSOLUTE_YEARLY
      | ERecurrencePatternType.RELATIVE_YEARLY;
    interval?: number;
    daysOfWeek?: EDayOfWeek[];
    dayOfMonth?: number;
    index?: EWeekIndex;
    month?: number;
  };
  range: IRecurrenceRange;
}
export interface ICreateBooking {
  accessToken?: string;
  attendees?: IAttendees[];
  body?: string;
  roomId?: string;
  address?: string;
  building?: string;
  floor?: string;
  start?: string;
  currentDate?: string;
  end?: string;
  general?: IOutlookAddInMeetingRoomDetails;
  it?: IOutlookAddInMeetingRoomIT;
  facilities?: IOutlookAddInMeetingRoomFacilities;
  catering?: {
    costCentre?: string;
    costCentreId?: number;
    dietaries?: string;
    notes?: string;
    foods?: IFoodOrder[];
  };
  organizer?: string;
  linkRoom?: string;
  recurringEvents?: IRecurringEventsParams;
}

/**
 * This IBookingResponse for Create a booking response
 */
export interface IBookingResponse {
  bookingId: string;
  bookingDetailId: string;
  eventId?: string;
  userId?: string;
}

export interface IMeetingRoomStatusResponse {
  isFree: boolean;
}

export interface IMeetingRoomStatusResponse {
  isFree: boolean;
}

export interface IMeetingRoomOrganizersForResponse {
  id?: string;
  label?: string;
  displayName?: string;
  mail?: string;
  mobilePhone?: string;
}

export interface IServicesComment {
  id: string;
  user: {
    id: string;
    name: string;
    email?: string;
  };
  comment: string;
  createdDate: string;
}

export enum IPageTypeOfService {
  DASHBOARD = 'dashboard',
  BOOKINGS = 'bookings',
  CATERING = 'catering',
  EQUIPMENT = 'equipment',
}

export const defaultRoomTypes: IAutoCompleteCustom[] = [
  { id: EMeetingRoomRoomServiceType.MANAGED, label: 'Managed' },
  { id: EMeetingRoomRoomServiceType.UNMANAGED, label: 'Un-Managed' },
];

export interface IDeleteEventFromOutlookParams {
  id: string;
  scope: string;
  accessToken: string;
  organizer: string;
}

export interface ILinkRooms {
  id: string;
  name: string;
  label?: string;
  capacity?: string;
}

export enum EEndOfBookingRecurrence {
  ON_THIS_DAY = 'on this day',
  AFTER = 'after',
}
export enum EOnOfMonthlyBookingRecurrence {
  DAY = 'day',
  ORDINAL = 'ordinal',
}

export enum EOnDayBookingRecurrence {
  DAY = 'Day',
  WEEKDAY = 'Weekday',
  WEEKEND_DAY = 'Weekend day',
}

/**
 * Schedule repeating appointments as recurring events in Outlook
 * link : https://learn.microsoft.com/en-us/graph/api/resources/enums?view=graph-rest-1.0
 **/

export enum EDayOfWeek {
  SUNDAY = 'sunday',
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
}

export enum EWeekIndex {
  FIRST = 'first',
  SECOND = 'second',
  THIRD = 'third',
  FOURTH = 'fourth',
  LAST = 'last',
}

export type EDayOfMonth = EDayOfWeek | EOnDayBookingRecurrence;

export enum ERecurrencePatternType {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  ABSOLUTE_MONTHLY = 'absoluteMonthly',
  RELATIVE_MONTHLY = 'relativeMonthly',
  ABSOLUTE_YEARLY = 'absoluteYearly',
  RELATIVE_YEARLY = 'relativeYearly',
}
export enum EOptionRecurrencePatternType {
  DOES_NOT_REPEAT = 'Does not repeat',
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
  CUSTOM = 'custom',
}

export type EUIRecurrencePatternType = EOptionRecurrencePatternType | ERecurrencePatternType;

/** NS do'nt use NO_END  */
export enum ERecurrenceRangeType {
  END_DATE = 'endDate',
  NUMBERED = 'numbered',
}

export interface IRecurrencePattern {
  type:
    | ERecurrencePatternType.DAILY
    | ERecurrencePatternType.WEEKLY
    | ERecurrencePatternType.ABSOLUTE_MONTHLY
    | ERecurrencePatternType.RELATIVE_MONTHLY
    | ERecurrencePatternType.ABSOLUTE_YEARLY
    | ERecurrencePatternType.RELATIVE_YEARLY;
  interval: number;
}
export interface IRecurrenceRange {
  type: ERecurrenceRangeType.END_DATE | ERecurrenceRangeType.NUMBERED;
  startDate: string;
  endDate: string;
  numberOfOccurrences: number | string;
}
export interface IRecurringEventDaily {
  pattern: IRecurrencePattern;
}

interface IRecurringEventWeeklyPattern extends IRecurrencePattern {
  daysOfWeek: EDayOfWeek[];
  dateSelectedOfWeek?: Date[];
}
export interface IRecurringEventWeekly {
  pattern: IRecurringEventWeeklyPattern;
}
interface IRecurringEventAbsoluteMonthlyPattern extends IRecurrencePattern {
  type: ERecurrencePatternType.ABSOLUTE_MONTHLY;
  dayOfMonth: number;
}
export interface IRecurringEventAbsoluteMonthly {
  pattern: IRecurringEventAbsoluteMonthlyPattern;
}
interface IRecurringEventRelativeMonthlyPattern extends IRecurrencePattern {
  type: ERecurrencePatternType.RELATIVE_MONTHLY;
  daysOfWeek: EDayOfMonth[];
  index?: EWeekIndex;
}
export interface IRecurringEventRelativeMonthly {
  pattern: IRecurringEventRelativeMonthlyPattern;
}

interface IRecurringEventAbsoluteYearlyPattern extends IRecurrencePattern {
  type: ERecurrencePatternType.ABSOLUTE_YEARLY;
  dayOfMonth: number;
  month: number;
}
export interface IRecurringEventAbsoluteYearly {
  pattern: IRecurringEventAbsoluteYearlyPattern;
}

interface IRecurringEventRelativeYearlyPattern extends IRecurrencePattern {
  type: ERecurrencePatternType.RELATIVE_YEARLY;
  daysOfWeek: EDayOfMonth[];
  index?: EWeekIndex;
  month: number;
}
export interface IRecurringEventRelativeYearly {
  pattern: IRecurringEventRelativeYearlyPattern;
}
/** end of recurring events */

interface IBookingRecurrenceDefault {
  start?: Date;
  localTz?: string;
  buildingTz?: string;
  repeat?: EUIRecurrencePatternType;
  every?: string;
}
interface IEndOfRecurrence {
  name: 'on this day' | 'after' | ' no end date';
  value: string | Date;
}
export interface IBookingRecurrence extends IBookingRecurrenceDefault {
  daily?: {
    end: IEndOfRecurrence;
  };
  weekly?: {
    end: IEndOfRecurrence;
    on: string;
  };
  monthly?: {
    end: IEndOfRecurrence;
    on: {
      value: string;
      unit: string;
    };
  };
  yearly?: {
    end: IEndOfRecurrence;
    on: {
      value: string;
      unit: string;
    };
  };
}

export enum ReadMeetingRoomAvailabilitiesWithServicesActionType {
  request = 'read.meetingRoom.availabilities.withServices.request',
  response = 'read.meetingRoom.availabilities.withServices.response',
  error = 'read.meetingRoom.availabilities.withServices.error',
}

export enum ReadResourcesForRoomAvailabilitiesActionType {
  request = 'read.resources.for.room.availabilities.request',
  response = 'read.resources.for.room.availabilities.response',
  error = 'read.resources.for.room.availabilities.error',
}

export enum ReadResourceForRoomBookingActionType {
  request = 'read.resource.for.room.booking.request',
  response = 'read.resource.for.room.booking.response',
  error = 'read.resource.for.room.booking.error',
}

export enum CreateMeetingRoomBookingFromOutlookActionType {
  request = 'create.booking.from.outlook.request',
  response = 'create.booking.from.outlook.response',
  error = 'create.booking.from.outlook.error',
}

export enum ReadMeetingRoomStatusActionType {
  request = 'read.meetingRoom.status.request',
  response = 'read.meetingRoom.status.response',
  error = 'read.meetingRoom.status.error',
}

export enum ReadMeetingRoomOrganizersForOutlookActionType {
  request = 'read.meetingRoom.organizers.request',
  response = 'read.meetingRoom.organizers.response',
  error = 'read.meetingRoom.organizers.error',
}

export enum DeleteMeetingRoomBookingFromOutlookActionType {
  request = 'delete.booking.from.outlook.request',
  response = 'delete.booking.from.outlook.response',
  error = 'delete.booking.from.outlook.error',
}
