import { ReadSasUrlBlobStorageActionType, ReadFileBlobStorageActionType } from '../../../constants';
import { ReadSasUrlBlobStorageAction, ReadFileBlobStorageAction } from '../../../actions';

export interface BlobStorageState {
  isFetching: boolean;
  sasToken: string;
  file: string;
  error?: string;
}

const initState: BlobStorageState = {
  isFetching: false,
  sasToken: '',
  file: '',
};

export function blobStorage(
  state = initState,
  action: ReadSasUrlBlobStorageAction | ReadFileBlobStorageAction,
): BlobStorageState {
  switch (action.type) {
    /** READ SAS */
    case ReadSasUrlBlobStorageActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadSasUrlBlobStorageActionType.response:
      return {
        ...state,
        isFetching: false,
        sasToken: action.data?.sasToken,
      };
    case ReadSasUrlBlobStorageActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** READ File */
    case ReadFileBlobStorageActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadFileBlobStorageActionType.response:
      return {
        ...state,
        isFetching: false,
        file: action.data?.file,
      };
    case ReadFileBlobStorageActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    default:
      return state;
  }
}
