import { AccountAdmin, ReadAccountAdminActionType, UpdateAccountActionType } from '../../../constants';
import { ReadAccountAdminAction, UpdateAccountAction } from '../../../actions';
import { QueryState } from '..';

export interface AccountAdminState extends QueryState {
  isFetching: boolean;
  error?: string;
  accountAdmin: AccountAdmin;
}

const initState: AccountAdminState = {
  isFetching: false,
  accountAdmin: null,
  pageInfo: {
    total: 0,
  },
};

export function accountAdmin(
  state = initState,
  action: ReadAccountAdminAction | UpdateAccountAction,
): AccountAdminState {
  switch (action.type) {
    /** READ */
    case ReadAccountAdminActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadAccountAdminActionType.response:
      return {
        ...state,
        isFetching: false,
        accountAdmin: action.data,
      };
    case ReadAccountAdminActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    /** Update */
    case UpdateAccountActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case UpdateAccountActionType.response:
      return {
        ...state,
        isFetching: false,
        accountAdmin: action.data,
      };
    case UpdateAccountActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    default:
      return state;
  }
}
