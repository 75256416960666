import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { api, DefaultResponse } from '../../../../../../../nura-helpers/lib/services/api';
import { RootState } from '../../../../reducers';
import { getCompanyId } from '../../../../../../../nura-helpers/lib/helpers/auth';
import { IOutlookAddInServiceTypesResponse, ReadResourceForRoomBookingActionType } from '../../../../constants';

export interface ReadResourceForRoomBookingAction extends Action<ReadResourceForRoomBookingActionType> {
  error?: any;
  data?: IOutlookAddInServiceTypesResponse;
}

export interface ReadResourceForRoomBookingResult {
  data: IOutlookAddInServiceTypesResponse;
}

type ReadResourceForRoomBookingResponse = DefaultResponse & ReadResourceForRoomBookingResult;
export interface IReadResourceParams {
  id: string;
}

export async function readResourceForRoomBookingsAsync({
  id,
}: IReadResourceParams): Promise<ReadResourceForRoomBookingResult> {
  const scope = getCompanyId();

  const response = await api(`/api/outlook/${scope}/resource/${id}`, {
    outlookId: true,
  }).get<ReadResourceForRoomBookingResponse>();

  if (response.status !== 200) throw response;

  if (!response || !response.data) {
    throw response;
  }
  return {
    data: response.data,
  };
}

export function readResourceForRoomBooking(
  params: IReadResourceParams,
): ThunkAction<Promise<ReadResourceForRoomBookingResult>, RootState, unknown, ReadResourceForRoomBookingAction> {
  return async (dispatch) => {
    try {
      dispatch({
        type: ReadResourceForRoomBookingActionType.request,
      });

      const response = await readResourceForRoomBookingsAsync(params);

      dispatch({
        type: ReadResourceForRoomBookingActionType.response,
        data: response.data,
      });
      return {
        data: response.data,
      };
    } catch (e) {
      dispatch({
        type: ReadResourceForRoomBookingActionType.error,
        error: e,
      });
      throw e;
    }
  };
}
