import {
  CreateAccessGroupActionType,
  ReadAccessGroupActionType,
  AccessGroup,
  ReadAccessGroupsActionType,
  DeleteAccessGroupActionType,
  UpdateAccessGroupActionType,
} from '../../../constants';
import {
  CreateAccessGroupAction,
  DeleteAccessGroupAction,
  ReadAccessGroupAction,
  ReadAccessGroupsAction,
  UpdateAccessGroupAction,
} from '../../../actions';
import { QueryState } from '..';

export interface AccessGroupState extends QueryState {
  isFetching: boolean;
  accessgroups: AccessGroup[];
  error?: string;
  accessgroup: Map<string, AccessGroup>;
}

const initState: AccessGroupState = {
  isFetching: false,
  accessgroups: [],
  accessgroup: new Map<string, AccessGroup>(),
  pageInfo: {
    total: 0,
  },
};

export function accessgroup(
  state = initState,
  action:
    | CreateAccessGroupAction
    | DeleteAccessGroupAction
    | ReadAccessGroupAction
    | ReadAccessGroupsAction
    | UpdateAccessGroupAction,
): AccessGroupState {
  switch (action.type) {
    /** READ */
    case ReadAccessGroupsActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadAccessGroupsActionType.response:
      return {
        ...state,
        isFetching: false,
        accessgroups: [...action.data],
        pageInfo: action.pageInfo,
      };
    case ReadAccessGroupsActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    case ReadAccessGroupActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadAccessGroupActionType.response:
      return {
        ...state,
        isFetching: false,
        accessgroup: state.accessgroup.set(action.data.id, action.data),
      };
    case ReadAccessGroupActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** CREATE */
    case CreateAccessGroupActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case CreateAccessGroupActionType.response:
      return {
        ...state,
        isFetching: false,
        accessgroups: [...state.accessgroups, action.data],
      };
    case CreateAccessGroupActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** DELETE */
    case DeleteAccessGroupActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case DeleteAccessGroupActionType.response:
      state.accessgroup.delete(action.data);
      return {
        ...state,
        isFetching: false,
        accessgroups: [...state.accessgroups.filter(({ id }) => id !== action.data)],
        accessgroup: state.accessgroup,
      };
    case DeleteAccessGroupActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** UPDATE */
    case UpdateAccessGroupActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case UpdateAccessGroupActionType.response:
      return {
        ...state,
        isFetching: false,
        accessgroups: [...state.accessgroups.map((user) => (user.id === action.data.id ? action.data : user))],
        accessgroup: state.accessgroup.set(action.data.id, action.data),
      };
    case UpdateAccessGroupActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    default:
      return state;
  }
}
