import {
  ResourceAnalyticsProps,
  ReadResourceAnalyticsActionType,
  BookingsReportProps,
  ReadBookingsReportActionType,
  ActiveUsersReportProps,
  ReadActiveUsersReportActionType,
  ReadGroupsActionType,
  ReportGroups,
  ResourceAnalyticsTotalDesksActionType,
  ReadResourceAnalyticsDeskUtilisation,
  ReadResourceAnalyticsDeskUtilisationActionType,
  ResourceUsage,
  ReadResourceAnalyticsResourceUsageType,
  TotalBookingsReport,
  ReadTotalBookingsReportType,
  CheckInsVSAutoCancellationsByDay,
  ReadCheckInsVSAutoCancellationsByDayType,
  CheckInsVSAutoCancellationsByGroup,
  ReadCheckInsVSAutoCancellationsByGroupType,
  ReadActivityUsersReport,
  ReadActivityUsersReportActionType,
  UserActivityTable,
  ReadActivityUsersByUserReportType,
  GroupStats,
  GroupStatsActionType,
  ResourceStatsProps,
  ReadResourceStatsActionType,
  ReadReportBookingDetailsType,
  BookingDetails,
  DeskHistory,
  ReadReportDeskHistoryType,
} from '../../../constants';
import {
  ReadResourceAnalyticsAction,
  ReadBookingsReportAction,
  ReadActiveUsersReportAction,
  ReadGroupsAction,
  ReadResourceAnalyticsTotalDesksAction,
  ReadResourceAnalyticsDeskUtilisationAction,
  ReadResourceAnalyticsResourceUsageAction,
  ReadTotalBookingsReportAction,
  ReadCheckInsVSAutoCancellationsByDayAction,
  ReadCheckInsVSAutoCancellationsByGroupAction,
  ReadActivityUsersReportAction,
  ReadActivityUsersByUserAction,
  ReadGroupStatsAction,
  ReadResourceStatsAction,
} from '../../../actions';
import { QueryState } from '..';
import { ReadReportBookingDetailsAction } from '../../../actions/api/report/readBookingDetailsReport';
import { ReadReportDeskHistoryAction } from '../../../actions/api/report/readDeskHistory';

export interface ResourceAnalyticState {
  isFetching: boolean;
  resourceAnalytics: ResourceAnalyticsProps;
  error?: string;
}

const initState: ResourceAnalyticState = {
  isFetching: false,
  resourceAnalytics: {
    deskUtilisation: [],
    resourceUsage: [],
    groupStats: [],
    totalPeakUsage: 0,
    avgBookings: 0,
    totalDesks: 0,
  },
};

export function reportResourceAnalytics(state = initState, action: ReadResourceAnalyticsAction): ResourceAnalyticState {
  switch (action.type) {
    /** READ */
    case ReadResourceAnalyticsActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadResourceAnalyticsActionType.response:
      return {
        ...state,
        isFetching: false,
        resourceAnalytics: action.data,
      };
    case ReadResourceAnalyticsActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    default:
      return state;
  }
}

export interface BookingsReportState {
  isFetching: boolean;
  bookingReports: BookingsReportProps;
  error?: string;
}

const initBookingState: BookingsReportState = {
  isFetching: false,
  bookingReports: {
    avgCheckIns: 0,
    totalAutoCancelled: 0,
    totalBookings: 0,
    totalCheckIns: 0,
    checkInsVSAutoCancellationsByDays: [],
    checkInsVSAutoCancellationsByGroups: [],
  },
};

export function bookingReport(state = initBookingState, action: ReadBookingsReportAction): BookingsReportState {
  switch (action.type) {
    /** READ */
    case ReadBookingsReportActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadBookingsReportActionType.response:
      return {
        ...state,
        isFetching: false,
        bookingReports: action.data,
      };
    case ReadBookingsReportActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    default:
      return state;
  }
}

export interface ActiveUsersReportState {
  isFetching: boolean;
  activeUsers: ActiveUsersReportProps;
  error?: string;
}

const initActiveUsersState: ActiveUsersReportState = {
  isFetching: false,
  activeUsers: {
    totalUsers: 0,
    totalActiveUsers: 0,
    userActivityChart: [],
    userActivityTable: [],
  },
};

export function activeUsersReport(
  state = initActiveUsersState,
  action: ReadActiveUsersReportAction,
): ActiveUsersReportState {
  switch (action.type) {
    /** READ */
    case ReadActiveUsersReportActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadActiveUsersReportActionType.response:
      return {
        ...state,
        isFetching: false,
        activeUsers: action.data,
      };
    case ReadActiveUsersReportActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    default:
      return state;
  }
}

export interface ReportGroupsState extends QueryState {
  isFetching: boolean;
  reportGroups: ReportGroups;
  error?: string;
}

const initGroupsState: ReportGroupsState = {
  isFetching: false,
  reportGroups: { userGroups: [], resourceGroups: [] },
  pageInfo: {
    total: 0,
  },
};

export function reportGroup(state = initGroupsState, action: ReadGroupsAction): ReportGroupsState {
  switch (action.type) {
    /** READ */
    case ReadGroupsActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadGroupsActionType.response:
      return {
        ...state,
        isFetching: false,
        reportGroups: { ...action.data },
      };
    case ReadGroupsActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    default:
      return state;
  }
}
/** Seperate Resource Analytics APIs */
export interface ResourceAnalyticsState {
  isFetching?: boolean;
  totalDesks: number;
  deskUtilisations: ReadResourceAnalyticsDeskUtilisation;
  resourceUsage?: ResourceUsage[];
  groupStats?: GroupStats[];
  error?: string;
}

const intiResourceAnalytics: ResourceAnalyticsState = {
  isFetching: false,
  totalDesks: 0,
  deskUtilisations: {
    deskUtilisation: [],
    totalPeakUsage: 0,
    avgBookings: 0,
  },
  resourceUsage: [],
  groupStats: [],
};

export function resourceAnalytics(
  state = intiResourceAnalytics,
  action:
    | ReadResourceAnalyticsTotalDesksAction
    | ReadResourceAnalyticsDeskUtilisationAction
    | ReadResourceAnalyticsResourceUsageAction
    | ReadGroupStatsAction,
): ResourceAnalyticsState {
  switch (action.type) {
    /** Read Total Desks */
    case ResourceAnalyticsTotalDesksActionType.request:
      return {
        ...state,
        isFetching: true,
      };

    case ResourceAnalyticsTotalDesksActionType.response:
      return {
        ...state,
        isFetching: false,
        totalDesks: action.data?.totalDesks,
      };
    case ResourceAnalyticsTotalDesksActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    /** Read Resource Analytics Desk Utilisation */
    case ReadResourceAnalyticsDeskUtilisationActionType.request:
      return {
        ...state,
        isFetching: true,
      };

    case ReadResourceAnalyticsDeskUtilisationActionType.response:
      return {
        ...state,
        isFetching: false,
        deskUtilisations: action.data,
      };
    case ReadResourceAnalyticsDeskUtilisationActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    /** Read Resource Analytics Resource Usage*/
    case ReadResourceAnalyticsResourceUsageType.request:
      return {
        ...state,
        isFetching: true,
      };

    case ReadResourceAnalyticsResourceUsageType.response:
      return {
        ...state,
        isFetching: false,
        resourceUsage: action.data,
      };
    case ReadResourceAnalyticsResourceUsageType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    case GroupStatsActionType.request:
      return {
        ...state,
        isFetching: true,
      };

    case GroupStatsActionType.response:
      return {
        ...state,
        isFetching: false,
        groupStats: action.data,
      };
    case GroupStatsActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    default:
      return state;
  }
}

/**  Seperate Bookings APIs */

export interface BookingReportsState {
  isFetching?: boolean;
  total: TotalBookingsReport;
  checkInsVSAutoCancellationsByDays: CheckInsVSAutoCancellationsByDay[];
  checkInsVSAutoCancellationsByGroups: CheckInsVSAutoCancellationsByGroup[];
  error?: string;
}

const intBookingReportsState: BookingReportsState = {
  isFetching: false,
  total: {
    avgCheckIns: 0,
    totalAutoCancelled: 0,
    totalBookings: 0,
    totalCheckIns: 0,
  },
  checkInsVSAutoCancellationsByDays: [],
  checkInsVSAutoCancellationsByGroups: [],
};

export function bookingReports(
  state = intBookingReportsState,
  action:
    | ReadTotalBookingsReportAction
    | ReadCheckInsVSAutoCancellationsByDayAction
    | ReadCheckInsVSAutoCancellationsByGroupAction,
): BookingReportsState {
  switch (action.type) {
    /** Read Total  */
    case ReadTotalBookingsReportType.request:
      return {
        ...state,
        isFetching: true,
      };

    case ReadTotalBookingsReportType.response:
      return {
        ...state,
        isFetching: false,
        total: action.data,
      };
    case ReadTotalBookingsReportType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    /** Read CheckIns VS Auto Cancellations By Day */
    case ReadCheckInsVSAutoCancellationsByDayType.request:
      return {
        ...state,
        isFetching: true,
      };

    case ReadCheckInsVSAutoCancellationsByDayType.response:
      return {
        ...state,
        isFetching: false,
        checkInsVSAutoCancellationsByDays: action.data,
      };
    case ReadCheckInsVSAutoCancellationsByDayType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Read CheckIns VS Auto Cancellations By Group */
    case ReadCheckInsVSAutoCancellationsByGroupType.request:
      return {
        ...state,
        isFetching: true,
      };

    case ReadCheckInsVSAutoCancellationsByGroupType.response:
      return {
        ...state,
        isFetching: false,
        checkInsVSAutoCancellationsByGroups: action.data,
      };
    case ReadCheckInsVSAutoCancellationsByGroupType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    default:
      return state;
  }
}

/**  Seperate Users APIs */

export interface UserReportsState {
  isFetching?: boolean;
  activeUsers: ReadActivityUsersReport;
  userActivityTable: UserActivityTable[];
  error?: string;
}

const initUserReportsState: UserReportsState = {
  isFetching: false,
  activeUsers: {
    totalUsers: 0,
    totalActiveUsers: 0,
    userActivityChart: [],
  },
  userActivityTable: [],
};

export function userReports(
  state = initUserReportsState,
  action: ReadActivityUsersReportAction | ReadActivityUsersByUserAction,
): UserReportsState {
  switch (action.type) {
    /** Read Total & Activity  */
    case ReadActivityUsersReportActionType.request:
      return {
        ...state,
        isFetching: true,
      };

    case ReadActivityUsersReportActionType.response:
      return {
        ...state,
        isFetching: false,
        activeUsers: action.data,
      };
    case ReadActivityUsersReportActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    /** Read User Activity for Table */
    case ReadActivityUsersByUserReportType.request:
      return {
        ...state,
        isFetching: true,
      };

    case ReadActivityUsersByUserReportType.response:
      return {
        ...state,
        isFetching: false,
        userActivityTable: action.data,
      };
    case ReadActivityUsersByUserReportType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    default:
      return state;
  }
}

export interface ResourceStatsState {
  isFetching: boolean;
  resourceStats: ResourceStatsProps[];
  error?: string;
}

const initStatsState: ResourceStatsState = {
  isFetching: false,
  resourceStats: [],
};

export function reportResourceStats(state = initStatsState, action: ReadResourceStatsAction): ResourceStatsState {
  switch (action.type) {
    /** READ */
    case ReadResourceStatsActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadResourceStatsActionType.response:
      return {
        ...state,
        isFetching: false,
        resourceStats: action.data,
      };
    case ReadResourceStatsActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    default:
      return state;
  }
}

export interface DeskHistoryState {
  isFetching: boolean;
  deskHistory: DeskHistory[];
  error?: string;
}

const initDeskHistoryState: DeskHistoryState = {
  isFetching: false,
  deskHistory: [],
};

export function deskHistoryReports(
  state = initDeskHistoryState,
  action: ReadReportDeskHistoryAction,
): DeskHistoryState {
  switch (action.type) {
    /** READ */
    case ReadReportDeskHistoryType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadReportDeskHistoryType.response:
      return {
        ...state,
        isFetching: false,
        deskHistory: action.data,
      };
    case ReadReportDeskHistoryType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    default:
      return state;
  }
}
export interface BookingDetailsState {
  isFetching: boolean;
  bookingDetails: BookingDetails[];
  error?: string;
}

const initBookingDetailsState: BookingDetailsState = {
  isFetching: false,
  bookingDetails: [],
};

export function bookingDetailsReports(
  state = initBookingDetailsState,
  action: ReadReportBookingDetailsAction,
): BookingDetailsState {
  switch (action.type) {
    /** READ */
    case ReadReportBookingDetailsType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadReportBookingDetailsType.response:
      return {
        ...state,
        isFetching: false,
        bookingDetails: action.data,
      };
    case ReadReportBookingDetailsType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    default:
      return state;
  }
}
