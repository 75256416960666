import { Grid, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { addMonths, eachMonthOfInterval, endOfYear, format, startOfYear } from 'date-fns';
import React, { useMemo } from 'react';
import { REACT_APP_RECURRING_MAX_INTERVAL_MONTHS, monthlyArr, yearlyArr } from '../../../constants/AddIn';
import { useBookingDetails } from '../../../contexts/BookingDetails';
import {
  ERecurrencePatternType,
  EOptionRecurrencePatternType,
} from '../../../middlewares/nura-client/lib/redux/constants';
import SelectCustom, { ISelectCustomOptions } from '../../SelectCustom';

interface IEveryBookingProps {
  value: string;
}
const defaultOptions: ISelectCustomOptions[] = Array.from(Array(99), (_, index) => ({
  name: `${index + 1}`,
  value: `${index + 1}`,
}));

const EveryBooking = ({ value }: IEveryBookingProps): JSX.Element => {
  const { state: bookingState, dispatch: bookingDispatch } = useBookingDetails();

  const handleChangeValue = (event: SelectChangeEvent) => {
    const newState = bookingState.recurringEvents;
    if (value === ERecurrencePatternType.DAILY) newState.daily.pattern.interval = Number(event.target.value);
    if (value === ERecurrencePatternType.WEEKLY) newState.weekly.pattern.interval = Number(event.target.value);

    if (newState?.absoluteMonthly?.pattern?.interval) {
      newState.absoluteMonthly.pattern.interval = Number(event.target.value);
    }

    if (newState?.relativeMonthly?.pattern?.interval) {
      newState.relativeMonthly.pattern.interval = Number(event.target.value);
    }

    if (newState?.absoluteYearly?.pattern?.interval) {
      newState.absoluteYearly.pattern.interval = Number(event.target.value);
      if (newState?.range?.startDate) {
        const currentMonth = event.target.value ? Number(event.target.value) - 1 : 0;
        const newMonth = new Date(newState.range.startDate);
        newMonth.setMonth(currentMonth);
        newState.range.startDate = newMonth.toDateString();
        newState.range.endDate = addMonths(newMonth, REACT_APP_RECURRING_MAX_INTERVAL_MONTHS).toDateString();
      }
    }

    if (newState?.relativeYearly?.pattern?.interval) {
      newState.relativeYearly.pattern.interval = Number(event.target.value);
      if (newState?.range?.startDate) {
        const newMonth = new Date(newState.range.startDate);
        const currentMonth = event.target.value ? Number(event.target.value) - 1 : 0;
        newMonth.setMonth(currentMonth);
        newState.range.startDate = newMonth.toDateString();
        newState.range.endDate = addMonths(newMonth, REACT_APP_RECURRING_MAX_INTERVAL_MONTHS).toDateString();
      }
    }

    bookingDispatch({
      type: 'setRecurringEvents',
      payload: { recurringEvents: { ...bookingState.recurringEvents, ...newState } },
    });
  };

  const renderEveryContent = useMemo(() => {
    const { daily, weekly, absoluteYearly, relativeYearly, absoluteMonthly, relativeMonthly } =
      bookingState.recurringEvents;

    let currentValue = value;
    if (monthlyArr.includes(value)) currentValue = EOptionRecurrencePatternType.MONTHLY;
    if (yearlyArr.includes(value)) currentValue = EOptionRecurrencePatternType.YEARLY;

    switch (currentValue) {
      case ERecurrencePatternType.WEEKLY:
        const currentWeeklyValue = weekly?.pattern?.interval?.toString();
        return (
          <Stack direction="row" spacing={2}>
            <SelectCustom
              options={defaultOptions}
              handleChange={handleChangeValue}
              minWidth={50}
              value={currentWeeklyValue || '1'}
            />
            <span>week(s)</span>
          </Stack>
        );
      case EOptionRecurrencePatternType.MONTHLY:
        let currentMonthlyValue =
          relativeMonthly?.pattern?.interval?.toString() || absoluteMonthly?.pattern?.interval?.toString();
        return (
          <Stack direction="row" spacing={2}>
            <SelectCustom
              options={defaultOptions}
              handleChange={handleChangeValue}
              minWidth={50}
              value={currentMonthlyValue}
            />
            <span>month(s)</span>
          </Stack>
        );
      case EOptionRecurrencePatternType.YEARLY:
        const eachMonthOfThisYears = eachMonthOfInterval({
          start: startOfYear(new Date()),
          end: endOfYear(new Date()),
        })?.map((itemDate) => ({
          name: format(itemDate, 'MMMM'),
          value: format(itemDate, 'M'),
        }));

        let currentYearlyValue =
          absoluteYearly?.pattern?.interval?.toString() || relativeYearly?.pattern?.interval?.toString();

        return (
          <Stack direction="row" spacing={2}>
            <SelectCustom
              options={eachMonthOfThisYears}
              handleChange={handleChangeValue}
              minWidth={50}
              value={currentYearlyValue}
            />
          </Stack>
        );
      default:
        return (
          <Stack direction="row" spacing={2}>
            <SelectCustom
              options={defaultOptions}
              handleChange={handleChangeValue}
              minWidth={50}
              value={daily?.pattern?.interval ? daily.pattern.interval.toString() : '1'}
            />
            <span>day(s)</span>
          </Stack>
        );
    }
  }, [value, bookingState.recurringEvents]);

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Typography variant="body2" component="span">
            Every
          </Typography>
        </Grid>
        <Grid item>{renderEveryContent}</Grid>
      </Grid>
    </Grid>
  );
};

export default EveryBooking;
