import { createContext, Dispatch, useContext } from 'react';
import { Mailbox } from '../../constants';

export interface IMailboxContextAction {
  type:
    | 'setDeviceId'
    | 'setSubject'
    | 'setBody'
    | 'setLocation'
    | 'setRequiredAttendees'
    | 'setOptionalAttendees'
    | 'setTime'
    | 'setStartTime'
    | 'setEndTime'
    | 'setDefault'
    | 'setLocationDetails'
    | 'clear'
    | undefined;
  payload?: Mailbox.IMailboxItem;
}
export interface IMailboxContextProps {
  state: Mailbox.IMailboxItem;
  dispatch: Dispatch<IMailboxContextAction>;
}

export const MailboxContext = createContext<IMailboxContextProps>({
  state: Mailbox.initialIMailboxItem,
  dispatch: () => {},
});

const useMailbox = (): IMailboxContextProps => useContext(MailboxContext);

export { useMailbox };
