export enum PolicyValueType {
  DAYS = 'days',
  HOURS = 'hours',
  MINUTES = 'minutes',
  SECONDS = 'seconds',
  TIME_RANGE = 'time_range',
  DAY_RANGE = 'day_range',
  COUNT = 'count',
  HOURS_MINUTES_ARRAY = 'hours_minutes_array',
}

export enum PolicyCategoryTypes {
  'DESK' = 'Desk',
  'LOCKER_BANK' = 'Locker Bank',
  'CAR_PARK_SPACES' = 'Car Park Spaces',
}

export enum PolicyName {
  ADVANCE_BOOKING_DAYS = 'Advance booking days',
  MAX_BOOKING_DURATION = 'Max booking duration',
  ACCESS_TO_BOOK_RESOURCES = 'Access to book resources',
  ANTI_CLAIMING_POLICY = 'Anti claiming policy',
  DESK_CLAIMING_POLICY = 'Desk claiming policy',
  AUTO_CANCELLATION_POLICY = 'Grace period before auto release',
  EARLY_CHECKIN_POLICY = 'Early Check-In Limit',
  PICKER_INTERVAL = 'Picker Interval',
  DEFAULT_BOOKING_HOURS = 'Default Booking Start and End Time',
  OVERALL_BOOKING_DURATION = 'Overall booking duration',
}

export enum PolicyCategoryName {
  BOOKING_CANCELLATION = 'Booking Cancellation',
  BOOKINGS = 'Bookings',
  NOTIFICATIONS = 'Notifications',
  OPERATIONAL_DAYS = 'Operational days',
  OPERATIONAL_HRS = 'Operational hours',
}
type DefaultBookingStartAndEndTime = {
  startTime: string;
  endTime: string;
};
type IUserPoliciesContextState = {
  advanceBookingDays: number;
  maxBookingDuration: number;
  pickerInterval: number;
  userOverlappingBookingLimit: number;
  defaultBookingStartAndEndTime: DefaultBookingStartAndEndTime;
  operationalDays: number[];
  operationalHours: number[];
  overallBookingDuration: number; // in seconds
};

export interface PoliciesContextState {
  policies: {
    carParkSpace: IUserPoliciesContextState;
    desk: IUserPoliciesContextState;
  };
}

export enum EPoliciesContextAction {
  SET_USER_POLICIES = 'SET_USER_POLICIES',
}

export type PolicyValueValue = number | string | number[] | string[];

export interface PolicyValue {
  type: PolicyValueType;
  value: PolicyValueValue;
}

export interface Policy {
  id: string;
  name: string;
  displayName?: string;
  description?: string;
  value?: PolicyValue;
  category: string;
  type?: string;
  createdAt?: Date;
  updatedAt?: Date;
  categoryType?: string;
}
/** Policies */
export enum ReadPoliciesActionType {
  request = 'read.policies.request',
  response = 'read.policies.response',
  error = 'read.policies.error',
}

/** Policy */
export enum ReadPolicyActionType {
  request = 'read.policy.request',
  response = 'read.policy.response',
  error = 'read.policy.error',
}

export enum CreatePolicyActionType {
  request = 'create.policy.request',
  response = 'create.policy.response',
  error = 'create.policy.error',
}

export enum UpdatePolicyActionType {
  request = 'update.policy.request',
  response = 'update.policy.response',
  error = 'update.policy.error',
}

export const PolicyDayRange = [
  { name: 'Sunday', value: 0 },
  { name: 'Monday', value: 1 },
  { name: 'Tuesday', value: 2 },
  { name: 'Wednesday', value: 3 },
  { name: 'Thursday', value: 4 },
  { name: 'Friday', value: 5 },
  { name: 'Saturday', value: 6 },
];

export const PolicyTimeRange = [
  { name: '0', value: 0 },
  { name: '1', value: 1 },
  { name: '2', value: 2 },
  { name: '3', value: 3 },
  { name: '4', value: 4 },
  { name: '5', value: 5 },
  { name: '6', value: 6 },
  { name: '7', value: 7 },
  { name: '8', value: 8 },
  { name: '9', value: 9 },
  { name: '10', value: 10 },
  { name: '11', value: 11 },
  { name: '12', value: 12 },
  { name: '13', value: 13 },
  { name: '14', value: 14 },
  { name: '15', value: 15 },
  { name: '16', value: 16 },
  { name: '17', value: 17 },
  { name: '18', value: 18 },
  { name: '19', value: 19 },
  { name: '20', value: 20 },
  { name: '21', value: 21 },
  { name: '22', value: 22 },
  { name: '23', value: 23 },
];
