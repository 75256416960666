import React, { useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import { useBookingDetails } from '../../../../../contexts/BookingDetails';
import { classes } from '../../styles';

const ITPreview = (): JSX.Element => {
  const { state: bookingState } = useBookingDetails();
  const itRequest = bookingState?.it;
  const renderEquipmentRequests = useMemo(() => {
    const equipmentRequests = itRequest?.equipment?.map((objRequest) => objRequest?.name);
    if (!equipmentRequests?.length) return null;
    return (
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography variant="body2" style={classes.label}>
              Equipment
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="body2" style={classes.value}>
              {equipmentRequests?.join(', ')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }, [itRequest?.equipment]);

  return (
    <Grid container spacing={1}>
      {renderEquipmentRequests}
      {itRequest?.notes && (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography variant="body2" style={classes.label}>
                Notes
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body2" style={classes.value}>
                {itRequest.notes}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ITPreview;
