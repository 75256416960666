import { ComponentProps, ReactNode, useReducer } from 'react';
import {
  BookingDetailsContext,
  IBookingDetailsContextAction,
  IReducerBookingDetailsState,
  initialBookingDetailsState,
} from '../../contexts/BookingDetails';
const reducer = (
  state: IReducerBookingDetailsState,
  action: IBookingDetailsContextAction,
): IReducerBookingDetailsState => {
  switch (action.type) {
    case 'setGeneral':
      return {
        ...state,
        general: action.payload.general,
      };
    case 'setIT':
      return {
        ...state,
        it: action.payload.it,
      };
    case 'setFacilities':
      return {
        ...state,
        facilities: action.payload.facilities,
      };
    case 'setCatering':
      return {
        ...state,
        catering: action.payload.catering,
      };
    case 'setTabValue':
      return {
        ...state,
        tabValue: Number(action.payload.tabValue),
      };
    case 'setCateringMenus':
      return {
        ...state,
        catering: {
          ...state.catering,
          cateringMenus: action.payload.currentMenus,
        },
        addMenu: false,
      };
    case 'setAddMenu':
      return {
        ...state,
        addMenu: action.payload.addMenu,
      };
    case 'setOrganizer':
      return {
        ...state,
        organizer: action.payload.organizer,
      };
    case 'setOnBehalf':
      return {
        ...state,
        isOnOrganizer: action.payload.isOnOrganizer,
      };
    case 'setLinkRoom':
      return {
        ...state,
        isLinkRoom: action.payload.isLinkRoom,
      };
    case 'setSelectedLinkRoom':
      return {
        ...state,
        selectedLinkRoom: action.payload.selectedLinkRoom,
      };
    case 'setEventsDateSeries':
      return {
        ...state,
        recurringEventsDateSeries: action.payload.recurringEventsDateSeries,
      };
    case 'setRecurringEvents':
      return {
        ...state,
        recurringEvents: action.payload.recurringEvents,
        reopenRecurringDialog: false,
      };
    case 'setReOpenRecurringDialog':
      return {
        ...state,
        reopenRecurringDialog: true,
      };

    case 'clear':
      return initialBookingDetailsState;
    default:
      return initialBookingDetailsState;
  }
};

function BookingDetailsProvider({ children }: ComponentProps<any>): ReactNode & JSX.Element {
  const [state, dispatch] = useReducer(reducer, initialBookingDetailsState);
  return (
    <BookingDetailsContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </BookingDetailsContext.Provider>
  );
}

export default BookingDetailsProvider;
