import { Policy } from '..';

export interface PolicyGroup {
  id: string;
  name: string;
  description?: string;
  isDefault?: boolean;
  policies?: Policy[];
  createdAt: string;
  updatedAt: string;
}

export enum ReadPolicyGroupsActionType {
  request = 'read.resourcegroups.request',
  response = 'read.resourcegroups.response',
  error = 'read.resourcegroups.error',
}

export enum ReadPolicyGroupActionType {
  request = 'read.resourcegroup.request',
  response = 'read.resourcegroup.response',
  error = 'read.resourcegroup.error',
}

export enum CreatePolicyGroupActionType {
  request = 'create.resourcegroup.request',
  response = 'create.resourcegroup.response',
  error = 'create.resourcegroup.error',
}

export enum UpdatePolicyGroupActionType {
  request = 'update.resourcegroup.request',
  response = 'update.resourcegroup.response',
  error = 'update.resourcegroup.error',
}

export enum DeletePolicyGroupActionType {
  request = 'delete.resourcegroup.request',
  response = 'delete.resourcegroup.response',
  error = 'delete.resourcegroup.error',
}
