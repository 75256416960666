import {
  ReadUserActionType,
  User,
  ReadUsersActionType,
  DeleteUserActionType,
  UpdateUserActionType,
  CreateUserGroupUserActionType,
  DeleteUserGroupUserActionType,
  CreateUserCompanyActionType,
  DeleteUserCompanyActionType,
  ResetUserActionType,
  UnDeleteUserActionType,
  UpdateUserModeActionType,
} from '../../../constants';
import {
  CreateUserGroupUserAction,
  DeleteUserAction,
  DeleteUserGroupUserAction,
  ReadUserAction,
  ReadUsersAction,
  ResetUserAction,
  UpdateUserAction,
  CreateUserCompanyAction,
  DeleteUserCompanyAction,
  UnDeleteUserAction,
  ImportUsersAction,
} from '../../../actions';
import { QueryState } from '..';
import { UpdateUserModeAction } from '../../../actions/api/user/updateUserMode';

export interface UserState extends QueryState {
  isFetching: boolean;
  users: User[];
  error?: string;
  user: Record<string, User>;
  isImporting: boolean;
  isUpdating: boolean;
}

const initState: UserState = {
  isFetching: false,
  users: [],
  user: {},
  pageInfo: {
    total: 0,
  },
  isImporting: false,
  isUpdating: false,
};

export function user(
  state = initState,
  action:
    | DeleteUserAction
    | ReadUserAction
    | ReadUsersAction
    | UpdateUserAction
    | CreateUserGroupUserAction
    | DeleteUserGroupUserAction
    | CreateUserCompanyAction
    | DeleteUserCompanyAction
    | ResetUserAction
    | UnDeleteUserAction
    | ImportUsersAction
    | UpdateUserModeAction,
): UserState {
  switch (action.type) {
    /** RESET */
    case ResetUserActionType.response:
      return {
        ...initState,
      };
    /** READ */
    case ReadUsersActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadUsersActionType.response:
      return {
        ...state,
        isFetching: false,
        users: action.data,
        pageInfo: action.pageInfo,
      };
    case ReadUsersActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    case ReadUserActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadUserActionType.response:
      return {
        ...state,
        isFetching: false,
        user: { ...state.user, [action.data.id]: action.data },
      };
    case ReadUserActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** DELETE */
    case DeleteUserActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case DeleteUserActionType.response:
      delete state.user[action.data];
      return {
        ...state,
        isFetching: false,
        users: [...state.users.filter(({ id }) => id !== action.data)],
        user: state.user,
      };
    case DeleteUserActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** UPDATE */
    case UpdateUserActionType.request:
      return {
        ...state,
        isUpdating: true,
      };
    case UpdateUserActionType.response:
      return {
        ...state,
        isUpdating: false,
        users: [...state.users],
        user: {
          ...state.user,
          [action.data.id]: {
            ...state.user[action.data.id],
            ...action.data,
          },
        },
      };
    case UpdateUserActionType.error:
      return {
        ...state,
        isUpdating: false,
        error: action.error.message,
      };
    case CreateUserGroupUserActionType.response:
      return {
        ...state,
        user: {
          ...state.user,
          [action.data.user]: {
            ...state.user[action.data.user],
            usergroups: [...state.user[action.data.user].usergroups, action.data.usergroup],
          },
        },
      };
    case DeleteUserGroupUserActionType.response:
      return {
        ...state,
        user: {
          ...state.user,
          [action.data.user]: {
            ...state.user[action.data.user],
            usergroups: [...state.user[action.data.user].usergroups.filter((group) => group.id !== action.data.id)],
          },
        },
      };

    /** User - Companies */
    case CreateUserCompanyActionType.response:
      return {
        ...state,
        user: {
          ...state.user,
          [action.data.user]: {
            ...state.user[action.data.user],
            companies: [...state.user[action.data.user].companies, action.data.company],
          },
        },
      };

    case DeleteUserCompanyActionType.response:
      return {
        ...state,
        user: {
          ...state.user,
          [action.data.user]: {
            ...state.user[action.data.user],
            companies: [...state.user[action.data.user].companies.filter((group) => group.id !== action.data.id)],
          },
        },
      };

    /** Restore */
    case UnDeleteUserActionType.request:
      return {
        ...state,
        isFetching: true,
      };

    case UnDeleteUserActionType.response:
      return {
        ...state,
        isFetching: false,
      };

    /** Incognito */
    case UpdateUserModeActionType.response:
      return {
        ...state,
        user: {
          ...state.user,
          [action.data.id]: {
            ...state.user[action.data.id],
            isIncognitoMode: action.data.isIncognitoMode,
          },
        },
      };

    default:
      return state;
  }
}
