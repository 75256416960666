import {
  CreateNotificationActionType,
  DeleteNotificationActionType,
  Device,
  Notification,
  NotificationRegistration,
  ReadNotificationsActionType,
} from '../../../constants';
import { CreateNotificationAction, DeleteNotificationAction, ReadNotificationsAction } from '../../../actions';
import { QueryState } from '..';

export interface NotificationState extends QueryState {
  isFetching: boolean;
  notifications: Notification[];
  error?: string;
  notification: Map<string, any>;
  notificationRegistrations: { device: Device; registration: NotificationRegistration }[];
}

const initState: NotificationState = {
  isFetching: false,
  notifications: [],
  notification: new Map<string, Notification>(),
  pageInfo: {
    total: 0,
  },
  notificationRegistrations: [],
};

export function notification(
  state = initState,
  action: CreateNotificationAction | ReadNotificationsAction | DeleteNotificationAction,
): NotificationState {
  switch (action.type) {
    /** READ */
    case ReadNotificationsActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadNotificationsActionType.response:
      return {
        ...state,
        isFetching: false,
        notifications: action.data || state.notifications,
      };
    case ReadNotificationsActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** CREATE */
    case CreateNotificationActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case CreateNotificationActionType.response:
      return {
        ...state,
        isFetching: false,
        notifications: action.data ? [...state.notifications, action.data] : state.notifications,
        notification: action.data ? state.notification.set(action.data.id, action.data) : state.notification,
      };
    case CreateNotificationActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    case DeleteNotificationActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case DeleteNotificationActionType.response:
      state.notification.delete(action.data.id);
      return {
        ...state,
        isFetching: false,
        notifications: state.notifications.filter((notification) => notification.id !== action.data.id),
        notification: state.notification,
      };
    case DeleteNotificationActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    default:
      return state;
  }
}
