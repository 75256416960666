import { Box, Button, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMailbox } from '../../../contexts';
import { useBookingDetails } from '../../../contexts/BookingDetails';
import { RootState } from '../../../middlewares/nura-client/lib/redux/reducers';
import { ReducerOutlookOfficeAddIn } from '../../../middlewares/nura-client/lib/redux/reducers/api/officeAddIns';

interface Props {
  tabValue: number;
  onNexButton: (value: number) => void;
  onSubmit: () => void;
  onPreview: (value: boolean) => void;
  loading: boolean;
}
export default function ActionsBooking({
  onNexButton,
  onSubmit,
  onPreview,
  tabValue = 0,
  loading = false,
}: Props): JSX.Element {
  const { dispatch: bookingDispatch } = useBookingDetails();
  const { dispatch: mailboxDispatch } = useMailbox();
  const { resource, statusRoomSelected } = useSelector<RootState, ReducerOutlookOfficeAddIn.IOutlookAddInState>(
    ({ outlookAddIn }) => outlookAddIn,
  );

  const [submitButton, setSubmitButton] = useState<boolean>(false);
  const [applyButton, setApplyButton] = useState<boolean>(false);

  let navigate = useNavigate();

  const handleBack = async () => {
    bookingDispatch({
      type: 'clear',
      payload: {},
    });

    //Set location into mailbox
    const getMailBoxLocation = async () => {
      await Office.context.mailbox.item.location.setAsync('');
      mailboxDispatch({
        type: 'setLocation',
        payload: {
          location: undefined,
        },
      });
    };

    Office.onReady(async () => {
      await getMailBoxLocation();
      bookingDispatch({ type: 'clear', payload: {} });
      navigate('/bookings', { replace: true });
    });
  };

  useEffect(() => {
    if (resource?.services?.length === tabValue) {
      //setSubmitButton(true);
      setApplyButton(true);
    } else {
      setSubmitButton(false);
    }
  }, [resource, tabValue]);

  return (
    <Box sx={{ m: 0, mt: 3 }}>
      <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
        {tabValue < 1 ? (
          <Button sx={{ minWidth: 100 }} variant="outlined" onClick={handleBack} fullWidth disabled={loading}>
            Close
          </Button>
        ) : (
          <Button
            sx={{ minWidth: 100 }}
            variant="outlined"
            onClick={() => onNexButton(-1)}
            fullWidth
            disabled={loading}
          >
            Back
          </Button>
        )}

        {resource?.services.length === tabValue ? (
          <>
            {submitButton && !applyButton ? (
              <Button
                sx={{ minWidth: 100 }}
                variant="contained"
                onClick={onSubmit}
                fullWidth
                disabled={loading || !statusRoomSelected}
              >
                Send
              </Button>
            ) : (
              <Button
                sx={{ minWidth: 100 }}
                variant="contained"
                onClick={() => onPreview(true)}
                fullWidth
                disabled={loading || !statusRoomSelected}
              >
                Apply
              </Button>
            )}
          </>
        ) : (
          <Button
            sx={{ minWidth: 100 }}
            variant="contained"
            onClick={() => onNexButton(1)}
            fullWidth
            disabled={loading || !statusRoomSelected}
          >
            Next
          </Button>
        )}
      </Stack>
    </Box>
  );
}
