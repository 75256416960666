import React from 'react';
import { Provider } from 'react-redux';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import AuthProvider from './providers/AuthProvider';
import theme from './styles/theme';
import FilterBookingsProvider from './providers/FilterBookings';
import AppRoutes from './routes';
import AppState from './middlewares/nura-client/lib/redux/store';
import { SnackbarProvider } from 'notistack';
import MailboxProvider from './providers/Mailbox';
import BookingDetailsProvider from './providers/BookingDeatils';
import { OfficeContextProvider } from './providers/OfficeContext';

function App() {
  return (
    <SnackbarProvider maxSnack={4}>
      <Provider store={AppState.instance.store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <MailboxProvider>
            <AuthProvider>
              <BookingDetailsProvider>
                <FilterBookingsProvider>
                  <OfficeContextProvider>
                    <Router>
                      <AppRoutes />
                    </Router>
                  </OfficeContextProvider>
                </FilterBookingsProvider>
              </BookingDetailsProvider>
            </AuthProvider>
          </MailboxProvider>
        </ThemeProvider>
      </Provider>
    </SnackbarProvider>
  );
}

export default App;
