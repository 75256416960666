import React, { Suspense } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import Layout from '../components/Layout';

import PageLoading from '../components/PageLoading';
import Bookings from '../pages/Bookings';
import Login from '../pages/Login';
import NotFound from '../pages/404';
import Filter from '../pages/Filter';
import BookingDetails from '../pages/BookingDetails';
import ProtectedRoute from './ProtectedRoute';

export default function AppRoutes(): JSX.Element {
  return (
    <Suspense fallback={<PageLoading />}>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route
          element={
            <Layout>
              <Outlet />
            </Layout>
          }
        >
          <Route path="/*" element={<NotFound />} />

          <Route
            path="bookings"
            element={
              <ProtectedRoute>
                <Bookings />
              </ProtectedRoute>
            }
          />
          <Route path="bookings/*" element={<NotFound />} />
        </Route>

        <Route
          element={
            <Layout>
              <Outlet />
            </Layout>
          }
        >
          <Route
            path="filter"
            element={
              <ProtectedRoute>
                <Filter />
              </ProtectedRoute>
            }
          />
          <Route path="filter/*" element={<NotFound />} />
        </Route>

        <Route
          element={
            <Layout header={false}>
              <Outlet />
            </Layout>
          }
        >
          <Route
            path="booking/:id"
            element={
              <ProtectedRoute>
                <BookingDetails />
              </ProtectedRoute>
            }
          />
        </Route>
      </Routes>
    </Suspense>
  );
}
