export interface BlobStorage {
  sasToken?: string;
  file?: string;
}

export enum ReadSasUrlBlobStorageActionType {
  request = 'read.sas.storage.request',
  response = 'read.sas.storage.response',
  error = 'read.sas.storage.error',
}

export enum ReadFileBlobStorageActionType {
  request = 'read.file.storage.request',
  response = 'read.file.storage.response',
  error = 'read.file.storage.error',
}
