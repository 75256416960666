export const API_URL = process.env.API_URL || process.env.REACT_APP_API_URL || 'https://api-1.nuraspace.com';
export const BROWSER_ID_KEY = process.env.BROWSER_ID_KEY || '@nura/bid';
export const ACCESS_TOKEN_KEY = process.env.ACCESS_TOKEN_KEY || process.env.REACT_APP_ACCESS_TOKEN_KEY || '@nura/at';
export const REFRESH_TOKEN_KEY = process.env.REFRESH_TOKEN_KEY || process.env.REACT_APP_REFRESH_TOKEN_KEY || '@nura/rt';
export const EXPIRY_WITHIN_AMOUNT =
  process.env.EXPIRY_WITHIN_AMOUNT || process.env.REACT_APP_EXPIRY_WITHIN_AMOUNT
    ? parseInt(process.env.EXPIRY_WITHIN_AMOUNT, 10)
    : 1800000;
export const CURRENT_COMPANY_KEY =
  process.env.CURRENT_COMPANY_KEY || process.env.REACT_APP_CURRENT_COMPANY_KEY || '@nura/cc';
export const LIVE_PAGE_KEY = process.env.LIVE_PAGE_KEY || 'livePage';

export const MS365_TOKEN_KEY = process.env.MS365_TOKEN_KEY || '@nura/feature/mtk';
export const REDIRECT_LINK_KEY = process.env.REDIRECT_LINK_KEY || '@nura/rl';

export const LOBBY_DISPLAY_SCREEN = process.env.REDIRECT_LINK_KEY || '@nura/feature/lds';

export const MY_TEAM = process.env.MY_TEAM || 'MY_TEAM';

export const OUTLOOK_ID_KEY = process.env.REACT_APP_OUTLOOK_ID_KEY || '@nura/oid';

export const BLOB_URL_KEY = process.env.BLOB_URL_KEY || '@nura/blob/url';
export const BLOB_QUERY_PARAMS_KEY = process.env.BLOB_QUERY_PARAMS_KEY || '@nura/blob/query_params';
