import { useSnackbar } from 'notistack';
import React, { ComponentProps, ReactNode, useEffect, useReducer } from 'react';
import { Mailbox } from '../../constants';
import { IMailboxContextAction, MailboxContext } from '../../contexts';
import { getAndSetOutlookId } from '../../services/Office/services';

const reducer = (state: Mailbox.IMailboxItem, action: IMailboxContextAction): Mailbox.IMailboxItem => {
  switch (action.type) {
    case 'setDeviceId':
      return {
        ...state,
        deviceId: action.payload.deviceId,
      };
    case 'setSubject':
      return {
        ...state,
        subject: action.payload.subject,
      };
    case 'setBody':
      return {
        ...state,
        body: action.payload.body,
      };
    case 'setTime':
      return {
        ...state,
        start: action.payload.start,
        end: action.payload.end,
      };
    case 'setStartTime':
      return {
        ...state,
        start: action.payload.start,
      };
    case 'setEndTime':
      return {
        ...state,
        end: action.payload.end,
      };
    case 'setLocation':
      return {
        ...state,
        location: action.payload.location,
      };
    case 'setLocationDetails':
      return {
        ...state,
        locationDetails: action.payload.locationDetails,
      };
    case 'setRequiredAttendees':
      return {
        ...state,
        requiredAttendees: action.payload.requiredAttendees,
      };
    case 'setOptionalAttendees':
      return {
        ...state,
        optionalAttendees: action.payload.optionalAttendees,
      };
    case 'setDefault':
      return {
        ...state,
        ...Mailbox.defaultIMailboxItem,
      };
    case 'clear':
      return Mailbox.initialIMailboxItem;
    default:
      return Mailbox.initialIMailboxItem;
  }
};

function MailboxProvider({ children }: ComponentProps<any>): ReactNode & JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(reducer, Mailbox.initialIMailboxItem);

  useEffect(() => {
    const getAttendees = async () => {
      await Office.context.mailbox.item.requiredAttendees.getAsync(
        (asyncResult: Office.AsyncResult<Office.EmailAddressDetails[]>) => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            dispatch({
              type: 'setRequiredAttendees',
              payload: {
                requiredAttendees: asyncResult.value,
              },
            });
          }
        },
      );
      await Office.context.mailbox.item.optionalAttendees.getAsync(
        (asyncResult: Office.AsyncResult<Office.EmailAddressDetails[]>) => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            dispatch({
              type: 'setOptionalAttendees',
              payload: {
                optionalAttendees: asyncResult.value,
              },
            });
          }
        },
      );
    };
    const getMailBoxLocation = async () => {
      try {
        await getAttendees();
        await Office.context.mailbox.item.enhancedLocation.getAsync(
          (asyncResult: Office.AsyncResult<Office.LocationDetails[]>) => {
            if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
              const locationsSelected = asyncResult?.value?.filter(
                (objLocation) => objLocation?.locationIdentifier?.type === Office.MailboxEnums.LocationType.Room,
              );
              dispatch({
                type: 'setLocationDetails',
                payload: {
                  locationDetails: locationsSelected?.map((objSelected) => {
                    return {
                      emailAddress: objSelected?.emailAddress,
                      displayName: objSelected?.displayName,
                    };
                  }),
                },
              });
            }
          },
        );

        await Office.context.mailbox.item.location.getAsync((asyncResult: Office.AsyncResult<string>) => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            dispatch({
              type: 'setLocation',
              payload: {
                location: asyncResult.value,
              },
            });
          }
        });
      } catch (error) {
        enqueueSnackbar(error, { variant: 'error' });
      }
    };

    Office.onReady(async () => {
      getAndSetOutlookId();
      Office.context.mailbox.item.addHandlerAsync(Office.EventType.AppointmentTimeChanged, (asyncResult): void => {
        dispatch({
          type: 'setTime',
          payload: {
            start: asyncResult?.start,
            end: asyncResult?.end,
          },
        });
      });
      Office.context.mailbox.item.addHandlerAsync(Office.EventType.RecipientsChanged, getMailBoxLocation);
    });
  }, [enqueueSnackbar]);

  return (
    <MailboxContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </MailboxContext.Provider>
  );
}

export default MailboxProvider;
