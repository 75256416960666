import { Booking } from '../booking';
import { Company } from '../company';
import { ResourceGroup } from '../resourcegroup';
import { User } from '../user';
import { LockerCluster } from '../lockerbank';

export interface ResourceTag {
  id?: string;
  type: string;
  name: string;
  ref?: Resource;
  code?: string;
}

export enum ResourceAvailability {
  AVAILABLE = 'AVAILABLE',
  BOOKED = 'BOOKED',
  UPCOMING_BOOKING = 'UPCOMING_BOOKING',
  UNAVAILABLE = 'UNAVAILABLE',
  ASSOCIATED = 'ASSOCIATED',
  INSTALLED = 'INSTALLED  ',
  CHECKED_IN = 'CHECKED_IN',
  INACTIVE = 'INACTIVE',
}

export enum DeskType {
  STANDARD = 'STANDARD',
  SIT_STAND = 'SIT_STAND',
  FIXED_HEIGHT = 'Fixed Height',
  HEIGHT_ADJUSTABLE = 'Height Adjustable',
}

export enum ECarParkTypes {
  REGULAR = 'REGULAR',
  STANDARD = 'STANDARD',
  DISABLED = 'DISABLED',
  SMALL_VEHICLE = 'SMALL_VEHICLE',
  MOTORBIKE = 'MOTORBIKE',
  ELECTRIC_VEHICLE = 'ELECTRIC_VEHICLE',
}

export enum ResourceTypes {
  'DESK' = 'Desk',
  'MEETING_ROOM' = 'Meeting Room',
  'LOCKER_BANK' = 'Locker Bank',
  'CAR_PARK_SPACE' = 'Car Park Space',
}

export enum ResourceSubTypes {
  'LOCKER_CLUSTER' = 'Locker Cluster',
}

export enum EFloorTypes {
  ALL_FLOORS = 'ALL_FLOORS',
  OFFICE_FLOORS = 'OFFICE_FLOORS',
  CAR_PARK_FLOORS = 'CAR_PARK_FLOORS',
}

export enum EResourceTag {
  FLOOR_TYPE = 'floorType',
  DESCRIPTION = 'description',
  INITIAL_ZOOM = 'initialzoom',
  BUILDING = 'building',
  FLOOR_TYPE_DESC = 'floorTypeDescription',
}

// this refers to the resource types table
export interface ResourceDeskType {
  id: string;
  parentId: string;
  name: string;
  displayName?: string;
  active: boolean;
}

export interface ResourceDevice {
  serialNumber: string;
  deviceType: string;
  deviceTypeName?: string;
}

export interface Resource {
  id: string;
  name?: string;
  description?: string;
  tags?: ResourceTag[];
  company?: Company;
  attributes?: any;
  active?: boolean;
  availability?: ResourceAvailability;
  isBooked?: boolean;
  hasUpcomingBooking?: boolean;
  booking?: Booking;
  upcomingBooking?: Booking;
  resourcegroups?: ResourceGroup[];
  createdAt?: string;
  updatedAt?: string;
  resourcedevices?: ResourceDevice[];
  rowHeight?: number;
  previewImage?: string;
  updatedBy?: string;
  backgroundImage?: string;
  isSearchable?: boolean;
  timezone?: string;
  capacity?: number;
  initialZoom?: string;
  locationX?: number;
  locationY?: number;
  resourceType?: string;
  floor?: {
    id: string;
    name: string;
    building?: {
      id: string;
      name: string;
      timezone: string;
    };
  };
  floorAdmins?: User[];
  lockerClusters?: LockerCluster[];
}

export interface AntiClaimingDesks {
  id: string;
  dates: string[];
  details?: Resource;
}

export type AccessibleResource = Pick<Resource, 'id' | 'name'> & {
  building?: string;
  hasFloorplan?: boolean;
  initialZoom?: string;
  timezone?: string;
  type?: FloorType;
  svgViewBox?: string;
  url?: string;
};

export interface PermanentBooking {
  id: string;
  startDate: string;
  endDate: string;
}

export interface FloorType {
  id: string;
  code?: string;
  description?: string;
  active?: boolean;
}

export enum ReadResourcesActionType {
  request = 'read.resources.request',
  response = 'read.resources.response',
  error = 'read.resources.error',
}

export enum ReadResourceActionType {
  request = 'read.resource.request',
  response = 'read.resource.response',
  error = 'read.resource.error',
}

export enum CreateResourceActionType {
  request = 'create.resource.request',
  response = 'create.resource.response',
  error = 'create.resource.error',
}

export enum UpdateResourceActionType {
  request = 'update.resource.request',
  response = 'update.resource.response',
  error = 'update.resource.error',
}

export enum ReadResourceAvailabilityActionType {
  request = 'read.resource.availability.request',
  response = 'read.resource.availability.response',
  error = 'read.resource.availability.error',
}

export enum ReadResourcesAccessibleActionType {
  request = 'read.resources.accessible.request',
  response = 'read.resources.accessible.response',
  error = 'read.resources.accessible.error',
}

export enum ReadResourceAvailabilityByUserGroupActionType {
  request = 'read.resource.availability.user.group.request',
  response = 'read.resource.availability.user.group.response',
  error = 'read.resource.availability.user.group.error',
}

export const DESKS_CSV_HEADER = [
  { label: 'name', key: 'name' },
  { label: 'zone', key: 'zone' },
  { label: 'deskType', key: 'deskType' },
];

export const DESKS_CSV_DUMMY_DATA = [
  {
    name: 'example-01',
    zone: 'Z1',
    deskType: 'Stand',
  },
];

interface OptionsFloors {
  Id: number;
  Name: string;
}
interface OptionsBuildings {
  Id: number;
  Name: string;
  Floors: OptionsFloors[];
}
export interface OptionsUploadCSV {
  Id: number;
  Name: string;
  Buildings: OptionsBuildings[];
}

export enum ImportResourcesActionType {
  request = 'read.resource.import.request',
  response = 'read.resource.import.response',
  error = 'read.resource.import.error',
}

export enum ReadResourceOptionsActionType {
  request = 'read.resource.options.request',
  response = 'read.resource.options.response',
  error = 'read.resource.options.error',
}

export enum ReadResourceDeskTypesActionType {
  request = 'read.resource.desktypes.request',
  response = 'read.resource.desktypes.response',
  error = 'read.resource.desktypes.error',
}

export enum CreateResourceFloorplanActionType {
  request = 'create.resource.floorplan.request',
  response = 'create.resource.floorplan.response',
  error = 'create.resource.floorplan.error',
}

export enum ImportDevicesActionType {
  request = 'read.resource.device.import.request',
  response = 'read.resource.device.import.response',
  error = 'read.resource.device.import.error',
}

export enum CreateResourceFloorplanPreviewActionType {
  request = 'create.resource.floorplan.preview.request',
  response = 'create.resource.floorplan.preview.response',
  error = 'create.resource.floorplan.preview.error',
}

export enum CreateResourcePreviewActionType {
  request = 'create.resource.preview.request',
  response = 'create.resource.preview.response',
  error = 'create.resource.preview.error',
}

export enum ReadResourceFloorTypesActionType {
  request = 'read.resource.floorTypes.request',
  response = 'read.resource.floorTypes.response',
  error = 'read.resource.floorTypes.error',
}

export type ResourceType = 'desk' | 'floor' | 'building' | 'meetingroom' | 'lockerbank' | ResourceTypes.CAR_PARK_SPACE;
