import { Breadcrumbs, Button, Grid, Stack, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { DateTime } from 'luxon';
import AutoCompleteCustom from '../../../../components/AutoCompleteCustom';
import { cateringMenuBreadcrumb, IBreadcrumbMenu } from '../constants';
import { dateService } from '../../../../services';
import MenuItems from './Menus';
import { useBookingDetails } from '../../../../contexts/BookingDetails';
import PackageItems from './Packages';
import FoodItems from './Foods';
import { useMailbox } from '../../../../contexts';
import {
  ICateringMenuSelected,
  IOutlookAddInMeetingRoomCateringFoodItem,
  IOutlookAddInMeetingRoomCateringMenu,
  IOutlookAddInMeetingRoomCateringPackage,
} from '../../../../middlewares/nura-client/lib/redux/constants';
import { handlePreventNumber } from '../../../../helpers';
import { useOfficeContext } from '../../../../providers/OfficeContext';
import { cateringMenuValidation } from '../../Helpers/formValidation';

export default function CateringMenuItems(): JSX.Element {
  const { state: bookingState, dispatch: bookingDispatch } = useBookingDetails();
  const { state: mailboxState } = useMailbox();
  const { enqueueSnackbar } = useSnackbar();
  const { startDate } = useOfficeContext();
  const [breadcrumbActive, setBreadcrumbActive] = useState<IBreadcrumbMenu[]>(cateringMenuBreadcrumb);
  const [tabActive, setTabActive] = useState<IBreadcrumbMenu>(cateringMenuBreadcrumb[0]);
  const [menuSelected, setMenuSelected] = useState<IOutlookAddInMeetingRoomCateringMenu>();
  const [packageSelected, setPackageSelected] = useState<IOutlookAddInMeetingRoomCateringPackage>();
  const [foodsSelected, setFoodsSelected] = useState<IOutlookAddInMeetingRoomCateringFoodItem[]>([]);
  const [deliveredAt, setDeliveredAt] = useState<string | null>(() => {
    return DateTime.fromJSDate(startDate).toFormat('h:mm a');
  });
  const [quantity, setQuantity] = useState<number>(1);
  const [timeDeliveryOptions, setTimeDeliveryOptions] = useState<string[]>([]);

  useEffect(() => {
    if (!deliveredAt && timeDeliveryOptions.length) {
      setDeliveredAt(timeDeliveryOptions[0]);
    }
  }, [deliveredAt, timeDeliveryOptions]);

  useEffect(() => {
    const timeOptions = dateService.getEachMinuteOfInterval({
      start: new Date(mailboxState.start),
      end: new Date(mailboxState.end),
      step: 15,
      isDeliveryTime: true,
    });
    setTimeDeliveryOptions(timeOptions);
  }, [mailboxState.start, mailboxState.end]);

  const resetState = () => {
    const activeBreadcrumb = cateringMenuBreadcrumb.map((itemActive) => {
      return {
        ...itemActive,
        active: itemActive.id === 1 ? true : false,
      };
    });
    setBreadcrumbActive(activeBreadcrumb);
    setTabActive(activeBreadcrumb[0]);
    setMenuSelected(undefined);
    setPackageSelected(undefined);
    setFoodsSelected([]);
    cateringMenuBreadcrumb[0].active = true;
    cateringMenuBreadcrumb[1].active = false;
    cateringMenuBreadcrumb[2].active = false;
    cateringMenuBreadcrumb[3].active = false;
  };

  const handleOnClick = (activeBreadcrumb: IBreadcrumbMenu) => {
    setTabActive(activeBreadcrumb);
    const newActive = breadcrumbActive?.map((itemActive) => {
      const isActive = Number(itemActive.id) <= Number(activeBreadcrumb.id) ? true : false;
      return {
        ...itemActive,
        active: isActive,
      };
    });
    setBreadcrumbActive(newActive);
  };
  const onAddCateringMenu = async () => {
    try {
      if (menuSelected && packageSelected && foodsSelected.length && deliveredAt && quantity) {
        const foodsSelectedIds = foodsSelected.map((food) => food.id);
        const newItem: ICateringMenuSelected = {
          id: `${new Date().getMilliseconds()}`,
          menu: menuSelected.id,
          package: packageSelected.id,
          foodItems: foodsSelectedIds,
          quantity: quantity,
          deliveredAt: deliveredAt,
        };

        const newCurrentMenus = [newItem, ...(bookingState?.catering?.cateringMenus || [])];
        await cateringMenuValidation.validate(newCurrentMenus);

        bookingDispatch({
          type: 'setCateringMenus',
          payload: {
            currentMenus: newCurrentMenus,
          },
        });

        resetState();
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error', autoHideDuration: 2000 });
    }
  };
  const handleOnClose = () => {
    bookingDispatch({
      type: 'setAddMenu',
      payload: {
        addMenu: false,
      },
    });
    resetState();
  };

  const handleOnNextFoods = () => {
    setTabActive(cateringMenuBreadcrumb[3]);
    cateringMenuBreadcrumb[3].active = true;
  };

  const handleDeliveredTime = (event: any, newValue: string | null) => {
    setDeliveredAt(newValue);
  };

  const handleQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      setQuantity(Number(event.target.value));
    } else {
      setQuantity(1);
    }
  };

  const handleBackTab = () => {
    const findIndex = cateringMenuBreadcrumb.findIndex((itemIndex) => Number(itemIndex.id) === Number(tabActive.id));
    if (findIndex < 0) {
    } else {
      setTabActive(cateringMenuBreadcrumb[Number(findIndex) - 1]);
      const newBreadCrumb = cateringMenuBreadcrumb.map((objBreadcrumb) => {
        if (Number(objBreadcrumb.id) >= Number(tabActive.id)) {
          objBreadcrumb.active = false;
        }
        return objBreadcrumb;
      });

      setBreadcrumbActive(newBreadCrumb);
    }

    setBreadcrumbActive(cateringMenuBreadcrumb);
  };

  //Render Menu Items
  const renderMenuItems = useMemo(() => {
    const handleOnSelectMenu = (newMenu: IOutlookAddInMeetingRoomCateringMenu) => {
      setMenuSelected(newMenu);
      cateringMenuBreadcrumb[1].active = true;
      cateringMenuBreadcrumb[2].active = false;
      cateringMenuBreadcrumb[3].active = false;
      setBreadcrumbActive(cateringMenuBreadcrumb);
      setTabActive(cateringMenuBreadcrumb[1]);
      setFoodsSelected([]);
    };

    return <MenuItems menuSelected={menuSelected} handleOnSelectMenu={handleOnSelectMenu} />;
  }, [menuSelected]);

  //Render Package Items
  const renderPackageItems = useMemo(() => {
    const handleOnSelectPackage = (newPackage: IOutlookAddInMeetingRoomCateringPackage) => {
      setPackageSelected(newPackage);
      cateringMenuBreadcrumb[2].active = true;
      cateringMenuBreadcrumb[3].active = false;
      setBreadcrumbActive(cateringMenuBreadcrumb);
      setTabActive(cateringMenuBreadcrumb[2]);
      setFoodsSelected([]);
    };

    return (
      <PackageItems
        menuSelected={menuSelected}
        packageSelected={packageSelected}
        handleOnSelectPackage={handleOnSelectPackage}
      />
    );
  }, [menuSelected, packageSelected]);

  //Render Food Items
  const renderFoodItems = useMemo(() => {
    const handleOnSelectFoods = (newFood: IOutlookAddInMeetingRoomCateringFoodItem) => {
      const findPrevFood = foodsSelected?.find((foodItem) => Number(foodItem.id) === Number(newFood.id));
      if (findPrevFood) {
        const filterFoods = foodsSelected?.filter((foodItem) => Number(foodItem.id) !== Number(newFood.id));
        setFoodsSelected(filterFoods);
      } else {
        if (foodsSelected?.length) {
          setFoodsSelected([...foodsSelected, newFood]);
        } else {
          setFoodsSelected([newFood]);
        }
      }
    };

    return (
      <FoodItems
        foodsSelected={foodsSelected}
        packageSelected={packageSelected}
        handleOnSelectFoods={handleOnSelectFoods}
      />
    );
  }, [packageSelected, foodsSelected]);

  const renderDetails = useMemo(() => {
    return (
      <Box sx={{ my: 3, mt: 5 }}>
        <Stack spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
          <AutoCompleteCustom
            options={timeDeliveryOptions}
            placeholder="Select a delivered time"
            value={deliveredAt}
            label="Delivery"
            onChange={handleDeliveredTime}
            width={'100%'}
          />

          <TextField
            id="quantity-outlined-multiline-flexible"
            name="Quantity"
            label="Quantity"
            placeholder="quantity"
            value={quantity}
            onChange={handleQuantity}
            onKeyDown={handlePreventNumber}
            type="number"
            sx={{ minWidth: 50 }}
            fullWidth
          />
        </Stack>
      </Box>
    );
  }, [timeDeliveryOptions, deliveredAt, quantity]);

  const cateringMenuActive = breadcrumbActive.filter((menu) => menu.active === true);
  return (
    <Box>
      <Box sx={{ my: 2 }}>
        <Stack spacing={2}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            {cateringMenuActive.map((menu, index) => (
              <Typography
                variant="body2"
                style={{ fontWeight: 'bolder', cursor: 'pointer' }}
                key={index}
                onClick={() => handleOnClick(menu)}
              >
                {menu.displayName}
              </Typography>
            ))}
          </Breadcrumbs>
        </Stack>
      </Box>
      <Box sx={{ my: 3 }}>
        <Grid container spacing={2}>
          {tabActive.name === 'menu' && <>{renderMenuItems}</>}
          {tabActive.name === 'packages' && <>{renderPackageItems}</>}
          {tabActive.name === 'foodItems' && <>{renderFoodItems}</>}
        </Grid>
      </Box>

      {tabActive.name === 'details' && <>{renderDetails}</>}

      <Box>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          {tabActive.name === 'menu' ? (
            <Button sx={{ minWidth: 100 }} variant="outlined" fullWidth onClick={handleOnClose}>
              Close
            </Button>
          ) : (
            <Button sx={{ minWidth: 100 }} variant="outlined" fullWidth onClick={() => handleBackTab()}>
              Back
            </Button>
          )}

          {tabActive.name === 'foodItems' && (
            <Button
              disabled={foodsSelected.length === 0}
              sx={{ minWidth: 100 }}
              variant="contained"
              fullWidth
              onClick={() => handleOnNextFoods()}
            >
              Next
            </Button>
          )}

          {tabActive.name === 'details' && (
            <Button sx={{ minWidth: 100 }} variant="contained" fullWidth onClick={onAddCateringMenu}>
              Apply
            </Button>
          )}
        </Stack>
      </Box>
    </Box>
  );
}
