import {
  CreatePolicyActionType,
  ReadPolicyActionType,
  Policy,
  ReadPoliciesActionType,
  UpdatePolicyActionType,
} from '../../../constants';
import { CreatePolicyAction, ReadPolicyAction, ReadPoliciesAction, UpdatePolicyAction } from '../../../actions';
import { QueryState } from '..';

export interface PolicyState extends QueryState {
  isFetching: boolean;
  policies: Policy[];
  error?: string;
  policy: Map<string, Policy>;
}

const initState: PolicyState = {
  isFetching: false,
  policies: [],
  policy: new Map<string, Policy>(),
  pageInfo: {
    total: 0,
  },
};

export function policy(
  state = initState,
  action: CreatePolicyAction | ReadPolicyAction | ReadPoliciesAction | UpdatePolicyAction,
): PolicyState {
  switch (action.type) {
    /** READ */
    case ReadPoliciesActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadPoliciesActionType.response:
      return {
        ...state,
        isFetching: false,
        policies: [...action.data],
        pageInfo: action.pageInfo,
      };
    case ReadPoliciesActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    case ReadPolicyActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadPolicyActionType.response:
      return {
        ...state,
        isFetching: false,
        policy: state.policy.set(action.data.id, action.data),
      };
    case ReadPolicyActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** CREATE */
    case CreatePolicyActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case CreatePolicyActionType.response:
      return {
        ...state,
        isFetching: false,
        policies: [action.data, ...state.policies],
        policy: state.policy.set(action.data.id, action.data),
      };
    case CreatePolicyActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** UPDATE */
    case UpdatePolicyActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case UpdatePolicyActionType.response:
      return {
        ...state,
        isFetching: false,
        policies: [...state.policies.map((user) => (user.id === action.data.id ? action.data : user))],
        policy: state.policy.set(action.data.id, action.data),
      };
    case UpdatePolicyActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    default:
      return state;
  }
}
