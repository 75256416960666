import {
  ReadUserVehiclesActionType,
  CreateUserVehicleActionType,
  DeleteUserVehiclesActionType,
  UserVehicleAttributes,
  ReadUserVehicleActionType,
  UpdateUserVehicleActionType,
} from '../../../constants';
import {
  ReadUserVehiclesAction,
  CreateUserVehicleAction,
  DeleteUserVehiclesAction,
  ReadUserVehicleAction,
} from '../../../actions';
import { QueryState } from '..';
import { UpdateUserVehicleAction } from '../../../actions/api/userVehicle/updateUserVehicle';

export interface UserLicensePlateState extends QueryState {
  isFetching: boolean;
  userLicensePlates: UserVehicleAttributes[];
  error?: string;
  userLicensePlate: UserVehicleAttributes;
}

const initState: UserLicensePlateState = {
  isFetching: false,
  userLicensePlates: [],
  userLicensePlate: undefined,
  pageInfo: {
    total: 0,
  },
};

export function userLicensePlate(
  state = initState,
  action:
    | CreateUserVehicleAction
    | ReadUserVehiclesAction
    | DeleteUserVehiclesAction
    | ReadUserVehicleAction
    | UpdateUserVehicleAction,
): UserLicensePlateState {
  switch (action.type) {
    /** READ */
    case ReadUserVehiclesActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadUserVehiclesActionType.response:
      return {
        ...state,
        isFetching: false,
        userLicensePlates: [...action.data],
        pageInfo: action.pageInfo,
      };
    case ReadUserVehiclesActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** CREATE */
    case CreateUserVehicleActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case CreateUserVehicleActionType.response:
      return {
        ...state,
        isFetching: false,
        userLicensePlates: [action.data, ...state.userLicensePlates],
        userLicensePlate: undefined,
      };
    case CreateUserVehicleActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** DELETE */
    case DeleteUserVehiclesActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case DeleteUserVehiclesActionType.response:
      const currentData = action.data.id
        ? state.userLicensePlates.filter((registration) => Number(registration.id) !== Number(action?.data?.id))
        : state.userLicensePlates;

      return {
        ...state,
        isFetching: false,
        userLicensePlates: currentData,
        userLicensePlate: undefined,
      };
    case DeleteUserVehiclesActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** READ */
    case ReadUserVehicleActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadUserVehicleActionType.response:
      return {
        ...state,
        isFetching: false,
        userLicensePlate: action.data,
      };
    case ReadUserVehicleActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** UPDATE */
    case UpdateUserVehicleActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case UpdateUserVehicleActionType.response:
      return {
        ...state,
        isFetching: false,
        userLicensePlates: [
          ...state.userLicensePlates.map((userLicensePlate) =>
            userLicensePlate.id === action.data.id ? action.data : userLicensePlate,
          ),
        ],
        userLicensePlate: action.data,
      };
    case UpdateUserVehicleActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    default:
      return state;
  }
}
