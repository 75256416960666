import { useEffect, useState } from 'react';
import { api } from '../middlewares/nura-helpers/lib/services/api';
import { getCompanyId } from '../middlewares/nura-helpers/lib/helpers/auth';
import { useSnackbar } from 'notistack';

export enum RoomFeatures {
  InPersonAttendees = 'InPersonAttendees',
}
export type RoomFeatureType = keyof typeof RoomFeatures;

export const useRoomFeatures = (resourceId?: number) => {
  const [features, setFeatures] = useState<{ id: number; name: string }[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();
  const hasFeature = (feat: RoomFeatureType) => {
    return features.filter((f) => f.name === feat).length > 0;
  };
  useEffect(() => {
    const getFeatures = async () => {
      try {
        const companyId = getCompanyId();
        setIsFetching(true);
        const { data: features } = await api(`/api/outlook/${companyId}/resource/${resourceId}/features`, {
          outlookId: true,
        }).get<{
          data: { id: number; name: string }[];
        }>();
        setFeatures(features);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
      setIsFetching(false);
    };

    if (!!resourceId) {
      getFeatures();
    }
  }, [resourceId]);

  return { isFetching, features, hasFeature };
};
