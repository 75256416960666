import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function PageLoading(): JSX.Element {
  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,
        background: 'rgba(0,0,0,0.3)',
      }}
      style={{ position: 'absolute', top: 0, left: 0, right: 0 }}
    >
      <CircularProgress />
    </Box>
  );
}
