import React, { useMemo, useState, useEffect, useRef } from 'react';
import { Box } from '@mui/system';
import { useNavigate, useParams } from 'react-router-dom';
import RoomCard from '../../components/RoomCard';
import ActionsBooking from './Actions';
import ServicesTabs from './ServicesTabs';
import PageLoading from '../../components/PageLoading';
import { useMailbox } from '../../contexts';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { ActionOutlookOfficeAddIn } from '../../middlewares/nura-client/lib/redux/actions';
import { useBookingDetails } from '../../contexts/BookingDetails';
import { RootState } from '../../middlewares/nura-client/lib/redux/reducers';
import { ReducerOutlookOfficeAddIn } from '../../middlewares/nura-client/lib/redux/reducers/api/officeAddIns';
import { getDataServiceTypes } from './Helpers';
import { AddInConstant } from '../../constants';
import { officeService } from '../../services/Office';
import DialogSuccessPage from '../../components/DialogSuccessPage';
import {
  EDayOfWeek,
  EMeetingRoomService,
  ERecurrencePatternType,
  ERecurrenceRangeType,
  IAttendees,
  ICreateBooking,
  IDeleteEventFromOutlookParams,
} from '../../middlewares/nura-client/lib/redux/constants';
import { addDays, addMinutes, addYears, differenceInHours, format, startOfDay } from 'date-fns';
import { roundToNearest30 } from '../../services/Format/Date';
import {
  ParamsReadMeetingRoomStatus,
  deleteMeetingRoomBookingFromOutlookAsync,
} from '../../middlewares/nura-client/lib/redux/actions/api/officeAddIns/outlook';
import { useAuthentication } from '../../providers/AuthProvider';
import DialogBookingPreviewPage from '../../components/DialogBookingPreviewPage';
import BookingPreview from './Preview';
import {
  REACT_APP_DELAY_FOR_ITEM_CLOSE_ACTION,
  REACT_APP_MAX_RETRIES_FOR_ITEM_CLOSE_ACTION,
} from '../../constants/AddIn';
import DialogBookingRecurrence from '../../components/DialogBookingRecurrence';
import { getRecurringEventsDateSeries } from '../../helpers';
import { useRoomFeatures, RoomFeatures } from '../../hooks/useRoomFeatures';
import { useLocationTyped } from '../../hooks/useLocationTyped';
import { validateStep, validateAllSteps } from './Helpers/formValidation';

// To Do => All the form should use any kind of form library such as Formik or react-form-hook,
// it will make the code more readable and easier to maintain
export default function BookingDetails(): JSX.Element {
  let navigate = useNavigate();

  const { resource, statusRoomSelected } = useSelector<RootState, ReducerOutlookOfficeAddIn.IOutlookAddInState>(
    ({ outlookAddIn }) => outlookAddIn,
  );
  const { officeUser } = useAuthentication();
  const location = useLocationTyped();
  const { hasFeature } = useRoomFeatures(Number(location.state.id));
  const { state: bookingState, dispatch: bookingDispatch } = useBookingDetails();
  const { recurringEvents } = bookingState;
  const { state: mailboxState, dispatch: mailboxDispatch } = useMailbox();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const { id } = useParams();
  // const location = useLocation();
  const [success, setSuccess] = useState<boolean>(false);
  const [isCloseLoading, setIsCloseLoading] = useState<boolean>(false);
  const [openPreviewDialog, setOpenPreviewDialog] = useState<boolean>(false);
  const [restIdDeleteEvent, setRestIdDeleteEvent] = useState<string>();
  const [reloadDeleteSaveItem, setReloadDeleteSaveItem] = useState<Date>(undefined);
  const [linkedRoomNames, setLinkedRoomNames] = useState<string[]>([]);

  const countRequest = useRef<number>(0);
  const deleteSaveDataTimeOut = useRef<NodeJS.Timer>();

  const clearItemAfterCloseAction = () => {
    countRequest.current = 0;
    setSuccess(false);
    setIsCloseLoading(false);
    setRestIdDeleteEvent(undefined);
    setReloadDeleteSaveItem(undefined);
    setLinkedRoomNames([]);
    /** 2. close windows */
    Office.context.mailbox.item.close();
  };

  useEffect(() => {
    if (recurringEvents) {
      const { dates: dateSeries } = getRecurringEventsDateSeries({
        state: bookingState,
        type: recurringEvents.selectedUIType,
      });
      bookingDispatch({
        type: 'setEventsDateSeries',
        payload: { recurringEventsDateSeries: dateSeries },
      });
    }
  }, [recurringEvents]);

  const tryToCloseWindow = (newParams: Pick<IDeleteEventFromOutlookParams, 'accessToken' | 'organizer' | 'id'>) => {
    deleteSaveDataTimeOut.current = setTimeout(async () => {
      countRequest.current += 1;

      if (countRequest.current < REACT_APP_MAX_RETRIES_FOR_ITEM_CLOSE_ACTION) {
        const { data } = await deleteMeetingRoomBookingFromOutlookAsync(newParams);
        //If return true
        if (data?.isDeleted) {
          clearItemAfterCloseAction();
        } else {
          tryToCloseWindow(newParams);
        }
      } else {
        countRequest.current = 0;
        setSuccess(false);
        setIsCloseLoading(false);
        setRestIdDeleteEvent(undefined);
        setReloadDeleteSaveItem(undefined);
        setLinkedRoomNames([]);
        enqueueSnackbar(`You may now close this window manually - do not save as draft.`, {
          variant: 'info',
          autoHideDuration: 2000,
        });
      }
    }, REACT_APP_DELAY_FOR_ITEM_CLOSE_ACTION * countRequest.current);
  };

  const deleteEventByRestId = async (newId: string) => {
    try {
      const userAccessToken = await officeService.getAccessToken();
      const organizer = officeUser?.email;
      if (userAccessToken && userAccessToken) {
        const newParams: Pick<IDeleteEventFromOutlookParams, 'accessToken' | 'organizer' | 'id'> = {
          id: newId,
          accessToken: userAccessToken,
          organizer: organizer,
        };
        tryToCloseWindow(newParams);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (restIdDeleteEvent && reloadDeleteSaveItem) {
      deleteEventByRestId(restIdDeleteEvent);
    }

    return () => {
      if (deleteSaveDataTimeOut.current) {
        clearTimeout(deleteSaveDataTimeOut.current);
        deleteSaveDataTimeOut.current = undefined;
        countRequest.current = 0;
      }
    };
  }, [restIdDeleteEvent, reloadDeleteSaveItem, countRequest]);

  //Fetching Schedule
  const fetchRoomSchedule = async () => {
    try {
      /** Recurring Events */

      const params: ParamsReadMeetingRoomStatus = {
        companyId: undefined,
        accessToken: undefined,
        address: undefined,
        startDate: undefined,
        endDate: undefined,
        recurringEvents: {
          pattern: {
            type: undefined,
            interval: undefined,
            daysOfWeek: undefined,
            dayOfMonth: undefined,
            index: undefined,
            month: undefined,
          },
          range: {
            type: undefined,
            startDate: undefined,
            endDate: undefined,
            numberOfOccurrences: undefined,
          },
        },
      };

      if (bookingState?.recurringEvents?.selectedUIType) {
        const {
          daily: dailyPattern,
          weekly: weeklyPattern,
          absoluteMonthly,
          absoluteYearly,
          range,
          relativeMonthly,
          relativeYearly,
        } = bookingState?.recurringEvents;
        const recurringEventsParam = params.recurringEvents;
        //DAILY
        if (
          dailyPattern?.pattern?.type === ERecurrencePatternType.DAILY &&
          dailyPattern?.pattern?.type &&
          dailyPattern?.pattern?.interval
        ) {
          const { type, interval } = dailyPattern.pattern;
          recurringEventsParam.pattern.type = type;
          recurringEventsParam.pattern.interval = interval;
        }
        //WEEKLY
        if (
          weeklyPattern?.pattern?.type === ERecurrencePatternType.WEEKLY &&
          weeklyPattern?.pattern?.type &&
          weeklyPattern?.pattern?.interval &&
          weeklyPattern?.pattern?.daysOfWeek
        ) {
          const { type, interval, daysOfWeek } = weeklyPattern.pattern;
          recurringEventsParam.pattern.type = type;
          recurringEventsParam.pattern.interval = interval;
          recurringEventsParam.pattern.daysOfWeek = daysOfWeek;
        }
        //Absolute Monthly
        if (
          absoluteMonthly?.pattern?.type &&
          absoluteMonthly?.pattern?.interval &&
          absoluteMonthly?.pattern?.dayOfMonth
        ) {
          const { dayOfMonth, interval, type } = absoluteMonthly?.pattern;
          recurringEventsParam.pattern.type = type;
          recurringEventsParam.pattern.interval = interval;
          recurringEventsParam.pattern.dayOfMonth = dayOfMonth;
        }
        //Absolute Yearly
        if (
          absoluteYearly?.pattern?.type &&
          absoluteYearly?.pattern?.interval &&
          absoluteYearly?.pattern?.dayOfMonth &&
          absoluteYearly?.pattern?.month
        ) {
          const { dayOfMonth, month, interval, type } = absoluteYearly?.pattern;
          recurringEventsParam.pattern.type = type;
          recurringEventsParam.pattern.interval = interval;
          recurringEventsParam.pattern.month = month;
          recurringEventsParam.pattern.dayOfMonth = dayOfMonth;
        }

        if (
          relativeMonthly?.pattern?.index &&
          relativeMonthly?.pattern?.daysOfWeek &&
          relativeMonthly?.pattern?.interval &&
          relativeMonthly?.pattern?.type
        ) {
          const { index, interval, type } = relativeMonthly.pattern;
          recurringEventsParam.pattern.type = type;
          recurringEventsParam.pattern.interval = interval;
          recurringEventsParam.pattern.index = index;
          if (range?.startDate) {
            const getDayOfWeek = format(new Date(range.startDate), 'EEEE').toUpperCase();
            if (EDayOfWeek[getDayOfWeek]) {
              recurringEventsParam.pattern.daysOfWeek = EDayOfWeek[getDayOfWeek];
            }
          }
        }

        if (
          relativeYearly?.pattern?.daysOfWeek &&
          relativeYearly?.pattern?.index &&
          relativeYearly?.pattern?.interval &&
          relativeYearly?.pattern?.month &&
          relativeYearly?.pattern?.type
        ) {
          const { index, interval, type, month } = relativeYearly.pattern;
          recurringEventsParam.pattern.type = type;
          recurringEventsParam.pattern.interval = interval;
          recurringEventsParam.pattern.index = index;
          recurringEventsParam.pattern.month = month;
          if (range?.startDate) {
            const getDayOfWeek = format(new Date(range.startDate), 'EEEE').toUpperCase();
            if (EDayOfWeek[getDayOfWeek]) {
              recurringEventsParam.pattern.daysOfWeek = EDayOfWeek[getDayOfWeek];
            }
          }
        }

        if (range?.startDate && range?.endDate && range?.type) {
          recurringEventsParam.range = range;
          const dateRangeStart = new Date(range.startDate);
          dateRangeStart.setHours(new Date(mailboxState.start).getHours());
          dateRangeStart.setMinutes(new Date(mailboxState.start).getMinutes());
          dateRangeStart.setSeconds(0);
          dateRangeStart.setMilliseconds(0);
          const dateRangeEnd = new Date(range.endDate);
          dateRangeEnd.setHours(new Date(mailboxState.end).getHours());
          dateRangeEnd.setMinutes(new Date(mailboxState.end).getMinutes());
          dateRangeEnd.setSeconds(0);
          dateRangeEnd.setMilliseconds(0);

          recurringEventsParam.range.startDate = dateRangeStart?.toISOString();
          recurringEventsParam.range.endDate = dateRangeEnd?.toISOString();
          if (range.type === ERecurrenceRangeType.END_DATE) recurringEventsParam.range.numberOfOccurrences = 0;
        }

        params.recurringEvents = recurringEventsParam;
      }
      /** End of recurring events */

      const userAccessToken = await officeService.getAccessToken();
      if (userAccessToken && mailboxState?.start && mailboxState?.end && resource?.emailAddress && !success) {
        params.accessToken = userAccessToken;
        params.address = resource.emailAddress;
        params.startDate = mailboxState.start;
        params.endDate = mailboxState.end;

        await dispatch(ActionOutlookOfficeAddIn.readMeetingRoomScheduleForOutlook(params));
      }
    } catch (error) {
      enqueueSnackbar(`Error: ${error?.message}`, { variant: 'error', autoHideDuration: 2000 });
    }
  };

  useEffect(() => {
    const pathName = location?.pathname;
    const pathNameSplit = pathName?.split('/');
    if (pathNameSplit?.length > 1 && resource?.id && id) {
      if (pathNameSplit[1] === 'booking' && Number(id) === Number(resource?.id)) {
        fetchRoomSchedule();
      }
    }
  }, [resource, id, location]);

  /** Busy */
  useEffect(() => {
    if (!statusRoomSelected && resource) {
      enqueueSnackbar(`This room is not available. Please select another times.`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  }, [statusRoomSelected, resource]);

  //Fetching Resource Data
  useEffect(() => {
    const fetchData = async (id: string) => {
      try {
        await dispatch(ActionOutlookOfficeAddIn.readResourceForRoomBooking({ id }));
      } catch (error) {
        enqueueSnackbar(`Error: ${error?.message}`, { variant: 'error', autoHideDuration: 2000 });
      }
    };

    if (id) {
      fetchData(id);
    }
  }, [id]);

  useEffect(() => {
    Office.onReady(async () => {
      const getMailBoxLocation = async (item: any) => {
        // currently has to be one string for locations separated with ; - no option for an array
        await Office.context.mailbox.item.location.setAsync(`${item.name}; Microsoft Teams Meeting`); // when the MS teams toggle is created in our extension will have to dynamically add this
      };
      const item: any = location.state;
      if (item) {
        getMailBoxLocation(item);
      }
    });
  }, [location?.state]);

  const renderRoomDetail = useMemo(() => {
    if (!location.state) return null;

    const item: any = location.state;
    let currentCapacity = item?.capacity;
    if (resource?.listLinkRooms?.length) {
      bookingState?.selectedLinkRoom?.map((roomSelected) => {
        if (roomSelected?.capacity) {
          currentCapacity += Number(roomSelected?.capacity);
        }
        return roomSelected;
      });
    }

    return (
      <RoomCard
        services={item.services}
        name={item.name}
        start={mailboxState.start}
        end={mailboxState.end}
        building={item.building.name}
        floor={item.floor.name}
        selected={true}
        capacity={currentCapacity}
      />
    );
  }, [location.state, mailboxState.start, mailboxState.end, resource, bookingState?.selectedLinkRoom]);

  const handleNextButton = (value: number) => {
    const item: any = location.state;
    const tabValue = bookingState.tabValue;
    let newTabValue = tabValue + value;
    const { isValid, message: messageValidation } = validateStep({
      tabValue,
      data: { mailboxState, bookingState },
      options: {
        validateInPersonAttendeesField: hasFeature(RoomFeatures.InPersonAttendees),
      },
    });
    if (!isValid) {
      enqueueSnackbar(messageValidation, { variant: 'error', autoHideDuration: 2000 });
      return;
    }
    if (newTabValue < 0) newTabValue = 0;
    bookingDispatch({
      type: 'setTabValue',
      payload: { tabValue: newTabValue > item.services.length ? item.services.length : newTabValue },
    });
  };

  const handlesMailBoxClearItem = async () => {
    await Office.context.mailbox.item.subject.setAsync('');
    await Office.context.mailbox.item.location.setAsync('');
    await Office.context.mailbox.item.body.setAsync('');
    await Office.context.mailbox.item.requiredAttendees.setAsync([], (asyncResult: Office.AsyncResult<void>) => {
      if (asyncResult.error) enqueueSnackbar(`Clear required attendees.`, { variant: 'error' });
    });
    await Office.context.mailbox.item.optionalAttendees.setAsync([], (asyncResult: Office.AsyncResult<void>) => {
      if (asyncResult.error) enqueueSnackbar(`Clear optional attendees.`, { variant: 'error' });
    });
    const defaultStartTime = roundToNearest30(new Date());
    const defaultEndTime = addMinutes(defaultStartTime, 30);
    if (defaultStartTime && defaultEndTime) {
      await Office.context.mailbox.item.start.setAsync(
        defaultStartTime,
        async (asyncResult: Office.AsyncResult<void>) => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            await Office.context.mailbox.item.end.setAsync(
              defaultEndTime,
              (asyncEndResult: Office.AsyncResult<void>) => {
                if (asyncEndResult.error) enqueueSnackbar(`End date.`, { variant: 'error' });
                if (asyncEndResult.status === Office.AsyncResultStatus.Succeeded) handleClickCloseEvent(false);
              },
            );
          } else {
            enqueueSnackbar(`Start date.`, { variant: 'error' });
          }
        },
      );
    }
  };

  const onHandleSubmit = async () => {
    try {
      setLoading(true);

      //Setup Params
      const userAccessToken = await officeService.getAccessToken();
      if (!userAccessToken) {
        enqueueSnackbar(`Error: Check the token.`, { variant: 'error' });
        return;
      }
      const currentRoom: any = location.state;

      const params: ICreateBooking = {
        body: '',
        address: undefined,
        facilities: {
          noChairsSelected: undefined,
          noTablesSelected: undefined,
          cleanDown: undefined,
          setup: undefined,
          notes: undefined,
          equipment: [],
        },
        it: {
          notes: undefined,
          equipment: [],
        },
        catering: {
          dietaries: undefined,
          costCentre: undefined,
          notes: undefined,
          foods: [],
        },
        general: {
          notes: undefined,
          comments: undefined,
          behalfOfSomeoneElse: undefined,
          inPersonAttendees: undefined,
        },
        organizer: undefined,
        linkRoom: undefined,
        recurringEvents: {
          pattern: {
            type: undefined,
            interval: undefined,
            daysOfWeek: undefined,
            dayOfMonth: undefined,
            index: undefined,
            month: undefined,
          },
          range: {
            type: undefined,
            startDate: undefined,
            endDate: undefined,
            numberOfOccurrences: undefined,
          },
        },
      };

      /** Recurring Events */

      if (bookingState?.recurringEvents?.selectedUIType) {
        const {
          daily: dailyPattern,
          weekly: weeklyPattern,
          absoluteMonthly,
          absoluteYearly,
          range,
          relativeMonthly,
          relativeYearly,
        } = bookingState?.recurringEvents;
        const recurringEventsParam = params.recurringEvents;
        //DAILY
        if (
          dailyPattern?.pattern?.type === ERecurrencePatternType.DAILY &&
          dailyPattern?.pattern?.type &&
          dailyPattern?.pattern?.interval
        ) {
          const { type, interval } = dailyPattern.pattern;
          recurringEventsParam.pattern.type = type;
          recurringEventsParam.pattern.interval = interval;
        }
        //WEEKLY
        if (
          weeklyPattern?.pattern?.type === ERecurrencePatternType.WEEKLY &&
          weeklyPattern?.pattern?.type &&
          weeklyPattern?.pattern?.interval &&
          weeklyPattern?.pattern?.daysOfWeek
        ) {
          const { type, interval, daysOfWeek } = weeklyPattern.pattern;
          recurringEventsParam.pattern.type = type;
          recurringEventsParam.pattern.interval = interval;
          recurringEventsParam.pattern.daysOfWeek = daysOfWeek;
        }
        //Absolute Monthly
        if (
          absoluteMonthly?.pattern?.type &&
          absoluteMonthly?.pattern?.interval &&
          absoluteMonthly?.pattern?.dayOfMonth
        ) {
          const { dayOfMonth, interval, type } = absoluteMonthly?.pattern;
          recurringEventsParam.pattern.type = type;
          recurringEventsParam.pattern.interval = interval;
          recurringEventsParam.pattern.dayOfMonth = dayOfMonth;
        }
        //Absolute Yearly
        if (
          absoluteYearly?.pattern?.type &&
          absoluteYearly?.pattern?.interval &&
          absoluteYearly?.pattern?.dayOfMonth &&
          absoluteYearly?.pattern?.month
        ) {
          const { dayOfMonth, month, interval, type } = absoluteYearly?.pattern;
          recurringEventsParam.pattern.type = type;
          recurringEventsParam.pattern.interval = interval;
          recurringEventsParam.pattern.month = month;
          recurringEventsParam.pattern.dayOfMonth = dayOfMonth;
        }

        if (
          relativeMonthly?.pattern?.index &&
          relativeMonthly?.pattern?.daysOfWeek &&
          relativeMonthly?.pattern?.interval &&
          relativeMonthly?.pattern?.type
        ) {
          const { index, interval, type } = relativeMonthly.pattern;
          recurringEventsParam.pattern.type = type;
          recurringEventsParam.pattern.interval = interval;
          recurringEventsParam.pattern.index = index;
          if (range?.startDate) {
            const getDayOfWeek = format(new Date(range.startDate), 'EEEE').toUpperCase();
            if (EDayOfWeek[getDayOfWeek]) {
              recurringEventsParam.pattern.daysOfWeek = EDayOfWeek[getDayOfWeek];
            }
          }
        }

        if (
          relativeYearly?.pattern?.daysOfWeek &&
          relativeYearly?.pattern?.index &&
          relativeYearly?.pattern?.interval &&
          relativeYearly?.pattern?.month &&
          relativeYearly?.pattern?.type
        ) {
          const { index, interval, type, month } = relativeYearly.pattern;
          recurringEventsParam.pattern.type = type;
          recurringEventsParam.pattern.interval = interval;
          recurringEventsParam.pattern.index = index;
          recurringEventsParam.pattern.month = month;
          if (range?.startDate) {
            const getDayOfWeek = format(new Date(range.startDate), 'EEEE').toUpperCase();
            if (EDayOfWeek[getDayOfWeek]) {
              recurringEventsParam.pattern.daysOfWeek = EDayOfWeek[getDayOfWeek];
            }
          }
        }

        if (range?.startDate && range?.endDate && range?.type) {
          recurringEventsParam.range = range;
          const dateRangeStart = new Date(range.startDate);
          dateRangeStart.setHours(new Date(mailboxState.start).getHours());
          dateRangeStart.setMinutes(new Date(mailboxState.start).getMinutes());
          dateRangeStart.setSeconds(0);
          dateRangeStart.setMilliseconds(0);
          const dateRangeEnd = new Date(range.endDate);
          dateRangeEnd.setHours(new Date(mailboxState.end).getHours());
          dateRangeEnd.setMinutes(new Date(mailboxState.end).getMinutes());
          dateRangeEnd.setSeconds(0);
          dateRangeEnd.setMilliseconds(0);

          recurringEventsParam.range.startDate = dateRangeStart?.toISOString();
          recurringEventsParam.range.endDate = dateRangeEnd?.toISOString();
          if (range.type === ERecurrenceRangeType.END_DATE) recurringEventsParam.range.numberOfOccurrences = 0;
        }

        params.recurringEvents = recurringEventsParam;
      }
      /** End of recurring events */

      await Office.context.mailbox.item.body.getAsync(Office.CoercionType.Html, async (bodyResult) => {
        if (bodyResult.status === Office.AsyncResultStatus.Succeeded) {
          params.body = bodyResult.value;
          /** Get Subject */
          await Office.context.mailbox.item.subject.getAsync(async (asyncResult: Office.AsyncResult<string>) => {
            if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
              const { isValid, messages } = validateAllSteps({
                data: { mailboxState, bookingState },
                options: {
                  validateInPersonAttendeesField: hasFeature(RoomFeatures.InPersonAttendees),
                },
              });
              if (!isValid) {
                setLoading(false);
                enqueueSnackbar(messages, { variant: 'error', autoHideDuration: 2000 });
                return;
              }
              params.general.notes = asyncResult.value;
              //TO DO => Use Yup for validation
              /** After get Subject from outlook */
              params.address = resource.emailAddress;
              params.accessToken = userAccessToken;
              params.roomId = currentRoom?.id;
              params.building = currentRoom?.building?.id;
              params.floor = currentRoom?.floor?.id;

              params.start = mailboxState.start ? new Date(mailboxState.start).toISOString() : undefined;
              params.end = mailboxState.end ? new Date(mailboxState.end).toISOString() : undefined;

              const checkDurationsAllDay = differenceInHours(new Date(mailboxState.end), new Date(mailboxState.start));

              if (Number(checkDurationsAllDay) === 24) {
                const startBookingOfToday = startOfDay(new Date(mailboxState.start));
                const endBookingOfToday = addDays(startBookingOfToday, 1);
                if (startBookingOfToday && endBookingOfToday) {
                  params.start = startBookingOfToday.toISOString();
                  params.end = endBookingOfToday.toISOString();
                }
              }

              params.currentDate = new Date().toISOString();

              if (!params.general.notes) {
                setLoading(false);
                enqueueSnackbar(`Subject is required.`, { variant: 'error', autoHideDuration: 2000 });
                return;
              }

              if (
                !params.address ||
                !params.roomId ||
                !params.building ||
                !params.floor ||
                !params.start ||
                !params.end
              ) {
                setLoading(false);
                enqueueSnackbar(`Check the parameters.`, { variant: 'error', autoHideDuration: 2000 });
                return;
              }

              if (
                hasFeature(RoomFeatures.InPersonAttendees) &&
                typeof bookingState.general.inPersonAttendees !== 'number'
              ) {
                setLoading(false);
                enqueueSnackbar(`In person attendees is required.`, { variant: 'error', autoHideDuration: 2000 });
                return;
              }

              const attendeesRequired: IAttendees[] = mailboxState.requiredAttendees?.map((itemRequired) => {
                return {
                  emailAddress: {
                    address: itemRequired?.emailAddress,
                    name: itemRequired?.displayName,
                  },
                  type: 'required',
                };
              });
              const attendeesOptionals: IAttendees[] = mailboxState.optionalAttendees?.map((itemRequired) => {
                return {
                  emailAddress: {
                    address: itemRequired?.emailAddress,
                    name: itemRequired?.displayName,
                  },
                  type: 'optional',
                };
              });
              const attendees: IAttendees[] = [...attendeesRequired, ...attendeesOptionals];

              if (attendees?.length > 0) {
                params.attendees = attendees;
              }

              if (bookingState.general.comments) {
                params.general.comments = bookingState.general.comments;
              }

              if (typeof bookingState.general?.inPersonAttendees === 'number') {
                params.general.inPersonAttendees = bookingState.general.inPersonAttendees;
              }

              if (bookingState?.organizer?.mail) {
                params.organizer = bookingState.organizer.mail;
              }

              if (bookingState?.selectedLinkRoom?.length > 0) {
                const linksRoomIds = bookingState?.selectedLinkRoom?.map((selectedRoom) =>
                  selectedRoom?.id?.toString(),
                );

                params.linkRoom = linksRoomIds?.length > 0 ? linksRoomIds.join(',') : undefined;
                const linksRoomNames = bookingState?.selectedLinkRoom?.map((selectedRoom) => selectedRoom?.name);
                setLinkedRoomNames(linksRoomNames);
              }

              //1. Facilities
              // 1.1 noChairs
              if (bookingState.facilities?.noChairs) {
                const noChairsService = getDataServiceTypes({
                  data: resource,
                  service: EMeetingRoomService.FACILITIES,
                  type: AddInConstant.EServiceType.NO_CHAIRS,
                });

                params.facilities.noChairsSelected = {
                  id: `${noChairsService.id}`,
                  value: bookingState.facilities.noChairs,
                };
              }

              // 1.2 noTables
              if (bookingState.facilities?.noTables) {
                const noTableService = getDataServiceTypes({
                  data: resource,
                  service: EMeetingRoomService.FACILITIES,
                  type: AddInConstant.EServiceType.NO_TABLES,
                });
                params.facilities.noTablesSelected = {
                  id: `${noTableService.id}`,
                  value: bookingState.facilities.noTables,
                };
              }

              // 1.3 cleanDown
              if (bookingState.facilities?.cleanDown?.value) {
                const cleanDown = getDataServiceTypes({
                  data: resource,
                  service: EMeetingRoomService.FACILITIES,
                  type: AddInConstant.EServiceType.CLEAN_DOWN,
                });
                params.facilities.cleanDown = {
                  id: `${cleanDown.id}`,
                  value: bookingState.facilities.cleanDown.value,
                };
              }

              // 1.4 set up
              if (bookingState.facilities?.setup?.value) {
                const setUp = getDataServiceTypes({
                  data: resource,
                  service: EMeetingRoomService.FACILITIES,
                  type: AddInConstant.EServiceType.SET_UP,
                });
                params.facilities.setup = {
                  id: `${setUp.id}`,
                  value: bookingState.facilities.setup.value,
                };
              }

              // 1.5 equipment
              if (bookingState.facilities?.equipment.length > 0) {
                params.facilities.equipment = bookingState.facilities.equipment?.map(({ id }) => {
                  return {
                    id: `${id}`,
                    value: 'true',
                  };
                });
              }

              // 1.5 equipment
              if (bookingState.facilities?.notes) {
                params.facilities.notes = bookingState.facilities?.notes;
              }

              //2. IT
              // 2.1 equipment
              if (bookingState.it?.equipment.length > 0) {
                params.it.equipment = bookingState.it.equipment?.map(({ id }) => {
                  return {
                    id: `${id}`,
                    value: 'true',
                  };
                });
              }

              // 2.2 notes
              if (bookingState.it?.notes) {
                params.it.notes = bookingState.it.notes;
              }

              //3 Catering
              if (bookingState.catering.costCentreId) {
                params.catering.costCentre = bookingState.catering.costCentre;
                params.catering.costCentreId = bookingState.catering.costCentreId;
              }
              if (bookingState.catering.notes) {
                params.catering.notes = bookingState.catering.notes;
              }

              if (bookingState.catering.dietaries) {
                params.catering.dietaries = bookingState.catering.dietaries;
              }
              if (bookingState.catering.cateringMenus.length > 0) {
                if (!params.catering.costCentre) {
                  enqueueSnackbar(`Cost centre is required.`, { variant: 'error', autoHideDuration: 2000 });
                  return;
                }

                bookingState.catering.cateringMenus?.map((itemMenu) => {
                  let newDeliveryDateTime = new Date()?.toISOString();
                  if (itemMenu?.deliveredAt && mailboxState?.start) {
                    const bookingStartDate = mailboxState?.start
                      ? format(new Date(mailboxState.start), 'MM/dd/yyyy')
                      : undefined;
                    newDeliveryDateTime = new Date(`${bookingStartDate} ${itemMenu.deliveredAt}`)?.toISOString();
                  }

                  itemMenu.foodItems.map((itemFood) => {
                    params.catering.foods.push({
                      id: `${itemFood}`,
                      quantity: itemMenu.quantity,
                      deliveredAt: newDeliveryDateTime,
                    });

                    return itemFood;
                  });
                  return itemMenu;
                });
              }

              try {
                //Need undo
                await dispatch(ActionOutlookOfficeAddIn.createMeetingRoomBookingFromOutlook(params));
                mailboxDispatch({ type: 'setDefault', payload: {} });
                bookingDispatch({ type: 'clear', payload: {} });

                await handlesMailBoxClearItem();
                // end need undo

                setSuccess(true);
                setLoading(false);
              } catch (error) {
                setLoading(false);
                setSuccess(false);
                enqueueSnackbar(`Error: ${error?.message}`, { variant: 'error' });
              }
            } else {
              setLoading(false);
              enqueueSnackbar(`Subject is required.`, { variant: 'error', autoHideDuration: 2000 });
              return;
            }
          });
        }
      });
    } catch (error) {
      setLoading(false);
      setSuccess(false);
      enqueueSnackbar(`Error: ${error?.message}`, { variant: 'error' });
    }
  };

  const handleClickContinue = (value: boolean) => {
    setSuccess(value);
    setLoading(false);
    navigate('/bookings', { replace: true });
  };

  const handleClickOpen = (value: boolean) => {
    setSuccess(value);
    setLoading(false);
    navigate('/bookings', { replace: true });
  };

  const handleClickCloseEvent = async (value: boolean) => {
    try {
      setIsCloseLoading(true);
      const userAccessToken = await officeService.getAccessToken();
      const organizer = officeUser?.email;
      /**
       * For closing the windows
       * 1. Save  */
      const defaultStartTime = roundToNearest30(addYears(new Date(), 10));
      const defaultEndTime = addMinutes(defaultStartTime, 30);
      if (defaultStartTime && defaultEndTime) {
        await Office.context.mailbox.item.start.setAsync(
          defaultStartTime,
          async (asyncResult: Office.AsyncResult<void>) => {
            if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
              await Office.context.mailbox.item.end.setAsync(
                defaultEndTime,
                async (asyncEndResult: Office.AsyncResult<void>) => {
                  if (asyncEndResult.error) enqueueSnackbar(`End date.`, { variant: 'error' });
                  if (asyncEndResult.status === Office.AsyncResultStatus.Succeeded) {
                    await Office.context.mailbox.item.saveAsync(async function (resultSave) {
                      if (resultSave.status === Office.AsyncResultStatus.Succeeded) {
                        if (userAccessToken && resultSave.value && organizer) {
                          const ewsId = resultSave.value;
                          const restId = Office.context.mailbox.convertToRestId(
                            ewsId,
                            Office.MailboxEnums.RestVersion.v1_0,
                          );
                          if (restId) {
                            setRestIdDeleteEvent(restId);
                            setReloadDeleteSaveItem(new Date());
                          }
                        }
                      } else {
                        setIsCloseLoading(false);
                        if (resultSave.error.message)
                          enqueueSnackbar(`saveAsync failed with message ${resultSave.error.message}`, {
                            variant: 'error',
                          });
                      }
                    });
                  } else {
                    if (asyncEndResult.error.message)
                      enqueueSnackbar(`saveAsync failed with message ${asyncEndResult.error.message}`, {
                        variant: 'error',
                      });
                  }
                },
              );
            } else {
              setIsCloseLoading(false);
              enqueueSnackbar(`Start date.`, { variant: 'error' });
            }
          },
        );
      }
    } catch (error) {
      setIsCloseLoading(false);
      if (error?.message) enqueueSnackbar(`saveAsync failed with message ${error?.message}`, { variant: 'error' });
    }
  };

  const onHandlePreview = async (newValue: boolean) => {
    const { isValid, messages } = validateAllSteps({
      data: { mailboxState, bookingState },
      options: {
        validateInPersonAttendeesField: hasFeature(RoomFeatures.InPersonAttendees),
      },
    });
    if (!isValid) {
      enqueueSnackbar(messages[0], { variant: 'error', autoHideDuration: 2000 });
      return;
    }

    if (hasFeature(RoomFeatures.InPersonAttendees) && typeof bookingState?.general?.inPersonAttendees !== 'number') {
      setLoading(false);
      enqueueSnackbar(`In person attendees is required.`, { variant: 'error', autoHideDuration: 2000 });
      return;
    }

    await Office.context.mailbox.item.subject.getAsync(async (asyncResult: Office.AsyncResult<string>) => {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        if (asyncResult.value) {
          setOpenPreviewDialog(newValue);
        } else {
          enqueueSnackbar(`Subject is required.`, { variant: 'error', autoHideDuration: 2000 });
          setOpenPreviewDialog(false);
        }
      }
    });
  };

  const handleSendOnPreview = async () => {
    setOpenPreviewDialog(false);
    onHandleSubmit();
  };

  const renderSuccessPage = useMemo(() => {
    let textMessage = `Room ${resource?.name} has been successfully booked.`;
    if (linkedRoomNames?.length > 0) {
      textMessage = `Room ${resource?.name}, ${linkedRoomNames?.join(', ')} has been successfully booked.`;
    }

    return (
      <DialogSuccessPage
        textMessage={textMessage}
        open={success}
        handleClickContinue={handleClickContinue}
        handleClickCloseEvent={handleClickCloseEvent}
        handleClickOpen={handleClickOpen}
        loading={isCloseLoading}
      />
    );
  }, [resource, isCloseLoading, success, linkedRoomNames]);

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      {renderSuccessPage}
      {loading && <PageLoading />}
      {renderRoomDetail}
      <ServicesTabs />
      <ActionsBooking
        loading={loading}
        tabValue={bookingState.tabValue}
        onSubmit={onHandleSubmit}
        onPreview={onHandlePreview}
        onNexButton={handleNextButton}
      />

      <DialogBookingPreviewPage
        open={openPreviewDialog}
        handleSendOnPreview={handleSendOnPreview}
        handleClickOpen={onHandlePreview}
        children={<BookingPreview />}
      />

      <DialogBookingRecurrence />
    </Box>
  );
}
