import React, { useEffect, useMemo } from 'react';
import { Box, Stack, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { sxCustom } from '../styles';
import BookingOrganizer from './Organizer';
import { useMailbox } from '../../../contexts';
import { useBookingDetails } from '../../../contexts/BookingDetails';
import LinkRoom from './LinkRoom';
import { useRoomFeatures, RoomFeatures } from '../../../hooks/useRoomFeatures';
import { useLocationTyped } from '../../../hooks/useLocationTyped';

export default function GeneralInfo(): JSX.Element {
  const { state: mailboxState, dispatch: mailboxDispatch } = useMailbox();
  const { state: bookingState, dispatch: bookingDispatch } = useBookingDetails();
  const location = useLocationTyped();
  const { hasFeature } = useRoomFeatures(Number(location.state.id));
  const maxCapacity: number | null = location?.state?.capacity ?? null;
  const hasInPersonAttendees = hasFeature(RoomFeatures.InPersonAttendees);

  // Check Subject when tab is changed
  const getSubject = async () => {
    await Office.context.mailbox.item.subject.getAsync((asyncResult: Office.AsyncResult<string>) => {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        if (!mailboxState.subject || mailboxState.subject !== asyncResult.value)
          mailboxDispatch({
            type: 'setSubject',
            payload: {
              subject: asyncResult.value,
            },
          });
      }
    });
  };

  useEffect(() => {
    if (bookingState.tabValue) {
      getSubject();
    }
  }, [bookingState.tabValue]);

  // Get mailbox for subject
  const getAttendees = async () => {
    await Office.context.mailbox.item.requiredAttendees.getAsync(
      (asyncResult: Office.AsyncResult<Office.EmailAddressDetails[]>) => {
        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
          mailboxDispatch({
            type: 'setRequiredAttendees',
            payload: {
              requiredAttendees: asyncResult.value,
            },
          });
        }
      },
    );
    await Office.context.mailbox.item.optionalAttendees.getAsync(
      (asyncResult: Office.AsyncResult<Office.EmailAddressDetails[]>) => {
        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
          mailboxDispatch({
            type: 'setOptionalAttendees',
            payload: {
              optionalAttendees: asyncResult.value,
            },
          });
        }
      },
    );
  };

  useEffect(() => {
    getSubject();
    getAttendees();
  }, [bookingState.tabValue]);

  const renderComments = useMemo(() => {
    //Handle Comment  and set into booking details providers
    const handleComments = async (event: React.ChangeEvent<HTMLInputElement>) => {
      bookingDispatch({
        type: 'setGeneral',
        payload: {
          general: {
            ...bookingState.general,
            comments: event.target.value,
          },
        },
      });
    };
    const handleOnFocusSubject = async () => {
      await Office.context.mailbox.item.subject.getAsync((asyncResult: Office.AsyncResult<string>) => {
        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
          if (!mailboxState.subject || mailboxState.subject !== asyncResult.value)
            mailboxDispatch({
              type: 'setSubject',
              payload: {
                subject: asyncResult.value,
              },
            });
        }
      });
    };
    return (
      <TextField
        id="comments-outlined-multiline-flexible"
        label="Comments"
        name="comments"
        placeholder="Enter a comment"
        multiline
        maxRows={4}
        value={bookingState.general.comments || ''}
        onChange={handleComments}
        InputLabelProps={{ shrink: true }}
        fullWidth
        onFocus={handleOnFocusSubject}
      />
    );
  }, [bookingState.general, bookingDispatch, mailboxState.subject, mailboxDispatch]);

  const renderSubject = useMemo(() => {
    //Set Mailbox
    const handleSubject = async (event: React.ChangeEvent<HTMLInputElement>) => {
      mailboxDispatch({
        type: 'setSubject',
        payload: {
          subject: event.target.value,
        },
      });
      await Office.context.mailbox.item.subject.setAsync(event.target.value);
    };

    const handleOnFocusSubject = async () => {
      await Office.context.mailbox.item.subject.getAsync((asyncResult: Office.AsyncResult<string>) => {
        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
          if (!mailboxState.subject || mailboxState.subject !== asyncResult.value)
            mailboxDispatch({
              type: 'setSubject',
              payload: {
                subject: asyncResult.value,
              },
            });
        }
      });
    };
    return (
      <TextField
        id="notes-outlined-multiline-flexible"
        name="notes"
        label="Subject *"
        placeholder="Enter a subject"
        multiline
        maxRows={4}
        value={mailboxState.subject || ''}
        onChange={handleSubject}
        InputLabelProps={{ shrink: true }}
        fullWidth
        onFocus={handleOnFocusSubject}
      />
    );
  }, [mailboxDispatch, mailboxState.subject]);

  const renderInPersonAttendeesField = useMemo(() => {
    if (!hasInPersonAttendees) return <></>;
    const handleInPersonAttendees = async (value: number) => {
      bookingDispatch({
        type: 'setGeneral',
        payload: {
          general: {
            ...bookingState.general,
            inPersonAttendees: value,
          },
        },
      });
    };
    const options = [];
    for (let i = 1; i <= maxCapacity; i++) {
      options.push(i);
    }
    return (
      <Autocomplete
        id="in-person-attendees"
        value={bookingState?.general?.inPersonAttendees}
        options={options}
        getOptionLabel={(option) => option.toString()}
        onChange={(_, v) => {
          if (typeof v === 'number') {
            handleInPersonAttendees(v);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            name="in-person-attendees"
            label="In-Person Attendees *"
            placeholder="Enter the attendes"
            maxRows={4}
            required={bookingState.catering?.cateringMenus?.length > 0}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        )}
      />
    );
  }, [hasInPersonAttendees, bookingState?.general?.inPersonAttendees]);

  return (
    <Box sx={sxCustom.box}>
      <Stack spacing={3} sx={{ my: 1 }}>
        {renderSubject}
        {renderInPersonAttendeesField}
        <Stack spacing={0}>
          <BookingOrganizer />
          <LinkRoom />
        </Stack>
        {renderComments}
      </Stack>
    </Box>
  );
}
