import { Store, PreloadedState, applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

import { SingletonPattern } from '../../../nura-helpers/lib/helpers/singleton';
import reducers, { RootState } from './reducers';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '../../../nura-helpers/lib/constants';

export default class AppState extends SingletonPattern {
  protected static _instance: AppState;
  public static get instance(): AppState {
    if (!this._instance) {
      this._instance = new AppState();
    }
    return this._instance;
  }

  public initialState: PreloadedState<any> = {
    login: {
      isLoggedIn: Boolean(localStorage.getItem(ACCESS_TOKEN_KEY)) && Boolean(localStorage.getItem(REFRESH_TOKEN_KEY)),
    },
  };

  private constructor() {
    super();
    this.store = this.configureStore(this.initialState);
  }

  public store: Store<RootState, any>;

  private configureStore(preloadedState?: PreloadedState<any>): Store<RootState> {
    const middlewares = [];
    if (process.env.NODE_ENV?.toLowerCase() === 'debug') {
      const logger = createLogger();
      middlewares.push(logger);
    }
    const middlewareEnhancer = applyMiddleware(thunk, ...middlewares);
    const composedEnhancers = composeWithDevTools(middlewareEnhancer);

    return createStore(reducers, preloadedState, composedEnhancers);
  }
}
