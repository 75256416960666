import axios, { AxiosResponse } from 'axios';
import { TraceData } from '../../../nura-client/lib/helpers/ip';
import { DeviceMeta } from '../../../nura-client/lib/redux/constants';
import { API_URL } from '../constants';

interface LoginWithOfficeAddInResponse {
  data: { accessToken: string; refreshToken: string };
}

export interface ILoginWithOfficeAddInParams {
  provider: string;
  deviceId: string;
  user?: {
    email: string;
    oid: string;
    tid: string;
    name?: string;
  };
  selectedCompany?: string;
  meta?: DeviceMeta;
  ipDetails?: TraceData;
  officeAccessToken: string;
}

export async function requestLoginWithOfficeAddIn<T>({
  provider,
  deviceId,
  user,
  meta,
  ipDetails,
  selectedCompany,
  officeAccessToken,
}: ILoginWithOfficeAddInParams): Promise<AxiosResponse<LoginWithOfficeAddInResponse & T>> {
  return axios.post<LoginWithOfficeAddInResponse & T>(`${API_URL}/auth/loginWithOfficeAddIn`, {
    provider,
    deviceId,
    user,
    meta,
    ipDetails,
    selectedCompany,
    officeAccessToken,
  });
}
