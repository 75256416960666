export interface UserVehicleAttributes {
  id: string;
  userId: string;
  licensePlate: string;
  licensePlateCountry: string;
  licensePlateState: string;
  vehicleBodyType?: string;
  vehicleType?: string;
  vehicleModel?: string;
  vehicleMake?: string;
  vehicleColor?: string;
  vehicleVIN?: string;
}

export enum ReadUserVehicleActionType {
  request = 'read.userVehicle.request',
  response = 'read.userVehicle.response',
  error = 'read.userVehicle.error',
}

export enum CreateUserVehicleActionType {
  request = 'create.userVehicle.request',
  response = 'create.userVehicle.response',
  error = 'create.userVehicle.error',
}

export enum ReadUserVehiclesActionType {
  request = 'read.userVehicles.request',
  response = 'read.userVehicles.response',
  error = 'read.userVehicles.error',
}

export enum UpdateUserVehicleActionType {
  request = 'update.userVehicle.request',
  response = 'update.userVehicle.response',
  error = 'update.userVehicle.error',
}

export enum DeleteUserVehiclesActionType {
  request = 'delete.userVehicles.request',
  response = 'delete.userVehicles.response',
  error = 'delete.userVehicles.error',
}
