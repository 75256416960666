export interface ICurrentUser {
  name?: string;
  email?: string;
  isAuth: boolean;
}

export interface ICurrentUserContext {
  officeUser: ICurrentUser | undefined;
  nuraUser?: ICurrentUser | undefined;
  signIn?: () => void;
}

export const INITIAL_DATA: ICurrentUserContext = {
  officeUser: undefined,
  nuraUser: undefined,
};

export interface OfficeAccessTokenDecoded {
  aud: string;
  name: string;
  email: string;
  iat: number;
  exp: number;
  tid: string;
  oid: string;
}
