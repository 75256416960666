import { common } from '@mui/material/colors';
import { palette } from '../../styles/theme';
import backgroundPattern from '../../assets/images/login/background_pattern.png';
export const classes = {
  root: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  bg: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundImage: `url(${backgroundPattern})`,
    backgroundSize: 'cover',
    zIndex: 0,
  },
  bgOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    opacity: 0.95,
    backgroundColor: palette.primary.dark,
    zIndex: 1,
  },
  content: {
    zIndex: 2,
  },
  welcome: {
    color: common.white,
    fontSize: 20,
  },
  span: {
    color: '#707171',
  },
};

export const sxCustom = {
  box: {
    width: '90%',
    height: 'auto',
    maxWidth: 400,
  },
  paper: {
    background: 'white',
    padding: 4,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  caption: {
    fontWeight: 400,
    fontSize: 9,
    color: 'rgba(0, 0, 0, 0.54) ',
  },
};
