import {
  CreateResourceActionType,
  ReadResourceActionType,
  Resource,
  ReadResourcesActionType,
  UpdateResourceActionType,
  ImportResourcesActionType,
  OptionsUploadCSV,
  ReadResourceOptionsActionType,
  AccessibleResource,
  ReadResourcesAccessibleActionType,
  ReadResourceDeskTypesActionType,
  ResourceDeskType,
  CreateResourceFloorplanActionType,
  CreateResourceFloorplanPreviewActionType,
  CreateResourcePreviewActionType,
  CreateMeetingRoomBackgroundActionType,
  ResourceTypes,
  FloorType,
  ReadResourceFloorTypesActionType,
  User,
} from '../../../constants';
import {
  CreateResourceAction,
  ReadResourceAction,
  ReadResourcesAction,
  UpdateResourceAction,
  ImportResourcesAction,
  ReadResourceOptionsAction,
  ReadResourcesAccessibleAction,
  ReadResourceDeskTypesAction,
  CreateResourceFloorplanAction,
  CreateResourceFloorplanPreviewAction,
  CreateResourcePreviewAction,
  CreateMeetingRoomBackgroundAction,
  ReadResourceFloorTypesAction,
} from '../../../actions';
import { QueryState } from '..';

export interface ResourceState extends QueryState {
  isFetching: boolean;
  resources: Resource[];
  options: OptionsUploadCSV[];
  error?: string;
  resource: Map<string, Resource>;
  buildings: Resource[];
  building: Map<string, Resource>;
  floors: Resource[];
  floor: Map<string, Resource>;
  desks: Resource[];
  desk: Map<string, Resource>;
  meetingrooms: Resource[];
  meetingroom: Map<string, Resource>;
  accessibleResources: {
    buildings: AccessibleResource[];
    floors: AccessibleResource[];
  };
  deskTypes: ResourceDeskType[];
  carParkSpaces: Resource[];
  carParkSpace: Map<string, Resource>;
  floorTypes: FloorType[];
  floorAdmins: User[];
}

const initState: ResourceState = {
  isFetching: false,
  resources: [],
  resource: new Map<string, Resource>(),
  options: [],
  pageInfo: {
    total: 0,
  },
  buildings: [],
  building: new Map<string, Resource>(),
  floors: [],
  floor: new Map<string, Resource>(),
  desks: [],
  desk: new Map<string, Resource>(),
  meetingrooms: [],
  meetingroom: new Map<string, Resource>(),
  accessibleResources: {
    buildings: [],
    floors: [],
  },
  deskTypes: [],
  carParkSpaces: [],
  carParkSpace: new Map<string, Resource>(),
  floorTypes: [],
  floorAdmins: [],
};

export function resource(
  state = initState,
  action:
    | CreateResourceAction
    | ReadResourceAction
    | ReadResourcesAction
    | UpdateResourceAction
    | ImportResourcesAction
    | ReadResourceOptionsAction
    | ReadResourcesAccessibleAction
    | ReadResourceDeskTypesAction
    | CreateResourceFloorplanAction
    | CreateResourceFloorplanPreviewAction
    | CreateResourcePreviewAction
    | CreateMeetingRoomBackgroundAction
    | ReadResourceFloorTypesAction,
): ResourceState {
  switch (action.type) {
    /** READ */
    case ReadResourcesActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadResourcesActionType.response:
      const extraReadResources: any = {};
      if (action.resourceType === 'building') extraReadResources.buildings = [...action.data];
      if (action.resourceType === 'floor') extraReadResources.floors = [...action.data];
      if (action.resourceType === 'desk') extraReadResources.desks = [...action.data];
      if (action.resourceType === 'meetingroom') extraReadResources.meetingrooms = [...action.data];
      if (action.resourceType === ResourceTypes.CAR_PARK_SPACE) extraReadResources.carParkSpaces = [...action.data];

      return {
        ...state,
        isFetching: false,
        resources: [...action.data],
        ...extraReadResources,
      };
    case ReadResourcesActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    case ReadResourceActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadResourceActionType.response:
      const extraReadResource: any = {};
      if (action.resourceType === 'building')
        extraReadResource.building = state.building.set(action.data.id, action.data);
      if (action.resourceType === 'floor') extraReadResource.floor = state.floor.set(action.data.id, action.data);
      if (action.resourceType === 'desk') extraReadResource.desk = state.desk.set(action.data.id, action.data);
      if (action.resourceType === 'meetingroom')
        extraReadResource.meetingroom = state.meetingroom.set(action.data.id, action.data);
      if (action.resourceType?.toLowerCase() === ResourceTypes.CAR_PARK_SPACE?.toLowerCase()) {
        extraReadResource.carParkSpace = state.carParkSpace.set(action.data.id, action.data);
      }

      return {
        ...state,
        isFetching: false,
        resource: state.resource.set(action.data.id, action.data),
        ...extraReadResource,
      };
    case ReadResourceActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    case ReadResourcesAccessibleActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadResourcesAccessibleActionType.response:
      return {
        ...state,
        isFetching: false,
        accessibleResources: {
          buildings: [...action.data.buildings],
          floors: [...action.data.floors],
        },
      };
    case ReadResourcesAccessibleActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    /** CREATE */
    case CreateResourceActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case CreateResourceActionType.response:
      const extraCreateResource: any = {};
      if (action.resourceType === 'building') {
        extraCreateResource.building = state.building.set(action.data.id, action.data);
        extraCreateResource.buildings = [...state.buildings, action.data];
      }
      if (action.resourceType === 'floor') {
        extraCreateResource.floor = state.floor.set(action.data.id, action.data);
        extraCreateResource.floors = [...state.floors, action.data];
      }
      if (action.resourceType === 'desk') {
        extraCreateResource.desk = state.desk.set(action.data.id, action.data);
        extraCreateResource.desks = [...state.desks, action.data];
      }

      if (action.resourceType === 'meetingroom') {
        extraCreateResource.meetingroom = state.meetingroom.set(action.data.id, action.data);
        extraCreateResource.meetingrooms = [...state.meetingrooms, action.data];
      }

      return {
        ...state,
        isFetching: false,
        resources: [...state.resources, action.data],
        resource: state.resource.set(action.data.id, action.data),
        ...extraCreateResource,
      };
    case CreateResourceActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** UPDATE */
    case UpdateResourceActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case UpdateResourceActionType.response:
      const extraUpdateResource: any = {};
      if (action.resourceType === 'building') {
        extraUpdateResource.building = state.building.set(action.data.id, action.data);
        extraUpdateResource.buildings = [
          ...state.buildings.map((building) => (building.id === action.data.id ? action.data : building)),
        ];
      }
      if (action.resourceType === 'floor') {
        extraUpdateResource.floor = state.floor.set(action.data.id, action.data);
        extraUpdateResource.floors = [
          ...state.floors.map((floor) => (floor.id === action.data.id ? action.data : floor)),
        ];
      }
      if (action.resourceType === 'desk') {
        extraUpdateResource.desk = state.desk.set(action.data.id, action.data);
        extraUpdateResource.desks = [...state.desks.map((desk) => (desk.id === action.data.id ? action.data : desk))];
      }

      if (action.resourceType === 'meetingroom') {
        extraUpdateResource.meetingroom = state.meetingroom.set(action.data.id, action.data);
        extraUpdateResource.meetingrooms = [
          ...state.meetingrooms.map((room) => (room.id === action.data.id ? action.data : room)),
        ];
      }

      if (action.resourceType?.toLowerCase() === ResourceTypes.CAR_PARK_SPACE?.toLowerCase()) {
        extraUpdateResource.carParkSpace = state.carParkSpace.set(action.data.id, action.data);
        extraUpdateResource.carParkSpaces = [
          ...state.carParkSpaces.map((carParkSpace) =>
            carParkSpace.id === action.data.id ? action.data : carParkSpace,
          ),
        ];
      }

      return {
        ...state,
        isFetching: false,
        resources: [...state.resources.map((resource) => (resource.id === action.data.id ? action.data : resource))],
        resource: state.resource.set(action.data.id, action.data),
        ...extraUpdateResource,
      };
    case UpdateResourceActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    /** Import CSV */
    case ImportResourcesActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ImportResourcesActionType.response:
      return {
        ...state,
        isFetching: false,
      };
    case ImportResourcesActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Read Options CSV */
    case ReadResourceOptionsActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadResourceOptionsActionType.response:
      return {
        ...state,
        isFetching: false,
        options: action.data,
      };
    case ReadResourceOptionsActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Read desk types */
    case ReadResourceDeskTypesActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadResourceDeskTypesActionType.response:
      return {
        ...state,
        deskTypes: action.data,
        isFetching: false,
      };
    case ReadResourceDeskTypesActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** CREATE FLOORPLAN */
    case CreateResourceFloorplanActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case CreateResourceFloorplanActionType.response:
      const createFloorplanResource: any = {};
      createFloorplanResource.floor = state.floor.set(action.data.id, action.data);
      createFloorplanResource.floors = [
        ...state.floors.map((floor) => (floor.id === action.data.id ? action.data : floor)),
      ];

      return {
        ...state,
        isFetching: false,
        resources: [...state.resources.map((resource) => (resource.id === action.data.id ? action.data : resource))],
        resource: state.resource.set(action.data.id, action.data),
        ...createFloorplanResource,
      };
    case CreateResourceFloorplanActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** CREATE FLOORPLAN PREVIEW IMAGE */
    case CreateResourceFloorplanPreviewActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case CreateResourceFloorplanPreviewActionType.response:
      const createResourceFoorplanPreviewResource: any = {};
      createResourceFoorplanPreviewResource.floor = state.floor.set(action.data.id, action.data);
      createResourceFoorplanPreviewResource.floors = [
        ...state.floors.map((floor) => (floor.id === action.data.id ? action.data : floor)),
      ];

      return {
        ...state,
        isFetching: false,
        resources: [...state.resources.map((resource) => (resource.id === action.data.id ? action.data : resource))],
        resource: state.resource.set(action.data.id, action.data),
        ...createResourceFoorplanPreviewResource,
      };
    case CreateResourceFloorplanPreviewActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    case CreateResourceFloorplanActionType.response:
      const createFloorplanPreviewResource: any = {};
      createFloorplanResource.floor = state.floor.set(action.data.id, action.data);
      createFloorplanResource.floors = [
        ...state.floors.map((floor) => (floor.id === action.data.id ? action.data : floor)),
      ];
      return {
        ...state,
        isFetching: false,
        resources: [...state.resources.map((resource) => (resource.id === action.data.id ? action.data : resource))],
        resource: state.resource.set(action.data.id, action.data),
        ...createFloorplanPreviewResource,
      };
    case CreateResourceFloorplanActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** CREATE RESOURCE PREVIEW IMAGE */
    case CreateResourcePreviewActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case CreateResourcePreviewActionType.response:
      const createResourcePreviewResource: any = {};
      createResourcePreviewResource.floor = state.floor.set(action.data.id, action.data);
      createResourcePreviewResource.floors = [
        ...state.floors.map((floor) => (floor.id === action.data.id ? action.data : floor)),
      ];

      return {
        ...state,
        isFetching: false,
        resources: [...state.resources.map((resource) => (resource.id === action.data.id ? action.data : resource))],
        resource: state.resource.set(action.data.id, action.data),
        ...createResourcePreviewResource,
      };
    case CreateResourcePreviewActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** CREATE RESOURCE BACKGROUND IMAGE */
    case CreateMeetingRoomBackgroundActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case CreateMeetingRoomBackgroundActionType.response:
      const createResourcePreviewResourceBg: any = {};
      createResourcePreviewResourceBg.floor = state.floor.set(action.data.id, action.data);
      createResourcePreviewResourceBg.floors = [
        ...state.floors.map((floor) => (floor.id === action.data.id ? action.data : floor)),
      ];

      return {
        ...state,
        isFetching: false,
        resources: [...state.resources.map((resource) => (resource.id === action.data.id ? action.data : resource))],
        resource: state.resource.set(action.data.id, action.data),
        ...createResourcePreviewResource,
      };
    case CreateMeetingRoomBackgroundActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    /** Read Floor Types */
    case ReadResourceFloorTypesActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadResourceFloorTypesActionType.response:
      return {
        ...state,
        isFetching: false,
        floorTypes: action.data,
      };
    case ReadResourceFloorTypesActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    default:
      return state;
  }
}
