import { Company } from '../company';
import { Team } from '../myteam';
import { Permission } from '../permission';
import { PermissionGroup } from '../permissiongroup';
import { Role } from '../role';
import { UserGroup } from '../usergroup';

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  PENDING_VERIFICATION = 'PENDING_VERIFICATION',
  SUSPENDED = 'SUSPENDED',
  INVITED = 'INVITED',
  INACTIVE = 'INACTIVE',
  ACCEPTED = 'ACCEPTED',
  SENT = 'SENT',
  DECLINED = 'DECLINED',
  QUEUED = 'QUEUED',
}

export enum UserConnectionProvider {
  EMAIL = 'EMAIL',
  ACTIVE_DIRECTORY = 'ACTIVE_DIRECTORY',
  GOOGLE = 'GOOGLE',
  APPLE = 'APPLE',
}
export interface UserConnection {
  provider: UserConnectionProvider;
  id: string;
}

export interface User {
  id?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  name?: string;
  department?: string;
  division?: string;
  companies?: Company[];
  permissions?: Permission[];
  permissiongroups?: PermissionGroup[];
  usergroups?: UserGroup[];
  socials?: any;
  avatar?: string;
  settings?: any[];
  passwordIsExpired?: boolean;
  status: UserStatus;
  roles?: Role[];
  phone?: string;
  mobile?: string;
  connections?: UserConnection[];
  teams?: Team[];
  adObjectId?: string;
  createdAt?: string;
  updatedAt?: string;
  //TO DO => remove profilePhoto, use profilePhotoUrl instead
  profilePhoto?: string;
  isIncognitoMode?: boolean;
  employeeId?: string;
  hasAllocationDesk?: boolean;
  isDragging?: boolean;
  AzureDepartmentHierarchy?: string;
  profilePhotoUrl?: string;
  hasVehicleRegistration?: boolean;
}

export enum ReadUsersActionType {
  request = 'read.users.request',
  response = 'read.users.response',
  error = 'read.users.error',
}

export enum ReadUserActionType {
  request = 'read.user.request',
  response = 'read.user.response',
  error = 'read.user.error',
}

export enum UpdateUserActionType {
  request = 'update.user.request',
  response = 'update.user.response',
  error = 'update.user.error',
}

export enum DeleteUserActionType {
  request = 'delete.user.request',
  response = 'delete.user.response',
  error = 'delete.user.error',
}

export enum ResetUserActionType {
  response = 'reset.user.response',
}

export enum UpdateUserModeActionType {
  request = 'update.user.mode.request',
  response = 'update.user.mode.response',
  error = 'update.user.mode.error',
}

export enum UnDeleteUserActionType {
  request = 'un.delete.user.request',
  response = 'un.delete.user.response',
  error = 'un.delete.user.error',
}

export enum ImportUsersActionType {
  request = 'import.users.request',
  response = 'import.users.response',
  error = 'import.users.error',
}
