import {
  ReadQuestionnaireActionType,
  Questionnaire,
  ReadQuestionActionType,
  UpdateQuestionActionType,
  ConditionsOfEntrySettings,
  ReadConditionOfEntrySettingsActionType,
  initialConditionsOfEntrySettings,
  CreateConditionOfEntrySettingsActionType,
  UpdateConditionOfEntrySettingsActionType,
  CreateDailyChecklistActionType,
  DailyChecklistUser,
  ReadDailyChecklistActionType,
  ReadDailyChecklistUsersActionType,
  DailyChecklistUserForGrid,
} from '../../../constants';
import {
  ReadQuestionnaireAction,
  ReadQuestionAction,
  UpdateQuestionAction,
  ReadConditionOfEntrySettingsAction,
  CreateConditionOfEntrySettingAction,
  UpdateConditionOfEntrySettingAction,
  CreateDailyChecklistAction,
  ReadDailyChecklistAction,
  ReadDailyChecklistUsersAction,
} from '../../../actions';
import { QueryState } from '..';

export interface QuestionnaireState extends QueryState {
  isFetching: boolean;
  questionnaire: Questionnaire[];
  question: Questionnaire;
  conditionsOfEntrySettings: ConditionsOfEntrySettings;
  dailyChecklistUsers: DailyChecklistUserForGrid[];
  dailyChecklistAnswer: DailyChecklistUser;
  error?: string;
}

const initState: QuestionnaireState = {
  isFetching: false,
  questionnaire: [],
  question: {},
  conditionsOfEntrySettings: initialConditionsOfEntrySettings,
  dailyChecklistUsers: [],
  dailyChecklistAnswer: undefined,
  pageInfo: {
    total: 0,
  },
};

export function questionnaire(
  state = initState,
  action:
    | ReadQuestionnaireAction
    | ReadQuestionAction
    | UpdateQuestionAction
    | ReadConditionOfEntrySettingsAction
    | CreateConditionOfEntrySettingAction
    | UpdateConditionOfEntrySettingAction
    | CreateDailyChecklistAction
    | ReadDailyChecklistAction
    | ReadDailyChecklistUsersAction,
): QuestionnaireState {
  switch (action.type) {
    /** Read Questionnaire*/
    case ReadQuestionnaireActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadQuestionnaireActionType.response:
      return {
        ...state,
        isFetching: false,
        questionnaire: [...action.data],
        pageInfo: action.pageInfo,
      };
    case ReadQuestionnaireActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Read Question */
    case ReadQuestionActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadQuestionActionType.response:
      return {
        ...state,
        isFetching: false,
        questionnaire: [...state.questionnaire.map((item) => (item.id === action.data.id ? action.data : item))],
        question: action.data,
      };
    case ReadQuestionActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Update Question */
    case UpdateQuestionActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case UpdateQuestionActionType.response:
      return {
        ...state,
        isFetching: false,
        questionnaire: [...state.questionnaire.map((item) => (item.id === action.data.id ? action.data : item))],
        question: action.data,
      };
    case UpdateQuestionActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Read Condition Of Entry Settings  */
    case ReadConditionOfEntrySettingsActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadConditionOfEntrySettingsActionType.response:
      return {
        ...state,
        isFetching: false,
        conditionsOfEntrySettings: action.data,
      };
    case ReadConditionOfEntrySettingsActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Create  */
    case CreateConditionOfEntrySettingsActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case CreateConditionOfEntrySettingsActionType.response:
      return {
        ...state,
        isFetching: false,
        conditionsOfEntrySettings: action.data,
      };
    case CreateConditionOfEntrySettingsActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Update */
    case UpdateConditionOfEntrySettingsActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case UpdateConditionOfEntrySettingsActionType.response:
      return {
        ...state,
        isFetching: false,
        conditionsOfEntrySettings: action.data,
      };
    case UpdateConditionOfEntrySettingsActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** User: Create Daily Checklist Action */
    case CreateDailyChecklistActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case CreateDailyChecklistActionType.response:
      return {
        ...state,
        isFetching: false,
        dailyChecklistAnswer: action.data,
      };
    case CreateDailyChecklistActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** User: Read Daily Checklist Action */
    case ReadDailyChecklistActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadDailyChecklistActionType.response:
      return {
        ...state,
        isFetching: false,
        dailyChecklistAnswer: action.data,
      };
    case ReadDailyChecklistActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Users: Read Daily Checklist Users Action */
    case ReadDailyChecklistUsersActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadDailyChecklistUsersActionType.response:
      return {
        ...state,
        isFetching: false,
        dailyChecklistUsers: [...action.data],
        pageInfo: action.pageInfo,
      };
    case ReadDailyChecklistUsersActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    default:
      return state;
  }
}
