import React, { useReducer, ComponentProps, useContext, ReactNode } from 'react';
import {
  FilterBookingContextProps,
  FilterBookingsAction,
  FilterBookingsContext,
  FilterBookingsState,
  initialFilterBookingsState,
} from '../../contexts/FilterBookings';

const reducer = (state: FilterBookingsState, action: FilterBookingsAction): FilterBookingsState => {
  switch (action.type) {
    case 'setParams':
      const newParams = action.payload;
      return {
        ...state,
        filter: { ...state.filter, ...newParams },
      };

    case 'setFilter':
      const newFilter = action.payload?.isFiltering;
      return {
        ...state,
        filter: { ...state.filter, isFiltering: Boolean(newFilter) },
      };

    case 'clear':
      return initialFilterBookingsState;
    default:
      return initialFilterBookingsState;
  }
};

function FilterBookingsProvider({ children }: ComponentProps<any>): ReactNode & JSX.Element {
  const [state, dispatch] = useReducer(reducer, initialFilterBookingsState);
  return (
    <FilterBookingsContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </FilterBookingsContext.Provider>
  );
}

const useFilterBookings = (): FilterBookingContextProps => useContext(FilterBookingsContext);

export { useFilterBookings };

export default FilterBookingsProvider;
