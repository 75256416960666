import {
  CreateCompanyActionType,
  ReadCompanyActionType,
  Company,
  ReadCompaniesActionType,
  DeleteCompanyActionType,
  UpdateCompanyActionType,
  CreateCompanyAdminActionType,
  DeleteCompanyAdminActionType,
  UpdateCompanyActiveDirectoryMappingActionType,
  DeleteCompanyActiveDirectoryMappingActionType,
  CreateCompanyActiveDirectoryMappingActionType,
  CreateUserCompanyActionType,
  DeleteUserCompanyActionType,
  CreateCompanyLogoActionType,
  GenerateAPITokenActionType,
  CreateCustomLogoActionType,
  GenerateAPISecretActionType,
  ApplyAllActiveDirectoryMappingsActionType,
  ReadCompanyAPIDetailsActionType,
} from '../../../constants';
import {
  CreateCompanyAction,
  DeleteCompanyAction,
  ReadCompanyAction,
  ReadCompaniesAction,
  UpdateCompanyAction,
  CreateCompanyAdminAction,
  DeleteCompanyAdminAction,
  UpdateCompanyActiveDirectoryMappingAction,
  DeleteCompanyActiveDirectoryMappingAction,
  CreateCompanyActiveDirectoryMappingAction,
  CreateUserCompanyAction,
  DeleteUserCompanyAction,
  CreateCompanyLogoAction,
  GenerateAPITokenAction,
  CreateCustomLogoAction,
  GenerateAPISecretAction,
  ApplyAllActiveDirectoryMappingsAction,
  ReadCompanyAPIDetailsAction,
} from '../../../actions';
import { QueryState } from '..';

export interface CompanyState extends QueryState {
  isFetching: boolean;
  companies: Company[];
  error?: string;
  company: Record<string, Company>;
}

const initState: CompanyState = {
  isFetching: false,
  companies: [],
  company: {},
  pageInfo: {
    total: 0,
  },
};

export function company(
  state = initState,
  action:
    | CreateCompanyAction
    | DeleteCompanyAction
    | ReadCompanyAction
    | ReadCompaniesAction
    | UpdateCompanyAction
    | CreateCompanyAdminAction
    | DeleteCompanyAdminAction
    | UpdateCompanyActiveDirectoryMappingAction
    | DeleteCompanyActiveDirectoryMappingAction
    | CreateCompanyActiveDirectoryMappingAction
    | CreateUserCompanyAction
    | DeleteUserCompanyAction
    | CreateCompanyLogoAction
    | GenerateAPITokenAction
    | CreateCustomLogoAction
    | GenerateAPISecretAction
    | ApplyAllActiveDirectoryMappingsAction
    | ReadCompanyAPIDetailsAction,
): CompanyState {
  switch (action.type) {
    /** READ */
    case ReadCompaniesActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadCompaniesActionType.response:
      return {
        ...state,
        isFetching: false,
        companies: action.pageInfo?.cursor ? [...state.companies, ...action.data] : action.data,
        pageInfo: action.pageInfo,
      };
    case ReadCompaniesActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    case ReadCompanyActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadCompanyActionType.response:
      return {
        ...state,
        isFetching: false,
        company: { ...state.company, [action.data.id]: action.data },
      };
    case ReadCompanyActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** CREATE */
    case CreateCompanyActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case CreateCompanyActionType.response:
      return {
        ...state,
        isFetching: false,
        companies: [...state.companies, action.data],
        company: { ...state.company, [action.data.id]: action.data },
      };
    case CreateCompanyActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** DELETE */
    case DeleteCompanyActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case DeleteCompanyActionType.response:
      delete state.company[action.data];
      return {
        ...state,
        isFetching: false,
        companies: [...state.companies.filter(({ id }) => id !== action.data)],
        company: state.company,
      };
    case DeleteCompanyActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** UPDATE */
    case UpdateCompanyActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case UpdateCompanyActionType.response:
      return {
        ...state,
        isFetching: false,
        companies: [...state.companies.map((company) => (company.id === action.data.id ? action.data : company))],
        company: { ...state.company, [action.data.id]: action.data },
      };
    case UpdateCompanyActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Company admin */
    case CreateCompanyAdminActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case CreateCompanyAdminActionType.response:
      return {
        ...state,
        isFetching: false,
        companies: [...state.companies.map((company) => (company.id === action.data.id ? action.data : company))],
        company: { ...state.company, [action.data.id]: action.data },
      };
    case CreateCompanyAdminActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    case DeleteCompanyAdminActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case DeleteCompanyAdminActionType.response:
      return {
        ...state,
        isFetching: false,
        companies: [...state.companies.map((company) => (company.id === action.data.id ? action.data : company))],
        company: { ...state.company, [action.data.id]: action.data },
      };
    case DeleteCompanyAdminActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** CREATE - Company AD Mapping */
    case CreateCompanyActiveDirectoryMappingActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case CreateCompanyActiveDirectoryMappingActionType.response:
      return {
        ...state,
        isFetching: false,
        companies: [...state.companies.map((company) => (company.id === action.data.id ? action.data : company))],
        company: { ...state.company, [action.data.id]: action.data },
      };
    case CreateCompanyActiveDirectoryMappingActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** PATCH - Company AD Mapping */
    case UpdateCompanyActiveDirectoryMappingActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case UpdateCompanyActiveDirectoryMappingActionType.response:
      return {
        ...state,
        isFetching: false,
        companies: [...state.companies.map((company) => (company.id === action.data.id ? action.data : company))],
        company: { ...state.company, [action.data.id]: action.data },
      };
    case UpdateCompanyActiveDirectoryMappingActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** DELETE - Company AD Mapping */
    case DeleteCompanyActiveDirectoryMappingActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case DeleteCompanyActiveDirectoryMappingActionType.response:
      return {
        ...state,
        isFetching: false,
        companies: [...state.companies.map((company) => (company.id === action.data.id ? action.data : company))],
        company: state.company,
      };
    case DeleteCompanyActiveDirectoryMappingActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    /** User - User Company */
    case CreateUserCompanyActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case CreateUserCompanyActionType.response:
      return {
        ...state,
        isFetching: false,
      };
    case CreateUserCompanyActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** DELETE - User Company */
    case DeleteUserCompanyActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case DeleteUserCompanyActionType.response:
      return {
        ...state,
        isFetching: false,
      };
    case DeleteUserCompanyActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Create company logo */
    case CreateCompanyLogoActionType.request:
      return {
        ...state,
        isFetching: true,
      };

    case CreateCompanyLogoActionType.response:
      const updatedCompany = state.company[action.data.id];
      updatedCompany.logo = action.data.logo;
      updatedCompany.logoDark = action.data.logoDark;
      if (action.data?.logoFile && action.data?.logoFile?.trim() !== '') {
        updatedCompany.logoFile = action.data.logoFile;
      }
      if (action.data?.logoFileDark && action.data?.logoFileDark?.trim() !== '') {
        updatedCompany.logoFileDark = action.data.logoFileDark;
      }

      return {
        ...state,
        companies: [
          ...state.companies.map((company) => (company.id === action.data.id ? { ...updatedCompany } : company)),
        ],
        company: { ...state.company, [action.data.id]: { ...updatedCompany } },
        isFetching: false,
      };

    case CreateCompanyLogoActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    case GenerateAPITokenActionType.request:
      return {
        ...state,
        isFetching: true,
      };

    case GenerateAPITokenActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    case GenerateAPITokenActionType.response:
      return {
        ...state,
        isFetching: false,
      };

    case GenerateAPITokenActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    case CreateCompanyLogoActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Create custom logo */
    case CreateCustomLogoActionType.request:
      return {
        ...state,
        isFetching: true,
      };

    case GenerateAPISecretActionType.request:
      return {
        ...state,
        isFetching: true,
      };

    // data is not returned to redux as the secret needs to be protected
    case GenerateAPISecretActionType.response:
      return {
        ...state,
        isFetching: false,
      };

    case GenerateAPISecretActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    case CreateCustomLogoActionType.response:
      const updatedCurrentCompany = state.company[action.data.id];
      updatedCurrentCompany.webUILogo = action.data.webUILogo;
      updatedCurrentCompany.logoCustomFile = action.data.logoCustomFile;
      updatedCurrentCompany.features.map((feature) => {
        if (feature.Name === 'HasWebUILogo') {
          return {
            ...feature,
            IsEnabled: true,
          };
        }
        return feature;
      });
      return {
        ...state,
        companies: [
          ...state.companies.map((company) => (company.id === action.data.id ? { ...updatedCurrentCompany } : company)),
        ],
        company: { ...state.company, [action.data.id]: { ...updatedCurrentCompany } },
        isFetching: false,
      };

    case CreateCustomLogoActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    case GenerateAPISecretActionType.request:
      return {
        ...state,
        isFetching: true,
      };

    // data is not returned to redux as the secret needs to be protected
    case GenerateAPISecretActionType.response:
      return {
        ...state,
        isFetching: false,
      };

    case GenerateAPISecretActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** CREATE - Company AD Mapping */
    case ApplyAllActiveDirectoryMappingsActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ApplyAllActiveDirectoryMappingsActionType.response:
      return {
        ...state,
        isFetching: false,
        companies: [...state.companies.map((company) => (company.id === action.data.id ? action.data : company))],
        company: { ...state.company, [action.data.id]: action.data },
      };
    case ApplyAllActiveDirectoryMappingsActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    case ReadCompanyAPIDetailsActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadCompanyAPIDetailsActionType.response:
      return {
        ...state,
        isFetching: false,
        company: { ...state.company, [action.data.id]: action.data },
      };
    case ReadCompanyAPIDetailsActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    default:
      return state;
  }
}
