import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { api, DefaultResponse } from '../../../../../../../nura-helpers/lib/services/api';
import { RootState } from '../../../../reducers';
import { getCompanyId } from '../../../../../../../nura-helpers/lib/helpers/auth';
import { IBooking, IRoomManagement, ReadMeetingRoomAvailabilitiesWithServicesActionType } from '../../../../constants';

export interface ReadMeetingRoomAvailabilitiesWithServicesAction
  extends Action<ReadMeetingRoomAvailabilitiesWithServicesActionType> {
  error?: any;
  data?: IBooking[];
}

export interface ReadMeetingRoomAvailabilitiesWithServicesResult {
  data: IBooking[];
}

type ReadMeetingRoomAvailabilitiesWithServicesResponse = DefaultResponse &
  ReadMeetingRoomAvailabilitiesWithServicesResult;

export interface ILocationDetail {
  emailAddress: string;
  displayName?: string;
}
export interface ParamsReadMeetingRoomAvailabilitiesWithServices {
  accessToken?: string;
  companyId?: number;
  currentDate?: string;
  start?: string;
  end?: string;
  localTz?: string;
  building?: number;
  floor?: number;
  management?: IRoomManagement;
  services?: number[];
  locations?: string;
  locationDetails?: ILocationDetail[];
  capacity?: number;
}

export async function readMeetingRoomAvailabilitiesWithServicesAsync({
  accessToken,
  companyId,
  currentDate,
  start,
  end,
  localTz,
  building,
  floor,
  management,
  services,
  locations,
  locationDetails,
  capacity = 0,
}: ParamsReadMeetingRoomAvailabilitiesWithServices): Promise<ReadMeetingRoomAvailabilitiesWithServicesResult> {
  const scope = getCompanyId();
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const response = await api('/api/outlook/meetingRoomAvailabilities', {
    outlookId: true,
  }).post<ReadMeetingRoomAvailabilitiesWithServicesResponse>({
    companyId: `${scope}`,
    accessToken,
    currentDate,
    start,
    end,
    localTz: tz || 'Australia/Melbourne',
    building,
    floor,
    management,
    services,
    locations,
    locationDetails,
    capacity,
  });

  if (response.status !== 200) throw response;

  if (!response || !response.data) {
    throw response;
  }
  return {
    data: response.data,
  };
}

export function readMeetingRoomAvailabilitiesWithServices(
  params: ParamsReadMeetingRoomAvailabilitiesWithServices,
): ThunkAction<
  Promise<ReadMeetingRoomAvailabilitiesWithServicesResult>,
  RootState,
  unknown,
  ReadMeetingRoomAvailabilitiesWithServicesAction
> {
  return async (dispatch) => {
    try {
      dispatch({
        type: ReadMeetingRoomAvailabilitiesWithServicesActionType.request,
      });

      const response = await readMeetingRoomAvailabilitiesWithServicesAsync(params);

      dispatch({
        type: ReadMeetingRoomAvailabilitiesWithServicesActionType.response,
        data: response.data,
      });
      return {
        data: response.data,
      };
    } catch (e) {
      dispatch({
        type: ReadMeetingRoomAvailabilitiesWithServicesActionType.error,
        error: e,
      });
      throw e;
    }
  };
}
