export interface IMenuParams {
  id: string;
  scope: string;
  name: string;
  description?: string;
  active?: boolean;
}

export interface IMenuResponse {
  id: string;
  name: string;
  description?: string;
}

export enum ReadCateringMenuActionType {
  request = 'read.catering.menu.request',
  response = 'read.catering.menu.response',
  error = 'read.catering.menu.error',
}

export enum CreateCateringMenuActionType {
  request = 'create.catering.menu.request',
  response = 'create.catering.menu.response',
  error = 'create.catering.menu.error',
}

export enum ReadCateringMenusActionType {
  request = 'read.catering.menus.request',
  response = 'read.catering.menus.response',
  error = 'read.catering.menus.error',
}

export enum UpdateCateringMenuActionType {
  request = 'update.catering.menu.request',
  response = 'update.catering.menu.response',
  error = 'update.catering.menu.error',
}

export enum DeleteCateringMenuActionType {
  request = 'delete.catering.menu.request',
  response = 'delete.catering.menu.response',
  error = 'delete.catering.menu.error',
}
