import { createTheme } from '@mui/material';
import { common, orange, red } from '@mui/material/colors';

const fontFamily =
  'relative-book,"IBM Plex Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"';

export const palette = {
  primary: {
    main: '#2F4AD1',
    light: '#9DA6E9',
    dark: '#00169C',
    contrastText: '#F9F9F9',
  },
  error: {
    main: '#FF5D46',
    light: '#F6A6A4',
    dark: '#C63C2C',
    contrastText: '#F9F9F9',
  },
  warning: {
    main: orange[600],
    light: orange[100],
    dark: orange[900],
    contrastText: '#F9F9F9',
  },
  success: {
    main: '#00C045',
    light: '#8EE1A4',
    dark: '#006C0D',
    contrastText: '#2E2F2F',
  },
  secondary: {
    main: red[600],
    light: red[400],
    dark: red[800],
    contrastText: common.white,
  },
  background: {
    default: '#f3f3f3',
    paper: '#f9f9f9',
    card: '#F7F7FB',
  },
  grey: {
    50: '#FCFDFD',
    100: '#FAFAFA',
    200: '#F9F9F9',
    300: '#EEEEEE',
    400: '#E0E0E0',
    500: '#BDBDBD',
    600: '#757575',
    700: '#707171',
    800: '#505151',
    900: '#2E2F2F',
  },
  border: {
    main: '#E8E9FA',
    light: '#C5C9F2',
  },
  equipment: {
    main: '#D6F0FF',
  },
};

export const classes = {
  icon: {
    fontSize: 14,
  },
  iconLabel: {
    fontSize: 12,
  },
  iconButton: {
    maxWidth: '30px',
    maxHeight: '30px',
    minWidth: '30px',
    minHeight: '30px',
    borderColor: palette.border.main,
  },
  iconInput: {
    fontSize: 18,
    cursor: 'pointer',
  },
  boxIconInput: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#F3F4FC',
    height: '100%',
    cursor: 'pointer',
    width: 45,
  },
  iconCell: {
    cursor: 'pointer',
  },
};

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          boxShadow: 'none',
          height: 45,
          borderRadius: 8,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          // minWidth: 250,
          borderColor: palette.border.light,
          borderRadius: 8,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
        notchedOutline: {
          borderColor: palette.border.light,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          '&:hover': {
            background: '#F1F2FC',
          },
        },
      },
    },
  },
  palette: {
    ...palette,
  },
  typography: {
    fontFamily: `relative-book, ${fontFamily}`,
    h6: { fontFamily: `relative-book, ${fontFamily}`, color: palette.grey[900] },
    h5: { fontFamily: `relative-book, ${fontFamily}`, color: palette.grey[900] },
    h4: { fontFamily: `relative-book, ${fontFamily}`, color: palette.grey[900] },
    h3: { fontFamily: `relative-book, ${fontFamily}`, color: palette.grey[900] },
    h2: { fontFamily: `relative-book, ${fontFamily}`, color: palette.grey[900] },
    h1: { fontFamily: `relative-book, ${fontFamily}`, color: palette.grey[900] },
    subtitle1: { fontFamily: `relative-book, ${fontFamily}`, color: palette.grey[900] },
    subtitle2: { fontFamily: `relative-book, ${fontFamily}`, color: palette.grey[900] },
    body1: { fontFamily: `relative-book, ${fontFamily}`, color: palette.grey[900] },
    body2: { fontFamily: `relative-book, ${fontFamily}`, color: palette.grey[900] },
  },
});

export default theme;
