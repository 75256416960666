import { LoginWithOfficeAddInActionType } from '../../../constants';
import { IUserCompany, LoginWithOfficeAddInAction } from '../../../actions';

export interface ILoginLoginWithOfficeAddInState {
  isFetching: boolean;
  isLoggedIn: boolean;
  error?: string | null;
  accessToken?: string;
  refreshToken?: string;
  isMultiCompany?: boolean;
  companies?: string[];
  selectedCompany?: string;
  userCompanies?: IUserCompany[];
}

const initState: ILoginLoginWithOfficeAddInState = {
  isFetching: false,
  isLoggedIn: false,
  error: null,
  accessToken: undefined,
  refreshToken: undefined,
  isMultiCompany: false,
  companies: [],
  selectedCompany: undefined,
  userCompanies: [],
};

export function loginWithOfficeAddIn(
  state = initState,
  action: LoginWithOfficeAddInAction,
): ILoginLoginWithOfficeAddInState {
  switch (action.type) {
    case LoginWithOfficeAddInActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case LoginWithOfficeAddInActionType.response:
      return {
        ...state,
        error: null,
        isFetching: false,
        isLoggedIn: Boolean(action.data?.accessToken),
        accessToken: action.data?.accessToken,
        refreshToken: action.data?.refreshToken,
        isMultiCompany: action.data?.isMultiCompany,
        companies: action.data?.companies || [],
        userCompanies: action.data?.userCompanies || [],
      };
    case LoginWithOfficeAddInActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    default:
      return state;
  }
}
