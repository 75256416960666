import { ChangeEvent } from 'react';
import { Company, Resource, User } from '..';

export interface Tablet {
  id: string;
  company: Company;
  user: User;
  resource: Resource;
  appVersion: string;
  serialNumber: string;
  ip: string;
  createdAt: string;
  updatedAt: string;
  tz: string;
  isConnected: boolean;
  active: boolean;
  lastContact: string;
}

export interface TabletParams {
  id?: string;
  company?: string;
  user?: string;
  appVersion?: string;
  serialNumber?: string;
  tz?: string;
  createdAt?: string;
  updatedAt?: string;
  ip?: string;
  active?: boolean;
}

export interface TabletCreateProps {
  tablet: TabletParams;
  error?: RegisterTabletError;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onChangeCompany?: (value: string) => void;
  onChangeTZ?: (value: string) => void;
  onSubmit: () => void;
  type: 'register' | 'update';
}

export interface RegisterTabletError {
  name: string;
  message: string | undefined;
}

export enum ReadTabletsActionType {
  request = 'read.tablets.request',
  response = 'read.tablets.response',
  error = 'read.tablets.error',
}

export enum ReadTabletActionType {
  request = 'read.tablet.request',
  response = 'read.tablet.response',
  error = 'read.tablet.error',
}

export enum CreateTabletActionType {
  request = 'create.tablet.request',
  response = 'create.tablet.response',
  error = 'create.tablet.error',
}

export enum UpdateTabletActionType {
  request = 'update.tablet.request',
  response = 'update.tablet.response',
  error = 'update.tablet.error',
}
