import { eachDayOfInterval, endOfMonth, endOfWeek, format, setMonth, startOfMonth, startOfWeek } from 'date-fns';
import { ISelectCustomOptions } from '../../components/SelectCustom';
import {
  EOnDayBookingRecurrence,
  EOptionRecurrencePatternType,
  ERecurrencePatternType,
  EWeekIndex,
  ITimeItem,
} from '../../middlewares/nura-client/lib/redux/constants';

export enum EServiceType {
  EQUIPMENT = 'equipment',
  NO_TABLES = 'No. tables',
  NO_CHAIRS = 'No. chairs',
  SET_UP = 'Set up',
  CLEAN_DOWN = 'clean down',
}

export enum EServiceInputType {
  CHIP = 'chip',
  COUNTER = 'counter',
  DROPDOWN = 'dropdown',
}

export const setupTimes: ITimeItem[] = [
  { id: 1, label: '15 Minutes', value: 15 },
  { id: 2, label: '30 Minutes', value: 30 },
  { id: 3, label: '45 Minutes', value: 45 },
  { id: 4, label: '60 Minutes', value: 60 },
  { id: 5, label: '75 Minutes', value: 75 },
  { id: 6, label: '90 Minutes', value: 90 },
];

export const MAX_WIDTH_TAB = 390;

export const DEFAULT_DELAY_OFFICE_ITEMS =
  Number(process.env.REACT_APP_DEFAULT_DELAY_OFFICE_ITEMS) > 0
    ? Number(process.env.REACT_APP_DEFAULT_DELAY_OFFICE_ITEMS)
    : 2000;

export const REACT_APP_DELAY_FOR_ITEM_CLOSE_ACTION =
  Number(process.env.REACT_APP_DELAY_FOR_ITEM_CLOSE_ACTION) > 0
    ? Number(process.env.REACT_APP_DELAY_FOR_ITEM_CLOSE_ACTION)
    : 5000;
export const REACT_APP_MAX_RETRIES_FOR_ITEM_CLOSE_ACTION =
  Number(process.env.REACT_APP_MAX_RETRIES_FOR_ITEM_CLOSE_ACTION) > 0
    ? Number(process.env.REACT_APP_MAX_RETRIES_FOR_ITEM_CLOSE_ACTION)
    : 3;

export const REACT_APP_MEETING_ROOM_MIN_TIME_INTERVAL =
  Number(process.env.REACT_APP_MEETING_ROOM_MIN_TIME_INTERVAL) > 0
    ? Number(process.env.REACT_APP_MEETING_ROOM_MIN_TIME_INTERVAL)
    : 15;

export const REACT_APP_RECURRING_MAX_INTERVAL_DAYS =
  Number(process.env.REACT_APP_RECURRING_MAX_INTERVAL_DAYS) > 0
    ? Number(process.env.REACT_APP_RECURRING_MAX_INTERVAL_DAYS)
    : 180;

export const REACT_APP_RECURRING_MAX_INTERVAL_MONTHS =
  Number(process.env.REACT_APP_RECURRING_MAX_INTERVAL_MONTHS) > 0
    ? Number(process.env.REACT_APP_RECURRING_MAX_INTERVAL_MONTHS)
    : 6;

export const repeatOptions: ISelectCustomOptions[] = [
  { name: 'Does not repeat', value: EOptionRecurrencePatternType.DOES_NOT_REPEAT },
  { name: 'Repeat daily', value: ERecurrencePatternType.DAILY },
  { name: 'Repeat weekly', value: ERecurrencePatternType.WEEKLY },
  { name: 'Repeat monthly', value: EOptionRecurrencePatternType.MONTHLY },
  { name: 'Repeat yearly', value: EOptionRecurrencePatternType.YEARLY },
];

export const monthlyArr = [
  ERecurrencePatternType.ABSOLUTE_MONTHLY.toString(),
  ERecurrencePatternType.RELATIVE_MONTHLY.toString(),
  EOptionRecurrencePatternType.MONTHLY.toString(),
];

export const yearlyArr = [
  ERecurrencePatternType.ABSOLUTE_YEARLY.toString(),
  ERecurrencePatternType.RELATIVE_YEARLY.toString(),
  EOptionRecurrencePatternType.YEARLY.toString(),
];

export const getAbsoluteOptions = (dateSelected: Date) => {
  let result: ISelectCustomOptions[] = [];
  if (dateSelected) {
    const startOfNMonth = startOfMonth(dateSelected);
    const endOfNMonth = endOfMonth(dateSelected);
    const eachDaysOfMonth = eachDayOfInterval({
      start: startOfNMonth,
      end: endOfNMonth,
    });
    result = eachDaysOfMonth?.map((day) => ({
      name: `${format(day, 'd')}`,
      value: `${format(day, 'd')}`,
    }));
  }

  return result;
};

export const weekIndexOptions: ISelectCustomOptions[] = [
  { name: EWeekIndex.FIRST, value: EWeekIndex.FIRST },
  { name: EWeekIndex.SECOND, value: EWeekIndex.SECOND },
  { name: EWeekIndex.THIRD, value: EWeekIndex.THIRD },
  { name: EWeekIndex.FOURTH, value: EWeekIndex.FOURTH },
  { name: EWeekIndex.LAST, value: EWeekIndex.LAST },
];

export const defaultOnDayOptions: ISelectCustomOptions[] = [
  { name: EOnDayBookingRecurrence.DAY, value: EOnDayBookingRecurrence.DAY?.toLowerCase() },
  { name: EOnDayBookingRecurrence.WEEKDAY, value: EOnDayBookingRecurrence.WEEKDAY?.toLowerCase() },
  { name: EOnDayBookingRecurrence.WEEKEND_DAY, value: EOnDayBookingRecurrence.WEEKEND_DAY?.toLowerCase() },
];

export const weekDaysOptions = eachDayOfInterval({
  start: startOfWeek(new Date()),
  end: endOfWeek(new Date()),
})?.map((day) => ({ name: format(day, 'EEEE'), value: format(day, 'EEEE')?.toLowerCase() }));
