import { QueryState } from '../..';
import {
  CreateCateringPackageActionType,
  IPackageResponse,
  ReadCateringPackagesActionType,
  ReadCateringPackageActionType,
  UpdateCateringPackageActionType,
  ReadCateringPackageByMenuActionType,
} from '../../../../constants';
import {
  CreateCateringPackageAction,
  ReadCateringPackagesAction,
  ReadCateringPackageAction,
  UpdateCateringPackageAction,
  ReadCateringPackageByMenuAction,
} from '../../../../actions';

export interface ICateringPackageState extends QueryState {
  isFetching: boolean;
  packages: IPackageResponse[];
  package: IPackageResponse;
  packageByMenu: IPackageResponse[];
  error?: string;
}

const initState: ICateringPackageState = {
  isFetching: false,
  packages: [],
  package: undefined,
  packageByMenu: [],
  pageInfo: {
    total: 0,
  },
};

export function cateringPackage(
  state = initState,
  action:
    | CreateCateringPackageAction
    | ReadCateringPackagesAction
    | ReadCateringPackageAction
    | UpdateCateringPackageAction
    | ReadCateringPackageByMenuAction,
): ICateringPackageState {
  switch (action.type) {
    /** Rad Packages */
    case ReadCateringPackagesActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadCateringPackagesActionType.response:
      return {
        ...state,
        isFetching: false,
        packages: action.data,
        pageInfo: action.pageInfo,
      };
    case ReadCateringPackagesActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Rad Package */
    case ReadCateringPackageActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadCateringPackageActionType.response:
      return {
        ...state,
        isFetching: false,
        package: action.data,
      };
    case ReadCateringPackageActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Create a Package */
    case CreateCateringPackageActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case CreateCateringPackageActionType.response:
      return {
        ...state,
        isFetching: false,
        package: action.data,
      };
    case CreateCateringPackageActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Update Package */
    case UpdateCateringPackageActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case UpdateCateringPackageActionType.response:
      return {
        ...state,
        isFetching: false,
        package: action.data,
      };
    case UpdateCateringPackageActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Rad Package by menu */
    case ReadCateringPackageByMenuActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadCateringPackageByMenuActionType.response:
      return {
        ...state,
        isFetching: false,
        packageByMenu: action.data,
      };
    case ReadCateringPackageByMenuActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    default:
      return state;
  }
}
