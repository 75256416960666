import { PermissionGroup, ReadPermissionGroupsActionType } from '../../../constants';
import { ReadPermissionGroupsAction } from '../../../actions';
import { QueryState } from '..';

export interface PermissionGroupState extends QueryState {
  isFetching: boolean;
  permissiongroups: PermissionGroup[];
  error?: string;
  permissiongroup: Map<string, PermissionGroup>;
}

const initState: PermissionGroupState = {
  isFetching: false,
  permissiongroups: [],
  permissiongroup: new Map<string, PermissionGroup>(),
  pageInfo: {
    total: 0,
  },
};

export function permissiongroup(state = initState, action: ReadPermissionGroupsAction): PermissionGroupState {
  switch (action.type) {
    /** READ */
    case ReadPermissionGroupsActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadPermissionGroupsActionType.response:
      return {
        ...state,
        isFetching: false,
        permissiongroups: [...action.data],
        pageInfo: action.pageInfo,
      };
    case ReadPermissionGroupsActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    default:
      return state;
  }
}
