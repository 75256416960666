import { set, isToday, differenceInHours, parseISO } from 'date-fns';
import {
  FilterModel,
  Policy,
  PolicyCategoryName,
  PolicyCategoryTypes,
  PolicyName,
  PolicyValueType,
  User,
} from '../redux/constants';
import {
  getPolicies,
  getStartAndEndTimeFromPolicy,
  getStartAndEndTimeFromPolicyTz,
  sortHoursMinutesPoliciesArray,
} from './policy';
import { DateTime } from 'luxon';
import { toInteger } from 'lodash';

export const MINUTE_INTERVAL = 30;

export const getDates = (start?: Date, end?: Date): { startDate: Date; endDate: Date } => {
  const now = new Date();
  const startDate = set(now, {
    minutes: now.getMinutes() >= MINUTE_INTERVAL ? MINUTE_INTERVAL : 0,
    seconds: 0,
    milliseconds: 0,
  });
  const endDate = set(now, { hours: 18, minutes: startDate.getMinutes(), seconds: 0, milliseconds: 0 });

  if (now.getHours() > 18 || now.getHours() < 6) {
    endDate.setHours(now.getHours() + 1);
  }

  return {
    startDate: start || startDate,
    endDate: end || endDate,
  };
};

export const getHoursAndMinutesFromDate = (date: Date): { hours: number; minutes: number } => {
  return {
    hours: date.getHours(),
    minutes: date.getMinutes(),
  };
};

export const getInitStartDate = (date = new Date(), intervalMinutes = MINUTE_INTERVAL): Date => {
  const initDate = new Date(date);
  if (initDate.getHours() < 6) {
    initDate.setHours(6);
    initDate.setMinutes(0);
  } else if (initDate.getHours() >= 18) {
    initDate.setMinutes(0);
  }

  if (initDate.getDate() > new Date().getDate() && Math.abs(differenceInHours(initDate, new Date())) > 12) {
    initDate.setHours(6);
    initDate.setMinutes(0);
  }

  initDate.setMinutes(
    Math.floor(initDate.getMinutes() / intervalMinutes) * intervalMinutes === 60
      ? 0
      : Math.floor(initDate.getMinutes() / intervalMinutes) * intervalMinutes,
  );

  initDate.setSeconds(0);
  initDate.setMilliseconds(0);

  return initDate;
};

export const getInitEndDate = (date = new Date(), intervalMinutes = MINUTE_INTERVAL): Date => {
  const initDate = new Date(date);
  if (initDate.getHours() <= 18) {
    initDate.setHours(18);
    initDate.setMinutes(0);
  } else if (initDate.getHours() > 18) {
    initDate.setHours(6);
    initDate.setMinutes(0);
  }

  if (initDate.getDate() > new Date().getDate() && Math.abs(differenceInHours(initDate, new Date())) > 12) {
    initDate.setHours(18);
    initDate.setMinutes(0);
  }

  initDate.setMinutes(
    Math.floor(initDate.getMinutes() / intervalMinutes) * intervalMinutes === 60
      ? 0
      : Math.floor(initDate.getMinutes() / intervalMinutes) * intervalMinutes,
  );

  initDate.setSeconds(0);
  initDate.setMilliseconds(0);

  return initDate;
};

export const getInitDate = (date?: Date): Date => {
  const initDate = new Date(date) || new Date();
  if (initDate.getHours() < 6) {
    initDate.setHours(6);
    initDate.setMinutes(0);
  } else if (initDate.getHours() >= 18) {
    initDate.setDate(initDate.getDate() + 1);
    initDate.setHours(6);
    initDate.setMinutes(0);
  }
  if (initDate.getMinutes() < MINUTE_INTERVAL) {
    initDate.setMinutes(0);
  } else {
    initDate.setMinutes(MINUTE_INTERVAL);
  }
  initDate.setSeconds(0);
  initDate.setMilliseconds(0);
  return initDate;
};

interface GetPickerDatesArgs {
  extraStartDate?: Date;
  extraEndDate?: Date;
  user: User;
  scope: string;
  timezone?: string;
  allDay?: boolean;
  intervalMinutes?: number;
  categoryType?: PolicyCategoryTypes;
}

interface GetPickerDatesResponse {
  startDate: Date;
  endDate: Date;
}
/** Get the picker dates to initialise the pickers. */
export function getPickerDates({
  extraEndDate,
  extraStartDate,
  user,
  scope,
  timezone,
  allDay = true,
  intervalMinutes = MINUTE_INTERVAL,
  categoryType = PolicyCategoryTypes.DESK,
}: GetPickerDatesArgs): GetPickerDatesResponse {
  const dtNowtz = DateTime.now().setZone(timezone);
  const defaultMinutes = toInteger(dtNowtz.minute / intervalMinutes) * intervalMinutes;
  const [defaultStartDtTz, defaultEndDtTz] = getDefaultStartEndForTimezone(timezone);
  let startDtTz: DateTime;
  let endDtTz: DateTime;

  // if no date is passed set the dates as default
  if (!extraStartDate || !extraStartDate) {
    startDtTz = defaultStartDtTz;
    endDtTz = defaultEndDtTz;
  } else {
    // else set the dates to the passed date
    startDtTz = DateTime.fromJSDate(extraStartDate, { zone: timezone }).set({
      minute: 0,
      second: 0,
      millisecond: 0,
    });

    endDtTz = DateTime.fromJSDate(extraEndDate, { zone: timezone }).set({
      minute: 0,
      second: 0,
      millisecond: 0,
    });
  }

  // set dates to the same if start date is after end date
  if (startDtTz > endDtTz) {
    endDtTz = startDtTz;
  }

  /* get the policies for the default start and end times from the user object - these probably should come 
  from the resource though since currently you can be attached to an Access group through a user group that the resources you are
  seeing are not necessarily attached to. Sam Wrote a ticket for this in december 2022.
  */
  const defaultBookingTimePolicies = sortHoursMinutesPoliciesArray(
    getPolicies(user, scope, [
      { name: PolicyName.DEFAULT_BOOKING_HOURS, category: PolicyCategoryName.BOOKINGS, categoryType },
    ]),
  );

  // have to create a default start and end time policy in case user is a super admin attached to no access group 6am - 6pm
  const defaultStartEndTimePolicy: Policy = {
    id: '',
    name: PolicyName.DEFAULT_BOOKING_HOURS,
    value: { type: PolicyValueType.HOURS_MINUTES_ARRAY, value: ['06:00', '18:00'] },
    category: PolicyCategoryName.BOOKINGS,
    categoryType,
  };

  // if the policies are null then passed dates are returned - the passed dates must already be zoned date time objects
  const policyStartEndDtTz = getStartAndEndTimeFromPolicyTz(
    startDtTz,
    endDtTz,
    defaultBookingTimePolicies.length ? defaultBookingTimePolicies[0] : defaultStartEndTimePolicy,
  );
  startDtTz = policyStartEndDtTz.startTime;
  endDtTz = policyStartEndDtTz.endTime;

  // if now is greater than start then set start as now with the default mins
  if (dtNowtz > startDtTz) {
    startDtTz = dtNowtz.set({ minute: defaultMinutes, second: 0, millisecond: 0 });
  }

  /* if end time before or equal to startDtTz but before 12am set start time to now and set end time to policyStartEndDtTz.startTime
  which will be either the default of 6am or the policy start time
  */
  if (startDtTz >= endDtTz && dtNowtz.hour <= 24 && allDay) {
    endDtTz = policyStartEndDtTz.startTime.plus({ days: 1 });
  }
  // if after 12am use policyStartEndDtTz.startTime time - policyStartEndDtTz.endTime for the following day
  else if (startDtTz >= endDtTz && dtNowtz.hour >= 1 && dtNowtz.hour <= policyStartEndDtTz.startTime.hour && allDay) {
    startDtTz = policyStartEndDtTz.startTime.plus({ days: 1 });
    endDtTz = policyStartEndDtTz.endTime.plus({ days: 1 });

    // if not all day and start time greater than end time add one interval
  } else if (startDtTz >= endDtTz && dtNowtz.hour <= 24 && !allDay) {
    endDtTz = startDtTz.plus({ minutes: intervalMinutes });
  }

  return {
    startDate: startDtTz.toJSDate(),
    endDate: endDtTz.toJSDate(),
  };
}

interface GetPickerInitialDatesArgs {
  extraStartDate?: Date;
  extraEndDate?: Date;
  user: User;
  scope: string;
  is24Hour?: boolean;
  timezone?: string;
}

export const rebaseDate = (date: Date, startHour = 6): Date => {
  const newDate = new Date(date);
  newDate.setHours(startHour);
  newDate.setMinutes(0);
  newDate.setSeconds(0);
  newDate.setMilliseconds(0);

  return newDate;
};

export function roundMinutesDownToInterval(date: Date, interval = MINUTE_INTERVAL): number {
  const minutes = date.getMinutes();
  return Math.floor(minutes / interval) * interval;
}

interface FixDatesArgs {
  defaultStartDate: Date;
  defaultEndDate: Date;
  startDate?: Date;
  endDate?: Date;
  is24Hour?: boolean;
}

interface FixAfterHoursDatesArgs extends Pick<FixDatesArgs, 'defaultStartDate' | 'defaultEndDate'> {
  initStartDate: Date;
  initEndDate: Date;
  is24Hour?: boolean;
  intervalMinutes?: number;
}

interface FixAfterHoursDatesResponse {
  startDate: Date;
  endDate: Date;
  defaultStartDate: Date;
  defaultEndDate: Date;
}

export function fixAfterHoursDates({
  initStartDate,
  initEndDate,
  defaultStartDate,
  defaultEndDate,
  is24Hour,
  intervalMinutes = MINUTE_INTERVAL,
}: FixAfterHoursDatesArgs): FixAfterHoursDatesResponse {
  const now = new Date();
  now.setSeconds(0);
  now.setMilliseconds(0);

  //is today will have issues if building TZ is not browser tz!!!
  if (isToday(initStartDate)) {
    // First check if the start date is after the policy end date.
    if (now > initEndDate || now < initStartDate) {
      // Now is after the policy end date. Or before the start date.
      initEndDate.setFullYear(now.getFullYear());
      initEndDate.setMonth(now.getMonth());
      initEndDate.setDate(now.getDate());

      initStartDate.setFullYear(now.getFullYear());
      initStartDate.setMonth(now.getMonth());
      initStartDate.setDate(now.getDate());
      initStartDate.setHours(now.getHours());
      initStartDate.setMinutes(roundMinutesDownToInterval(now, intervalMinutes));

      // We now need to check if the end date is after hours
      if (isDateAfterHours({ date: initStartDate, defaultStartDate, defaultEndDate })) {
        // The end date is after hours so we need to set the date += 1 and the hours to = defaultStartDate Hours.
        if (isDateAfterDark({ date: initStartDate, defaultEndDate })) {
          // If the end date is before 12am, but after hours we need to increment the end dates day.
          initEndDate.setDate(initStartDate.getDate() + 1);
          initEndDate.setHours(defaultStartDate.getHours());
          initEndDate.setMinutes(defaultStartDate.getMinutes());
        } else {
          initStartDate.setHours(defaultStartDate.getHours());
          initStartDate.setMinutes(defaultStartDate.getMinutes());
          initEndDate.setHours(defaultEndDate.getHours());
          initEndDate.setMinutes(defaultEndDate.getMinutes());
        }
      }
    } else if (now > initStartDate) {
      // The time has already passed the default start date.
      initStartDate.setFullYear(now.getFullYear());
      initStartDate.setMonth(now.getMonth());
      initStartDate.setDate(now.getDate());
      initStartDate.setHours(now.getHours());
      initStartDate.setMinutes(roundMinutesDownToInterval(now, intervalMinutes));
    }
  }

  return {
    startDate: initStartDate,
    endDate: initEndDate,
    defaultStartDate,
    defaultEndDate,
  };
}

export function fixDates({
  defaultStartDate,
  defaultEndDate,
  startDate,
  endDate,
  is24Hour,
}: FixDatesArgs): FixAfterHoursDatesResponse {
  const initStartDate = rebaseDate(startDate || new Date(), 6);
  const initEndDate = rebaseDate(endDate || new Date(), 18);

  if (defaultStartDate) {
    initStartDate.setHours(defaultStartDate.getHours());
    initStartDate.setMinutes(defaultStartDate.getMinutes());
    initStartDate.setSeconds(defaultStartDate.getSeconds());
    initStartDate.setMilliseconds(defaultStartDate.getMilliseconds());
  }

  if (defaultEndDate) {
    initEndDate.setHours(defaultEndDate.getHours());
    initEndDate.setMinutes(defaultEndDate.getMinutes());
    initEndDate.setSeconds(defaultEndDate.getSeconds());
    initEndDate.setMilliseconds(defaultEndDate.getMilliseconds());
  }

  // If the date is not today, we keep it at 6am-6pm or the policy.
  // However if the date is today, we need to check if the current time is after the policy time.
  // Or before etc.
  return fixAfterHoursDates({ initStartDate, initEndDate, defaultStartDate, defaultEndDate, is24Hour });
}

interface PickerDatesResponse {
  startDate: Date;
  endDate: Date;
  defaultStartDate: Date;
  defaultEndDate: Date;
}

export function getPickerInitialDates({
  user,
  scope,
  extraEndDate,
  extraStartDate,
  is24Hour = true,
  timezone,
}: GetPickerInitialDatesArgs): PickerDatesResponse {
  const initStartDate = rebaseDate(extraStartDate || new Date(), 6);
  const initEndDate = rebaseDate(extraEndDate || new Date(), 18);

  const defaultBookingTimePolicies = sortHoursMinutesPoliciesArray(
    getPolicies(user, scope, [
      { name: 'Default Booking Start and End Time', category: 'Bookings', categoryType: PolicyCategoryTypes.DESK },
    ]),
  );

  const defaultBookingTimePolicyTimes = defaultBookingTimePolicies?.length
    ? getStartAndEndTimeFromPolicy(defaultBookingTimePolicies[0])
    : {
        startTime: initStartDate,
        endTime: initEndDate,
      };

  const defaultTimes = {
    startDate: defaultBookingTimePolicyTimes?.startTime || initStartDate,
    endDate: defaultBookingTimePolicyTimes?.endTime || initEndDate,
  };

  const defaultStartDate = new Date(defaultTimes.startDate);
  const defaultEndDate = new Date(defaultTimes.endDate);

  const dates = fixDates({
    startDate: initStartDate,
    endDate: initEndDate,
    defaultStartDate,
    defaultEndDate,
    is24Hour,
  });
  if (timezone && isToday(dates.startDate)) {
    const startUTCDate = dates.startDate.toISOString();
    const buildingTzStartDate = new Date(parseISO(startUTCDate).toLocaleString('en-US', { timeZone: timezone }));

    return {
      startDate: buildingTzStartDate,
      endDate: dates.endDate,
      defaultStartDate,
      defaultEndDate,
    };
  }

  return {
    startDate: dates.startDate,
    endDate: dates.endDate,
    defaultStartDate,
    defaultEndDate,
  };
}

/**
 * Check if the date is the inverse of the operation start and end date.
 * If it is, we will need to add 1 day to the endDate that we are using.
 */
export function isDateAfterHours({
  date,
  defaultStartDate,
  defaultEndDate,
}: {
  date: Date;
  defaultStartDate: Date;
  defaultEndDate: Date;
}): boolean {
  return date.getHours() >= defaultEndDate.getHours() || date.getHours() < defaultStartDate.getHours();
}

export function isDateAfterDark({ date, defaultEndDate }: { date: Date; defaultEndDate: Date }): boolean {
  return date.getHours() >= defaultEndDate.getHours();
}

export function is24HourOperation(user: User, scope?: string): boolean {
  const policies = getPolicies(user, scope, [
    {
      name: 'Access to book resources',
      category: 'Operational hours',
      categoryType: PolicyCategoryTypes.DESK,
    },
  ]);

  const hours = new Set<number>();
  policies.forEach((policy) => {
    if (Array.isArray(policy.value?.value)) {
      policy.value?.value.forEach((hour) => hours.add(Number(hour)));
    }
  });

  return hours.size === 24;
}

export function resetDate(date: Date): Date {
  return set(date, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
}

export function isSameDate(date1: Date, date2: Date): boolean {
  return resetDate(date1).getTime() === resetDate(date2).getTime();
}

/**
 * Convert the dates into a date object so the query string converts them into an ISO string.
 */
export function prepareQsDate(filter: FilterModel, fields: string[]): any {
  fields.forEach((field) => {
    if (filter[field]) {
      filter[field] = {
        ...filter[field],
        dateFrom: filter[field].dateFrom ? new Date(filter[field].dateFrom) : undefined,
        dateTo: filter[field].dateTo ? new Date(filter[field].dateTo) : undefined,
      } as any;
    }
  });
  return filter;
}

export const getOffsetDifferenceForDates = ({ tzA, tzB, date }: { tzA: string; tzB: string; date: string }): number => {
  const tzADate = new Date(parseISO(date).toLocaleString('en-US', { timeZone: tzA }));

  const tzBDate = new Date(parseISO(date).toLocaleString('en-US', { timeZone: tzB }));
  const diffTzStartOffset = tzADate.getTime() - tzBDate.getTime();
  return diffTzStartOffset;
};

/* this method returns the default start and end time in luxon dt which should be 
overwritten by policy. It uses the building tz to set the time to 6am - 6pm */
export function getDefaultStartEndForTimezone(timezone: string): [DateTime, DateTime] {
  const defaultStartDt = DateTime.now().setZone(timezone).startOf('day').set({
    hour: 6,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  const defaultEndDt = DateTime.now().setZone(timezone).startOf('day').set({
    hour: 18,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  return [defaultStartDt, defaultEndDt];
}

/* ONLY USE THIS FOR DISPLAYING DATE DO NOT USE THIS FOR DB AS IT WILL NOT BE ACCURATE
this is used because native date pickers insist on showing you the date for the current 
timezone so we must convert the date relative to the local time in order to display correctly
you must add the seconds difference back when processing the date!!! */

export interface DisplayDate {
  displayDate: Date;
  secondsDifference: number;
}

export function getLocalTimeForDisplayOnly({ date, timezone }: { date: Date; timezone: string }): DisplayDate {
  // to force the date to display correctly we have to convert local time to be the time of the timezone we are converting to
  const displayDate = new Date(DateTime.fromJSDate(date).setZone(timezone).toLocaleString(DateTime.DATETIME_MED));

  // because we have manipulated the time we need to add the seconds we changed back to the date before using it
  const secondsDifference = DateTime.fromJSDate(date).toSeconds() - DateTime.fromJSDate(displayDate).toSeconds();

  return { displayDate, secondsDifference };
}
