import React from 'react';
import { Badge, Button, Stack, Typography } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import { useNavigate } from 'react-router-dom';
import { classes } from '../../../styles/theme';
import { useFilterBookings } from '../../../providers/FilterBookings';
interface Props {
  title: string;
}
export default function TopBar({ title }: Props): JSX.Element {
  const { state } = useFilterBookings();
  let navigate = useNavigate();

  const onFilter = async () => {
    navigate('/filter', { replace: true });
  };

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
      <Typography variant="body1">{title}</Typography>
      <Badge badgeContent={state.filter.isFiltering ? undefined : undefined} color="primary">
        <Button variant="outlined" color="primary" style={classes.iconButton} onClick={onFilter}>
          <TuneIcon color="primary" />
        </Button>
      </Badge>
    </Stack>
  );
}
