import { QueryState } from '../..';
import {
  ReadConciergeRoomsAction,
  CreateRoomBookingSavedViewAction,
  ReadRoomBookingSavedViewsAction,
  DeleteRoomBookingSavedViewAction,
  ReadConciergeRoomBookingSchedulesAction,
  UpdateConciergeServicesRequestAction,
  ReadConciergeRoomBookingScheduleAction,
  UpdateConciergeRoomBookingScheduleAction,
} from '../../../../actions';
import {
  IRoomCalendar,
  ReadConciergeRoomsActionType,
  CreateRoomBookingSavedViewActionType,
  IRoomBookingSavedView,
  ReadRoomBookingSavedViewsActionType,
  DeleteRoomBookingSavedViewActionType,
  ReadConciergeRoomBookingSchedulesActionType,
  IConciergeBookingSchedule,
  UpdateConciergeServicesRequestActionType,
  ReadConciergeRoomBookingScheduleActionType,
  UpdateConciergeRoomBookingScheduleActionType,
  IServicesData,
  IResponseLinkRoomsBooking,
} from '../../../../constants';

export interface IConciergeBookingState extends QueryState {
  isFetching: boolean;
  rooms: IRoomCalendar[];
  savedViews: Omit<IRoomBookingSavedView, 'scope'>[];
  savedView: Omit<IRoomBookingSavedView, 'scope'>;
  schedules: IConciergeBookingSchedule[];
  schedule: IConciergeBookingSchedule;
  servicesData: IServicesData;
  linkRoomsBookings: IResponseLinkRoomsBooking[];

  error?: string;
}

const initState: IConciergeBookingState = {
  isFetching: false,
  rooms: [],
  savedViews: [],
  schedules: [],
  savedView: undefined,
  schedule: undefined,
  error: undefined,
  pageInfo: {
    total: 0,
  },
  servicesData: {
    it: [],
    facilities: [],
    catering: [],
  },
  linkRoomsBookings: [],
};

export function conciergeRoomBooking(
  state = initState,
  action:
    | ReadConciergeRoomsAction
    | CreateRoomBookingSavedViewAction
    | ReadRoomBookingSavedViewsAction
    | DeleteRoomBookingSavedViewAction
    | ReadConciergeRoomBookingSchedulesAction
    | UpdateConciergeServicesRequestAction
    | ReadConciergeRoomBookingScheduleAction
    | UpdateConciergeRoomBookingScheduleAction,
): IConciergeBookingState {
  switch (action.type) {
    /** Read Booking Saved Views */
    case ReadRoomBookingSavedViewsActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadRoomBookingSavedViewsActionType.response:
      return {
        ...state,
        isFetching: false,
        savedViews: action.data,
      };
    case ReadRoomBookingSavedViewsActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Create Booking Saved View */
    case CreateRoomBookingSavedViewActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case CreateRoomBookingSavedViewActionType.response:
      return {
        ...state,
        isFetching: false,
        savedView: action.data,
      };
    case CreateRoomBookingSavedViewActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Removed Booking Saved View */
    case DeleteRoomBookingSavedViewActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case DeleteRoomBookingSavedViewActionType.response:
      return {
        ...state,
        isFetching: false,
        savedViews: state.savedViews?.filter((item) => Number(item.id) !== Number(action.data.id)),
      };
    case DeleteRoomBookingSavedViewActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Read Rooms*/
    case ReadConciergeRoomsActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadConciergeRoomsActionType.response:
      return {
        ...state,
        isFetching: false,
        rooms: action.data,
      };
    case ReadConciergeRoomsActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Read Concierge Room Booking Schedules Action */
    case ReadConciergeRoomBookingSchedulesActionType.request:
      return {
        ...state,
        isFetching: true,
        schedules: [],
      };
    case ReadConciergeRoomBookingSchedulesActionType.response:
      return {
        ...state,
        isFetching: false,
        schedules: action.data,
      };
    case ReadConciergeRoomBookingSchedulesActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Update  Service & Catering Request */
    case UpdateConciergeServicesRequestActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case UpdateConciergeServicesRequestActionType.response:
      return {
        ...state,
        isFetching: false,
        schedules: [
          ...state.schedules.map((item) =>
            Number(item.bookingDetailId) === Number(action.data.bookingDetailId) ? action.data : item,
          ),
        ],
        schedule: action.data,
      };
    case UpdateConciergeServicesRequestActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Read Schedule : Booking Detail */
    case ReadConciergeRoomBookingScheduleActionType.request:
      return {
        ...state,
        isFetching: true,
        servicesData: {
          it: [],
          facilities: [],
          catering: [],
        },
        linkRoomsBookings: [],
      };
    case ReadConciergeRoomBookingScheduleActionType.response:
      return {
        ...state,
        isFetching: false,
        schedules: [
          ...state.schedules.map((item) =>
            Number(item.bookingDetailId) === Number(action.data.bookingDetailId) ? action.data : item,
          ),
        ],
        schedule: action.data,
        servicesData: action.servicesData,
        linkRoomsBookings: action.linkRoomsBookings,
      };
    case ReadConciergeRoomBookingScheduleActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Update Schedule : Booking Detail */
    case UpdateConciergeRoomBookingScheduleActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case UpdateConciergeRoomBookingScheduleActionType.response:
      return {
        ...state,
        isFetching: false,
        schedules: [
          ...state.schedules.map((item) =>
            Number(item.bookingDetailId) === Number(action.data.bookingDetailId) ? action.data : item,
          ),
        ],
        schedule: action.data,
      };
    case UpdateConciergeRoomBookingScheduleActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    default:
      return state;
  }
}
