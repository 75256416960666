import { IRecurringEventsDateSeriesResponse } from '.';
import { IRecurringEventAbsoluteMonthly } from '../../middlewares/nura-client/lib/redux/constants';

export const getAbsoluteMonthlyDateSeries = (
  data: IRecurringEventAbsoluteMonthly,
): IRecurringEventsDateSeriesResponse => {
  return {
    dates: [],
  };
};
