import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { sxCustom } from './styles';
import { Box, Stack, Typography } from '@mui/material';
import PageLoading from '../PageLoading';
type Props = {
  children?: JSX.Element;
  open?: boolean;
  handleClickOpen?: (value: boolean) => void;
  handleClickContinue?: (value: boolean) => void;
  handleClickCloseEvent?: (value: boolean) => void;
  textMessage?: string;
  loading?: boolean;
};

const DialogSuccessPage = ({
  children,
  open,
  handleClickOpen,
  handleClickContinue,
  handleClickCloseEvent,
  textMessage,
  loading = false,
}: Props): JSX.Element => {
  return (
    <Dialog
      open={open}
      onClose={() => handleClickOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        {loading && <PageLoading />}
        <Stack direction="column" justifyContent="space-between" alignItems="center" spacing={2}>
          <Box>
            <Typography variant="h5">Success!</Typography>
          </Box>
          <Box>
            <Typography variant="body1">{textMessage}</Typography>
          </Box>
          <Box>
            <CheckCircleOutlineIcon color="success" fontSize="large" sx={sxCustom.icon} />
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default DialogSuccessPage;
