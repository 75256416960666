export interface AppVersion {
  id: string;
  build: string;
  version: string;
  isRequired: boolean;
  notes?: string;
  minimumVersion: string;
  createdAt: string;
  updatedAt: string;
}

export enum ReadLatestAppVersionActionType {
  request = 'read.appversion.latest.request',
  response = 'read.appversion.latest.response',
  error = 'read.appversion.latest.error',
}
