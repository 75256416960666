import { Company } from '../company';
import { UserGroup } from '../usergroup';

export enum InvitationStatus {
  ACCEPTED = 'ACCEPTED',
  SENT = 'SENT',
  DECLINED = 'DECLINED',
  QUEUED = 'QUEUED',
  INACTIVE = 'INACTIVE',
}

export interface Invitation {
  id: string;
  email: string;
  firstName: string;
  lastName?: string;
  company: Company;
  status: InvitationStatus;
  userGroups?: UserGroup[];
  createdAt: string;
  updatedAt: string;
}

export enum CreateInvitationActionType {
  request = 'create.invitation.request',
  response = 'create.invitation.response',
  error = 'create.invitation.error',
}

export enum UpdateInvitationActionType {
  request = 'update.invitation.request',
  response = 'update.invitation.response',
  error = 'update.invitation.error',
}

export enum DeleteInvitationActionType {
  request = 'delete.invitation.request',
  response = 'delete.invitation.response',
  error = 'delete.invitation.error',
}
