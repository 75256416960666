import {
  CreateLockerBankActionType,
  ReadLockerBankActionType,
  LockerBank,
  ReadLockerBanksActionType,
  UpdateLockerBankActionType,
  LockerCluster,
  ReadLockerClustersActionType,
  ReadLockerClusterActionType,
  CreateLockerClusterActionType,
  UpdateLockerClusterActionType,
} from '../../../constants';
import {
  CreateLockerBankAction,
  CreateLockerClusterAction,
  ReadLockerBankAction,
  ReadLockerBanksAction,
  ReadLockerClusterAction,
  ReadLockerClustersAction,
  UpdateLockerBankAction,
  UpdateLockerClusterAction,
} from '../../../actions';
import { QueryState } from '..';

export interface LockerBankState extends QueryState {
  isFetching: boolean;
  lockerbanks: LockerBank[];
  error?: string;
  lockerbank: Map<string, LockerBank>;
}

const initState: LockerBankState = {
  isFetching: false,
  lockerbanks: [],
  lockerbank: new Map<string, LockerBank>(),
  pageInfo: {
    total: 0,
  },
};

export function lockerbank(
  state = initState,
  action: CreateLockerBankAction | ReadLockerBankAction | ReadLockerBanksAction | UpdateLockerBankAction,
): LockerBankState {
  switch (action.type) {
    /** READ */
    case ReadLockerBanksActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadLockerBanksActionType.response:
      return {
        ...state,
        isFetching: false,
        lockerbanks: [...action.data],
        pageInfo: action.pageInfo,
      };
    case ReadLockerBanksActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    case ReadLockerBankActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadLockerBankActionType.response:
      return {
        ...state,
        isFetching: false,
        lockerbank: state.lockerbank.set(action.data.id, action.data),
      };
    case ReadLockerBankActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** CREATE */
    case CreateLockerBankActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case CreateLockerBankActionType.response:
      return {
        ...state,
        isFetching: false,
        lockerbanks: [action.data, ...state.lockerbanks],
      };
    case CreateLockerBankActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** UPDATE */
    case UpdateLockerBankActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case UpdateLockerBankActionType.response:
      return {
        ...state,
        isFetching: false,
        lockerbanks: [...state.lockerbanks.map((user) => (user.id === action.data.id ? action.data : user))],
        lockerbank: state.lockerbank.set(action.data.id, action.data),
      };
    case UpdateLockerBankActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    default:
      return state;
  }
}

// Locker Cluster

export interface LockerClusterState extends QueryState {
  isFetching: boolean;
  lockerClusters: LockerCluster[];
  error?: string;
  lockerCluster: Map<string, LockerCluster>;
}

const clusterInitState: LockerClusterState = {
  isFetching: false,
  lockerClusters: [],
  lockerCluster: new Map<string, LockerCluster>(),
  pageInfo: {
    total: 0,
  },
};

export function lockerCluster(
  state = clusterInitState,
  action: CreateLockerClusterAction | ReadLockerClusterAction | ReadLockerClustersAction | UpdateLockerClusterAction,
): LockerClusterState {
  switch (action.type) {
    /** READ */
    case ReadLockerClustersActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadLockerClustersActionType.response:
      return {
        ...state,
        isFetching: false,
        lockerClusters: [...action.data],
        pageInfo: action.pageInfo,
      };
    case ReadLockerClustersActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    case ReadLockerClusterActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadLockerClusterActionType.response:
      return {
        ...state,
        isFetching: false,
        lockerCluster: state.lockerCluster.set(action.data.id.toString(), action.data),
      };
    case ReadLockerClusterActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** CREATE */
    case CreateLockerClusterActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case CreateLockerClusterActionType.response:
      return {
        ...state,
        isFetching: false,
        lockerClusters: [action.data, ...state.lockerClusters],
      };
    case CreateLockerClusterActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** UPDATE */
    case UpdateLockerClusterActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case UpdateLockerClusterActionType.response:
      return {
        ...state,
        isFetching: false,
        lockerClusters: [...state.lockerClusters.map((user) => (user.id === action.data.id ? action.data : user))],
        lockerCluster: state.lockerCluster.set(action.data.id, action.data),
      };
    case UpdateLockerClusterActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    default:
      return state;
  }
}
