import { QueryState } from '../..';
import { ActionOutlookOfficeAddIn } from '../../../../actions';
import {
  CreateMeetingRoomBookingFromOutlookActionType,
  IBooking,
  IBookingResponse,
  IMeetingRoomOrganizersForResponse,
  IOutlookAddInServiceTypesResponse,
  IResourceForRoomAvailabilities,
  ReadMeetingRoomAvailabilitiesWithServicesActionType,
  ReadMeetingRoomOrganizersForOutlookActionType,
  ReadMeetingRoomStatusActionType,
  ReadResourceForRoomBookingActionType,
  ReadResourcesForRoomAvailabilitiesActionType,
} from '../../../../constants';

export interface IOutlookAddInState extends QueryState {
  isFetching: boolean;
  availabilities: IBooking[];
  isFetchingResources: boolean;
  resources: IResourceForRoomAvailabilities;
  isFetchingResource: boolean;
  resource: IOutlookAddInServiceTypesResponse;
  isCreateBooking: boolean;
  booking: IBookingResponse;
  statusRoomSelected: boolean;
  isFetchingSchedule: boolean;
  organizers: IMeetingRoomOrganizersForResponse[];
  isFetchingOrganizer: boolean;
  error?: string;
}

export const initState: IOutlookAddInState = {
  isFetching: false,
  availabilities: [],
  isFetchingResources: false,
  isFetchingResource: false,
  resource: undefined,
  resources: {
    buildings: [],
    services: [],
  },
  booking: undefined,
  isCreateBooking: false,
  statusRoomSelected: true,
  isFetchingSchedule: false,
  organizers: [],
  isFetchingOrganizer: false,
  pageInfo: {
    total: 0,
  },
};

export function outlookAddIn(
  state = initState,
  action:
    | ActionOutlookOfficeAddIn.ReadMeetingRoomAvailabilitiesWithServicesAction
    | ActionOutlookOfficeAddIn.ReadResourcesForRoomAvailabilitiesAction
    | ActionOutlookOfficeAddIn.ReadResourceForRoomBookingAction
    | ActionOutlookOfficeAddIn.CreateMeetingRoomBookingFromOutlookAction
    | ActionOutlookOfficeAddIn.ReadMeetingRoomStatusAction
    | ActionOutlookOfficeAddIn.ReadMeetingRoomOrganizersForOutlookAction,
): IOutlookAddInState {
  switch (action.type) {
    /** Read meeting room availabilities */
    case ReadMeetingRoomAvailabilitiesWithServicesActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadMeetingRoomAvailabilitiesWithServicesActionType.response:
      return {
        ...state,
        isFetching: false,
        availabilities: [...action.data],
      };
    case ReadMeetingRoomAvailabilitiesWithServicesActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Read resources by company id */
    case ReadResourcesForRoomAvailabilitiesActionType.request:
      return {
        ...state,
        isFetchingResources: true,
      };
    case ReadResourcesForRoomAvailabilitiesActionType.response:
      return {
        ...state,
        isFetchingResources: false,
        resources: action.data,
      };
    case ReadResourcesForRoomAvailabilitiesActionType.error:
      return {
        ...state,
        isFetchingResources: false,
        error: action.error.message,
      };

    /** Read resources by company id & Resource Id */
    case ReadResourceForRoomBookingActionType.request:
      return {
        ...state,
        isFetchingResource: true,
      };
    case ReadResourceForRoomBookingActionType.response:
      return {
        ...state,
        isFetchingResource: false,
        resource: action.data,
      };
    case ReadResourceForRoomBookingActionType.error:
      return {
        ...state,
        isFetchingResource: false,
        error: action.error.message,
      };

    /** Create a booking */
    case CreateMeetingRoomBookingFromOutlookActionType.request:
      return {
        ...state,
        isCreateBooking: true,
      };
    case CreateMeetingRoomBookingFromOutlookActionType.response:
      return {
        ...state,
        isCreateBooking: false,
        booking: action.data,
      };
    case CreateMeetingRoomBookingFromOutlookActionType.error:
      return {
        ...state,
        isCreateBooking: false,
        error: action.error.message,
      };

    /** Read meeting room schedule */
    case ReadMeetingRoomStatusActionType.request:
      return {
        ...state,
        isFetchingSchedule: true,
      };
    case ReadMeetingRoomStatusActionType.response:
      return {
        ...state,
        isFetchingSchedule: false,
        statusRoomSelected: action.data.isFree,
      };
    case ReadMeetingRoomStatusActionType.error:
      return {
        ...state,
        isFetchingSchedule: false,
        error: action.error.message,
      };
    /** Read Organizers */
    case ReadMeetingRoomOrganizersForOutlookActionType.request:
      return {
        ...state,
        isFetchingOrganizer: true,
      };
    case ReadMeetingRoomOrganizersForOutlookActionType.response:
      return {
        ...state,
        isFetchingOrganizer: false,
        organizers: action.data,
      };
    case ReadMeetingRoomOrganizersForOutlookActionType.error:
      return {
        ...state,
        isFetchingOrganizer: false,
        error: action.error.message,
      };
    default:
      return state;
  }
}
