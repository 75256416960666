export enum DeviceTypes {
  QR = 'QR',
  NSD = 'NSD',
  CMS_SB = 'CMS_SB',
  CMS_OS = 'CMS_OS',
}

export interface DeviceDetails {
  serialNumber: string;
  bleAddress?: string;
  deviceType: string;
  deviceManufacturer?: string;
}

export interface DeviceType {
  id: string;
  name: DeviceTypes;
  description: string;
  isThirdParty?: boolean;
  active?: boolean;
}

export interface DeviceManufacturer {
  id: string;
  name: string;
  description: string;
  active?: boolean;
  settingColumnName?: string;
}

export enum ReadDeviceTypesActionType {
  request = 'read.device.types.request',
  response = 'read.device.types.response',
  error = 'read.device.types.error',
}

export enum ReadDeviceManufacturersActionType {
  request = 'read.device.manufacturers.request',
  response = 'read.device.manufacturers.response',
  error = 'read.device.manufacturers.error',
}
