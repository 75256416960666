import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ICurrentUser, ICurrentUserContext } from '../../constants/Auth';
import { AuthContext } from '../../contexts';
import { getOfficeUserInfo } from '../../services/Office/services';

type Props = {
  children: JSX.Element;
};

function AuthProvider({ children }: Props): JSX.Element {
  const [officeUser, setOfficeUser] = useState<ICurrentUser>();
  const getOfficeUserProfile = async () => {
    const userInfo = await getOfficeUserInfo();
    setOfficeUser(userInfo);
  };

  useEffect(() => {
    Office.onReady(() => {
      getOfficeUserProfile();
    });
  }, []);

  const value: ICurrentUserContext = useMemo(
    () => ({
      officeUser,
    }),
    [officeUser],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

const useAuthentication = (): ICurrentUserContext => useContext(AuthContext);

export { useAuthentication };

export default AuthProvider;
