import React, { useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import { useBookingDetails } from '../../../contexts/BookingDetails';
import { format } from 'date-fns';
import { getTextDateSeries } from '../../../helpers/recurrence';
import { EOptionRecurrencePatternType } from '../../../middlewares/nura-client/lib/redux/constants';

const TextRecurringBooking = (): JSX.Element => {
  const { state: bookingState } = useBookingDetails();
  const { recurringEvents } = bookingState;

  const renderText = useMemo(() => {
    const { weekly, range } = recurringEvents;

    let OccursText = <b>{getTextDateSeries(bookingState)}</b>;
    let StartingText = <></>;

    if (recurringEvents?.range?.startDate) {
      StartingText = range?.startDate ? (
        <>
          {format(new Date(range.startDate), 'EEEE')}, {format(new Date(range.startDate), 'dd MMMM yyyy')}.
        </>
      ) : (
        <></>
      );
    }

    if (weekly?.pattern?.type && weekly?.pattern?.daysOfWeek?.length === 0) return null;
    if (bookingState.recurringEvents.selectedUIType === EOptionRecurrencePatternType.DOES_NOT_REPEAT) return null;

    return (
      <Typography variant="caption" component="span">
        Occurs {OccursText} starting {StartingText}
      </Typography>
    );
  }, [recurringEvents, recurringEvents?.weekly?.pattern?.daysOfWeek]);

  return (
    <Grid item xs={12}>
      {renderText}
    </Grid>
  );
};

export default TextRecurringBooking;
