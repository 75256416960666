import { v4 as uuidv4 } from 'uuid';
import JwtDecode from 'jwt-decode';
import axios, { AxiosResponse } from 'axios';

import { hasLocalStorage } from './storage';
import {
  BROWSER_ID_KEY,
  EXPIRY_WITHIN_AMOUNT,
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  CURRENT_COMPANY_KEY,
  API_URL,
  MS365_TOKEN_KEY,
  REDIRECT_LINK_KEY,
  LOBBY_DISPLAY_SCREEN,
  MY_TEAM,
  OUTLOOK_ID_KEY,
  BLOB_QUERY_PARAMS_KEY,
  BLOB_URL_KEY,
} from '../constants';
import FingerprintService from '../services/fingerprint';
import { DeviceMeta } from '../../../nura-client/lib/redux/constants';
import { getIpDetails, TraceData } from '../../../nura-client/lib/helpers/ip';

export function getTokensFromLocalStorage(
  accessTokenKey?: string,
  refreshTokenKey?: string,
): { accessToken: string | null; refreshToken: string | null } {
  if (!hasLocalStorage()) throw new Error('Local storage is unavailable.');
  return {
    accessToken: localStorage.getItem(accessTokenKey),
    refreshToken: localStorage.getItem(refreshTokenKey),
  };
}

/**
 * Creates a unique browser id, used for device authentication.
 */
export function createBrowserId(): void {
  if (!localStorage.getItem(BROWSER_ID_KEY)) {
    localStorage.setItem(BROWSER_ID_KEY, uuidv4());
  }
}

export function getBrowserId(): Promise<string> {
  return FingerprintService.instance.getVisitorId();
}

/**
 * Check if jwt is expired
 */
export function isExpired(expiry: number): boolean {
  return Date.now() >= expiry * 1000;
}

/**
 * Check if the token is about to expire.
 */
export function almostExpired(expiry: number): boolean {
  const exp = expiry * 1000;

  return exp - Date.now() <= EXPIRY_WITHIN_AMOUNT;
}

export function getCompanyId(): string | undefined {
  const id = localStorage.getItem(CURRENT_COMPANY_KEY);
  return id ? id : undefined;
}

export function getTokens(): { accessToken: string | null; refreshToken: string | null } {
  return getTokensFromLocalStorage(ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY);
}

export interface AccessTokenDecoded {
  userId: string | null;
  deviceId: string | null;
  email: string;
  iat: number;
  exp: number;
}

export interface RefreshTokenDecoded {
  userId: string | null;
  deviceId: string | null;
  iat: number;
  exp: number;
  email: string;
}

export function decodeAccessToken(accessToken: string): AccessTokenDecoded {
  return JwtDecode<AccessTokenDecoded>(accessToken);
}

export function decodeRefreshToken(refreshToken: string): RefreshTokenDecoded {
  return JwtDecode<RefreshTokenDecoded>(refreshToken);
}

export function storeAccessToken(accessToken: string): void {
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
}

export function storeRefreshToken(refreshToken: string): void {
  localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
}

export function storeTokens(accessToken: string, refreshToken: string): void {
  storeAccessToken(accessToken);
  storeRefreshToken(refreshToken);
}

export function storeBlobStorage(blobUrl: string, blobQueryParams: string): void {
  localStorage.setItem(BLOB_URL_KEY, blobUrl);
  localStorage.setItem(BLOB_QUERY_PARAMS_KEY, blobQueryParams);
}

export function clearTokens(): void {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  localStorage.removeItem(CURRENT_COMPANY_KEY);
  localStorage.removeItem(REDIRECT_LINK_KEY);
  localStorage.removeItem(MY_TEAM);
  localStorage.removeItem(LOBBY_DISPLAY_SCREEN);
  localStorage.removeItem(BLOB_URL_KEY);
  localStorage.removeItem(BLOB_QUERY_PARAMS_KEY);

  localStorage.clear();
}

export function clearRedirectLink(): void {
  localStorage.removeItem(REDIRECT_LINK_KEY);
}

export async function clearLocalStorage(): Promise<void> {
  return new Promise((resolve) => {
    clearTokens();
    resolve();
  });
}

export async function getRefreshedAccessToken(refreshToken: string): Promise<string | null> {
  if (!refreshToken) {
    throw new Error('There is no refresh token, cannot refresh access token.');
  }
  const ipDetails = getIpDetails();
  const res = await fetch(API_URL + '/auth/token', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
    body: JSON.stringify({
      refreshToken: refreshToken || '',
      ipDetails: ipDetails,
    }),
  }).then<{
    data: {
      accessToken: string;
      blobStorage: {
        url: string;
        queryParams: string;
      };
    };
  }>((r) => r.json());

  // Save new blob storage url and query params
  if (res.data && res.data.blobStorage) {
    storeBlobStorage(res.data.blobStorage.url, res.data.blobStorage.queryParams);
  }
  if (res.data && res.data.accessToken) {
    // We save the new access token for subsequent requests.
    storeAccessToken(res.data.accessToken);

    return res.data.accessToken;
  }

  return null;
}

export function isTokenExpired(exp: number): boolean {
  return Date.now() >= exp * 1000;
}

export function isTokenAboutToExpire(exp: number, iat?: number): boolean {
  const expMs = exp * 1000;
  const expThres = 5 * 60 * 1000; // 5 minutes
  return Date.now() >= expMs - expThres;
}

export async function requestLogout({ refreshToken }: { refreshToken?: string | null }): Promise<void> {
  try {
    await axios.put(`${API_URL}/auth/logout`, { refreshToken });
  } catch (err) {
    console.warn(err);
  }
}

export interface LoginResponse {
  data: {
    accessToken: string;
    refreshToken: string;
    mfaRequired?: boolean;
    qrCode?: string;
    blobStorage: {
      url: string;
      queryParams: string;
    };
  };
}

export async function requestLogin({
  email,
  password,
  deviceId,
  user2faToken,
  meta,
  ipDetails,
}: {
  email: string;
  password: string;
  deviceId: string;
  user2faToken?: string;
  meta?: DeviceMeta;
  ipDetails?: TraceData;
}): Promise<AxiosResponse<LoginResponse>> {
  return axios.post<LoginResponse>(`${API_URL}/auth/login`, {
    email,
    password,
    deviceId,
    user2faToken,
    meta,
    ipDetails,
  });
}

export async function requestLoginWithProvider<T>({
  idToken,
  provider,
  deviceId,
  user,
  meta,
  ipDetails,
  ...props
}: {
  idToken: string;
  provider: string;
  deviceId: string;
  user: {
    companyName?: string;
    departments?: string;
    employeeId?: string;
  };
  meta?: DeviceMeta;
  ipDetails?: TraceData;
  [index: string]: any;
}): Promise<AxiosResponse<LoginResponse & T>> {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone; // Rejoin Feature : This is for date and time format in email.

  return axios.post<LoginResponse & T>(`${API_URL}/auth/loginWithProvider`, {
    idToken,
    provider,
    deviceId,
    user,
    meta,
    ipDetails,
    userTz: tz || 'Australia/Melbourne',
    ...props,
  });
}

export function storeMSToken(token: string): void {
  localStorage.setItem(MS365_TOKEN_KEY, token);
}

export function getMSTokenFromLocalStorage(): { accessToken: string | undefined } {
  return {
    accessToken: localStorage.getItem(MS365_TOKEN_KEY) ? localStorage.getItem(MS365_TOKEN_KEY) : undefined,
  };
}

export function getRedirectLinkFromStorage(redirectLinkKey?: string): {
  link: string | null;
} {
  if (!hasLocalStorage()) throw new Error('Local storage is unavailable.');
  return { link: localStorage.getItem(redirectLinkKey) };
}

export function getRedirectLink(): { link: string | null } {
  return getRedirectLinkFromStorage(REDIRECT_LINK_KEY);
}

export function storeRedirectLink(redirectLink: string): void {
  localStorage.setItem(REDIRECT_LINK_KEY, redirectLink);
}

export function getLobbyDisplayScreen(): Boolean {
  if (!localStorage.getItem(LOBBY_DISPLAY_SCREEN)) {
    return false;
  }

  if (Number(localStorage.getItem(LOBBY_DISPLAY_SCREEN)) === 1) {
    return true;
  }
  return false;
}

export function storeLobbyDisplayScreen(value: Boolean): void {
  localStorage.setItem(LOBBY_DISPLAY_SCREEN, value ? '1' : '0');
}

export function clearLobbyDisplayScreen(): void {
  localStorage.removeItem(LOBBY_DISPLAY_SCREEN);
}

export function setOutlookDeviceId(id: string): void {
  localStorage.setItem(OUTLOOK_ID_KEY, id);
}

export function getOutlookDeviceId(): string {
  return localStorage.getItem(OUTLOOK_ID_KEY);
}
