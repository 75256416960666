import {
  decodeAccessToken,
  getTokens,
  isTokenAboutToExpire,
  isTokenExpired,
  getBrowserId,
  decodeRefreshToken,
  getLobbyDisplayScreen,
  getOutlookDeviceId,
} from '../helpers/auth';
import { API_URL } from '../constants';
import AuthEventHub from '../events/auth';
import AuthTokenService from './auth';

export interface DefaultResponse {
  status: number;
}
export interface ICustomHeaderProps {
  outlookId?: boolean;
}

export const defaultRequestOptions = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

export type MethodType = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
export interface Api {
  get<T>(query?: string, additionalOptions?: RequestInit): Promise<T & ResponseType>;
  patch<T>(id?: string, body?: any, additionalOptions?: RequestInit): Promise<T & ResponseType>;
  post<T>(body?: any, additionalOptions?: RequestInit): Promise<T & ResponseType>;
  put<T>(id?: string, body?: any, additionalOptions?: RequestInit): Promise<T & ResponseType>;
  delete<T>(id?: string, additionalOptions?: RequestInit): Promise<T & ResponseType>;
  upload<T>(body: any, additionalOptions?: RequestInit): Promise<T & ResponseType>;
  req<T>(method: MethodType, body?: any, additionalOptions?: RequestInit): Promise<T & ResponseType>;
  getFile: (
    customOptions?: RequestInit & {
      validateStatus?: (status: number) => boolean;
    },
  ) => Promise<Response>;
}

const defaultValidateStatus = (status: number) => status >= 200 && status < 300;

export function api(endpoint: string, customHeader?: ICustomHeaderProps): Api {
  async function getRequestOptions(customOptions: RequestInit = {}) {
    const isLobbyDisplayScreen = getLobbyDisplayScreen() || false; // If it is true and then The user will always login

    const browserId = !customHeader?.outlookId ? await getBrowserId() : getOutlookDeviceId();

    const headers: { [index: string]: string } = {
      f: browserId,
    };

    const { accessToken, refreshToken } = getTokens();

    let newAccessToken = accessToken;
    if (accessToken && refreshToken) {
      if (!isLobbyDisplayScreen) {
        const decodedRefreshToken = decodeRefreshToken(refreshToken);
        // We first check if the refresh token has expired. If so we need to logout the user.
        if (!decodedRefreshToken || isTokenExpired(decodedRefreshToken.exp)) {
          AuthEventHub.instance.emit(AuthEventHub.REFRESH_TOKEN_EXPIRED);
          return;
        }
      }

      const decoded = decodeAccessToken(accessToken);
      if (isTokenAboutToExpire(decoded.exp, decoded.iat) || isTokenExpired(decoded.exp)) {
        // Token about to expire
        // Lets refresh it.
        const refreshedToken = await AuthTokenService.instance.handleRefreshAccessToken();
        if (refreshedToken) {
          newAccessToken = refreshedToken;
        }
      }
    }

    if (newAccessToken) {
      headers.Authorization = `Bearer ${newAccessToken}`;
    }

    const options: RequestInit = {
      ...customOptions,
      headers: {
        ...headers,
        ...customOptions.headers,
      },
    };

    return options;
  }

  async function customFetch<T>(path: string, customOptions?: RequestInit): Promise<T & ResponseType> {
    const options = await getRequestOptions(customOptions);
    const _path = path.startsWith('http') ? path : `${API_URL}${path}`;
    return fetch(_path, options).then(async (res) => {
      const defaultResponse = { status: res.status };
      if (res.status < 200 || res.status >= 300) {
        const json = await res.json();
        if (res.status === 401) {
          // Unauthorized so we logout
          AuthEventHub.instance.emit(AuthEventHub.REFRESH_TOKEN_EXPIRED);
        } else if (res.status === 403) {
          // Forbidden so we logout
          AuthEventHub.instance.emit(AuthEventHub.FORBIDDEN, json);
        }

        throw {
          ...defaultResponse,
          ...json,
        };
      }
      try {
        const json = await res.json();
        if (!json) return defaultResponse;
        return {
          ...defaultResponse,
          ...json,
        };
      } catch (e) {
        return defaultResponse;
      }
    });
  }

  async function uploadFetch<T>(path: string, customOptions?: RequestInit): Promise<T & ResponseType> {
    return customFetch<T>(path, customOptions);
  }

  async function get<ReturnType>(query?: string, additionalOptions?: RequestInit) {
    return customFetch<ReturnType>(query ? `${endpoint}${query}` : endpoint, {
      method: 'GET',
      ...defaultRequestOptions,
      ...additionalOptions,
    });
  }

  async function patch<ReturnType>(id?: string, body?: any, additionalOptions?: RequestInit) {
    return customFetch<ReturnType>(id ? `${endpoint}/${id}` : endpoint, {
      method: 'PATCH',
      body: JSON.stringify(body || {}),
      ...defaultRequestOptions,
      ...additionalOptions,
    });
  }

  async function put<ReturnType>(id?: string, body?: any, additionalOptions?: RequestInit) {
    return customFetch<ReturnType>(id ? `${endpoint}/${id}` : endpoint, {
      method: 'PUT',
      body: JSON.stringify(body || {}),
      ...defaultRequestOptions,
      ...additionalOptions,
    });
  }

  async function post<ReturnType>(body: any, additionalOptions?: RequestInit) {
    return customFetch<ReturnType>(endpoint, {
      method: 'POST',
      body: JSON.stringify(body || {}),
      ...defaultRequestOptions,
      ...additionalOptions,
    });
  }

  async function del<ReturnType>(id?: string, body?: any, additionalOptions?: RequestInit) {
    if (!id || !body) body = {}; //Safari - Cannot remove usergroup from user profile
    return customFetch<ReturnType>(id ? `${endpoint}/${id}` : endpoint, {
      method: 'DELETE',
      body: JSON.stringify(body),
      ...defaultRequestOptions,
      ...additionalOptions,
    });
  }

  async function upload<ReturnType>(body: any, additionalOptions?: RequestInit) {
    return uploadFetch<ReturnType>(endpoint, {
      method: 'POST',
      body,
      ...additionalOptions,
    });
  }

  async function req<ReturnType>(method: MethodType, body?: any, additionalOptions?: RequestInit) {
    return customFetch<ReturnType>(endpoint, {
      method,
      body: typeof body === 'object' ? JSON.stringify(body) : undefined,
      ...defaultRequestOptions,
      ...additionalOptions,
    });
  }

  async function getFile(
    customOptions?: RequestInit & {
      validateStatus?: (status: number) => boolean;
    },
  ): Promise<Response> {
    const validateStatus = customOptions?.validateStatus || defaultValidateStatus;
    const options = await getRequestOptions(customOptions);
    const _path = endpoint.startsWith('http') ? endpoint : `${API_URL}${endpoint}`;
    const resp = await fetch(_path, {
      method: 'GET',
      ...options,
    });
    if (!validateStatus(resp.status)) {
      throw resp;
    }
    return resp;
  }

  return {
    get,
    patch,
    put,
    post,
    delete: del,
    upload,
    req,
    getFile,
  };
}
