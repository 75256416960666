import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { api, DefaultResponse } from '../../../../../../../nura-helpers/lib/services/api';
import { RootState } from '../../../../reducers';
import { getCompanyId } from '../../../../../../../nura-helpers/lib/helpers/auth';
import { DeleteMeetingRoomBookingFromOutlookActionType, IDeleteEventFromOutlookParams } from '../../../../constants';

export interface DeleteMeetingRoomBookingFromOutlookAction
  extends Action<DeleteMeetingRoomBookingFromOutlookActionType> {
  error?: any;
  data?: {
    isDeleted: boolean;
  };
}

export interface DeleteMeetingRoomBookingFromOutlookActionResult {
  data: {
    isDeleted: boolean;
  };
}

type DeleteMeetingRoomBookingFromOutlookActionResponse = DefaultResponse &
  DeleteMeetingRoomBookingFromOutlookActionResult;

export async function deleteMeetingRoomBookingFromOutlookAsync(
  params: Pick<IDeleteEventFromOutlookParams, 'accessToken' | 'organizer' | 'id'>,
): Promise<DeleteMeetingRoomBookingFromOutlookActionResult> {
  const scope = getCompanyId();

  const response = await api('/api/outlook/event', {
    outlookId: true,
  }).post<DeleteMeetingRoomBookingFromOutlookActionResponse>({
    id: `${params.id}`,
    accessToken: params.accessToken,
    organizer: params.organizer,
    scope: `${scope}`,
  });

  if (![200, 201, 204].includes(response.status)) throw response;

  if (!response || !response.data) {
    throw response;
  }

  return {
    data: response.data,
  };
}

export function deleteMeetingRoomBookingFromOutlook(
  params: Pick<IDeleteEventFromOutlookParams, 'accessToken' | 'organizer' | 'id'>,
): ThunkAction<
  Promise<DeleteMeetingRoomBookingFromOutlookActionResult>,
  RootState,
  unknown,
  DeleteMeetingRoomBookingFromOutlookAction
> {
  return async (dispatch) => {
    try {
      dispatch({
        type: DeleteMeetingRoomBookingFromOutlookActionType.request,
      });

      const response = await deleteMeetingRoomBookingFromOutlookAsync(params);

      dispatch({
        type: DeleteMeetingRoomBookingFromOutlookActionType.response,
        data: response.data,
      });
      return {
        data: response.data,
      };
    } catch (e) {
      dispatch({
        type: DeleteMeetingRoomBookingFromOutlookActionType.error,
        error: e,
      });
      throw e;
    }
  };
}
