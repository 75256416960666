import React, { useEffect, useMemo, useState } from 'react';
import { Box, Stack } from '@mui/material';
import TopBar from './TopBar';
import BookingCards from './BookingCards';
import { useSnackbar } from 'notistack';
import { useMailbox } from '../../contexts';
import { dateService } from '../../services';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../middlewares/nura-client/lib/redux/reducers';
import { ReducerOutlookOfficeAddIn } from '../../middlewares/nura-client/lib/redux/reducers/api/officeAddIns';
import PageLoading from '../../components/PageLoading';
import { ActionOutlookOfficeAddIn } from '../../middlewares/nura-client/lib/redux/actions';
import { officeService } from '../../services/Office';
import { getTokens } from '../../middlewares/nura-helpers/lib/helpers/auth';
import { useFilterBookings } from '../../providers/FilterBookings';
import { ParamsReadMeetingRoomAvailabilitiesWithServices } from '../../middlewares/nura-client/lib/redux/actions/api/officeAddIns/outlook';
import { useNavigate } from 'react-router-dom';
import { useBookingDetails } from '../../contexts/BookingDetails';
import { differenceInHours, differenceInMinutes, endOfDay, endOfToday, startOfDay } from 'date-fns';
import { EMeetingRoomRoomServiceType } from '../../middlewares/nura-client/lib/redux/constants';
import useWindowDimensions from '../../hooks/useWindowDimensions';

export default function Bookings(): JSX.Element {
  const dispatch = useDispatch();
  const { height: windowsHeight } = useWindowDimensions();
  const { enqueueSnackbar } = useSnackbar();
  const { readMeetingRoomAvailabilitiesWithServices } = ActionOutlookOfficeAddIn;
  const { accessToken: nuraAccessToken } = getTokens();
  const { dispatch: bookingDispatch } = useBookingDetails();
  const { state: mailboxState, dispatch: mailboxDispatch } = useMailbox();
  let navigateRoute = useNavigate();
  const [isFetching, setIsFetching] = useState<boolean>(true);

  /** States */
  const { state: filterState } = useFilterBookings();
  const { isFetching: isFetchingResources, resources } = useSelector<
    RootState,
    ReducerOutlookOfficeAddIn.IOutlookAddInState
  >(({ outlookAddIn }) => outlookAddIn);

  //on office ready
  useEffect(() => {
    Office.onReady(async () => {
      const clearBookingState = async () => {
        bookingDispatch({ type: 'clear', payload: {} });

        await Office.context.mailbox.item.location.setAsync('');
        mailboxDispatch({
          type: 'setLocation',
          payload: {
            location: undefined,
          },
        });

        mailboxDispatch({
          type: 'setBody',
          payload: {
            body: undefined,
          },
        });
      };
      clearBookingState();

      /** Set Times : get from outlooks */
      Office.context.mailbox.item.start.getAsync((asyncStartResult: Office.AsyncResult<Date>) => {
        if (asyncStartResult.status === Office.AsyncResultStatus.Succeeded) {
          Office.context.mailbox.item.end.getAsync((asyncEndResult: Office.AsyncResult<Date>) => {
            if (asyncEndResult.status === Office.AsyncResultStatus.Succeeded) {
              mailboxDispatch({
                type: 'setTime',
                payload: {
                  start: asyncStartResult.value.toUTCString(),
                  end: asyncEndResult.value.toUTCString(),
                },
              });
            }
          });
        }
      });
    });

    if (!nuraAccessToken) {
      navigateRoute('/login', { replace: true });
    }
  }, [nuraAccessToken]);

  /** Fetching Room Availabilities by date times or locations */
  async function fetchAvailabilities() {
    try {
      setIsFetching(true);
      let currentStart = new Date(mailboxState.start);

      const checkDurationsWitCurrentTime = differenceInMinutes(currentStart, new Date());

      const currentEndOfDay = endOfDay(currentStart);

      const checkDurationsWitEndOfDay = differenceInHours(currentEndOfDay, currentStart);

      const checkDurationsAllDay = differenceInHours(new Date(mailboxState.end), new Date(mailboxState.start));

      if (checkDurationsWitCurrentTime < 0 && checkDurationsWitEndOfDay < 23 && checkDurationsAllDay < 24) {
        enqueueSnackbar(`This event occurs in the past.`, { variant: 'error', autoHideDuration: 2000 });
        return;
      }

      const deviceId = await officeService.getAndSetOutlookId();
      if (deviceId) {
        const userAccessToken = await officeService.getAccessToken();
        if (userAccessToken && mailboxState.start && mailboxState.end && deviceId) {
          //Check Date & Time

          const newParams: ParamsReadMeetingRoomAvailabilitiesWithServices = {
            accessToken: userAccessToken,
            currentDate: new Date().toISOString(),
            start: checkDurationsWitEndOfDay > 20 ? startOfDay(currentStart).toISOString() : mailboxState.start,
            end: checkDurationsWitEndOfDay > 20 ? currentEndOfDay.toISOString() : mailboxState.end,
            locations: mailboxState.location,
            locationDetails: mailboxState.locationDetails,
            management: {
              managed: false,
              unmanaged: false,
            },
            services: [],
            capacity: filterState?.filter?.capacity ? Number(filterState?.filter?.capacity) : 0,
          };

          //Check Filter
          if (filterState.filter.isFiltering) {
            //service types

            if (filterState.filter.management.types.length > 0) {
              filterState.filter.management.data.map((itemMType) => {
                const findSelected = filterState.filter.management.types.find(
                  (idTypeSelected) => Number(idTypeSelected) === Number(itemMType.id),
                );
                if (findSelected) {
                  if (itemMType.displayName.toLowerCase() === EMeetingRoomRoomServiceType.MANAGED.toLowerCase()) {
                    newParams.management.managed = true;
                  }
                  if (itemMType.displayName.toLowerCase() === EMeetingRoomRoomServiceType.UNMANAGED.toLowerCase()) {
                    newParams.management.unmanaged = true;
                  }
                }

                return itemMType;
              });
            }

            if (filterState.filter.management.services.length > 0 && resources?.services.length > 0) {
              newParams.services = filterState.filter.management.services;
            }

            if (filterState.filter.building) newParams.building = Number(filterState.filter.building.id);
            if (filterState.filter.floor) newParams.floor = Number(filterState.filter.floor.id);
          }

          await dispatch(readMeetingRoomAvailabilitiesWithServices(newParams));
        }
      }
    } catch (error) {
      enqueueSnackbar(`Error: ${error?.message}`, { variant: 'error', autoHideDuration: 2000 });
      if (Number(error?.status) === 403 || Number(error?.status) === 401) {
        localStorage.clear();
        navigateRoute('/login', { replace: true });
      }
    }
    setIsFetching(false);
  }
  useEffect(() => {
    if (nuraAccessToken) fetchAvailabilities();
  }, [mailboxState, nuraAccessToken, filterState.filter, resources.services]);

  /** Render Date */
  const renderTopBar = useMemo(() => {
    const dateSelected = mailboxState.start
      ? dateService.formatDateTime({
          date: new Date(mailboxState.start),
          format: 'PPPP',
        })
      : dateService.formatDateTime({
          date: new Date(),
          format: 'PPPP',
        });

    return (
      <Box component="div" sx={{ px: 1, height: 'auto' }}>
        <TopBar title={dateSelected} />
      </Box>
    );
  }, [mailboxState.start]);

  /** Render schedules */
  const renderAvailabilities = useMemo(() => {
    let currentStart = new Date(mailboxState.start);
    const currentEndOfDay = endOfToday();
    const checkDurationsWitEndOfDay = differenceInHours(currentEndOfDay, currentStart);
    const checkDurations = differenceInMinutes(currentStart, new Date());
    if (checkDurations < 0 && checkDurationsWitEndOfDay < 23) {
      return <Box>Please select a valid date</Box>;
    }

    return (
      <Box
        component="div"
        sx={{
          p: 1,
          m: 0,
          height: windowsHeight - 120 || '82vh',
          overflowY: 'auto',
          overflowX: 'hidden',
          position: 'relative',
        }}
        color="primary"
      >
        {isFetching || isFetchingResources ? <PageLoading /> : <BookingCards />}
      </Box>
    );
  }, [isFetchingResources, isFetching, mailboxState, windowsHeight]);

  // Top bar and availabilities
  return (
    <Stack spacing={1}>
      {renderTopBar}
      {renderAvailabilities}
    </Stack>
  );
}
