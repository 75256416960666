import { RegisterActionType } from '../../../constants';
import { RegisterAction } from '../../../actions';

export interface RegisterState {
  isFetching: boolean;
  error?: string;
  message?: string;
}

const initState: RegisterState = {
  isFetching: false,
};

export function register(state = initState, action: RegisterAction): RegisterState {
  switch (action.type) {
    case RegisterActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case RegisterActionType.response:
      return {
        ...state,
        isFetching: false,
        message: action.data,
      };
    case RegisterActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };
    default:
      return state;
  }
}
