import {
  DashboardDesks,
  DashboardCheckedIn,
  ReadDashboardCheckedInVsBookedActionType,
  ReadDashboardAvailableVsTotalDesksActionType,
  DashboardTotals,
  ReadDashboardTotalsActionType,
  DashboardActiveUsers,
  ReadDashboardActiveUsersActionType,
  ReadDashboardPeakVsAverageUtilisationActionType,
  DashboardPeakVsAverage,
} from '../../../constants';
import { ReadDashboardTotalsAction } from '../../../actions';
import { ReadDashboardAvailableVsTotalDesksAction } from '../../../actions/api/dashboard/readDashboardAvailableVsTotalDesks';
import { ReadDashboardCheckedInVsBookedAction } from '../../../actions/api/dashboard/readDashboardCheckedInVsAvailable';
import { ReadDashboardActiveUsersAction } from '../../../actions/api/dashboard/readDashboardActiveUsers';
import { ReadDashboardPeakVsAverageUtilisationAction } from '../../../actions/api/dashboard/readDashboardPeakVsAverageUtilisation';

export interface DashboardState {
  isFetching: boolean;
  error?: string;
  dashboardDesks?: DashboardDesks;
  dashboardCheckedIn?: DashboardCheckedIn;
  dashboardTotals?: DashboardTotals;
  dashboardActiveUsers?: DashboardActiveUsers[];
  dashboardPeakVsAverage?: DashboardPeakVsAverage[];
}

const initState: DashboardState = {
  isFetching: false,
};

export function dashboard(
  state = initState,
  action:
    | ReadDashboardAvailableVsTotalDesksAction
    | ReadDashboardCheckedInVsBookedAction
    | ReadDashboardTotalsAction
    | ReadDashboardActiveUsersAction
    | ReadDashboardPeakVsAverageUtilisationAction,
): DashboardState {
  switch (action.type) {
    case ReadDashboardAvailableVsTotalDesksActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadDashboardAvailableVsTotalDesksActionType.response:
      return {
        ...state,
        isFetching: false,
        dashboardDesks: action.data,
      };
    case ReadDashboardAvailableVsTotalDesksActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    case ReadDashboardCheckedInVsBookedActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadDashboardCheckedInVsBookedActionType.response:
      return {
        ...state,
        isFetching: false,
        dashboardCheckedIn: action.data,
      };
    case ReadDashboardCheckedInVsBookedActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    case ReadDashboardTotalsActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadDashboardTotalsActionType.response:
      return {
        ...state,
        isFetching: false,
        dashboardTotals: { ...state.dashboardTotals, ...action.data },
      };
    case ReadDashboardTotalsActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    case ReadDashboardActiveUsersActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadDashboardActiveUsersActionType.response:
      return {
        ...state,
        isFetching: false,
        dashboardActiveUsers: action.data,
      };
    case ReadDashboardActiveUsersActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    case ReadDashboardPeakVsAverageUtilisationActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadDashboardPeakVsAverageUtilisationActionType.response:
      return {
        ...state,
        isFetching: false,
        dashboardPeakVsAverage: action.data,
      };
    case ReadDashboardPeakVsAverageUtilisationActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    default:
      return state;
  }
}
