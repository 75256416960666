export interface Vehicles {
  registration: string;
  location: string;
  unit: string;
  department: string;
  make: string;
  model: string;
  colour: string;
  odo: number;
  odoDate: Date;
  status: string;
}

export enum ReadVehiclesActionType {
  request = 'read.vehicles.request',
  response = 'read.vehicles.response',
  error = 'read.vehicles.error',
}