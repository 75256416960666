import { QueryState } from '..';
import {
  Tablet,
  ReadTabletActionType,
  ReadTabletsActionType,
  CreateTabletActionType,
  UpdateTabletActionType,
} from '../../../constants';
import { ReadTabletsAction, ReadTabletAction, CreateTabletAction, UpdateTabletAction } from '../../../actions';

export interface TabletState extends QueryState {
  isFetching: boolean;
  tablets: Tablet[];
  tablet: Tablet;
  error?: string;
}

const initState: TabletState = {
  isFetching: false,
  tablets: [],
  tablet: undefined,
  pageInfo: {
    total: 0,
  },
};

export function tablet(
  state = initState,
  action: ReadTabletsAction | ReadTabletAction | CreateTabletAction | UpdateTabletAction,
): TabletState {
  switch (action.type) {
    /** READ Tablets */
    case ReadTabletsActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadTabletsActionType.response:
      return {
        ...state,
        isFetching: false,
        tablets: action.data,
        pageInfo: action.pageInfo,
      };
    case ReadTabletsActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** READ Tablet */
    case ReadTabletActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadTabletActionType.response:
      return {
        ...state,
        isFetching: false,
        tablet: action.data,
      };
    case ReadTabletActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** CREATE */
    case CreateTabletActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case CreateTabletActionType.response:
      return {
        ...state,
        isFetching: false,
        tablets: [action.data, ...state?.tablets],
        pageInfo: { ...state.pageInfo, total: state.pageInfo?.total + 1 },
      };
    case CreateTabletActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** UPDATE */
    case UpdateTabletActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case UpdateTabletActionType.response:
      return {
        ...state,
        isFetching: false,
        tablets: [
          ...state?.tablets.map((tablet) => (tablet.id === action.data.id ? action.data : tablet)),
          action.data,
        ],
      };
    case UpdateTabletActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    default:
      return state;
  }
}
