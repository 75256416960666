export interface AccountAdmin {
  Id?: string;
  Name?: string;
  AccountCode?: string;
  Website?: string;
  Address?: string;
  Suburb?: string;
  City?: string;
  State?: string;
  Country?: string;
  Postcode?: string;
  ContactEmail?: string;
  ContactName?: string;
  ContactPhone?: string;
  Active?: boolean;
  CreatedOnUTC?: string;
  UpdatedOnUTC?: string;
  features?: AccountFeature[];
}

export interface AccountFeature {
  Name: string;
  DisplayName?: string;
  Description?: string;
  IsEnabled: boolean;
  IsHidden: boolean;
  IsReadOnly: boolean;
}

export enum ReadAccountAdminActionType {
  request = 'read.accountadmin.request',
  response = 'read.accountadmin.response',
  error = 'read.accountadmin.error',
}

export enum UpdateAccountActionType {
  request = 'update.account.request',
  response = 'update.account.response',
  error = 'update.account.error',
}
