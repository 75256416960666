import { QueryState } from '../..';
import {
  IMenuResponse,
  CreateCateringMenuActionType,
  ReadCateringMenusActionType,
  ReadCateringMenuActionType,
  UpdateCateringMenuActionType,
} from '../../../../constants';
import {
  CreateCateringMenuAction,
  ReadCateringMenusAction,
  ReadCateringMenuAction,
  UpdateCateringMenuAction,
} from '../../../../actions';

export interface ICateringState extends QueryState {
  isFetching: boolean;
  menus: IMenuResponse[];
  menu: IMenuResponse;
  error?: string;
}

const initState: ICateringState = {
  isFetching: false,
  menus: [],
  menu: undefined,
  pageInfo: {
    total: 0,
  },
};

export function catering(
  state = initState,
  action: CreateCateringMenuAction | ReadCateringMenusAction | ReadCateringMenuAction | UpdateCateringMenuAction,
): ICateringState {
  switch (action.type) {
    /** Create a Menu */
    case CreateCateringMenuActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case CreateCateringMenuActionType.response:
      return {
        ...state,
        isFetching: false,
        menu: action.data,
      };
    case CreateCateringMenuActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Read Menus*/
    case ReadCateringMenusActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadCateringMenusActionType.response:
      return {
        ...state,
        isFetching: false,
        menus: action.data,
        pageInfo: action.pageInfo,
      };
    case ReadCateringMenusActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Read Menu*/
    case ReadCateringMenuActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case ReadCateringMenuActionType.response:
      return {
        ...state,
        isFetching: false,
        menu: action.data,
      };
    case ReadCateringMenuActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    /** Update Menu*/
    case UpdateCateringMenuActionType.request:
      return {
        ...state,
        isFetching: true,
      };
    case UpdateCateringMenuActionType.response:
      return {
        ...state,
        isFetching: false,
        menu: action.data,
      };
    case UpdateCateringMenuActionType.error:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      };

    default:
      return state;
  }
}
