import { AddInConstant, Mailbox } from '../../../constants';
import { IReducerBookingDetailsState } from '../../../contexts/BookingDetails';
import {
  EMeetingRoomService,
  IOutlookAddInServiceTypesResponse,
  IServicesType,
} from '../../../middlewares/nura-client/lib/redux/constants';
import { dateService } from '../../../services';

/** These are for IT & Facilities */
export const getDataServiceTypes = ({
  data,
  service,
  type,
}: {
  data: IOutlookAddInServiceTypesResponse;
  service: EMeetingRoomService;
  type: AddInConstant.EServiceType;
}): IServicesType => {
  const findEquipment = data[`${service.toLowerCase()}`].find((itemResource) => {
    const lowerName = itemResource.name.toLowerCase();
    if (lowerName.includes(type?.toLowerCase())) {
      return itemResource;
    }
    return undefined;
  });
  return findEquipment;
};

interface IBodyHTML {
  id?: string;
  currentRoom: any;
  mailbox: Mailbox.IMailboxItem;
  resource: IOutlookAddInServiceTypesResponse;
  bookingState: IReducerBookingDetailsState;
}
export const createEmilBodyHTML = ({ id, currentRoom, resource, mailbox, bookingState }: IBodyHTML): string => {
  let html = '';
  if (resource && currentRoom && mailbox) {
    //Room Details
    if (mailbox?.start && mailbox?.end && resource?.name) {
      const startDateFormat = dateService.formatDateTime({ date: new Date(mailbox.start), format: 'LLL dd' });
      const startFormat = dateService.formatDateTime({ date: new Date(mailbox.start), format: 'p' });
      const endFormat = dateService.formatDateTime({ date: new Date(mailbox.end), format: 'p' });
      const buildingName = currentRoom?.building?.name;
      const floorName = currentRoom?.floor?.name;

      html += `
          <p><span style="font-size:16px"><strong>${resource?.name}</strong></span></p>
          <p><strong>location : </strong>${buildingName}, ${floorName}</p>
          <p><strong>Time :&nbsp;&nbsp;</strong>${startDateFormat}, from ${startFormat} to ${endFormat}</p>
          <p>&nbsp;</p>
      `;
    }
    //General Info
    if (bookingState.general?.comments || bookingState.general?.notes) {
      html += `
      <p><span style="font-size:16px"><strong>General</strong></span></p>
    `;
    }
    if (bookingState.general?.comments) {
      html += `
      <p style="margin-left:40px"><strong>Comments : </strong>${bookingState.general.comments}</p>
    `;
    }

    if (bookingState.general?.notes) {
      html += `
      <p style="margin-left:40px"><strong>Notes : </strong>${bookingState.general.notes}</p>
    `;
    }
  }

  if (bookingState.it?.equipment.length > 0 || bookingState.it?.notes) {
    html += `<p><span style="font-size:16px"><strong>IT</strong></span></p>`;
  }

  if (bookingState.it?.equipment.length > 0) {
    const itEquipmentText = bookingState.it?.equipment?.map((objIE) => objIE.displayName || objIE.name);
    html += `
    <p style="margin-left:40px"><strong>Equipment : </strong>${itEquipmentText?.join(', ')}</p>
  `;
  }

  if (bookingState.it?.notes) {
    html += `
    <p style="margin-left:40px"><strong>Notes : </strong>${bookingState.it.notes}</p>
  `;
  }

  //Facilities Info
  if (
    bookingState.facilities?.equipment.length > 0 ||
    bookingState.facilities?.noChairs ||
    bookingState.facilities?.noTables ||
    bookingState.facilities?.setup?.value ||
    bookingState.facilities?.cleanDown?.value
  ) {
    html += `
    <p><span style="font-size:16px"><strong>Facilities</strong></span></p>
  `;
  }

  if (bookingState.facilities?.equipment.length > 0) {
    const facilitiesEquipmentText = bookingState.facilities?.equipment?.map((objFE) => objFE.displayName || objFE.name);
    html += `
    <p style="margin-left:40px"><strong>Equipment : </strong>${facilitiesEquipmentText?.join(', ')}</p>
  `;
  }

  if (bookingState.facilities?.noChairs) {
    html += `
    <p style="margin-left:40px"><strong>No. chairs : </strong>${bookingState.facilities.noChairs}</p>
  `;
  }

  if (bookingState.facilities?.noTables) {
    html += `
    <p style="margin-left:40px"><strong>No. tables: </strong>${bookingState.facilities?.noTables}</p>
  `;
  }

  if (bookingState.facilities?.setup?.value) {
    html += `
    <p style="margin-left:40px"><strong>Set up:  </strong>${bookingState.facilities?.setup?.value} Minutes</p>
  `;
  }

  if (bookingState.facilities?.cleanDown?.value) {
    html += `
    <p style="margin-left:40px"><strong>Clean down: </strong>${bookingState.facilities?.cleanDown?.value} Minutes</p>
  `;
  }

  if (bookingState.facilities?.notes) {
    html += `
    <p style="margin-left:40px"><strong>Clean down: </strong>${bookingState.facilities?.notes}</p>
  `;
  }

  if (bookingState.catering?.notes || bookingState.catering?.cateringMenus?.length > 0) {
    html += `
    <p>&nbsp;</p>
    <p><span style="font-size:16px"><strong>Catering</strong></span></p>
  `;
  }

  if (bookingState.catering?.dietaries) {
    html += `
    <p style="margin-left:40px"><strong>Dietaries : </strong>${bookingState.catering?.dietaries}</p>
  `;
  }

  if (bookingState.catering?.costCentre) {
    html += `
    <p style="margin-left:40px"><strong>Cost centre : </strong>${bookingState.catering?.costCentre}</p>
  `;
  }

  if (bookingState.catering?.notes) {
    html += `
    <p style="margin-left:40px"><strong>Notes: </strong>${bookingState.catering?.notes}</p>
  `;
  }

  if (bookingState.catering?.cateringMenus?.length > 0) {
    html += `
    <p>&nbsp;</p>
    <table align="left" border="1" cellpadding="0" cellspacing="0" style="width:552px">
    <tbody>
      <tr>
        <td style="text-align:center" width:190px">Package</td>
        <td style="text-align:center; width:215px">Foods</td>
        <td style="text-align:center; width:46px">Qty</td>
        <td style="text-align:center; width:89px">&nbsp;Delivery</td>
      </tr>
  `;

    bookingState.catering?.cateringMenus?.map((itemCatering) => {
      const getMenu = resource.catering.find((itemMenu) => Number(itemMenu.id) === Number(itemCatering.menu));
      const getPackage = getMenu.packages.find(
        (itemPackage) => Number(itemPackage.id) === Number(itemCatering.package),
      );
      const getFoods = getPackage.foodItems.filter((itemFood) => itemCatering.foodItems.includes(itemFood.id));
      const foodNames = getFoods.map(({ name, displayName }) => displayName || name);
      html += `
      <tr>
        <td style="width:86px">${getPackage.displayName || getPackage.name}</td>
        <td style="width:84px">${foodNames.join(', ')}</td>
        <td style="width:121px">${itemCatering.quantity}</td>
        <td style="width:121px">${itemCatering.deliveredAt}</td>
      </tr>
    `;
      return itemCatering;
    });

    html += `
  </tbody>
</table>
`;
  }

  return html;
};
