import {
  addDays,
  addMonths,
  addWeeks,
  eachDayOfInterval,
  endOfMonth,
  getDay,
  isWeekend,
  setDay,
  startOfMonth,
} from 'date-fns';
import { getDayNumber } from '..';
import { EDayOfWeek, EOnDayBookingRecurrence, EWeekIndex } from '../../middlewares/nura-client/lib/redux/constants';

interface INthDayOfMonth {
  nth: string;
  dayOfWeek: string;
  startDate: Date;
}

export const getNumber = (nth: string): number => {
  switch (nth) {
    case EWeekIndex.FIRST:
      return 1;
    case EWeekIndex.SECOND:
      return 2;
    case EWeekIndex.THIRD:
      return 3;
    case EWeekIndex.FOURTH:
      return 4;
    default:
      return 5;
  }
};

export const getNthDayOfMonth = ({ nth, dayOfWeek, startDate }: INthDayOfMonth): Date => {
  let resultDate = new Date(startDate);
  const dayOfWeekNoSpace = dayOfWeek?.replace(' ', '_')?.toUpperCase();

  const dayNumber = getDayNumber(dayOfWeek);
  const numberOrdinal = getNumber(nth);
  const startThisMonth = startOfMonth(startDate);

  const allDaysOfTheMonth = eachDayOfInterval({
    start: startOfMonth(startDate),
    end: endOfMonth(startDate),
  });

  // (FIRST, SECOND, THIRD, FOURTH)
  if (numberOrdinal < 5) {
    // with  (SUNDAY to SATURDAY)
    if (EDayOfWeek[dayOfWeekNoSpace?.toUpperCase()]) {
      const firstNthDay = setDay(startThisMonth, dayNumber, { weekStartsOn: getDay(startThisMonth) });
      resultDate = addWeeks(firstNthDay, numberOrdinal - 1);
      if (new Date().getTime() > resultDate.getTime()) {
        const nexDayOfStartDate = addDays(startDate, 1);
        return getNthDayOfMonth({ nth, dayOfWeek, startDate: nexDayOfStartDate });
      } else {
        return resultDate;
      }
    }

    // with DAY
    if (EOnDayBookingRecurrence.DAY?.toLowerCase() === dayOfWeek?.toLowerCase()) {
      const startOfThisMonth = startOfMonth(startDate);
      const newDateOfDay = addDays(startOfThisMonth, numberOrdinal - 1);
      resultDate = newDateOfDay;

      if (new Date().getTime() > resultDate.getTime()) {
        const nexMonthDateOfDay = addMonths(resultDate, 1);
        return getNthDayOfMonth({ nth, dayOfWeek, startDate: nexMonthDateOfDay });
      } else {
        return resultDate;
      }
    }
    // WEEKDAY
    if (dayOfWeek?.toLowerCase() === EOnDayBookingRecurrence.WEEKDAY?.toLowerCase()) {
      if (allDaysOfTheMonth?.length > 0) {
        const allBusinessDay = allDaysOfTheMonth.filter((day) => !isWeekend(day));
        const firstBusinessDay = allBusinessDay[0];
        resultDate = addDays(firstBusinessDay, numberOrdinal - 1);
        if (new Date().getTime() > resultDate.getTime()) {
          const nexMonthDateOfLastDay = addMonths(resultDate, 1);
          return getNthDayOfMonth({ nth, dayOfWeek, startDate: nexMonthDateOfLastDay });
        } else {
          return resultDate;
        }
      }
    }

    // WEEKEND_DAY
    if (dayOfWeek?.toLowerCase() === EOnDayBookingRecurrence.WEEKEND_DAY?.toLowerCase()) {
      if (allDaysOfTheMonth?.length > 0 && numberOrdinal > 0) {
        const allWeekendDays = allDaysOfTheMonth.filter((day) => isWeekend(day));
        resultDate = allWeekendDays[numberOrdinal - 1];
        if (new Date().getTime() > resultDate.getTime()) {
          const nexMonthWeekendDay = addMonths(resultDate, 1);
          return getNthDayOfMonth({ nth, dayOfWeek, startDate: nexMonthWeekendDay });
        } else {
          return resultDate;
        }
      }
    }
  }

  // LAST
  if (nth === EWeekIndex.LAST) {
    // WEEKEND_DAY
    if (dayOfWeek?.toLowerCase() === EOnDayBookingRecurrence.WEEKEND_DAY?.toLowerCase()) {
      if (allDaysOfTheMonth?.length > 0) {
        const allWeekendDays = allDaysOfTheMonth.filter((day) => isWeekend(day));
        resultDate = allWeekendDays[allWeekendDays?.length - 1];
        if (new Date().getTime() > resultDate.getTime()) {
          const nexMonthDateOfLastDay = addMonths(resultDate, 1);
          return getNthDayOfMonth({ nth, dayOfWeek, startDate: nexMonthDateOfLastDay });
        } else {
          return resultDate;
        }
      }
    }
    // WEEKDAY
    if (dayOfWeek?.toLowerCase() === EOnDayBookingRecurrence.WEEKDAY?.toLowerCase()) {
      if (allDaysOfTheMonth?.length > 0) {
        const allBusinessDay = allDaysOfTheMonth.filter((day) => !isWeekend(day));
        resultDate = allBusinessDay[allBusinessDay?.length - 1];
        if (new Date().getTime() > resultDate.getTime()) {
          const nexMonthDateOfLastDay = addMonths(resultDate, 1);
          return getNthDayOfMonth({ nth, dayOfWeek, startDate: nexMonthDateOfLastDay });
        } else {
          return resultDate;
        }
      }
    }
    // DAY
    if (dayOfWeek?.toLowerCase() === EOnDayBookingRecurrence.DAY?.toLowerCase()) {
      const lastOfThisMonth = endOfMonth(startDate);
      resultDate = lastOfThisMonth;
      if (new Date().getTime() > resultDate.getTime()) {
        const nexMonthDateOfLastDay = addMonths(resultDate, 1);
        return getNthDayOfMonth({ nth, dayOfWeek, startDate: nexMonthDateOfLastDay });
      } else {
        return resultDate;
      }
    }

    // (SUNDAY to SATURDAY)
    if (!EOnDayBookingRecurrence[dayOfWeekNoSpace]) {
      let newStartDateFormat = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
      let weekDay = newStartDateFormat.getDay();
      newStartDateFormat.setDate(newStartDateFormat.getDate() - (weekDay < dayNumber ? 6 : weekDay - dayNumber));
      resultDate = newStartDateFormat;
      if (new Date().getTime() > resultDate.getTime()) {
        const nexDayOfStartDate = addDays(startDate, 1);
        return getNthDayOfMonth({ nth, dayOfWeek, startDate: nexDayOfStartDate });
      } else {
        return resultDate;
      }
    }
  }

  return resultDate;
};
