import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box } from '@mui/system';
import { Tab, Tabs } from '@mui/material';
import GeneralInfo from '../General';
import ITServices from '../IT/ITServices';
import Facilities from '../Facilities';
import CateringServices from '../Catering';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useBookingDetails } from '../../../contexts/BookingDetails';
import { useMailbox } from '../../../contexts';
import { EMeetingRoomService, IResourcesItem } from '../../../middlewares/nura-client/lib/redux/constants';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { MAX_WIDTH_TAB } from '../../../constants/AddIn';
import TabPanel from './TabPanel';
import { useLocationTyped } from '../../../hooks/useLocationTyped';
import { validateStep } from '../Helpers/formValidation';
import { RoomFeatures, useRoomFeatures } from '../../../hooks/useRoomFeatures';

export default function ServicesTabs(): JSX.Element {
  const location = useLocationTyped();
  const { enqueueSnackbar } = useSnackbar();
  const { state: stateDetails, dispatch: dispatchDetails } = useBookingDetails();
  const { state: stateMailbox } = useMailbox();
  const { hasFeature } = useRoomFeatures(Number(location.state.id));

  const { width: windowsWidth } = useWindowDimensions();

  const [currentServices, setCurrentServices] = useState<IResourcesItem[]>([]);

  useEffect(() => {
    const item: any = location?.state;
    const newItem = item?.services?.map(({ id, name, displayName }) => {
      return {
        id: Number(id),
        name,
        displayName,
      };
    });
    setCurrentServices(
      newItem.sort(function (a, b) {
        return a.id - b.id || a.name.localeCompare(b.name);
      }),
    );
  }, [location?.state]);

  const renderTabs = useMemo(() => {
    const handleChange = (event: SyntheticEvent, newValue: number) => {
      const tabValue = stateDetails.tabValue;
      const { isValid, message: messageValidation } = validateStep({
        tabValue,
        data: { mailboxState: stateMailbox, bookingState: stateDetails },
        options: {
          validateInPersonAttendeesField: hasFeature(RoomFeatures.InPersonAttendees),
        },
      });
      if (!isValid) {
        enqueueSnackbar(messageValidation, { variant: 'error', autoHideDuration: 2000 });
        return;
      }
      dispatchDetails({
        type: 'setTabValue',
        payload: { tabValue: Number(newValue) },
      });
    };
    return (
      <Tabs
        variant={Number(windowsWidth ? windowsWidth : 0) >= MAX_WIDTH_TAB ? 'fullWidth' : 'scrollable'}
        value={stateDetails.tabValue}
        onChange={handleChange}
        aria-label="services tabs"
        sx={{ minHeight: 72, height: 71 }}
        style={{
          overflowX: 'auto',
        }}
      >
        <Tab
          icon={
            stateMailbox.subject || stateDetails.general.notes || stateDetails.general.comments ? (
              <CheckCircleIcon fontSize="small" color="primary" />
            ) : undefined
          }
          iconPosition="start"
          label="General"
          id="tab-general"
          sx={{ textTransform: 'none', fontSize: 16, minHeight: 72, height: 71 }}
          style={{
            overflowX: 'auto',
          }}
          wrapped
        />
        {currentServices.map((service) => {
          let isCompletedCatering = false;
          if (
            service.name.toLowerCase() === EMeetingRoomService.CATERING.toLowerCase() &&
            (stateDetails.catering.cateringMenus?.length > 0 ||
              stateDetails.catering.notes ||
              stateDetails.catering.costCentre)
          ) {
            isCompletedCatering = true;
          }

          let isCompletedFacilities = false;

          if (
            service.name.toLowerCase() === EMeetingRoomService.FACILITIES.toLowerCase() &&
            (stateDetails.facilities.equipment?.length > 0 ||
              stateDetails.facilities.notes ||
              stateDetails.facilities.noChairs > 0 ||
              stateDetails.facilities.noTables > 0)
          ) {
            isCompletedFacilities = true;
          }

          let isCompletedIT = false;

          if (
            service.name.toLowerCase() === EMeetingRoomService.IT.toLowerCase() &&
            (stateDetails.it.equipment?.length > 0 || stateDetails.it.notes)
          ) {
            isCompletedIT = true;
          }
          return (
            <Tab
              key={service.id}
              icon={
                // stateDetails.tabValue > Number(service.id) ||
                isCompletedCatering || isCompletedFacilities || isCompletedIT ? (
                  <CheckCircleIcon fontSize="small" color="primary" sx={{ p: 0, m: 0 }} />
                ) : undefined
              }
              iconPosition="start"
              label={service.displayName}
              id={`tab-${service.id}`}
              sx={{ textTransform: 'none', fontSize: 15, minHeight: 72, height: 71 }}
              wrapped
            />
          );
        })}
      </Tabs>
    );
  }, [currentServices, stateDetails, dispatchDetails, stateMailbox, windowsWidth]);

  const renderPanels = useMemo(() => {
    return (
      <>
        <TabPanel value={stateDetails.tabValue} index={0}>
          <GeneralInfo />
        </TabPanel>
        {currentServices.map((tabPanelService, index) => {
          return (
            <TabPanel key={tabPanelService.id} value={stateDetails.tabValue} index={Number(index + 1)}>
              {tabPanelService.name.toLowerCase() === EMeetingRoomService.IT.toLowerCase() ? <ITServices /> : null}
              {tabPanelService.name.toLowerCase() === EMeetingRoomService.FACILITIES.toLowerCase() ? (
                <Facilities />
              ) : null}
              {tabPanelService.name.toLowerCase() === EMeetingRoomService.CATERING.toLowerCase() ? (
                <CateringServices />
              ) : null}
            </TabPanel>
          );
        })}
      </>
    );
  }, [currentServices, stateDetails.tabValue, stateMailbox?.start, stateMailbox?.end]);

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>{renderTabs}</Box>
      {renderPanels}
    </Box>
  );
}
